export const SET_IS_SMART_PHONE = "SET_IS_SMART_PHONE";
export const SET_SELECTED_CLASSROOM_STUDENT = "SET_SELECTED_CLASSROOM_STUDENT";
export const SET_SELECTED_CLASSROOM = "SET_SELECTED_CLASSROOM";
export const SET_DELIVERY_IMGS = "SET_DELIVERY_IMGS";
export const CLEAR_DELIVERY_IMGS = "CLEAR_DELIVERY_IMGS";
export const SET_DELIVERY_IMGS_NAME = "SET_DELIVERY_IMGS_NAME";
export const SET_USER_NAME = "SET_USER_NAME"
export const SET_USER_PASSWORD = "SET_USER_PASSWORD"
export const SET_USER_LOGIN_TOKEN = "SET_USER_LOGIN_TOKEN"
export const CHANGE_LOGIN_STATUS = "CHANGE_LOGIN_STATUS"
export const SET_STUDENTS_ALL = "SET_STUDENTS_ALL"
export const SET_STAFF_ALL  = "SET_STAFF_ALL"
export const SET_CREATED_STAFF = "SET_CREATED_STAFF"
export const SET_CLASSROOM_ALL  = "SET_CLASSROOM_ALL"
export const SET_GRADE_ALL = "SET_GRADE_ALL"
export const SET_DELIVERY_ITEM =  "SET_DELIVERY_ITEM"
export const SET_ABSENCE_ALL =  "SET_ABSENCE_ALL"
export const SET_AUTH =  "SET_AUTH"
export const SET_LOGIN_USER =  "SET_LOGIN_USER"
export const SET_CREATED_STUDENT = "SET_CREATED_STUDENT"
export const CHANGE_LOGGED_STATUS = "CHANGE_LOGGED_STATUS"
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD"
export const SET_DELIVERY_DETAIL = "SET_DELIVERY_DETAIL"
export const SET_SCHOOL_ITEMS = "SET_SCHOOL_ITEMS"
export const SET_DELETE_TARGETS = "SET_DELETE_TARGETS"
export const SET_DELIVERY_HISTORY_ITEMS = "SET_DELIVERY_HISTORY_ITEMS"
export const SET_GRADE_CLASS_ALL = "SET_GRADE_CLASS_ALL"
export const SET_STUDENT_CSV = 'SET_STUDENT_CSV'
export const SET_NEED_CREATE_SCHOOL = 'SET_NEED_CREATE_SCHOOL'
export const SET_NEED_CREATE_STAFF = 'SET_NEED_CREATE_STAFF'
export const SET_NEED_CREATE_STUDENT = 'SET_NEED_CREATE_STUDENT'
export const SET_SEARCH_YEAR = 'SET_SEARCH_YEAR'
export const SET_SCHEDULE_ITEMS = 'SET_SCHEDULE_ITEMS'
// 送迎バス機能
export const SET_BUS_ALL = "SET_BUS_ALL"
export const SET_CREATED_BUS = "SET_CREATED_BUS"
export const SET_BUS_ROUTE_LIST = "SET_BUS_ROUTE_LIST"
export const SET_BUS_ROUTE_LENGTH = "SET_BUS_ROUTE_LENGTH"
export const SET_BUS_LOG_HISTORY = "SET_BUS_LOG_HISTORY"
export const SET_SELECTED_BUS = "SET_SELECTED_BUS"
export const SET_SELECTED_BUS_ROUTE = "SET_SELECTED_BUS_ROUTE"
export const SET_IS_DRIVING = "SET_IS_DRIVING"
export const SET_RESPONSE_NOTIFICATION_TEAMS_BUS_LOCATION = 'SET_RESPONSE_NOTIFICATION_TEAMS_BUS_LOCATION'
export const SET_RESPONSE_SEND_BUS_LOCATION = 'SET_RESPONSE_SEND_BUS_LOCATION'
export const SET_CURRENT_SCHOOL = 'SET_CURRENT_SCHOOL'
export const SET_EMOJI_OVER_20_ERROR = 'SET_EMOJI_OVER_20_ERROR'
// エディタ機能
export const SET_INPUT_URL = 'SET_INPUT_URL'
/**
 * アンケート機能
 */
export const SET_ENQUETE_DELIVERY_DETAIL = "SET_ENQUETE_DELIVERY_DETAIL";
export const SET_ENQUETE_SETTINGS = "SET_ENQUETE_SETTINGS";
export const SET_ENQUETE_OPNE_INFOS = "SET_ENQUETE_OPNE_INFOS";
export const SET_ENQUETE_OPNE_INFO = "SET_ENQUETE_OPNE_INFO";
export const SET_IS_UN_PUBLISHED = "SET_IS_UN_PUBLISHED";
export const SET_ANALYSIS_ENQUETE_ANSWER = "SET_ANALYSIS_ENQUETE_ANSWER";
export const SET_EXTERNAL_ENQUETE_URL = "SET_EXTERNAL_ENQUETE_URL";
//form editor
export const SET_FORM_EDIT_MODE = "SET_FORM_EDIT_MODE";
export const SET_FORM_TITLE = "SET_FORM_TITLE";
export const SET_FORM_SCHEMA = "SET_FORM_SCHEMA";
export const SET_EDIT_FORM_TYPE = "SET_EDIT_FORM_TYPE";
export const UPDATE_FORM_SCHEMA_ELEMENT = "UPDATE_FORM_SCHEMA_ELEMENT";
export const UPDATE_SURVEY_SCHEMA = "UPDATE_SURVEY_SCHEMA";
export const UPDATE_FORM_SCHEMA_ITEM = "UPDATE_FORM_SCHEMA_ITEM";
export const UPDATE_FORM_SCHEMA_PAGE_HEADER = "UPDATE_FORM_SCHEMA_PAGE_HEADER";
export const UPDATE_FORM_SCHEMA_END_OF_SURVEY_MESSAGE = "UPDATE_FORM_SCHEMA_END_OF_SURVEY_MESSAGE";
export const UPDATE_FORM_SCHEMA_DELIVERY_MESSAGE = "UPDATE_FORM_SCHEMA_DELIVERY_MESSAGE";
export const UPDATE_FORM_SCHEMA_TEAM_LIST = "UPDATE_FORM_SCHEMA_TEAM_LIST";
export const UPDATE_FORM_RESERVATION_EMAIL_LIST = "UPDATE_FORM_RESERVATION_EMAIL_LIST";
export const REMOVE_FORM_SCHEMA_DELIVERY_MESSAGE = "REMOVE_FORM_SCHEMA_DELIVERY_MESSAGE";
export const UPDATE_MEMBER_SCHEMA_LINKED_FORMS = "UPDATE_MEMBER_SCHEMA_LINKED_FORMS";
export const ADD_FORM_SCHEMA_ITEM = "ADD_FORM_SCHEMA_ITEM";
export const ADD_FORM_SCHEMA_SECTION = "ADD_FORM_SCHEMA_SECTION";
export const DELETE_FORM_SCHEMA_ITEM = "DELETE_FORM_SCHEMA_ITEM";
export const TOGGLE_MOVE_SECTION_MODAL = "TOGGLE_MOVE_SECTION_MODAL";
export const TOGGLE_CODE_EDITOR_MODAL = "TOGGLE_CODE_EDITOR_MODAL";
export const DUPLICATE_FORM_SCHEMA_ITEM = "DUPLICATE_FORM_SCHEMA_ITEM";
export const DUPLICATE_FORM_SCHEMA_SECTION = "DUPLICATE_FORM_SCHEMA_SECTION";
export const MERGE_FORM_SCHEMA_SECTION = "MERGE_FORM_SCHEMA_SECTION";
export const DELETE_FORM_SCHEMA_SECTION = "DELETE_FORM_SCHEMA_SECTION";
export const SET_ACTIVE_FORM_SCHEMA_ITEM = "SET_ACTIVE_FORM_SCHEMA_ITEM";
export const SET_ACTIVE_FORM_SCHEMA_SECTION = "SET_ACTIVE_FORM_SCHEMA_SECTION";
export const SET_ACTIVE_FORM_TAB = "SET_ACTIVE_FORM_TAB";
export const SET_LANGUAGE_SETTING = "SET_LANGUAGE_SETTING";
export const INIT_LANGUAGE_SETTING = "INIT_LANGUAGE_SETTING";
export const REMOVE_LANGUAGE_SETTING = "REMOVE_LANGUAGE_SETTING";
export const REMOVE_LANGUAGE_DICTIONARY_SETTING = "REMOVE_LANGUAGE_DICTIONARY_SETTING";
export const SET_BACK_BUTTON_CONDITION = "SET_BACK_BUTTON_CONDITION";
export const UPDATE_VACCINATION_INTERVAL_TYPE = "UPDATE_VACCINATION_INTERVAL_TYPE";

export const SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1 = "SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1";
export const SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1 = "SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1";
export const SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1 = "SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1";
export const SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1 = "SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1";
export const SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1 = "SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1";
export const SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR = "SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR";

// forms mutations
export const SET_FORM_CONFIGS = "SET_FORM_CONFIGS";
export const ADD_FORM_CONFIG = "ADD_FORM_CONFIG";

export const SURVEY_CONFIGS_LIST_TYPE = "SURVEY_CONFIGS_LIST_TYPE";
export const SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE = "SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE";
export const SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID = "SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID";
export const SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR = "SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR";
export const SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR = "SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR";

export const SET_IS_FETCHING_FORM_CONFIGS = "SET_IS_FETCHING_FORM_CONFIGS";
export const SET_FETCH_FORM_CONFIGS_ERROR = "SET_FETCH_FORM_CONFIGS_ERROR";
export const SET_FETCH_FORM_CONFIG_ERROR = "SET_FETCH_FORM_CONFIG_ERROR";
export const INCREASE_FORM_COMPONENT_KEY = "INCREASE_FORM_COMPONENT_KEY";
export const SET_FORM_CONFIG_DETAIL = "SET_FORM_CONFIG_DETAIL";
export const SET_IS_FETCHING_FORM_CONFIG_DETAIL = "SET_IS_FETCHING_FORM_CONFIG_DETAIL";
export const SET_FETCH_FORM_CONFIG_DETAIL_ERROR = "SET_FETCH_FORM_CONFIG_DETAIL_ERROR";

export const SET_IS_REGISTING_SURVEY_CONFIG = "SET_IS_REGISTING_SURVEY_CONFIG";
export const SET_REGISTER_SURVEY_CONFIG_ERROR = "SET_REGISTER_SURVEY_CONFIG_ERROR";
export const SET_REGISTERED_SURVEY_CONFIG_ID = "SET_REGISTERED_SURVEY_CONFIG_ID";

export const SET_IS_UPDATING_SURVEY_CONFIG = "SET_IS_UPDATING_SURVEY_CONFIG";
export const SET_UPDATE_SURVEY_CONFIG_ERROR = "SET_UPDATE_SURVEY_CONFIG_ERROR";

export const SET_IS_REGISTERING_SURVEY_CONFIGS = "SET_IS_REGISTERING_SURVEY_CONFIGS";
export const SET_REGISTER_SURVEY_CONFIGS_ERROR = "SET_REGISTER_SURVEY_CONFIGS_ERROR";

export const SET_IS_DELETING_SURVEY_CONFIGS = "SET_IS_DELETING_SURVEY_CONFIGS";
export const SET_DELETE_SURVEY_CONFIGS_ERROR = "SET_DELETE_SURVEY_CONFIGS_ERROR";

export const UPDATE_SURVEY_CONFIG_IN_STORE = "UPDATE_SURVEY_CONFIG_IN_STORE";
export const REMOVE_SURVEY_CONFIG_IN_STORE = "REMOVE_SURVEY_CONFIG_IN_STORE";

export const SET_IS_EXIST_SURVEY_RESULTS = "SET_IS_EXIST_SURVEY_RESULTS";
export const SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS = "SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS";
export const SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR = "SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR";
export const SET_EDITED_MULTIPLE_ITEM = "SET_EDITED_MULTIPLE_ITEM";

// 欠席連絡機能
export const SET_ABSENCE_FORM_TO_SEND = 'SET_ABSENCE_FORM_TO_SEND'
export const SET_ABSENCE_FORM_ALL = 'SET_ABSENCE_FORM_ALL'
export const SET_ABSENCE_FORM_ALL_LENGTH = 'SET_ABSENCE_FORM_ALL_LENGTH'

//
export const SET_DELIVERY_PDFS = "SET_DELIVERY_PDFS";
export const CLEAR_DELIVERY_PDFS = "CLEAR_DELIVERY_PDFS";
export const SET_DELIVERY_PDFS_NAME = "SET_DELIVERY_PDFS_NAME";

// 欠席連絡機能
export const SET_SCHOOL_LUNCH_FOOD_LIST = 'SET_SCHOOL_LUNCH_FOOD_LIST';
export const SET_SCHOOL_LUNCH_MENU_INFO_LIST = 'SET_SCHOOL_LUNCH_MENU_INFO_LIST';
export const SET_SCHOOL_LUNCH_ALLERGEN_INFO_LIST = 'SET_SCHOOL_LUNCH_ALLERGEN_INFO_LIST';
export const SET_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST = 'SET_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST';