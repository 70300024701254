<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-title class="text-h6">{{ item.title }}</v-card-title>
                <v-card-subtitle class="text-h6">{{ answerNum }}件の回答</v-card-subtitle>
                <v-row cols="12" justify="center">
                    <v-col cols="12">
                        <v-col v-for="(value, index) in item.values" :key="index">
                            <v-card class="text-background-color">
                                <v-card-text>{{ value }}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
  name: "TextAnswerItem",
  props: {
    answerNum: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
  async created() {},
  async mounted() {},
};
</script>

<style scoped>
.text-background-color {
    background-color: #F8F9FA;
}
</style>