/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import Item from "@/model/Form/Item";

export default class SingleChoiceSectionItem extends Item {

  sectionOptions;
  default;

  constructor(type, title, description) {
    super(type, title, description);
  }

  init() {
    this.sectionOptions = [
      {
        option: {
          value: "選択肢 1",
        },
        groupheader: {
          value: null,
        },
      },
    ];
    this.default = null;

    return {
      type: this.type,
      title: this.title,
      description: this.description,
      sectionOptions: this.sectionOptions,
      default: this.default,
      attributes: { isSesshuKaisuu: false },
    };
  }

  static newOptionValue(index) {
    return `選択肢 ${index}`;
  }

  static newSectionOption(index) {
    return {
      option: {
        value: this.newOptionValue(index),
      },
      groupheader: {
        value: null,
      },
    };
  }
}
