<template>
  <div class="contentsArea">
    <v-dialog v-if="invalidDialog" v-model="invalidDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">生徒無効化</span>
        </v-card-title>
        <v-card-text>
          「{{ invalidItem.last_name + invalidItem.first_name }}」さんを無効化します。本当によろしいですか？<br>
          ※この操作は取り消せません。<br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="blue darken-1" text @click="invalidDialog = false, invalidItem = null">
            キャンセル
          </v-btn>
          <v-btn :loading="loading" color="blue darken-1" text @click="invalid(invalidItem)">
            無効化
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog" persistent max-width="600px">
      <div v-if="selected.length < 1">
        <v-card>
          <v-card-title>
            <span class="text-h5">削除処理</span>
          </v-card-title>
          <v-card-text>
            対象の生徒が選択されていません。
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading"  color="blue darken-1" text @click="deleteDialog = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div v-else>
        <v-card>
          <v-card-title>
            <span class="text-h5">一括削除</span>
          </v-card-title>
          <v-card-text>
            選択した職員情報を無効化します。本当によろしいですか？<br>
            ※この操作は取り消せません。<br>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading"  color="blue darken-1" text @click="deleteDialog = false">
              キャンセル
            </v-btn>
            <v-btn :disabled="false" :Loading="loading" color="red white--text" @click="invalidChoice()">
              削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-if="pdfErrDialog" v-model="pdfErrDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">利用案内発行エラー</span>
        </v-card-title>
        <v-card-text>
          <div v-if="pdfErrMsg != null" style="white-space: pre-wrap;">{{ pdfErrMsg }}</div>
          <div v-else>{{ pdfDefaultErrMsg }}</div>
          <div v-if="pdfErrStudent != null && pdfErrStudent != ''" style="white-space: pre-wrap;">{{ pdfErrStudent }}</div>
          ページの再読み込みを行い、最初からやり直してください。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="primary" class="white--text" 
            @click="$router.go({path: $router.currentRoute.path, force: true})"
            >
            ページの再読み込みを行う
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      show-select
      :loading="loading"
      :loading-text="loadingText"
      :no-data-text="noDataText"
      :no-results-text="noResultText"
      v-model="selectStudentList"
      :single-select="false"
      item-key="student_id"
      :headers="headers"
      :items="students"
      :search="search"
      sort-by="school_grade"
      class="elevation-1 paddingHarf"
      checkbox-color="green"
      :footer-props="{ 'items-per-page-options': [30, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
    >
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          style="justify-content: left;"
          v-if="!(item.is_delete == 1)"
          :value="isSelected"
          @input="select($event)"
          color="primary"
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.classroom.school_grade`]="{item}">
        {{ item.classroom.school_grade + ' ' + item.classroom.school_class }}
      </template>
      <template v-slot:[`item.classroom.school_class`]="{}">
      </template>
      <template v-slot:[`item.number`]="{ item }">
        <div>{{ item.number }}</div>
      </template>
      <template v-slot:[`item.first_name`]="{}">
      </template>
      <template v-slot:[`item.last_name`]="{ item }">
        <div>{{ item.last_name + ' ' + item.first_name }}</div>
      </template>
      <template v-slot:[`item.first_name_kana`]="{}">
      </template>
      <template v-slot:[`item.last_name_kana`]="{ item }">
        <div>{{ item.last_name_kana + ' ' + item.first_name_kana }}</div>
      </template>
      <template v-slot:[`item.security_key_count.used_security_key_count`]="{ item }">
        <v-btn outlined :disabled="item.is_delete == 1" color="light-blue darken-1" class="white--text" @click="openSecurityKeyListDialog(item.student_id)">
          {{ item.security_key_count.used_security_key_count }}名利用中
        </v-btn>
      </template>
      <template v-slot:[`item.editStudent`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="green white--text" class="mr-2" @click="editItem(item)">編集</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`item.deleteStudent`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
          </v-col>
        </div>
        <div v-else style="align-content: center;">
          <v-col align="center" class="px-0">
            <v-chip color="grey" text-color="white">
              無効
            </v-chip>
          </v-col>
        </div>
      </template>
      <!-- <template v-slot:[`item`]="{ item }">
        <tr :style="{ backgroundColor: (item.is_delete == 1) ? '#CCC' : 'transparent' }">
          <td>
            <v-checkbox
              v-if="!(item.is_delete == 1)" 
              v-model="selectStudentList"
              :value="item"
              color="primary"
              hide-details
            ></v-checkbox>
          </td>
          <td></td>
          <td>{{ item.classroom.school_grade + ' ' + item.classroom.school_class }}</td>
          <td style="text-align:center;">{{ item.number }}</td>
          <td>{{ item.last_name + ' ' + item.first_name }}</td>
          <td>{{ item.last_name_kana + ' ' + item.first_name_kana }}</td>
          <td></td>
          <td style="text-align:center;">
            <v-btn outlined :disabled="item.is_delete == 1" color="light-blue darken-1" class="white--text" @click="openSecurityKeyListDialog(item.student_id)">
              {{ item.security_key_count.used_security_key_count }}名利用中
            </v-btn>
          </td>
          <td>
            <div v-if="item.is_delete == 0">
              <v-col align="center">
                <v-btn color="green white--text" class="mr-2" @click="editItem(item)">編集</v-btn>
              </v-col>
            </div>
          </td>
          <td>
            <div v-if="item.is_delete == 0">
              <v-col align="center">
                <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
              </v-col>
            </div>
            <div v-else style="align-content: center;">
              <v-col align="center">
                <v-chip color="grey" text-color="white">
                  無効
                </v-chip>
              </v-col>
            </div>
          </td>
        </tr>
      </template> -->
      <template v-slot:top>
        <v-toolbar style="height: 100px;" flat class="titleBar">
          <v-row>
            <v-card-title>生徒一覧</v-card-title>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="primary" class="mb-2 mr-4" outlined @click="reload">
                <v-icon left>
                  mdi-reload
                </v-icon>
                データ更新
              </v-btn>
              <v-btn :disabled="selected.length < 1" :loading="loading" color="primary" class="mb-2 mr-4" outlined
                @click="dialogCsvOpen">
                利用案内発行
              </v-btn>
              <v-btn :loading="loading" color="green" dark class="mb-2" @click="addItem">新規追加</v-btn>
            </v-card-actions>
          </v-row>
        </v-toolbar>
        <v-card-actions>
          
            <v-row>
              <!-- <v-col v-if="(loginUser.authority == 1 || loginUser.authority == 2)">
                <v-select
                  class="mx-6"
                  label="年度選択"
                  v-model="year"
                  :items="yearItems"
                  item-text="category_text"
                  item-value="category_id"
                ></v-select>
              </v-col> -->
              <v-col>
                <v-autocomplete :items="classroomAll" :item-text="item => (item.school_grade + ' ' + item.school_class)"
                  item-value="classroom_id" v-model="classroomFilterValue" clearable hide-details label="クラス" class="pl-3">
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field v-model="studentNameSeiFilterValue" label="氏名（姓）">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="studentNameMeiFilterValue" label="氏名（名）">
                </v-text-field>
              </v-col>
            </v-row>

        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark class="mb-2 mr-4" @click="dialogCsv = true">
            CSVインポート
          </v-btn>
          <v-btn :disabled="selected.length < 1" :loading="loading" color="green" class="mb-2 mr-4" outlined @click="downloadCsv">
            CSVエクスポート
          </v-btn>
          <v-btn :loading="loading" color="red white--text" class="mb-2" @click="openDeleteDialog()">
            削除
          </v-btn>
        </v-card-actions>

        <!-- <v-toolbar flat style="height:120px;" class="titleBar">
          <v-toolbar-title style="width:12%;">生徒一覧</v-toolbar-title>
          <div class="d-flex flex-column" style="width:88%;">
            <div class="d-flex justify-end">
              <div v-if="false">
                <v-btn color="red" outlined class="mb-4 mr-3" :disabled="selected.length < 1"
                  @click="dialogDelete = true">選択項目を削除</v-btn>
              </div>
              <v-btn :disabled="selected.length < 1" :loading="loading" color="primary" class="mb-2 mr-4" outlined
                @click="dialogCsvOpen">
                利用案内発行
              </v-btn>
              <v-btn :loading="loading" color="green" dark class="mb-2" @click="addItem">新規追加</v-btn>
            </div>
          </div>
          <v-spacer></v-spacer> -->

          <v-dialog v-if="securityKeyListDialog" v-model="securityKeyListDialog" persistent max-width="600px"> 
            <v-card>
              <v-card-title>
                <span class="text-h4 font-weight-bold">保護者利用状況</span>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :loading="loading" color="primary" outlined
                    @click="dialogCsvOpen">
                    利用案内発行
                  </v-btn>
                  <v-btn :loading="loading" color="green" dark @click="closeSecurityKeyListDialog">閉じる</v-btn>
                </v-card-actions>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div>
                    <v-card-text>
                      <div v-if="studentsParentNameList.length == 0">
                        現在利用中の保護者はいません。
                      </div>
                      <div v-else>
                        <div v-for="(parent, index) in studentsParentNameList" :key="parent.parent_id">
                          <v-row>
                            <div style="display: grid; place-items: center;">
                              {{ index + 1 }}
                            </div>
                            <v-col style="font-size:large; text-align: center; display: grid; place-items: center;">
                              <v-text-field readonly
                              :value="(parent.last_name + ' ' + parent.first_name)"></v-text-field>
                            </v-col>
                            <v-col style="font-size:large; text-align: center; display: grid; place-items: center;">
                              <v-btn outlined color="red" @click="deleteRelation(parent.parent_id)">削除</v-btn>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-if="dialog" v-model="dialog" persistent :max-width="dialogMaxWidth">
            <v-card>
              <v-form @submit.prevent v-model="isValid">
                <v-card-title>
                  <span class="text-h4 font-weight-bold">生徒情報 {{ formTitle }}</span>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" color="green" dark @click="close" outlined>キャンセル</v-btn>
                    <v-btn :disabled="!isValid" :loading="loading" color="green white--text"
                      @click="save(editedItem)">保存</v-btn>
                  </v-card-actions>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-autocomplete :items="classroomAll"
                          :item-text="item => (item.school_grade + ' ' + item.school_class)" item-value="classroom_id"
                          v-model="editedItem.classroom_id" clearable label="クラス" :rules="[required('クラス')]">
                          <template v-slot:no-data>
                            <div class="px-4">データがありません</div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <div class="d-flex">
                          <v-text-field v-model="editedItem.number" label="出席番号"
                            :rules="[required('出席番号'), halfNumLength2]"></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="editedItem.last_name" label="生徒氏名（姓）"
                          :rules="[required('生徒氏名（姓）'), FullWidth, max_length_10]"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="editedItem.first_name" label="生徒氏名（名）"
                          :rules="[required('生徒氏名（名）'), FullWidth, max_length_10]"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="editedItem.last_name_kana" label="生徒氏名（せい・セイ）"
                          :rules="[required('生徒氏名（せい・セイ）'), FullWidthKana, max_length_20]"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="editedItem.first_name_kana" label="生徒氏名（めい・メイ）"
                          :rules="[required('生徒氏名（めい・メイ）'), FullWidthKana, max_length_20]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  {{ errMsg }}
                </v-card-text>

                <v-card-actions>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogCsv" :max-width="csvDialogMaxWidth">
            <v-card>
              <v-card-title>
                <span class="text-h4 font-weight-bold">CSVインポート</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-btn-toggle dense group>
                    <v-btn color="green" class="my-3" elevation="0" @click="uploadCsv()">CSVをアップロード</v-btn>
                    <div v-if="csvFile" style="display: grid; place-items: center;">{{ csvFile.name }}</div>
                  </v-btn-toggle>
                  {{ csvErrMsg }}
                  <div class="detailPair" style="display: none;">
                    <div class="elem">
                      <div class="upload_zone" style="dis">
                        <input type="file" class="input_file" ref="preview" @change="inputCsv()" accept=".csv" />
                      </div>
                    </div>
                  </div>
                  <div v-if="csvDataErrMsg.length != 0">
                    <v-row v-for="(data, index) in csvDataErrMsg" :key="index">
                      {{ data }}
                    </v-row>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :loading="loading" color="green" dark @click="dialogCsv = false" outlined>キャンセル</v-btn>
                <v-btn :disabled="csvDataErrMsg.length != 0 || !csvFile" :loading="loading" color="green white--text"
                  @click="sendCsv()">保存</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogPDF" v-if="dialogPDF" max-width="600px">
            <v-card>
              <v-card-title>
                利用案内発行
              </v-card-title>
              <v-card-text>
                保護者に配布する学校連絡アプリの利用案内を発行します。<br>
                「開始」ボタンを押して利用案内の発行を開始して下さい。<br>
                ※発行に時間を要する場合があります。
                <div v-if="studentSecurityKeyAllUsed.length != 0" color="red">
                  <br>※保護者数が上限に達している生徒が含まれる為、利用案内を発行できません。<br>
                  <div v-for="(student, index) in convertStudentSecurityKeyAllUsedText()"
                    :key="index" color="black">
                    {{ student }}
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn :disabled="studentSecurityKeyAllUsed.length != 0" :loading="loading"
                  color="primary" outlined @click="downloadPdf()">
                  利用案内発行
                </v-btn>
                <v-btn :loading="loading" color="primary"
                  @click="dialogPDF = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">本当に下記の生徒情報を削除しますか？</v-card-title>
              <v-card-text class="breakArea">
                {{ deleteTargetsName }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">キャンセル</v-btn>
                <v-btn color="blue darken-1" text @click="deleting">削除</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="securityKeyDialog" max-width="600px">
            <v-card>
              <v-card-title class="text-h5">確認</v-card-title>
              <v-card-text class="breakArea">
                再発行すると、セキュリティキーを利用中の保護者は生徒情報にアクセスできなくなります。<br>
                本当に再発行してもよろしいですか？
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined elevation="0" :loading="loading"
                  @click="closeSecurityKeyDialog">キャンセル</v-btn>
                <v-btn color="red" outlined :loading="loading" @click="updateKey()">再発行</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <!-- </v-toolbar> -->
        <!-- <v-container>
          <v-form @submit.prevent>
            <v-row>
              <v-col>
                <v-autocomplete :items="classroomAll" :item-text="item => (item.school_grade + ' ' + item.school_class)"
                  item-value="classroom_id" v-model="classroomFilterValue" clearable hide-details label="クラス">
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field v-model="studentNameSeiFilterValue" label="氏名（姓）">
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="studentNameMeiFilterValue" label="氏名（名）">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container> -->
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
      </template>
      <template v-slot:no-data>
        データがありません
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn :disabled="selected.length < 1" :loading="loading" color="primary" class="mb-2 mr-4" outlined
        @click="dialogCsvOpen">
        利用案内発行
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="green" dark class="mb-2 mr-4" @click="dialogCsv = true">
        CSVインポート
      </v-btn>
      <v-btn :disabled="selected.length < 1" :loading="loading" color="green" class="mb-2 mr-4" outlined @click="downloadCsv">
        CSVエクスポート
      </v-btn>
      <v-btn :loading="loading" color="red white--text" class="mb-2" @click="openDeleteDialog()">
        削除
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ (loadingDialogText == null || loadingDialogText == '' || loadingDialogText == undefined)  ? '読み込んでいます' : loadingDialogText }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
          {{ (allDownloadingLength != '' && allDownloadingLength != null && allDownloadingLength != undefined)
          && (currentDownloadingClass != '' && currentDownloadingClass != null && currentDownloadingClass != undefined)
          ? currentDownloadingClass + allDownloadingLength : '' }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Papa from 'papaparse';
import moment from 'moment';
import { saveAs } from "file-saver";
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_STUDENTS_ALL,
  GET_STUDENTS_ALL_LENGTH,
  CREATE_STUDENT,
  INVALID_STUDENT,
  INVALID_MULTI_STUDENTS,
  UPDATE_STUDENT,
  DELETE_STUDENTS,
  SEND_STUDENT_CSV,
  UPLOAD_MULTI_STUDENT,
  GET_CLASSROOM_ALL,
  // GET_STUDENT_KEY,
  // UPDATE_STUDENT_SECURITY_KEY,
  // STUDENT_PDF_ISSUE,
  // CREATE_STUDENT_SECURITY_KEY,
  GET_PARENTS_NAME,
  CREATE_STUDENT_PDF,
  DELETE_STUDENTS_PARENT,
} from "@/store/action-types";
import {
  SET_CREATED_STUDENT,
  SET_DELETE_TARGETS,
  SET_STUDENT_CSV,
  // SET_SEARCH_YEAR
} from "@/store/mutation-types";
import {
  // STUDENT_MANAGE_HEADER,
  CREATE,
  STUDENT_TEMPLATE,
  EDIT,
  CREATE_TITLE,
  EDIT_TITLE,
  LOADING_TEXT,
  REQUIRED_ERROR_MSG,
  MAX_LENGTH_30_ERROR_MSG,
  MAX_LENGTH_128_ERROR_MSG,
  MAX_LENGTH_319_ERROR_MSG,
  FULL_WIDTH_10_ERROR_MSG,
  FULL_WIDTH_20_KANA_ERROR_MSG,
  HALF_NUM_AND_MAX_LENGTH_2,
  NO_DATA_TEXT,
  NO_RESULT_TEXT
} from "@/constants";
import Encoding from 'encoding-japanese';
export default {
  name: "StudentManage",
  data: () => ({
    loadingDialogText: null,
    allDownloadingLength: '',
    currentDownloadingClass: '',
    selectStudentList: [],
    pdfDefaultErrMsg: "不明なエラーが発生しました",
    pdfErrDialog : false,
    pdfErrMsg : null,
    pdfErrStudent: '',
    deleteSecurityKeyItem: {},
    securityKeyDialog: false,
    securityKeyListDialog: false,
    studentSecurityKeyAllUsed: [],
    isValid: false,
    csvFile: null,
    dialogPDF: false,
    csvData: [],
    csvDataErrMsg: [],
    csvHeaders: ["姓", "姓（かな・カナ）", "名", "名（かな・カナ）", "学年", "クラス", "出席番号"],
    invalidItem: null,
    invalidDialog: false,
    deleteDialog: false,
    // year :  moment().subtract(3, 'months').year(),
    showSearchBox: false,
    dialog: false,
    dialogDelete: false,
    dialogCsv: false,
    search: "",
    searchKey: "",
    selected: [],
    // headers: STUDENT_MANAGE_HEADER,
    students: [],
    itemTemplate: STUDENT_TEMPLATE,
    editedItem: STUDENT_TEMPLATE,
    grades: [],
    classes: [],
    defaultItem: STUDENT_TEMPLATE,
    createOrEdit: CREATE,
    classroomAllLocal: [],
    studentCsv: null,
    errMsg: null,
    csvErrMsg: null,
    classroomFilterValue: '',
    studentNameSeiFilterValue: '',
    studentNameMeiFilterValue: '',
    SearchSchoolGrade: '',
    SearchSchoolClass: '',
    editItemsClassroom_id: '',
    studentSecurityKeyList: [],
    studentsParentNameList: [],
    loadingText: LOADING_TEXT,
    loading: false,
    index: [],
    noDataText: NO_DATA_TEXT,
    noResultText: NO_RESULT_TEXT,
    securityKeyDialogStudent: null,
    halfNumLength2: value => /^\d{1,2}$/.test(value) || HALF_NUM_AND_MAX_LENGTH_2,
    FullWidth: value => /* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(value) || FULL_WIDTH_10_ERROR_MSG,
    FullWidthKana: value => /^[ぁ-んァ-ヶー]+$/.test(value) || FULL_WIDTH_20_KANA_ERROR_MSG,
    max_length_10: value => value.length <= 10 || FULL_WIDTH_10_ERROR_MSG,
    max_length_20: value => value.length <= 20 || FULL_WIDTH_20_KANA_ERROR_MSG,
    max_length_30: value => value.length <= 30 || MAX_LENGTH_30_ERROR_MSG,
    max_length_128: value => value.length <= 128 || MAX_LENGTH_128_ERROR_MSG,
    max_length_319: value => value.length <= 319 || MAX_LENGTH_319_ERROR_MSG,
    mailaddress: value => /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value) || "メールアドレス" + REQUIRED_ERROR_MSG,
  }),
  watch: {
    selectStudentList() {
      this.selected = this.selectStudentList.filter(item => item.is_delete === 0);
    },
    async year() {
      this.loading = true
      await this.reload()
      this.classroomFilterValue = null
      this.loading = false
    },
    classroomFilterValue(classroom_id) {
      if (classroom_id != undefined && classroom_id != null) {
        let targetClassroom = this.classroomAll.find(classroom => classroom.classroom_id === classroom_id);
        this.SearchSchoolGrade = targetClassroom.school_grade;
        this.SearchSchoolClass = targetClassroom.school_class;
      } else {
        this.SearchSchoolGrade = null;
        this.SearchSchoolClass = null;
      }
    },
    editItemsClassroom_id(classroom_id) {
      if (classroom_id) {
        let targetClassroom = this.classroomAll.find(classroom => classroom.classroom_id === classroom_id);
        this.editedItem.classroom.school_grade = targetClassroom.school_grade;
        this.editedItem.classroom.school_class = targetClassroom.school_class;
      }
    },
    classroomAll(val) {
      this.classroomAllLocal = [...val];
      // this.groupByGrade();
      // this.groupByClass();
    },
    studentsAll: {
      handler: function () {
        this.students = [...this.studentsAll].filter(student => !student.is_delete);
      },
      deep: true
    },
    dialog(v) {
      if (v === false) {
        this.editedItem = { ...this.itemTemplate };
      }
      this.errMsg = null;
      this.studentSecurityKeyList = [];
    },
    dialogCsv() {
      this.csvFile = null;
      this.csvDataErrMsg = [];
      this.csvErrMsg = null;
      this.studentCsv = null;
      if (this.$refs.preview) {
        this.$refs.preview.value = null;
      }
    },
  },
  computed: {
    ...mapState({
      loginUser: state => state.loginUser,
      studentsAll: state => state.studentsAll,
      classroomAll: state => state.classroomAll,
      createdStudent: state => state.createdStudent,
      year: state => state.fiscalYear,
    }),
    headers() {
      return [
        { text: "", value: "classroom.school_class", filter: this.SearchClassFilter, sortable: false, width: "0" },
        { text: "クラス", value: "classroom.school_grade", filter: this.SearchGradeFilter, width: "10%" },
        { text: "出席番号", value: "number", width: "10%" },
        { text: "氏名", value: "last_name", width: "15%", filter: this.studentNameSeiFilter },
        { text: "氏名(かな・カナ)", value: "last_name_kana", width: "15%" },
        { text: "", value: "first_name", width: "0%", filter: this.studentNameMeiFilter, sortable: false },
        { text: "保護者利用状況", value: "security_key_count.used_security_key_count", width: "20%"},
        { text: "登録日時", value: "created_at", width: "20%"},
        // { text: "氏名(メイ)", value: "first_name_kana", width: "15%" },
        { value: "editStudent", sortable: false , width: '100px'},
        { value: "deleteStudent", sortable: false, width: '100px'},
      ]
    },
    // customFilters() {
    //   return (item) => {
    //     console.log('item', item)
    //     const studentNameSeiFilterValue = this.studentNameSeiFilterValue.toLowerCase();
    //     const last_name = item.last_name.toLowerCase();
    //     // const last_name_kana = item.last_name_kana.toLowerCase();
    //     return last_name.includes(studentNameSeiFilterValue);
    //     // return (
    //     //   last_name.includes(studentNameSeiFilterValue) || last_name_kana.includes(studentNameSeiFilterValue)
    //     // );
    //   }
    // },
    formTitle() {
      return this.createOrEdit === CREATE ? CREATE_TITLE : EDIT_TITLE;
    },
    csvDialogMaxWidth() {
      return "1100px";
    },
    dialogMaxWidth() {
      return "1600px";
    },
    deleteTargetsName() {
      let names = "";
      for (let i = 0; this.selected.length > i; i++) {
        names = names + this.selected[i].last_name + this.selected[i].first_name + "\n";
      }
      return names;
    },
    // securityKeyVif() {
    //   return this.createOrEdit === CREATE ? false : true;
    // }
  },
  async created() {
    this.createStudentList()
  },
  mounted() {
    // 全選択のラベル追加
    const elem = document.querySelector("tr i.v-icon.notranslate.mdi.mdi-checkbox-blank-outline.theme--light");
    if (elem) {
      const div = document.createElement('div');
      const textNode = document.createTextNode('全て');
      div.appendChild(textNode);
      div.style.fontSize = "12px";
      div.style.fontStyle = "normal";
      elem.appendChild(div);
    }
  },
  methods: {
    async deleteRelation(parent_id){
      this.loading = true;
      await this.deleteStudentsParent({'student_id' : this.securityKeyDialogStudent, 'parent_id' : parent_id})
      await this.openSecurityKeyListDialog(this.securityKeyDialogStudent)
      this.selectStudentList = []
      this.selected = []
      this.loading = true;
      this.securityKeyListDialog = false;
      await this.reload()
    },
    async reload(){
      this.loading = true;
      this.students = []
      await this.getStudentsAll();
      this.students = [...this.studentsAll].filter(student => !student.is_delete);

      await this.getClassroomAll()
      this.loading = false;
    },
    async openSecurityKeyListDialog(student_id) {
      this.loading = true;
      const data = await this.getParentsName(student_id)
      if (String(data.statusCode) == "200") {

        this.studentsParentNameList = data.body
      }
      this.selectStudentList = [this.students.find(obj => obj.student_id === student_id)];
      // const security_key_list = await this.getStudentKey(student_id)
      // this.makeStudentSecurityKey(security_key_list)
      this.securityKeyDialogStudent = student_id
      this.securityKeyListDialog = true;
      this.loading = false;
    },
    closeSecurityKeyListDialog() {
      this.loading = true;
      this.editedItem = { ...this.itemTemplate };
      this.securityKeyListDialog = false;
      this.loading = false;
    },
    openSecurityKeyDialog(security_key_id, student_id) {
      this.deleteSecurityKeyItem = {
        securityKeyId: security_key_id,
        studentId: student_id
      }
      this.securityKeyDialog = true;
    },
    closeSecurityKeyDialog() {
      this.deleteSecurityKeyItem = {}
      this.securityKeyDialog = false;
    },
    async dialogCsvOpen() {
      this.loading = true;
      await this.isStudentSecurityKeyAllUsed()
      this.dialogPDF = true
      this.loading = false;
    },
    convertStudentSecurityKeyAllUsedText() {
      let result = []
      for (let i = 0; this.studentSecurityKeyAllUsed.length > i; i++) {
        let student = this.studentSecurityKeyAllUsed[i]
        result.push(
          student.classroom.school_grade + ' ' + student.classroom.school_class + ' ' + student.number + '番 ' + student.last_name + student.first_name
        )
      }
      return result
    },
    isStudentSecurityKeyAllUsed() {
      this.studentSecurityKeyAllUsed = []
      for (let i = 0; this.selected.length > i; i++) {
        // チェックが入っている生徒のセキュリティキー総数と使用済み数の差が0なら追加する
        if (Number(this.selected[i].security_key_count.all_security_key_count) - Number(this.selected[i].security_key_count.used_security_key_count) == 0) {
          this.studentSecurityKeyAllUsed.push(this.selected[i])
        }
      }
    },
    async downloadPdf() {
      this.loading = true

      let createdList = []
      let resList = []
      let student_id_list = []
      for(let s = 0; s < this.selected.length; s++) {
        // 削除フラグが立っている場合はスルーする
        if(Number(this.selected[s].is_delete) == Number(0)){
          // 今回の生徒の学級ID
          let cId = this.selected[s].classroom_id
          // もし、完了した学級リストに今回の生徒の学級IDがあるなら
          if(!createdList.includes(cId)){
            // その生徒と同じ学級IDを持つ生徒のLISTを作り
            const onaziClassStudentsList = this.selected.filter(student => student.classroom_id == cId)
            // その生徒リストのid_listを作る
            let id_list_local = []
            for (let i = 0; onaziClassStudentsList.length > i; i++) {
              id_list_local.push(onaziClassStudentsList[i].student_id)
            }
            student_id_list.push(id_list_local)
            // そのid_listをapiに投げる
            createdList.push(cId)
          }
        }
      }
      this.loadingDialogText = '利用案内を発行中です'
      this.allDownloadingLength = '組 / ' + String(student_id_list.length) + '組'
      for (let s = 0; s < student_id_list.length; s++) {
        this.currentDownloadingClass = s+1
        const response = await this.createStudentPdf(student_id_list[s])
        resList.push(response)
      }
      this.loadingDialogText = null
      this.allDownloadingLength = ''
      this.currentDownloadingClass = ''
      // エラーになっているresponseのリストを作る
      let errResList = []
      errResList = resList.filter(obj => obj.statusCode != 200)
      // もし、エラーになっているレスポンスが１つでもあり
      if(errResList.length != 0){
        console.log('err ari')
        // そのエラーがすべて利用案内発行できない生徒がいるエラーだった場合
        let maxUsedErrList = []
        maxUsedErrList = errResList.filter(obj => obj.body == 'max_used_students')
        if(errResList.length == maxUsedErrList.length){
          // エラーが出ている生徒の一覧を作る
          this.pdfErrMsg = "保護者数が上限に達している生徒が含まれています。\n以下の生徒でエラーが発生しました。"
          for (let num = 0; num < maxUsedErrList.length; num++) {
            for(let i = 0; i < maxUsedErrList[num].max_used_students.length; i++){
              this.pdfErrStudent += maxUsedErrList[num].max_used_students[i] + '番\n'
            }
          }
        } else {
          this.pdfErrMsg = this.pdfDefaultErrMsg
        }
        this.pdfErrDialog = true; 
        this.loading = false;
      } else { //エラーになっているレスポンスがない場合
        for(let index = 0; index < resList.length;index++) {
          // // URLを全部踏んでファイルダウンロード
          // const link = document.createElement('a')
          // link.href = resList[index].body
  
          // link.click(); // リンクをクリックしてダウンロード開始

          // urlからファイル名を取得
          const fileName = resList[index].body.replace(/.*\//, '')
          console.log('fileName', fileName)
          // ファイルダウンロード
          await this.downloadItem({ url: resList[index].body, label: fileName})

          await new Promise(resolve => setTimeout(resolve, 500));
        }
        this.dialogPDF = false;
        this.loading = false
      }
    },
    async downloadItem ({ url, label }) {
      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    openInvalidDialog(item) {
      this.invalidItem = item;
      this.invalidDialog = true;
    },
    openDeleteDialog() {
      this.deleteDialog = true;
    },
    formatToYYYYMMDDHHmmss(value) {
      return moment(value).subtract(9, 'hours').format('YYYY-MM-DD HH:mm:ss')
    },
    formatToYYYYMMDD(value) {
      return moment(value).subtract(9, 'hours').format('YYYY-MM-DD')
    },
    // studentNameSeiFilter(item) {
    //   const studentNameSeiFilterValue = this.studentNameSeiFilterValue.toLowerCase();
    //   const last_name = item.last_name.toLowerCase();
    //   const last_name_kana = item.last_name_kana.toLowerCase();
    //   return (
    //     last_name.includes(studentNameSeiFilterValue) || last_name_kana.includes(studentNameSeiFilterValue)
    //   );
    // },
    async createStudentList() {
      this.loading = true;

      // Studentsの人数を取得する
      const getStudentsAllLengthRes = await this.getStudentsAllLength() 
      
      let studentsAllLength = 0
      if (getStudentsAllLengthRes.statusCode){
        if (getStudentsAllLengthRes.statusCode == 200) {
          studentsAllLength = getStudentsAllLengthRes.body 
        }
      }
      // studentsの人数が一致しない場合のみ取得処理を実行
      if (this.studentsAll.length != studentsAllLength){
        await this.getStudentsAll();
      }
      
      this.students = [...this.studentsAll].filter(student => !student.is_delete);//削除済みユーザーは取得しないように変更しました。

      await this.getClassroomAll()
      this.loading = false;
    },
    studentNameSeiFilter(value) {
      if (!this.studentNameSeiFilterValue) {
        return true
      }
      return value.includes(this.studentNameSeiFilterValue);
    },
    studentNameMeiFilter(value) {
      if (!this.studentNameMeiFilterValue) {
        return true
      }
      return value.includes(this.studentNameMeiFilterValue);
    },
    SearchGradeFilter(value) {
      if (!this.SearchSchoolGrade) {
        return true
      }
      return value.includes(this.SearchSchoolGrade)
    },
    SearchClassFilter(value) {
      if (!this.SearchSchoolClass) {
        return true
      }
      return value.includes(this.SearchSchoolClass);
    },
    ...mapActions({
      getStudentsAll: GET_STUDENTS_ALL,
      getStudentsAllLength: GET_STUDENTS_ALL_LENGTH,
      createStudent: CREATE_STUDENT,
      invalidStudent: INVALID_STUDENT,
      invalidMultiStudents: INVALID_MULTI_STUDENTS,
      updateStudent: UPDATE_STUDENT,
      deleteStudents: DELETE_STUDENTS,
      sendStudentCSV: SEND_STUDENT_CSV,
      uploadMultiStudent: UPLOAD_MULTI_STUDENT,
      getClassroomAll: GET_CLASSROOM_ALL,
      // getStudentKey: GET_STUDENT_KEY,
      getParentsName: GET_PARENTS_NAME,
      // updateStudentSecurityKey: UPDATE_STUDENT_SECURITY_KEY,
      // studenPdfIssue: STUDENT_PDF_ISSUE,
      // createStudentSecurityKey: CREATE_STUDENT_SECURITY_KEY,
      createStudentPdf: CREATE_STUDENT_PDF,
      deleteStudentsParent: DELETE_STUDENTS_PARENT,
    }),
    ...mapMutations({
      setCreateStudent: SET_CREATED_STUDENT,
      setDeleteTargets: SET_DELETE_TARGETS,
      setStudentCSV: SET_STUDENT_CSV,
      // setSearchYear:SET_SEARCH_YEAR
    }),
    uploadCsv() {
      this.$refs.preview.click();
    },
    async inputCsv() {
      this.csvDataErrMsg = [];
      this.csvFile = this.$refs.preview.files[0];
      const file = this.$refs.preview.files[0];
      const reader = new FileReader();
      reader.readAsText(file, 'Shift-JIS'); // Shift-JIS形式で読み込む
      reader.onload = async () => {
        const csv = reader.result;
        const results = Papa.parse(csv, { header: true });
        const products = results.data;
        this.csvData = this.csvDataKeyChange(products);

        await this.convertGradeIdType(); // Excelで保存した際に'01'が数値の1に変換されてしまう事象に対応
        await this.convertClassIdType(); // Excelで保存した際に'01'が数値の1に変換されてしまう事象に対応

        this.csvDataValidateCheck();
      };
    },
    convertGradeIdType() {
      // (数値,文字列,0埋め文字列)を0埋め文字列に変換
      for (let i = 0; i < this.csvData.length; i++) {
        const grade = this.csvData[i].grade;
        let gradeId = '';
        // if (typeof grade === 'number') {
        if(grade != undefined && grade != '' && grade != null){
          gradeId = String(grade).padStart(2, '0');
        }
        // } else {
          // gradeId = grade.padStart(2, '0');
        // }
        this.csvData[i].grade = gradeId;
      }
    },
    convertClassIdType() {
      // (数値,文字列,0埋め文字列)を0埋め文字列に変換
      for (let i = 0; i < this.csvData.length; i++) {
        const classroom = this.csvData[i].classroom;
        let classId = '';
        // if (typeof classroom === 'number') {
        if(classroom != undefined && classroom != '' && classroom != null){
          classId = String(classroom).padStart(2, '0');
        }
        // } else {
          // classId = classroom.padStart(2, '0');
        // }
        this.csvData[i].classroom = classId;
      }
    },
    csvDataValidateCheck() {
      let deleteRowIndexList = []
      for (let i = 0; i < this.csvData.length; i++) {
        const data = this.csvData[i]
        if (this.deleteNullOrEmptyRow(data)) {
          deleteRowIndexList.push(i)
        } else {
          this.checkLastNameValidate(data, i + 2)
          this.checkLastNameKanaValidate(data, i + 2)
          this.checkFirstNameValidate(data, i + 2)
          this.checkFirstNameKanaValidate(data, i + 2)
          this.checkGradeValidate(data, i + 2)
          this.checkClassValidate(data, i + 2)
          this.checkNumberValidate(data, i + 2)
          this.hasClassInClassroomAll(data, i + 2)
          this.sameClassroomNumberInfile(data, i + 2)
        }
      }
      for (let i = 0; i < deleteRowIndexList.length; i++) {
        this.csvData.splice(deleteRowIndexList[i] - i, 1)
      }
    },
    sameClassroomNumberInfile(data, n) {
      const posErrMsg = "行目でエラー : ";
      const sameClassroomNumberInFile = "ファイル内にクラスと出席番号が同一の生徒が居ます。"

      const grade = data.grade
      const classroom = data.classroom
      const number = data.number
      for (let i = (n - 1); i < this.csvData.length; i++) {
        if (this.csvData[i].grade === grade && this.csvData[i].classroom === classroom && this.csvData[i].number === number) {
          this.csvDataErrMsg.push(n + posErrMsg + sameClassroomNumberInFile)
          return
        }
      }
    },
    hasClassInClassroomAll(data, n) {
      const posErrMsg = "行目でエラー : ";
      const notHasClassErrMsg = "存在しないか、権限がないクラスです。"
      const sameClassroomNumber = "既にクラスと出席番号が同一の生徒が居ます。"

      const grade = data.grade
      const classroom = data.classroom

      for (let i = 0; i < this.classroomAll.length; i++) {
        const obj = this.classroomAll[i];
        if (obj.grade_position === grade && obj.class_position === classroom) {
          const classroom_id = obj.classroom_id
          let errFlag = 0
          for (let i = 0; i < this.studentsAll.length; i++) {
            if (Number(this.studentsAll[i].is_delete) === Number(0)) {
              if (this.studentsAll[i].number == data.number && this.studentsAll[i].classroom_id == classroom_id) {
                errFlag = 1
              }
            }
          }
          if (errFlag != 0) {
            this.csvDataErrMsg.push(n + posErrMsg + sameClassroomNumber)
          }
          return
        }
      }
      this.csvDataErrMsg.push(n + posErrMsg + notHasClassErrMsg)
    },
    checkNumberValidate(data, i) {
      // 姓（かな・）のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "出席番号" + REQUIRED_ERROR_MSG
      const lengthErrMsg = "出席番号は半角数字2文字以内で入力してください。"
      const halfNumLengthErrMsg = "出席番号は半角数字2文字以内で入力してください。"

      const validateTarget = data.number;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      } else if (validateTarget !== null && validateTarget.length > 2) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)

      } else if (!(/^\d{1,2}$/.test(validateTarget))) {
        this.csvDataErrMsg.push(i + posErrMsg + halfNumLengthErrMsg)
      }
    },
    checkClassValidate(data, i) {
      // 姓（かな・カナ）のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "クラス" + REQUIRED_ERROR_MSG
      // const lengthErrMsg = "クラスは全角10文字以内で入力してください。"
      // const notHasClassErrMsg = "存在しないか、権限がないクラスです。"
      const halfNumLengthErrMsg = "クラスは半角数字2文字以内で入力してください"

      const validateTarget = data.classroom;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      // } else if (validateTarget !== null && validateTarget.length > 2) {
      //   this.csvDataErrMsg.push(i + posErrMsg + halfNumLengthErrMsg)

        // } else if (!this.hasClassInClassroomAll(data)) {
        //   this.csvDataErrMsg.push(i + posErrMsg + notHasClassErrMsg)
      } else if (!(/^\d{1,2}$/.test(validateTarget))) {
        this.csvDataErrMsg.push(i + posErrMsg + halfNumLengthErrMsg)
      }
    },
    checkGradeValidate(data, i) {
      // 姓（かな・カナ）のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "学年" + REQUIRED_ERROR_MSG
      // const lengthErrMsg = "学年は10文字以内で入力してください。"
      // const halfNumLengthErrMsg = "学年は半角数字1文字以内で入力してください"

      const validateTarget = data.grade;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      // } else if (validateTarget !== null && validateTarget.length > 1) {
      //   this.csvDataErrMsg.push(i + posErrMsg + halfNumLengthErrMsg)

      // } else if (!(/^\d{1}$/.test(validateTarget))) {
      //   this.csvDataErrMsg.push(i + posErrMsg + halfNumLengthErrMsg)
      }
    },
    checkFirstNameKanaValidate(data, i) {
      // 姓（かな・カナ）のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "名（かな・カナ）" + REQUIRED_ERROR_MSG
      const lengthErrMsg = "名（かな・カナ）は全角ひらがな・カタカナ20文字以内で入力してください。"
      //const fullWidthErrMsg = "名（かな・カナ）は全角ひらがな・カタカナ20文字以内で入力してください。"

      const validateTarget = data.first_name_kana;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      } else if (validateTarget !== null && validateTarget.length > 20) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)

      } else if (!/^[ぁ-んァ-ヶー]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)
      }
    },
    checkFirstNameValidate(data, i) {
      // 姓のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "名" + REQUIRED_ERROR_MSG
      const lengthErrMsg = "名は全角10文字以内で入力してください。"
      //const fullWidthErrMsg = "名は全角で入力してください。"

      const validateTarget = data.first_name;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      } else if (validateTarget !== null && validateTarget.length > 10) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)

      } else if (!/* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)
      }
    },
    checkLastNameKanaValidate(data, i) {
      // 姓（かな・カナ）のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "姓（かな・カナ）" + REQUIRED_ERROR_MSG
      const lengthErrMsg = "姓（かな・カナ）は全角ひらがな・カタカナ20文字以内で入力してください。"
      const fullWidthErrMsg = "姓（かな・カナ）は全角ひらがな・カタカナ20文字以内で入力してください。"

      const validateTarget = data.last_name_kana;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      } else if (validateTarget !== null && validateTarget.length > 20) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)

      } else if (!/^[ぁ-んァ-ヶー]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + fullWidthErrMsg)
      }
    },
    checkLastNameValidate(data, i) {
      // 姓のvalidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "姓" + REQUIRED_ERROR_MSG
      const lengthErrMsg = "姓は全角10文字以内で入力してください。"
      const fullWidthErrMsg = "姓は全角10文字以内で入力してください。"

      const validateTarget = data.last_name;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg)

      } else if (validateTarget !== null && validateTarget.length > 10) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg)

      } else if (!/* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + fullWidthErrMsg)
      }
    },
    deleteNullOrEmptyRow(csvData) {
      if (
        (csvData.last_name == "" || !csvData.last_name) &&
        (csvData.last_name_kana == "" || !csvData.last_name_kana) &&
        (csvData.first_name == "" || !csvData.first_name) &&
        (csvData.first_name_kana == "" || !csvData.first_name_kana) &&
        (csvData.grade == "" || !csvData.grade) &&
        (csvData.classroom == "" || !csvData.classroom) &&
        (csvData.number == "" || !csvData.number)
      ) {
        return true
      }
      return false
    },
    csvDataKeyChange(csvData) {
      // csvDataのキー名を日本語から英語に変える
      for (let i = 0; i < csvData.length; i++) {
        csvData[i].last_name = csvData[i]['姓']
        delete csvData[i]['姓'];

        csvData[i].last_name_kana = csvData[i]['姓（かな・カナ）']
        delete csvData[i]['姓（かな・カナ）']

        csvData[i].first_name = csvData[i]['名']
        delete csvData[i]['名']

        csvData[i].first_name_kana = csvData[i]['名（かな・カナ）']
        delete csvData[i]['名（かな・カナ）']

        csvData[i].grade = csvData[i]['学年']
        delete csvData[i]['学年']

        csvData[i].classroom = csvData[i]['クラス']
        delete csvData[i]['クラス']

        csvData[i].number = csvData[i]['出席番号']
        delete csvData[i]['出席番号'];
      }
      return csvData
    },
    async sendCsv() {
      this.loading = true
      let index = 0;
      const chunkSize = 50;
      this.loadingDialogText = '生徒情報を登録中です。'
      this.allDownloadingLength = '%'
      while (index < this.csvData.length) {
        this.currentDownloadingClass = Math.floor(((index == 0 ? 1 : index) / this.csvData.length) * 100);
        let chunk = this.csvData.slice(index, index + chunkSize);
        await this.uploadMultiStudent(chunk);
        index += chunkSize;
      }
      this.loadingDialogText = ''
      this.allDownloadingLength = ''
      this.currentDownloadingClass = ''
      // const res = await this.uploadMultiStudent(this.csvData);
      // if (res.statusCode == 200) {
        this.dialogCsv = false;
        await this.reload()
      // } else {
        // alert('不明なエラーが発生したため、登録に失敗しました。管理者にお問い合わせください。')
      // }
    },
    downloadCsv() {
      const studentList = this.selected.filter((item) => item.is_delete == 0)
      const data = studentList.map(item => {
        const classroomDetail = this.classroomAll.find(classroom => classroom.classroom_id == item.classroom_id)
        const last_name = item.last_name;
        const last_name_kana = item.last_name_kana;
        const first_name = item.first_name;
        const first_name_kana = item.first_name_kana;
        const school_grade = classroomDetail.grade_position;
        const school_class = classroomDetail.class_position;
        const school_number = item.number
        return [last_name, last_name_kana, first_name, first_name_kana, school_grade, school_class, school_number];
      });
      const csvRows = [];
      const keys = Object.keys(data[0]);

      // ヘッダー行をCSVに追加
      csvRows.push(this.csvHeaders.join(','));

      // データ行をCSVに追加
      for (const row of data) {
        const values = keys.map(key => {
          return row[key];
        });
        // カンマ区切りの文字列にする
        let csvRow = values.join(',');
        // CSVデータに追加
        csvRows.push(csvRow);
      }
      // CSVをShift-JISにエンコード
      const csvString = csvRows.join('\r\n');
      const unicodeList = Encoding.stringToCode(csvString);
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE');
      const shiftJisString = new Uint8Array(shiftJisCodeList);
      // CSVファイルをダウンロード
      const fileName = 'Students.csv';
      const blob = new Blob([shiftJisString], { type: 'text/csv;charset=sjis' });
      saveAs(blob, fileName);
    },
    async groupByGrade() {
      const grades = this.classroomAllLocal.reduce((group, p) => {
        group[p.school_grade] = group[p.school_grade] ?? [];
        group[p.school_grade].push(p);
        return group;
      }, {});
      this.grades = Object.keys(grades);
    },
    async groupByClass() {
      const classes = this.classroomAllLocal.reduce((item, p) => {
        item[p.school_class] = item[p.school_class] ?? [];
        item[p.school_class].push(p);
        return item;
      }, {});
      this.classes = Object.keys(classes);
    },
    // async updateKey() {
    //   this.loading = true;
    //   const security_key = this.deleteSecurityKeyItem.securityKeyId
    //   const student_id = this.deleteSecurityKeyItem.studentId
    //   await this.updateStudentSecurityKey(security_key)
    //   const security_key_list = await this.getStudentKey(student_id)
    //   await this.makeStudentSecurityKey(security_key_list)
    //   this.closeSecurityKeyDialog()
    //   this.loading = false
    // },
    deleting() {
      // ストアの削除対象を更新
      this.setDeleteTargets(this.selected);
      // 削除のアクションを叩く
      this.deleteStudents();
      this.dialogDelete = false;
      this.selected = [];
      this.$forceUpdate();
    },
    addItem() {
      this.editedItem = JSON.parse(JSON.stringify(this.itemTemplate));//開いたときに編集アイテムを初期化
      this.createOrEdit = "CREATE";
      this.dialog = true;
    },
    async editItem(item) {
      this.loading = true;
      this.dialog = true;
      this.createOrEdit = EDIT;
      this.editedItem = item;
      this.loading = false;
    },
    async makeStudentSecurityKey(security_key_list) {
      if (security_key_list.length != 0) {
        this.studentSecurityKeyList = security_key_list
        await this.sortStudentSecurityKeyList()
      }
    },
    async invalid(item) {
      this.loading = true;
      this.setCreateStudent(item);
      const res = await this.invalidStudent();
      if (res.statusCode == 200) {
        this.invalidDialog = false;
        await this.reload()
      }
      this.selectStudentList = [];
      this.loading = false;
      this.invalidItem = null;
    },
    async invalidChoice() {
      this.loading = true;
      console.log("選択した生徒一覧を削除させる処理");
      console.log(this.selected);
      const res = await this.invalidMultiStudents(this.selected);
      if (res.statusCode != 200) {
        this.errMsg = '不明なエラーが発生しました。'
      } else {
        this.deleteDialog = false;
        await this.reload();
      }
      this.loading = false;
    },
    async save(item) {
      this.loading = true;
      this.setCreateStudent(item);
      switch (this.createOrEdit) {
        case CREATE: {
          const res = await this.createStudent();
          if (res.statusCode == 200) {
            this.students = [...this.studentsAll].filter(student => !student.is_delete);//削除済みは取得しないように変更
            this.dialog = false;
          }
          else if (res.body == "multi-unique") {
            this.errMsg = 'このクラスには、既に同じ出席番号の生徒がいます。'
          }
          break;
        }
        case EDIT:
          await this.updateStudent();
          this.students = [...this.studentsAll].filter(student => !student.is_delete);

          this.dialog = false;
          break;
        default:
          break;
      }
      this.selectStudentList = []
      this.loading = false;
    },
    close() {
      this.dialog = false;
    },
    clearSearchKey() {
      this.searchKey = null;
    },
    convertSecurityKeyStatus(status) {
      switch (status) {
        case 'INVALID':
          return '無効'
        case 'USED':
          return '利用中'
        case 'EXTIME':
          return '期限切'
        case 'CANUSE':
          return '利用可'
        default:
          return 'ーー'
      }
    },
    securityStatusColor(status) {
      switch (status) {
        case 'INVALID':
          return ''
        case 'USED':
          return 'primary'
        case 'EXTIME':
          return 'red'
        case 'CANUSE':
          return 'green'
        default:
          return ''
      }
    },
    securityIsNotDelete(status) {
      switch (status) {
        case 'USED':
        case 'EXTIME':
        case 'CANUSE':
          return true;
        case 'INVALID':
        default:
          return false;
      }
    },
    sortStudentSecurityKeyList() {
      for (let i = 0; i < this.studentSecurityKeyList.length; i++) {
        if (this.studentSecurityKeyList[i].is_delete == 1) {
          //無効化済
          this.studentSecurityKeyList[i].status = 'INVALID'
        } else if (this.studentSecurityKeyList[i].parent_id != null) {
          //利用中
          this.studentSecurityKeyList[i].status = 'USED'
        } else if (moment(this.studentSecurityKeyList[i].expire_time).isBefore(moment())) {
          //期限切れ
          this.studentSecurityKeyList[i].status = 'EXTIME'
        } else {
          //利用可能
          this.studentSecurityKeyList[i].status = 'CANUSE'
        }
      }
      const InvalidSecurityKeyList = this.studentSecurityKeyList.filter(item => item.status === 'INVALID');
      InvalidSecurityKeyList.sort((a, b) => moment(a.use_start_date) - moment(b.use_start_date));

      const UsedSecurityKeyList = this.studentSecurityKeyList.filter(item => item.status === 'USED');
      UsedSecurityKeyList.sort((a, b) => moment(a.use_start_date) - moment(b.use_start_date));

      const ExtimeSecurityKeyList = this.studentSecurityKeyList.filter(item => item.status === 'EXTIME');
      ExtimeSecurityKeyList.sort((a, b) => moment(a.expire_time) - moment(b.expire_time));

      const CanuseSecurityKeyList = this.studentSecurityKeyList.filter(item => item.status === 'CANUSE');
      CanuseSecurityKeyList.sort((a, b) => moment(a.expire_time) - moment(b.expire_time));

      this.studentSecurityKeyList = CanuseSecurityKeyList.concat(UsedSecurityKeyList, ExtimeSecurityKeyList, InvalidSecurityKeyList)
    },
    required(str) {
      return value => !!value && !this.isSpace(value) || str + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    // async addSecurityKey(student_id) {
    //   this.loading = true;
    //   // await this.createStudentSecurityKey(student_id);
    //   const security_key_list = await this.getStudentKey(student_id);
    //   await this.makeStudentSecurityKey(security_key_list);
    //   await this.getStudentsAll();
    //   this.students = [...this.studentsAll];
    //   this.loading = false;
    // },
  }
};
</script>

<style scoped>
.breakArea {
  white-space: pre-wrap;
}

.btnArea {
  display: flex;
}

.saveBtn {
  text-align: right;
}

</style>
<style>
.titleBar {
  height: 120px;
}

.titleBar>div.v-toolbar__content {
  height: 120px !important;
}

.paddingHarf.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.paddingHarf.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.paddingHarf.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.paddingHarf.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.paddingHarf.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.paddingHarf.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0 16px;
}

.v-input--selection-controls__input .v-icon {
  justify-content: left !important; 
}

tr .v-input--selection-controls__input {
  width: 48px;
}

</style>