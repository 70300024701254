<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <v-dialog v-model="show" min-width="600" max-width="900">
    <v-card>
      <v-system-bar :color="dialogColor" dark height="10"> </v-system-bar>
      <v-list three-line>
        <v-list-item>
          <v-list-item-avatar class="my-auto">
            <v-icon v-if="type === 'info'" x-large :color="dialogColor">mdi-alert-circle-outline</v-icon>
            <v-icon v-if="type === 'warning'" x-large :color="dialogColor">mdi-alert-outline</v-icon>
            <v-icon v-if="type === 'confirm'" x-large :color="dialogColor">mdi-help-circle-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="dialogColor" @click="confirm" :disabled="disableUpdateConfirm">
          {{ btnConfirmTitle }}
        </v-btn>
        <v-btn color="grey darken-2" outlined @click="show = false">
          {{ btnCancelTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

const DIALOG_COLOR = {
  info: "primary",
  warning: "warning",
  confirm: "primary",
};

export default Vue.extend({
  props: {
    visible: Boolean,
    title: String,
    type: String,
    btnConfirmTitle: String,
    disableUpdateConfirm: Boolean,
  },
  data() {
    return {
      btnCancelTitle: "キャンセル",
    };
  },
  computed: {
    ...mapState({
      formSchema: (state) => (state).formEditor.formSchema,
      teamListData: (state) => (state).users.teamList,
    }),
    dialogColor() {
      return DIALOG_COLOR[this.type] || DIALOG_COLOR.info;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    currentTeam() {
      if (this.formSchema[0].surveyTeams != null) {
        // check currentTeam existing in fullTeamList or not
        let teams = [];
        teams = this.formSchema[0].surveyTeams.filter((currTeam) => {
          return this.fullTeamList.find((team) => team.teamId == currTeam.teamId);
        });
        return teams.filter((item) => {
          return item.teamId !== "Administrator";
        });
      } else return null;
    },
    fullTeamList() {
      return this.teamListData.map((x) => {
        let teamName = x.teamName;
        if (teamName !== undefined && teamName.length > 25) {
          teamName = x.teamName.substring(0, 25).concat("...");
        }
        return { teamId: x.teamId, teamName: teamName };
      });
    },
    hasBunruiItem: {
      get() {
        let result = false;
        if (
          this.formSchema &&
          this.formSchema[0] &&
          this.formSchema[0].surveySchema &&
          Array.isArray(this.formSchema[0].surveySchema)
        ) {
          this.formSchema[0].surveySchema.forEach((schema) => {
            if (schema.type === "reservation") {
              result = true;
              return;
            }
          });
        }

        return result;
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("onConfirm");
    },
  },
});
</script>

<style lang="less">
.dialog-text {
  white-space: pre !important;
  display: block;
  line-height: 1.5 !important;
}
</style>
