<template>
  <div>
    <v-data-table
      :items="busList"
      item-key="bus_id"
      :headers="headers"
      :single-select="false"
      :loading="loading"
      :loading-text="loadingText"
      :no-data-text="noDataText"
      :no-results-text="noResultText"
      :footer-props="{ 'items-per-page-text': '行/ページ:' }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar height="36px" flat class="mb-4">
          <v-row>
            <v-spacer></v-spacer>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="primary" @click="reload">
                <v-icon left>mdi-reload</v-icon>
                データ更新
              </v-btn>
              <v-btn :loading="loading" color="green" dark @click="addItem">
                新規追加
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-toolbar>
        <v-card-actions>
          <v-text-field
            v-model="busNameFilterValue"
            append-icon="mdi-magnify"
            label="バス名で検索"
            single-line
            hide-details
            class="pl-3"
          ></v-text-field>
          <v-btn color="green" dark class="mb-2" @click="clearSearchKey">
            検索条件をクリア
          </v-btn>
        </v-card-actions>
      </template>
      <template v-slot:[`item.is_driving`]="{ item }">
        <div>{{ (item.is_driving === 1) ? '運行中' : (item.is_driving === 0) ? '運行前' : '--' }}</div>
      </template>
      <template v-slot:[`item.editBus`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="green white--text" class="mr-2" @click="openEditDialog(item)">編集</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`item.deleteBus`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="red white--text" class="mr-2" @click="openInvalidDialog(item)">無効</v-btn>
          </v-col>
        </div>
        <div v-else style="align-content: center;">
          <v-col align="center" class="px-0">
            <v-chip color="grey" text-color="white">
              無効
            </v-chip>
          </v-col>
        </div>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
      </template>
      <template v-slot:no-data>
        データがありません
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h4 font-weight-bold">新規追加</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent v-model="isNotValid">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editedItem.bus.bus_name"
                    label="バス名"
                    :rules="[required, max_length_10]"
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="dialog = false">キャンセル</v-btn>
          <v-btn :loading="loading" color="green" class="white--text" :disabled="!isNotValid" @click="save(editedItem)">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="editDialog" v-model="editDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">バス名編集</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent v-model="isNotValid">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="editBus.bus_name"
                    value=""
                    label="バス名"
                    :rules="[required, max_length_10]"
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="closeEditDialog()">
            キャンセル
          </v-btn>
          <v-btn :loading="loading" color="green" class="white--text" :disabled="!isNotValid" @click="update()">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="invalidDialog" v-model="invalidDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">バス無効化</span>
        </v-card-title>
        <v-card-text>
          「{{ invalidBus.bus_name }}」を無効化します。本当によろしいですか？<br>
          ※この操作は取り消せません。<br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="closeInvalidDialog()">
            キャンセル
          </v-btn>
          <v-btn :loading="loading" color="green" class="white--text" @click="invalid()">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" multi-line>
      {{ snackbarText }}ページを再読み込みしてください。
    </v-snackbar>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_BUS_ALL,
  GET_BUS_ALL_LENGTH,
  CREATE_BUS,
  UPDATE_BUS,
  INVALID_BUS,
} from "@/store/action-types";
import {
  SET_CREATED_BUS,
} from "@/store/mutation-types";
import {
  MAX_LENGTH_10_ERROR_MSG,
  REQUIRED_ERROR_MSG,
  LOADING_TEXT,
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
} from "@/constants";
import moment from 'moment';

export default {
  name: "BusList",
  data: () => ({
    loading: false,
    invalidBus: null,
    invalidDialog: false,
    loadingText: LOADING_TEXT,
    snackbar: false,
    snackbarText: null,
    dialog: false,
    editDialog: false,
    editBus: null,
    busNameFilterValue: "",
    busList: [],
    itemTemplate: {
      "bus": {
        "bus_name": "",
      }
    },
    editedItem: {
      "bus": {
        "bus_name": "",
      }
    },
    isNotValid: false,
    noDataText: NO_DATA_TEXT,
    noResultText: NO_RESULT_TEXT,
  }),
  computed: {
    ...mapState({
      busAll: state => state.busAll,
    }),
    headers() {
      return [
        { text: "バス名", value: "bus_name", filter: this.busNameFilter },
        { text: "ステータス", value: "is_driving" },
        { text: "登録日時", value: "created_at" },
        { value: "editBus", sortable: false, width: '100px' },
        { value: "deleteBus", sortable: false, width: '100px' }
      ]
    },
  },
  watch: {
    busAll: {
      handler: function () {
        this.busList = [...this.busAll].filter((bus) => !bus.is_delete);
      },
      deep: true
    },
    editDialog: {
      handler() {
        this.editedItem = structuredClone(this.items); // オブジェクトをディープコピー
      },
      deep: true
    },
  },
  async created() {
    this.loading = true;

    // バスの件数を取得する
    const response = await this.getBusAllLength();
    if (
      (response.statusCode)
      && (response.statusCode === 200)
    ) {
      // バスの件数が一致しない場合のみ取得処理を実行
      if (this.busAll.length !== response.body){
        await this.getBusAll();
      }
    }

    // Storeのバス情報をdataに保存
    this.busList = [...this.busAll].filter((bus) => !bus.is_delete);

    this.loading = false;
  },
  methods: {
    ...mapMutations({
      setCreateBus: SET_CREATED_BUS,
    }),
    ...mapActions({
      getBusAll: GET_BUS_ALL,
      getBusAllLength: GET_BUS_ALL_LENGTH,
      createBus: CREATE_BUS,
      updateBus: UPDATE_BUS,
      invalidateBus: INVALID_BUS,
    }),
    async reload() {
      this.loading = true;
      await this.getBusAll();
      this.loading = false;
    },
    openInvalidDialog(item) {
      this.invalidBus = item;
      this.invalidDialog = true;
    },
    closeInvalidDialog() {
      this.invalidDialog = false;
      this.invalidBus = null;
    },
    openEditDialog(item) {
      this.editBus = structuredClone(item);
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.editBus = null;
    },
    async invalid() {
      this.loading = true;
      this.setCreateBus(this.invalidBus);
      const res = await this.invalidateBus();
      if (res.statusCode != 200) {
        this.snackbar = true;
        this.snackbarText = 'バスの編集に失敗しました。';
      } else {
        this.invalidDialog = false;
        await this.getBusAll();
      }
      this.invalidBus = null;
      this.loading = false;
    },
    async update() {
      this.loading = true;
      this.setCreateBus(this.editBus);
      const res = await this.updateBus();
      if (res.statusCode != 200) {
        this.snackbar = true;
        this.snackbarText = 'バスの編集に失敗しました。';
      } else {
        this.editDialog = false;
        await this.getBusAll();
      }
      this.loading = false;
    },
    addItem() {
      this.editedItem = structuredClone(this.itemTemplate); // オブジェクトをディープコピー
      this.dialog = true;
    },
    async save(item) {
      this.loading = true;
      this.setCreateBus(item);
      const res = await this.createBus();
      if (res.statusCode !== 200) {
        this.snackbar = true
        this.snackbarText = 'バスの追加に失敗しました。'
      } else {
        this.dialog = false;
        await this.getBusAll();
      }
      this.loading = false;
    },
    busNameFilter(value) {
      if (!this.busNameFilterValue) {
        return true
      }
      return value.toLowerCase().includes(this.busNameFilterValue.toLowerCase());
    },
    clearSearchKey() {
      this.busNameFilterValue = "";
    },
    max_length_10(value) {
      return (value.length <= 10) || MAX_LENGTH_10_ERROR_MSG;
    },
    required(value) {
      const isSpace = (v) => {
        v = String(v);
        if(v === undefined || v === null){
          return true;
        }
        for (let i = 0; i < v.length; i++) {
          const code = v.charCodeAt(i);
          if (code === 0x3000 || code === 0x20) { // Unicodeのコードポイントを使って全角or半角スペースを判定
            continue; // ループを継続
          }
          return false; // それ以外の文字が含まれている場合はfalseを返す
        }
        return true; // 全角スペースor半角スペースしか含まれていない場合はtrueを返す
      };

      return (!!value && !isSpace(value)) || "バス名" + REQUIRED_ERROR_MSG;
    },
  }
};
</script>

<style scoped>
</style>