import { render, staticRenderFns } from "./StaffDeliveryCreation.vue?vue&type=template&id=590f8abd&scoped=true&"
import script from "./StaffDeliveryCreation.vue?vue&type=script&lang=js&"
export * from "./StaffDeliveryCreation.vue?vue&type=script&lang=js&"
import style0 from "./StaffDeliveryCreation.vue?vue&type=style&index=0&id=590f8abd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590f8abd",
  null
  
)

export default component.exports