/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import { DEFAULT_LANG_DICTIONARY } from "./formEditor.constants";
import { cloneDeep } from "lodash";
export default {
  formTitle: "無題のフォーム",
  formSchema: null,
  formType: "survey",
  activeItem: null,
  activeSection: null,
  showMoveSections: false,
  showCodeEditor: false,
  isEditMode: false,
  //language object
  langSettings: cloneDeep(DEFAULT_LANG_DICTIONARY),
  activeTab: null,

  //force re-render liff review
  previewComponentKey: 0,
  showBackButton: true,

  categoriesTree: {},
  categoriesTreeLarge: [],
  categoriesTreeMedium: [],
  getCategoriesTreeError: null,
  selectedLargeCategory: null,

  paymentServiceList: [],
  isFetchingPaymentServiceList: false,

  activeLanguageList: [],
  languageItemInfoWorking: false,
  languageItemInfoError: null,
};
