<template>
  <div class="contentsArea">
    <div class="d-flex align-center px-5">
      <v-card-title>アレルゲン通知設定状況</v-card-title>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="primary" @click="reload">
        <v-icon left>mdi-reload</v-icon>
        データ更新
      </v-btn>
    </div>

    <div class="d-flex px-5">
      <v-autocomplete
        :items="classroomAll"
        :item-text="classroom => `${classroom.school_grade} ${classroom.school_class}`"
        item-value="classroom_id"
        v-model="search.classroomId"
        clearable
        hide-details
        label="クラス"
        class="mr-5"
      >
        <template v-slot:no-data>
          <div class="px-4">データがありません</div>
        </template>
      </v-autocomplete>
      <v-text-field
        v-model="search.lastName"
        label="氏名（姓）"
        class="mr-5"
      ></v-text-field>
      <v-text-field
        v-model="search.firstName"
        label="氏名（名）"
      ></v-text-field>
    </div>
    <div class="d-flex align-center px-5">
      <div class="mr-3 text-body-1">アレルゲン品目:</div>
      <v-chip-group
        v-model="search.allergenList"
        column
        multiple
      >
        <v-chip
          v-for="schoolLunchAllergenInfo in schoolLunchAllergenInfoList"
          :key="schoolLunchAllergenInfo.school_lunch_allergen_id"
          :value="schoolLunchAllergenInfo.school_lunch_allergen_id"
          outlined
          filter
          class="custom-chip px-6"
        >{{ schoolLunchAllergenInfo.school_lunch_allergen_name }}</v-chip>
      </v-chip-group>
    </div>
    <v-data-table
      :headers="allergenSettingStatus.headers"
      :items="filteredAllergenSettingStatusItems"
      item-key="id"
      :loading="loading"
      :loading-text="loadingText"
      :no-data-text="noDataText"
      :no-results-text="noResultText"
      :footer-props="{
        'items-per-page-options': [5, 10, -1],
        'items-per-page-text': '行/ページ:'
      }"
      class="mt-5 elevation-1"
    >
      <template v-slot:[`item.allergenSettingStatus`]="{ item }">
        <v-btn outlined color="light-blue darken-1" @click="openDialogAllergenSetting(item)">
          {{ item.allergenSettingStatus }}品目設定中
        </v-btn>
      </template>
    </v-data-table>

    <!-- アレルゲン設定ダイアログ -->
    <DialogAllergenSetting
      v-model="showDialogAllergenSetting"
      :studentId="dialogAllergenSetting.studentId"
      :allergenSettingList="dialogAllergenSetting.allergenSettingList"
    />
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapState,
  mapActions
} from "vuex";
import {
  FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST,
  GET_CLASSROOM_ALL,
  FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST
} from "@/store/action-types";
import DialogAllergenSetting from "./fragments/DialogAllergenSetting.vue";
import {
  LOADING_TEXT,
  NO_DATA_TEXT,
  NO_RESULT_TEXT
} from "@/constants";

export default Vue.extend({
  components: {
    DialogAllergenSetting
  },
  data() {
    return {
      loading: false,
      loadingText: LOADING_TEXT,
      noDataText: NO_DATA_TEXT,
      noResultText: NO_RESULT_TEXT,

      /* アレルゲン通知設定状況 */
      allergenSettingStatus: {
        headers: [
          { text: "クラス", key: "classroom", value: "classroom", width: "10%" },
          { text: "出席番号", value: "number", sortable: false, width: "10%" },
          { text: "氏名", value: "fullName", sortable: false, width: "15%" },
          { text: "氏名(かな・カナ)", value: "fullNameKana", sortable: false, width: "15%" },
          { sortable: false, width: "40%" },
          { text: "アレルゲン設定状況", value: "allergenSettingStatus", sortable: false, width: "10%" }
        ],
        items: []
      },

      /* 検索 */
      search: {
        classroomId: "",
        lastName: "",
        firstName: "",
        allergenList: []
      },

      /* 通知設定ダイアログ */
      showDialogAllergenSetting: false,
      dialogAllergenSetting: {
        studentId: "",
        allergenSettingList: []
      }
    };
  },
  computed: {
    ...mapState({
      schoolId: state => state.loginUser.school_id,
      fiscalYear: state => state.fiscalYear,
      authority: state => state.loginUser.authority,
      managed_grade: state => state.loginUser.managed_grade,
      managed_class: state => state.loginUser.managed_class,
      classroomAll: state => state.classroomAll,
      schoolLunchAllergenSettingStatusList: state => state.schoolLunchAllergenSettingStatusList,
      schoolLunchAllergenInfoList: state => state.schoolLunchAllergenInfoList
    }),
    filteredAllergenSettingStatusItems() {
      return this.allergenSettingStatus.items.filter(item => {
        const isValidClassroomId = this.filterClassroomId(item.classroomId);
        const isValidLastName = this.filterLastName(item.lastName) || this.filterLastName(item.lastNameKana);
        const isValidFirstName = this.filterFirstName(item.firstName) || this.filterFirstName(item.firstNameKana);
        const isValidAllergenList = this.filterAllergenList(item.allergenList);
        return (isValidClassroomId && isValidLastName && isValidFirstName && isValidAllergenList);
      });
    }
  },
  watch: {
    schoolLunchAllergenSettingStatusList: {
      handler() {
        this.applyStoreToData();
      },
      deep: true
    }
  },
  async created() {
    /* ローディング開始 */
    this.loading = true;
    /* アレルゲン通知設定状況 */
    await this.fetchSchoolLunchAllergenSettingStatus({
      schoolId: this.schoolId,
      fiscalYear: this.fiscalYear,
      authority: this.authority,
      gradeIdManaged: this.managed_grade,
      classIdManaged: this.managed_class
    });
    /* クラス取得 */
    await this.getClassroomAll();
    /* アレルゲン情報取得 */
    await this.fetchSchoolLunchAllergenInfoList({
      schoolId: this.schoolId
    });
    /* ローディング終了 */
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchSchoolLunchAllergenSettingStatus: FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST,
      getClassroomAll: GET_CLASSROOM_ALL,
      fetchSchoolLunchAllergenInfoList: FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST
    }),
    applyStoreToData() {
      console.log(this.schoolLunchAllergenSettingStatusList)
      this.allergenSettingStatus.items = this.schoolLunchAllergenSettingStatusList
        .map(schoolLunchAllergenSettingStatus => {
          return {
            id: schoolLunchAllergenSettingStatus.student.student_id,
            classroom: `${schoolLunchAllergenSettingStatus.grade_display.grade_name}${schoolLunchAllergenSettingStatus.class_display.class_name}`,
            classroomId: `${schoolLunchAllergenSettingStatus.student.grade_id}${schoolLunchAllergenSettingStatus.student.class_id}`,
            number: schoolLunchAllergenSettingStatus.student.number,
            fullName: `${schoolLunchAllergenSettingStatus.student.last_name}${schoolLunchAllergenSettingStatus.student.first_name}`,
            lastName: schoolLunchAllergenSettingStatus.student.last_name,
            firstName: schoolLunchAllergenSettingStatus.student.first_name,
            fullNameKana: `${schoolLunchAllergenSettingStatus.student.last_name_kana}${schoolLunchAllergenSettingStatus.student.first_name_kana}`,
            lastNameKana: schoolLunchAllergenSettingStatus.student.last_name_kana,
            firstNameKana: schoolLunchAllergenSettingStatus.student.first_name_kana,
            allergenSettingStatus: schoolLunchAllergenSettingStatus.school_lunch_allergen_setting_list.length,
            allergenList: schoolLunchAllergenSettingStatus.school_lunch_allergen_setting_list.map(school_lunch_allergen_setting => {
              return {
                id: school_lunch_allergen_setting.school_lunch_allergen_id
              };
            })
          };
        });
    },

    /* 検索 */
    filterClassroomId(value) {
      if (!this.search.classroomId) return true;
      return value === this.search.classroomId;
    },
    filterLastName(value) {
      if (!this.search.lastName) return true;
      return value.toLowerCase().includes(this.search.lastName.toLowerCase());
    },
    filterFirstName(value) {
      if (!this.search.firstName) return true;
      return value.toLowerCase().includes(this.search.firstName.toLowerCase());
    },
    filterAllergenList(value) {
      if (!this.search.allergenList || this.search.allergenList.length === 0) return true;
      return this.search.allergenList.every(allergen => value.some(elem => elem.id === allergen));
    },

    /* データ更新 */
    async reload() {
      /* ローディング開始 */
      this.loading = true;
      /* アレルゲン通知設定状況 */
      await this.fetchSchoolLunchAllergenSettingStatus({
        schoolId: this.schoolId,
        fiscalYear: this.fiscalYear,
        authority: this.authority,
        gradeIdManaged: this.managed_grade,
        classIdManaged: this.managed_class
      });
      /* クラス取得 */
      await this.getClassroomAll();
      /* アレルゲン情報取得 */
      await this.fetchSchoolLunchAllergenInfoList({
        schoolId: this.schoolId
      });
      /* ローディング終了 */
      this.loading = false;
    },

    /* アレルゲン設定ダイアログを開く */
    openDialogAllergenSetting(allergenSettingStatusItem) {
      this.dialogAllergenSetting.studentId = allergenSettingStatusItem.id;
      this.dialogAllergenSetting.allergenSettingList = allergenSettingStatusItem.allergenList;
      this.showDialogAllergenSetting = true;
    }
  }
});
</script>

<style scoped>
.custom-chip {
  color: #666666;
  border-color: #666666;
}
.custom-chip.v-chip--active {
  color: #11993C;
  border-color: #11993C;
}
</style>