<template>
  <div>
    
    <v-card elevation="0" color="transparent">
      <v-card-actions style="height: 30px;" class="pt-0 pb-0">
        <v-btn :disabled="isMinMonth()" icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        {{ displayDate }}
        <v-btn :disabled="isMaxMonth()" icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="showCal">
      <v-sheet height="800">
        <v-calendar
          :key="key"
          locale="ja-jp"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="getEvents"
          :show-month-on-first="false"
          @click:event="clickEvent"
          @click:more="clickMore"
          @click:date="clickDate"
          :start="valueCal"
        ></v-calendar>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data: () => ({
    key: 0,
    displayDate : moment().format('YYYY年M月'),
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    valueCal: moment().format('YYYY-MM-DD'),
    showCal:true
  }),
  methods: {
    loading(bool) {
      this.$emit('loadingFunc', bool)
    },
    // 年度が変わったときなどに走るメソッド
    // 翌年度表示であれば、翌年度の4月に　今月表示であれば、現在を含む月を表示しなおす
    async changeDefaultMonth() {
      this.loading(true)
      const hizuke = this.displayDate + '1日'
      const yearTikan = hizuke.replace('年', '-')
      const monthTikan = yearTikan.replace('月', '-')
      const dayTikan = monthTikan.replace('日', '')
      const momentDisplayDay = moment(dayTikan)

      // 今何月か
      const currentMonth = moment().month() + 1
      // 今の年度
      let currentNendo = null
      // 4月以降であればmoment().yearがそのまま年度 1~3月であれば-1
      if(currentMonth >= 4){
        currentNendo = moment().year()
        
      }else {
        currentNendo = moment().year() - 1
      }

      // 移動したい月
      let targetDay = null
      // currentNendoとfiscalYearが違う = 翌年度閲覧　同じ = 今年度閲覧
      if (String(currentNendo) == String(this.fiscalYear)) {
        // 今年度閲覧なら、今日の日付が移動したい月になる
        targetDay = moment()
      } else {
        // 来年度の情報を見たい場合、4月1日が移動したい場所になる
        targetDay = moment((String(this.fiscalYear) + '-04-01'))
      }
      // 今表示されている月と、移動したい月の差分を調べる
      const diffMonth =  targetDay.diff(momentDisplayDay, 'months');
      if(diffMonth > 0) {
        // 0よりも大きい場合、未来に移動を差分の回数分繰り返す
        for (let i = 0; i < diffMonth; i++) {
          await this.$refs.calendar.next()
        }
      } else if (diffMonth < 0) {
        // 小さい場合は、過去に移動を差分の回数（絶対値）分繰り返す
        for (let i = 0; i < diffMonth * -1; i++) {
          await this.$refs.calendar.prev();
        }
        await this.$refs.calendar.prev();
      }
      this.loading(false)
    },
    isMinMonth() {
      return this.displayDate == moment((String(this.fiscalYear) + '-04-01')).format('YYYY年4月') ? true : false
    },
    isMaxMonth() {
      return this.displayDate == moment((String(Number(this.fiscalYear) + 1) + '-03-01')).format('YYYY年3月') ? true : false
    },
    clickEvent(v){
      this.$emit('clickedEvent', v.event)
    },
    clickMore(v){
      this.$emit('clickMore', moment(v.year + '-' + v.month + '-' + v.day))
    },
    clickDate(v){
      this.$emit('clickDate', moment(v.year + '-' + v.month + '-' + v.day))
    },
    getEvents ({ start, end }) {
      console.log(end)
      this.displayDate = moment(start.date).format('YYYY年M月')
      this.$emit('changeCalenderMon', start)
      // console.log(start)
      // const events = []
      // const min = new Date(`${start.date}T00:00:00`)
      // const max = new Date(`${end.date}T23:59:59`)
      // const days = (max.getTime() - min.getTime()) / 86400000
      // const eventCount = this.rnd(days, days + 20)

      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
      //   const second = new Date(first.getTime() + secondTimestamp)

      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   })
      // }

      // this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    currentDateDisplay(){
      return moment(this.currentDate).add(this.position, 'month').format('YYYY年MM月');
    }
  },
  computed: {
    ...mapState({
      scheduleItems: state => state.scheduleItems,
      fiscalYear: state => state.fiscalYear,
    }),
  },
  watch: {
    scheduleItems() {
      this.events = this.scheduleItems
    },
    fiscalYear() {
      this.changeDefaultMonth();
    }
  },
  created() {
    this.events = this.scheduleItems;
  },
  mounted(){
    this.changeDefaultMonth();
  }
}
</script>
<style>
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside{
  background-color: #e0e0e0 !important;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside{
  background-color: #e0e0e0 !important;
}
.v-calendar.v-calendar-events .v-calendar-weekly__day{
  /* height: 500px; */
}
</style>