/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import blank from "@/store/modules/forms/forms.templates/blank.json";
import survey from "@/store/modules/forms/forms.templates/survey.json";
import application from "@/store/modules/forms/forms.templates/application.json";
import contactForm from "@/store/modules/forms/forms.templates/contact-form.json";
import deliverySetting from "@/store/modules/forms/forms.templates/delivery-setting.json";
import coronaNTimesVaccination from "@/store/modules/forms/forms.templates/corona2.json";
import corona12TimesVaccination from "@/store/modules/forms/forms.templates/corona.json";
import member from "@/store/modules/forms/forms.templates/member.json";
import { generateUUID, generateShortUUID } from "@/utils/uuidUtils";

export const getFormTemplate = (templateName) => {
  console.log("getFormTemplate")
  let _configs = blank;
  switch (templateName) {
    case "survey":
      _configs = survey;
      break;
    case "application":
      _configs = application;
      break;
    case "contact-form":
      _configs = contactForm;
      break;
    case "delivery-setting":
      _configs = deliverySetting;
      break;
    case "corona-NTimesVaccination":
      _configs = coronaNTimesVaccination;
      break;
    case "corona-1-2TimesVaccination":
      _configs = corona12TimesVaccination;
      break;
    case "member":
      _configs = member;
      break;
    default:
      break;
  }

  return _configs.map((section) => {
    return {
      key: generateUUID(),
      ...section,
      surveySchema: section.surveySchema.map((item) => {
        return {
          itemKey: generateShortUUID(),
          ...item,
        };
      }),
    };
  });
};
