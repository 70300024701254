/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import {
  SET_FORM_SCHEMA,
  SET_EDIT_FORM_TYPE,
  UPDATE_FORM_SCHEMA_ITEM,
  UPDATE_FORM_SCHEMA_PAGE_HEADER,
  UPDATE_FORM_SCHEMA_END_OF_SURVEY_MESSAGE,
  UPDATE_FORM_SCHEMA_DELIVERY_MESSAGE,
  UPDATE_FORM_SCHEMA_TEAM_LIST,
  UPDATE_FORM_RESERVATION_EMAIL_LIST,
  UPDATE_MEMBER_SCHEMA_LINKED_FORMS,
  REMOVE_FORM_SCHEMA_DELIVERY_MESSAGE,
  ADD_FORM_SCHEMA_ITEM,
  ADD_FORM_SCHEMA_SECTION,
  DELETE_FORM_SCHEMA_ITEM,
  DUPLICATE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_SECTION,
  DUPLICATE_FORM_SCHEMA_SECTION,
  DELETE_FORM_SCHEMA_SECTION,
  TOGGLE_MOVE_SECTION_MODAL,
  TOGGLE_CODE_EDITOR_MODAL,
  SET_LANGUAGE_SETTING,
  INIT_LANGUAGE_SETTING,
  SET_ACTIVE_FORM_TAB,
  REMOVE_LANGUAGE_SETTING,
  REMOVE_LANGUAGE_DICTIONARY_SETTING,
  SET_FORM_TITLE,
  UPDATE_FORM_SCHEMA_ELEMENT,
  UPDATE_SURVEY_SCHEMA,
  INCREASE_FORM_COMPONENT_KEY,
  SET_FORM_EDIT_MODE,
  SET_BACK_BUTTON_CONDITION,
  UPDATE_VACCINATION_INTERVAL_TYPE,
  SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1,
  SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1,
  SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1,
  SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1,
  SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1,

  SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR,
  SET_IS_FETCHING_PAYMENT_SERVICE_LIST,

  SET_LANGUAGE_ITEM_INFO_WORK,
  SET_LANGUAGE_ITEM_INFO_ERROR,
  SET_LANGUAGE_ITEM_INFO,
} from "@/store/mutation-types";
import { generateUUID, generateShortUUID } from "@/utils/uuidUtils";
import Vue from "vue";

import { INIT_ITEM, INIT_SECTION, DEFAULT_LANG, OVERRIDE_ITEM_PROPS_WHEN_DUPLICATE } from "./formEditor.constants";
import { cloneDeep } from "lodash";
export default {
  [SET_FORM_EDIT_MODE]: (state, value) => {
    state.isEditMode = value;
  },
  [SET_FORM_SCHEMA]: (state, value) => {
    state.formSchema = value;
  },
  [SET_EDIT_FORM_TYPE]: (state, value) => {
    state.formType = value;
  },
  [INCREASE_FORM_COMPONENT_KEY]: (state) => {
    state.previewComponentKey += 1;
  },
  [SET_FORM_TITLE]: (state, value) => {
    state.formTitle = value;
    //set language setting for default lang
    state.langSettings[DEFAULT_LANG]["@[フォームのタイトル]"] = value;
  },
  [UPDATE_FORM_SCHEMA_ELEMENT]: (state, payload) => {
    let sectionIndex = 0;

    let value = {
      ...state.formSchema[sectionIndex],
    };

    if (payload.name && payload.name in payload) {
      value[payload.name] = payload[payload.name];
    }

    Vue.set(state.formSchema, sectionIndex, value);
  },
  [UPDATE_SURVEY_SCHEMA]: (state, value) => {
    Vue.set(state.formSchema[0], "surveySchema", value);
    state.previewComponentKey += 1;
  },
  [ADD_FORM_SCHEMA_ITEM]: (state, type = "textarea") => {
    let section = state.formSchema.find((obj) => obj.key === state.activeSection);
    const newItem = { itemKey: generateShortUUID(), type, ...INIT_ITEM };
    newItem.isNewItem = true;
    if (section.surveyType === "corona") {
      newItem.isAdminItem = true;
    }
    let activeItemIndex = section.surveySchema.findIndex((obj) => obj.itemKey === state.activeItem);

    if (activeItemIndex === -1) activeItemIndex = section.surveySchema.length;
    section.surveySchema.splice(activeItemIndex + 1, 0, cloneDeep(newItem));

    state.activeItem = newItem.itemKey;
  },
  [ADD_FORM_SCHEMA_SECTION]: (state) => {
    let newSection = { key: generateUUID(), ...cloneDeep(INIT_SECTION) };
    let activeSectionIndex = state.formSchema.findIndex((obj) => obj.key === state.activeSection);
    state.formSchema.splice(activeSectionIndex + 1, 0, newSection);
    state.activeItem = newSection.key;
    state.activeSection = newSection.key;
  },
  [DELETE_FORM_SCHEMA_ITEM]: (state, { sectionKey, itemKey }) => {
    let section = state.formSchema.find((obj) => obj.key === sectionKey);
    let itemIndex = section.surveySchema.findIndex((obj) => obj.itemKey === itemKey);

    state.activeItem = itemIndex === 0 ? sectionKey : section.surveySchema[itemIndex - 1].itemKey;

    section.surveySchema.splice(itemIndex, 1);

    state.previewComponentKey++;
  },
  [DELETE_FORM_SCHEMA_SECTION]: (state, sectionKey) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === sectionKey);
    let nextSection = sectionIndex === 0 ? state.formSchema[1] : state.formSchema[sectionIndex - 1];

    state.activeSection = nextSection.key;
    state.activeItem = nextSection;

    state.formSchema.splice(sectionIndex, 1);
  },
  [DUPLICATE_FORM_SCHEMA_ITEM]: (state, { sectionKey, item }) => {
    let section = state.formSchema.find((obj) => obj.key === sectionKey);
    let itemIndex = section.surveySchema.findIndex((obj) => obj.itemKey === item.itemKey);
    let newItem = cloneDeep(item);
    // アイテムコピー時、一部の属性はコピーせずにデフォルト値を引き継ぐ
    const additionalKeys = Object.keys(OVERRIDE_ITEM_PROPS_WHEN_DUPLICATE).reduce((acc, val) => {
      if (Object.keys(newItem).includes(val)) {
        acc[val] = OVERRIDE_ITEM_PROPS_WHEN_DUPLICATE[val]
      }
      return acc;
    }, {})
    delete newItem.itemKey;
    let cloneItem = {
      itemKey: generateShortUUID(),
      ...newItem,
      ...additionalKeys,
      isNewItem: true,
    };
    state.activeItem = cloneItem.itemKey;

    section.surveySchema.splice(itemIndex + 1, 0, cloneItem);
  },
  [DUPLICATE_FORM_SCHEMA_SECTION]: (state, section) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === section.key);
    let newSection = cloneDeep(section);

    delete newSection.key;
    let cloneSection = {
      key: generateUUID(),
      ...newSection,
      surveySchema: state.formSchema[sectionIndex].surveySchema.map((item) => {
        let newItem = cloneDeep(item);
        delete newItem.itemKey;

        return {
          itemKey: generateShortUUID(),
          ...newItem,
        };
      }),
    };
    state.activeItem = cloneSection.key;

    state.formSchema.splice(sectionIndex + 1, 0, cloneSection);
  },
  [SET_ACTIVE_FORM_SCHEMA_ITEM]: (state, value) => {
    console.log("SET_ACTIVE_FORM_SCHEMA_ITEM")
    state.activeItem = value;
    state.previewComponentKey++;
  },
  [SET_ACTIVE_FORM_TAB]: (state, value) => {
    state.activeTab = value;
  },
  [SET_ACTIVE_FORM_SCHEMA_SECTION]: (state, value) => {
    state.activeSection = value;
  },
  [TOGGLE_MOVE_SECTION_MODAL]: (state, value) => {
    state.showMoveSections = value;
  },
  [TOGGLE_CODE_EDITOR_MODAL]: (state, value) => {
    state.showCodeEditor = value;
  },
  [UPDATE_FORM_SCHEMA_PAGE_HEADER]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);

    Vue.set(state.formSchema, sectionIndex, {
      ...state.formSchema[sectionIndex],
      surveyTitle: payload.surveyTitle,
      description: payload.description,
      isAppending: payload.isAppending,
      isSearchable: payload.isSearchable,
      isEditDisabled: payload.isEditDisabled,
      isDeleteAllEnabled: payload.isDeleteAllEnabled,
      surveyStatus: payload.surveyStatus,
      headerImageUrl: payload.headerImageUrl, 
      usePayment: payload.usePayment,
    });
  },
  [UPDATE_FORM_SCHEMA_ITEM]: (state, payload) => {
    let section = state.formSchema.find((obj) => obj.key === payload.sectionKey);
    let itemIndex = section.surveySchema.findIndex((obj) => obj.itemKey === payload.item.itemKey);

    //update item at index by payload.item)
    section.surveySchema.splice(itemIndex, 1, cloneDeep(payload.item));
    state.previewComponentKey += 1;
  },
  [UPDATE_FORM_SCHEMA_END_OF_SURVEY_MESSAGE]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);

    Vue.set(state.formSchema, sectionIndex, {
      ...state.formSchema[sectionIndex],
      endOfSurveyMessage: payload.endOfSurveyMessage,
      endOfSurveyMessageType: payload.endOfSurveyMessageType,
    });
  },
  [UPDATE_FORM_SCHEMA_DELIVERY_MESSAGE]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);
    let _deliveryMessageKey = payload.deliveryMessageKey;
    if (!state.formSchema[sectionIndex].deliveryMessageSetting) {
      Vue.set(state.formSchema, sectionIndex, {
        ...state.formSchema[sectionIndex],
        deliveryMessageSetting: []
      });
    }

    Vue.set(state.formSchema[sectionIndex].deliveryMessageSetting, _deliveryMessageKey, {
      ...state.formSchema[sectionIndex].deliveryMessageSetting[_deliveryMessageKey],
      deliveryTitle: payload.deliveryTitle,
      deliveryMessage: payload.deliveryMessage,
    });
  },
  [REMOVE_FORM_SCHEMA_DELIVERY_MESSAGE]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);
    let _deliveryMessageKey = payload.deliveryMessageKey;
    if (!state.formSchema[sectionIndex].deliveryMessageSetting) {
      Vue.set(state.formSchema, sectionIndex, {
        ...state.formSchema[sectionIndex],
        deliveryMessageSetting: []
      });
    }

    state.formSchema[sectionIndex].deliveryMessageSetting.splice(_deliveryMessageKey, 1);

    let deliveryMessageSettingList = cloneDeep(state.formSchema[sectionIndex].deliveryMessageSetting);

    Vue.set(
      state.formSchema,
      sectionIndex,
      {
        ...state.formSchema[sectionIndex],
        deliveryMessageSetting: deliveryMessageSettingList
      }
    );

  },
  [UPDATE_FORM_SCHEMA_TEAM_LIST]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);

    Vue.set(state.formSchema, sectionIndex, {
      ...state.formSchema[sectionIndex],
      surveyTeams: payload.surveyTeams,
    });
  },
  [UPDATE_FORM_RESERVATION_EMAIL_LIST]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.surveyId);
    if (!state.formSchema[sectionIndex].reservationEmailList) {
      Vue.set(state.formSchema, sectionIndex, {
        ...state.formSchema[sectionIndex],
        reservationEmailList: {}
      });
    }
    state.formSchema[sectionIndex].reservationEmailList = payload
  },
  [UPDATE_MEMBER_SCHEMA_LINKED_FORMS]: (state, payload) => {
    let sectionIndex = state.formSchema.findIndex((obj) => obj.key === payload.key);

    Vue.set(state.formSchema, sectionIndex, {
      ...state.formSchema[sectionIndex],
      linkedForms: payload.linkedForms,
    });
  },
  [SET_LANGUAGE_SETTING]: (state, { langCode, langKey, langValue }) => {
    var _langObject = cloneDeep(state.langSettings[langCode]);
    //if exists object, add or update value by key
    if (_langObject) {
      let _langObj = state.langSettings[langCode];
      _langObj[langKey] = langValue;
      state.langSettings = Object.assign({}, state.langSettings, {
        [langCode]: cloneDeep(_langObj),
      });
    } else {
      //else create new object language
      state.langSettings[langCode] = {};
      state.langSettings[langCode][langKey] = langValue;
    }
  },
  [INIT_LANGUAGE_SETTING]: (state, langCode) => {
    Vue.set(state.langSettings, langCode, cloneDeep(state.langSettings[DEFAULT_LANG]));
  },
  [REMOVE_LANGUAGE_SETTING]: (state, langCode) => {
    Vue.delete(state.langSettings, langCode);
  },
  [REMOVE_LANGUAGE_DICTIONARY_SETTING]: (state, dictKey) => {
    Object.keys(state.langSettings).forEach((langCode) => {
      let _langObj = state.langSettings[langCode];

      //remove dictKey inside default lang
      Vue.delete(_langObj, dictKey);
      state.langSettings = Object.assign({}, state.langSettings, {
        [langCode]: cloneDeep(_langObj),
      });
    });
  },
  [SET_BACK_BUTTON_CONDITION]: (state, value) => {
    state.showBackButton = value;
  },
  [UPDATE_VACCINATION_INTERVAL_TYPE]: (state, payload) => {
    const { sectionKey, vaccinationIntervalType } = payload;
    const section = state.formSchema.find((obj) => obj.key === sectionKey);
    section.vaccinationIntervalType.input = vaccinationIntervalType;
  },

  [SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1]: (state, value) => {
    state.categoriesTree = value;
  },
  [SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1]: (state, value) => {
    state.categoriesTreeLarge = value;
  },
  [SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1]: (state, value) => {
    state.categoriesTreeMedium = value;
  },
  [SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1]: (state, value) => {
    state.getCategoriesTreeError = value;
  },

  [SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1]: (state, value) => {
    state.selectedLargeCategory = value;
  },

  [SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR]: (state, value) => (state.paymentServiceList = value),
  [SET_IS_FETCHING_PAYMENT_SERVICE_LIST]: (state, value) => (state.isFetchingPaymentServiceList = value),

  [SET_LANGUAGE_ITEM_INFO_WORK]: (state, value) => {
    state.languageItemInfoWorking = value;
  },
  [SET_LANGUAGE_ITEM_INFO_ERROR]: (state, value) => {
    state.languageItemInfoError = value;
  },
  [SET_LANGUAGE_ITEM_INFO]: (state, value) => {
    state.activeLanguageList = value;
  },
};
