<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-title class="text-h6">{{ item.title }}</v-card-title>
                <v-card-subtitle class="text-h6">{{ answerNum }}件の回答</v-card-subtitle>
                <v-row cols="12" justify="center" class="mb-2">
                    <v-col cols="6">
                        <HorizontalBarChart :dataSet="chartData" />
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import HorizontalBarChart from "@/components/charts/HorizontalBarChart.vue";
import { cloneDeep } from "lodash";

export default {
  name: "HorizontalBarAnswerItem",
  props: {
    answerNum: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  components: {
    HorizontalBarChart,
  },
  computed: {
    chartData() {
        if (this.item.values.length != 0) {
            const values = cloneDeep(this.item.values);

            let chartData = {}
            let datasets = [];
            let backgroundColor = [];
            let data = [];
            let labels = [];

            values.forEach((value, index) => {
                let color = this.generateColor(values.length, index);
                backgroundColor.push(color)
                data.push(value.count);
                labels.push(value.label);
                
            });

            let datasetsObject = {
                backgroundColor: backgroundColor,
                data: data,
            };
            datasets.push(datasetsObject);

            chartData.datasets = datasets;
            chartData.labels = labels;

            return chartData;
        } else {
            return {};
        }
    },
  },
  watch: {},
  methods: {
    generateColor(numOfSteps, step) {
        var r, g, b;
        var h = step / numOfSteps;
        var i = ~~(h * 6);
        var f = h * 6 - i;
        var q = 1 - f;
        switch (i % 6) {
            case 0:
            r = 1;
            g = f;
            b = 0;
            break;
            case 1:
            r = q;
            g = 1;
            b = 0;
            break;
            case 2:
            r = 0;
            g = 1;
            b = f;
            break;
            case 3:
            r = 0;
            g = q;
            b = 1;
            break;
            case 4:
            r = f;
            g = 0;
            b = 1;
            break;
            case 5:
            r = 1;
            g = 0;
            b = q;
            break;
        }
        var c =
            "#" +
            ("00" + (~~(r * 255)).toString(16)).slice(-2) +
            ("00" + (~~(g * 255)).toString(16)).slice(-2) +
            ("00" + (~~(b * 255)).toString(16)).slice(-2) +
            ("00" + (~~(0.5 * 255)).toString(16)).slice(-2);
        return c;
    },
  },
  async created() {},
  async mounted() {},
};
</script>