import { render, staticRenderFns } from "./StaffManage.vue?vue&type=template&id=0078b6a2&scoped=true&"
import script from "./StaffManage.vue?vue&type=script&lang=js&"
export * from "./StaffManage.vue?vue&type=script&lang=js&"
import style0 from "./StaffManage.vue?vue&type=style&index=0&id=0078b6a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0078b6a2",
  null
  
)

export default component.exports