/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import {
  SURVEY_CONFIGS_LIST_TYPE,
  SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE,
  SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID,
  SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR,
  SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR,

  SET_FORM_CONFIGS,
  SET_IS_FETCHING_FORM_CONFIGS,
  SET_FETCH_FORM_CONFIGS_ERROR,
  SET_FORM_CONFIG_DETAIL,
  SET_IS_FETCHING_FORM_CONFIG_DETAIL,
  SET_FETCH_FORM_CONFIG_DETAIL_ERROR,
  SET_IS_REGISTING_SURVEY_CONFIG,
  SET_REGISTER_SURVEY_CONFIG_ERROR,
  SET_REGISTERED_SURVEY_CONFIG_ID,
  SET_IS_UPDATING_SURVEY_CONFIG,
  SET_UPDATE_SURVEY_CONFIG_ERROR,
  SET_IS_REGISTERING_SURVEY_CONFIGS,
  SET_REGISTER_SURVEY_CONFIGS_ERROR,
  SET_IS_DELETING_SURVEY_CONFIGS,
  SET_DELETE_SURVEY_CONFIGS_ERROR,
  ADD_FORM_CONFIG,
  UPDATE_SURVEY_CONFIG_IN_STORE,
  SET_FETCH_FORM_CONFIG_ERROR,
  REMOVE_SURVEY_CONFIG_IN_STORE,
  SET_IS_EXIST_SURVEY_RESULTS,
  SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS,
  SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR,
  SET_EDITED_MULTIPLE_ITEM,
} from "@/store/mutation-types";

const _ = require('lodash');

export default {
  [SURVEY_CONFIGS_LIST_TYPE]: (state, value) => {
    state.surveyConfigsListType = value;
    state.surveyConfigsListType = _.orderBy(state.surveyConfigsListType, ["createdAt"], ["desc"])
  },
  [SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE]: (state, value) => {
    state.isFetchingSurveyConfigsListType = value;
  },
  [SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID]: (state, value) => {
    state.isFetchingSurveyConfigsById = value;
  },
  [SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR]: (state, value) => {
    state.fetchSurveyConfigsListTypeError = value;
  },
  [SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR]: (state, value) => {
    state.fetchSurveyConfigsByIdError = value;
  },

  //Set data
  [SET_FORM_CONFIGS]: (state, value) => {
    state.formConfigs = value;
  },
  //Add data tp list
  [ADD_FORM_CONFIG]: (state, value) => {
    state.formConfigs.splice(0, 0, value);
  },
  //Set loading
  [SET_IS_FETCHING_FORM_CONFIGS]: (state, value) => (state.isFetchingFormConfigs = value),
  //Set error
  [SET_FETCH_FORM_CONFIGS_ERROR]: (state, value) => (state.fetchFormConfigsError = value),
  //Set error
  [SET_FETCH_FORM_CONFIG_ERROR]: (state, value) => (state.fetchFormConfigError = value),
  //Set loading
  [SET_IS_REGISTING_SURVEY_CONFIG]: (state, value) => (state.isRegistingSurveyConfig = value),
  //Set error
  [SET_REGISTER_SURVEY_CONFIG_ERROR]: (state, value) => (state.registerSurveyConfigError = value),
  [SET_REGISTERED_SURVEY_CONFIG_ID]: (state, value) => (state.registeredSurveyConfigId = value),

  //Set data
  [SET_FORM_CONFIG_DETAIL]: (state, value) => {
    state.formConfigDetail = value;
  },
  //Set loading
  [SET_IS_FETCHING_FORM_CONFIG_DETAIL]: (state, value) => (state.isFetchingFormConfigDetail = value),
  //Set error
  [SET_FETCH_FORM_CONFIG_DETAIL_ERROR]: (state, value) => (state.fetchFormConfigDetailError = value),
  //Set loading
  [SET_IS_UPDATING_SURVEY_CONFIG]: (state, value) => (state.isUpdatingSurveyConfig = value),
  //Set error
  [SET_UPDATE_SURVEY_CONFIG_ERROR]: (state, value) => (state.updateSurveyConfigError = value),
  //Set loading
  [SET_IS_REGISTERING_SURVEY_CONFIGS]: (state, value) => (state.isRegisteringSurveyConfigs = value),
  //Set error
  [SET_REGISTER_SURVEY_CONFIGS_ERROR]: (state, value) => (state.registerSurveyConfigsError = value),
  //Set loading
  [SET_IS_DELETING_SURVEY_CONFIGS]: (state, value) => (state.isDeletingSurveyConfigs = value),
  //Set error
  [SET_DELETE_SURVEY_CONFIGS_ERROR]: (state, value) => (state.deleteSurveyConfigsError = value),

  //update data in list
  [UPDATE_SURVEY_CONFIG_IN_STORE]: (state, value) => {
    let _dataIndex = state.formConfigs.findIndex((obj) => obj.surveyId === value.surveyId);
    state.formConfigs.splice(_dataIndex, 1, value);
  },

  //Remove deleted item in survey config list in store
  [REMOVE_SURVEY_CONFIG_IN_STORE]: (state, value) => {
    state.formConfigs = state.formConfigs.filter((obj) => obj.surveyId !== value.surveyId);
  },

  [SET_IS_EXIST_SURVEY_RESULTS]: (state, value) => (state.isExistSurveyResutls = value),
  [SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS]: (state, value) => (state.isTimedOutForCountSurveyResults = value),
  [SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR]: (state, value) => (state.surveyResultsCountError = value),
  [SET_EDITED_MULTIPLE_ITEM]: (state, value) => (state.editedMultipleItem = value),
};
