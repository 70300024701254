<template>
    <div>
      <v-dialog v-model="isShow" width="500" persistent>
        <v-card v-if="isShow">
          <v-toolbar dark :color="color" dense="">
            <v-toolbar-title><v-icon left>mdi-alert-circle-outline</v-icon>装飾モード</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
  
          <v-card-text class="mt-4 text-center">
            <div class="blue-grey--text my-4 mx-2">
              <p>現在の入力内容を破棄して装飾モードに変更しますか？<br>
              装飾モードでは絵文字の入力は出来ません。</p>
            </div>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn outlined :color="color" @click="handleNo">いいえ</v-btn>
            <v-btn outlined color="red" @click="handleYes">はい</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script lang="ts">
  import Vue from "vue";
  
  export default Vue.extend({
    name: "DisplayFlexSegmentDeliveryModal",
    components: {},
    props: {
      value: Boolean,
      color: String,
    },
    computed: {
      isShow: {
        get() {
          return this.value;
        },
        set() {},
      },
    },
    methods: {
      close() {
        this.$emit("colse");
      },
      handleYes() {
        this.$emit("onChangeDecorationMode");
      },
      handleNo() {
        this.$emit("colse");
      },
    },
  });
  </script>
  
  <style></style>
  