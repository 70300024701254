<template>
  <div class="contentsArea">
    <v-tabs
      :mandatory="true"
      :hide-slider="true"
      height="40px"
      color="green"
      active-class="green lighten-4"
      class="mt-8"
    >
      <div class="d-flex ml-4">
        <v-tab href="#bus-view" class="customTab">バス一覧</v-tab>
        <v-tab href="#bus-route-view" class="customTab">バスルート一覧</v-tab>
        <v-tab href="#map-view" class="customTab">運行状況</v-tab>
      </div>

      <v-tab-item value="bus-view">
        <BusList/>
      </v-tab-item>
      <v-tab-item value="bus-route-view">
        <BusRouteList/>
      </v-tab-item>
      <v-tab-item value="map-view">
        <BusStatusMapView/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import BusList from './fragments/BusList.vue';
import BusRouteList from './fragments/BusRouteList.vue';
import BusStatusMapView from './fragments/BusStatusMapView.vue';
import { mapState, mapMutations, mapActions } from "vuex";
// import {
// } from "@/store/action-types";
// import {
// } from "@/store/mutation-types";

export default {
  name: "BusManage",
  components: {
    BusList,
    BusRouteList,
    BusStatusMapView,
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
    }),
  },
  watch: {
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
  }
};
</script>

<style scoped>
/* タブ */
::v-deep .customTab {
  border: solid 1px rgba(243, 243, 243, 1);
  margin-right: -1px;
  background-color: rgba(247, 247, 247, 1);
}
::v-deep .customTab:first-child {
  border-radius: 4px 0 0 4px;
}
::v-deep .customTab:last-child {
  border-radius: 0 4px 4px 0;
}
</style>