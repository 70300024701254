<style scoped>
.single-bubble-flex-container {
  width: 25em;
}

.fxC2 {
  color: #666666 !important;
}

.fxC1 {
  color: #aaaaaa !important;
}

.fxC0 {
  color: #999999 !important;
}

.LyGi {
  width: 100%;
  max-width: 500px;
  min-width: 0;
}

.LyMe {
  width: 100%;
  max-width: 300px;
  min-width: 0;
}

.LyKi {
  width: 100%;
  max-width: 260px;
  min-width: 0;
}

.LyMi {
  width: 100%;
  max-width: 160px;
  min-width: 0;
}

.LyNa {
  width: 100%;
  max-width: 120px;
  min-width: 0;
}

@import url(https://static.line-scdn.net/line_flexible_msg/172927b9b3c/css/sp/main.css?26687621);
</style>

<template>
  <v-container class="bubble-flex-preview-container">
    <div class="single-bubble-flex-container" v-html="renderedBubbleFlex"></div>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { render } from "@/services/flexRender";
import { cloneDeep } from "lodash";

// interface LocalState {
//   renderedBubbleFlex: string;
// }

export default Vue.extend({
  name: "BubbleFlexPreview",
  props: {
    message: Object,
    title: String,
    isFirstFlex: Boolean,
  },
  data() {
    return {
      renderedBubbleFlex: "<span>読み込み中</span>",
      msgLocal : null,
    };
  },
  watch: {
    message() {
      this.makeView()
    },
    title(){
      this.makeView()
    }
    
  },
  components: {},
  computed: {
    ...mapState({}),
  },
  methods: {
    makeView() {
      this.msgLocal = cloneDeep(this.message)
      if(this.isFirstFlex && (this.title != '' && this.title != null && this.title != undefined)){
        this.msgLocal.body.contents.unshift({
          "type" : "text",
          "size" : "lg",
          "color" : "#2E7D32",
          "weight" : "bold",
          "wrap" : true,
          "text" : ((this.title == '' || this.title == null || this.title == undefined) ? ' ' : this.title)
        },{
          "type" : "separator",
          "color" : "#00000000",
          "margin" : 'lg'
        })
      }
      this.renderedBubbleFlex = render(this.msgLocal);
      this.msgLocal = null
    }
  },
  created() {
    this.makeView()
  },
});
</script>
<style>
.bubble-flex-preview-container p {
  margin-bottom: 0px!important;
}
.bubble-flex-preview-container {
  min-height: 122px;
}
</style>
<style>
.style-italic {
  font-family: system-ui !important;
}
</style>