<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-text-field
      class="not-print-applicant-details-item"
      v-model="input"
      :rules="[
        validateRequired,
        validateIsNumber,
        validateRange,
        validateLength
      ]"
      :required="required"
      inputmode="numeric"
      :disabled="disabled"
    ></v-text-field>
    <span class="print-applicant-details-item">
      {{ input }}
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import InputBase from "./InputBase.vue";
import InputShortAnswer from "./InputShortAnswer.vue";
import {
  requireNumberGreaterThanOrEqualTo,
  requireNumberLessThanOrEqualTo,
  requireTextIsNumber,
} from "../utils/validationUtils";

export default Vue.extend({
  name: "InputNumber",
  mixins: [InputBase, InputShortAnswer],
  props: {
    mlLibJson:Object,
    isLiffMode: Boolean,
    isAdminMode: Boolean,
  },
  created() {
    this.itemKey = this.params.itemKey;
    // 省略されたパラメータを補完する
    var formParams = Object.assign(
      {
        isRequired: { value: false },
        isRequiredForUser: { value: false },
        range: { min: null, max: null },
        input: "",
      },
      this.params
    );

    this.itemKey = this.params.itemKey;
    this.options = this.params.options;
    this.required = this.params.isRequired.value;
    this.isRequiredForUser = formParams.isRequiredForUser.value;
    this.range = {
      min:
        formParams.range.min === null ||
        formParams.range.min === "" ||
        isNaN(formParams.range.min)
          ? null
          : parseInt(formParams.range.min),
      max:
        formParams.range.max === null ||
        formParams.range.max === "" ||
        isNaN(formParams.range.max)
          ? null
          : parseInt(formParams.range.max),
    };

    this.input = formParams.input;
  },
  methods: {
    getValue() {
      return !this.input ? undefined : this.input;
    },

    validateRequired(input) {
      const validateResult = !!input || "必須です";
      if (this.isAdminMode) {
        return !this.required || validateResult;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || validateResult;
      }
    },

    validateIsNumber(input) {
      if (!input) {
        return true;
      }
      var textisnumber = requireTextIsNumber(input);
      if (textisnumber) {
        return true;
      } else {
        var message = "0~9以外が入力されています";
        return message;
      }
    },

    validateRange(input) {
      if (!input) {
        return true;
      }
      const range = this.range;
      var validMin =
        range.min === null || range.min == ""
          ? true
          : requireNumberGreaterThanOrEqualTo(input, range.min);
      var validMax =
        range.max === null || range.max == ""
          ? true
          : requireNumberLessThanOrEqualTo(input, range.max);

      if (validMin && validMax) {
        return true;
      } else {
        var message = "範囲内の数値を入力してください。";
        return message;
      }
    },
  }
});
</script>
