/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import { find } from "lodash";
import {
  INIT_FORM_SCHEMA,
//   ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1,
  ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1,
  ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1,
  ACTION_SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1,

//   ACTION_FETCH_PAYMENT_SERVICE_LIST,
//   ACTION_GET_LANGUAGE_ITEM_INFO,
} from "@/store/action-types";
import { generateUUID } from "@/utils/uuidUtils";

import {
  SET_FORM_SCHEMA,
  SET_EDIT_FORM_TYPE,
  SET_FORM_EDIT_MODE,
  SET_ACTIVE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_SECTION,
  SET_REGISTERED_SURVEY_CONFIG_ID,
//   SET_REGISTERED_MEMBER_CONFIG_ID,
  SET_FETCH_FORM_CONFIG_DETAIL_ERROR,
//   SET_FETCH_MEMBER_CONFIG_ERROR,
//   SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1,
  SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1,
  SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1,
//   SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1,
  SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1,

//   SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR,
//   SET_IS_FETCHING_PAYMENT_SERVICE_LIST,

//   SET_LANGUAGE_ITEM_INFO_WORK,
//   SET_LANGUAGE_ITEM_INFO_ERROR,
//   SET_LANGUAGE_ITEM_INFO,
} from "@/store/mutation-types";

import { cloneDeep } from "lodash";
import { getFormTemplate } from "../forms/forms.templates/index";
import { 
  GetFormConfigById, 
  // GetCategoriesTrees 
} from "@/services/form.service";
// import { GetMemberFormConfigById } from "@/services/member.service";
// import {
//   FetchPaymentServiceList,
//   repeatQueryFunction,
// } from "@/services/payment.service";
// import { getLanguageItemInfo } from "@/services/settings.service";

// const INITIAL_FORM = [
//   {
//     key: generateUUID(),
//     surveyTitle: "無題アンケート",
//     description: "",
//     endOfSurveyMessage: DEFAULT_END_OF_SURVEY_MESSAGE,
//     type: "PAGE_BREAK",
//     surveyStatus: "enable",
//     surveySchema: [
//       {
//         itemKey: generateShortUUID(),
//         ...INIT_ITEM,
//       },
//     ],
//   },
// ];

const FORM_TYPE_ATTR_MAP = {
  mutationSetFetchDetailError: {
    survey: SET_FETCH_FORM_CONFIG_DETAIL_ERROR,
    // member: SET_FETCH_MEMBER_CONFIG_ERROR,
  },
  mutationSetRegisteredConfigId: {
    survey: SET_REGISTERED_SURVEY_CONFIG_ID,
    // member: SET_REGISTERED_MEMBER_CONFIG_ID,
  },
  getFormConfigMethod: {
    survey: GetFormConfigById,
    // member: GetMemberFormConfigById,
  },
};

export default {
  [INIT_FORM_SCHEMA]: async ({ commit }, payload) => {
    console.log("INIT_FORM_SCHEMA")
    console.log("payload", payload)
    let initForm = {};

    commit(SET_EDIT_FORM_TYPE, payload.formType);

    commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), null);
    // get template survey if init by template
    try {
      if (payload.template) {
        commit(SET_FORM_EDIT_MODE, false);
        let _initConfig = getFormTemplate(payload.template);
        initForm = cloneDeep(_initConfig);
      } else if (payload.surveyId) {
        //get survey config from list if init by detail survey
        //already get configs and save in store
        // commit(SET_FORM_EDIT_MODE, true);

        // アンケート開催情報の画面からテンプレート情報が渡ってきているかチェック
        if (payload.enqueteOpenInfoTemplate) {
          const targetSurveyId = payload.enqueteOpenInfoTemplate.surveyId;
          if (payload.surveyId == targetSurveyId) {
            // 一致するsurveyIdがある場合は、保持されているテンプレートでフォームを作成
            initForm = [payload.enqueteOpenInfoTemplate];
          } else {
            // surveyIdでテンプレートを取得してフォームを作成
            let _result = await formTypeAttr("getFormConfigMethod", payload.formType)(payload);
            if (_result.data.statusCode === 200) {
              // if (payload.surveyId)
              initForm = [
                {
                  key: generateUUID(),
                  ..._result.data.item,
                },
              ];
            } else {
              if (_result.errorMessage.indexOf("閲覧設定") > -1) {
                commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), new Error(_result.errorMessage));
                return { noInspectPermission: true };
              }
              commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), new Error());
              return false;
            }
          }
        } else {
          let _result = await formTypeAttr("getFormConfigMethod", payload.formType)(payload);
          if (_result.data.statusCode === 200) {
            // if (payload.surveyId)
            initForm = [
              {
                key: generateUUID(),
                ..._result.data.item,
              },
            ];
          } else {
            if (_result.errorMessage.indexOf("閲覧設定") > -1) {
              commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), new Error(_result.errorMessage));
              return { noInspectPermission: true };
            }
            commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), new Error());
            return false;
          }
        }
      } else {
        // surveyIdが設定されていない場合は、テンプレートなしで作成されている為、アンケート開催情報で保持しているテンプレートでフォームを作成
        initForm = [payload.enqueteOpenInfoTemplate];
      }
      console.log("initForm", initForm)
      commit(SET_FORM_SCHEMA, initForm);
      commit(SET_ACTIVE_FORM_SCHEMA_SECTION, initForm[0].key);
      // 先頭のアイテムを選択状態にする
      // commit(
      //   SET_ACTIVE_FORM_SCHEMA_ITEM,
      //   initForm[0] && initForm[0].surveySchema[0] && initForm[0].surveySchema[0].itemKey
      //     ? initForm[0].surveySchema[0].itemKey
      //     : null
      // );
      commit(formTypeAttr("mutationSetRegisteredConfigId", payload.formType), null);
      return true;
    } catch (error) {
      commit(formTypeAttr("mutationSetFetchDetailError", payload.formType), error);
      return error;
    }
  },

//   [ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1]: async ({ commit }) => {
//     try {
//       let result = await GetCategoriesTrees();

//       commit(SET_CALENDAR_DATA_OF_CATEGORIES_TREE_1, result);

//       if (result.tree) {
//         commit(SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1, result.tree);
//       }
//     } catch (error) {
//       commit(SET_CALENDAR_DATA_OF_CATEGORIES_TREE_ERROR_1, error);
//     }
//   },

  [ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1]: async ({ commit }, value) => {
    commit(SET_CALENDAR_DATA_OF_CATEGORIES_LARGE_1, value);
  },

  [ACTION_SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1]: async ({ commit, state }, value) => {
    let selectedLargeMediumName = value && value.name ? value.name : null;
    if (!selectedLargeMediumName) {
      return;
    }

    let categoriesMedium = findCategoriesTreeMedium(state, selectedLargeMediumName);
    if (categoriesMedium) {
      commit(SET_CALENDAR_DATA_OF_CATEGORIES_MEDIUM_1, categoriesMedium);
    }
  },

  [ACTION_SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1]: async ({ commit }, value) => {
    commit(SET_CALENDAR_SEARCH_SELECTED_LARGE_CATEGORY_1, value);
  },

//   [ACTION_FETCH_PAYMENT_SERVICE_LIST]: async ({ commit }) => {
//     try {
//       commit(SET_IS_FETCHING_PAYMENT_SERVICE_LIST, true);

//       const payload = { fields: 'sortKey,serviceId,serviceName,reservationServiceType' };
//       const serviceList = await repeatQueryFunction(FetchPaymentServiceList, payload);
//       commit(SET_PAYMENT_SERVICE_LIST_FOR_FORM_EDITOR, serviceList);
//     } catch (error) {
//       console.error(new Error(error));
//     } finally {
//       commit(SET_IS_FETCHING_PAYMENT_SERVICE_LIST, false);
//     }
//   },
  
//   [ACTION_GET_LANGUAGE_ITEM_INFO]: async ({ commit }) => {
//     try {
//       commit(SET_LANGUAGE_ITEM_INFO_WORK, true);
//       commit(SET_LANGUAGE_ITEM_INFO_ERROR, null);
//       let result = await getLanguageItemInfo();
//       const setData = result

//       commit(SET_LANGUAGE_ITEM_INFO, setData);
//     } catch (error) {
//       commit(SET_LANGUAGE_ITEM_INFO_ERROR, error);
//     } finally {
//       commit(SET_LANGUAGE_ITEM_INFO_WORK, false);
//     }
//   },
};

function findCategoriesTreeMedium(state, largeCategory) {
  if (state.categoriesTree && state.categoriesTree.tree) {
    let categoriesTreeLarge = find(state.categoriesTree.tree, { name: largeCategory });
    if (categoriesTreeLarge && categoriesTreeLarge.children) {
      return categoriesTreeLarge.children;
    }
  }
  return null;
}

function formTypeAttr(attr, formType) {
  console.log("attr", attr)
  console.log("formType", formType)
  return FORM_TYPE_ATTR_MAP[attr][formType ? formType : "survey"];
}
