<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-text-field
      class="not-print-applicant-details-item"
      v-model="input"
      :counter="counter"
      :rules="[
        validateRequired,
        validateLength,
        validateType,
        validateImeInput,
        validateFormat,
      ]"
      :required="required"
      :maxlength="maxLength"
      :disabled="disabled"
      :type="input_type"
    ></v-text-field>
    <span class="print-applicant-details-item">
      {{ input }}
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import { IME_TYPE } from "@/constants/index";
import { isHankakuOnly } from "../utils/stringUtil";
import InputBase from "./InputBase.vue";

import {
  requireTextMinLength,
  requireTextMaxLength,
  requireTextCharacterType,
  requireFormat,
  isBlank,
} from "../utils/validationUtils";

export default Vue.extend({
  name: "InputShortAnswer",
  mixins: [InputBase],
  props: {
    mlLibJson:Object,
    isLiffMode: Boolean,
    isAdminMode: Boolean,
  },
  computed: {
    //入力文字数表示
    counter() {
      return this.length.max !== Infinity ? this.length.max : null;
    },
    maxLength() {
      return this.length.max !== Infinity ? this.length.max : 300;
    },
    input_type: {
      get() {
        if (this.isLiffMode) {
          return "text";
        }

        if (this.imeType in IME_TYPE) {
          return IME_TYPE[this.imeType].input_type;
        }
        return "text";
      },
    },
  },
  data() {
    return {
      componentKey: 1,
      input: "",
    };
  },
  created() {
    this.itemKey = this.params.itemKey;

    // 省略されたパラメータを補完する
    var formParams = Object.assign(
      {
        isRequired: { value: false },
        isRequiredForUser: { value: false },
        characterType: ["all"],
        inputFormat: "any",
        length: { min: null, max: 300, limitation: 300 },
        input: "",
      },
      this.params
    );
    formParams.length.limitation = 300; // 固定

    this.imeType = this.params.imeType;
    this.itemKey = this.params.itemKey;
    this.options = this.params.options;
    this.required = this.params.isRequired.value;
    this.isRequiredForUser = formParams.isRequiredForUser.value;
    let limitation = formParams.length.limitation;
    let max =
      formParams.length.max === null || formParams.length.max === undefined
        ? limitation
        : formParams.length.max >= limitation
        ? limitation
        : formParams.length.max;
    this.length = {
      min: formParams.length.min === null ? 0 : formParams.length.min,
      max: max,
      limitation: limitation,
    };
    this.characterTypeList = formParams.characterType;
    this.inputFormat = formParams.inputFormat;
    this.input = formParams.input;
  },
  methods: {
    setValue(value) {
      this.input = value;
      this.componentKey++;
    },
    getValue() {
      return this.input;
    },
    validateRequired(input) {
      const validateResult = !isBlank(input) || "必須です";
      if (this.isAdminMode) {
        return !this.required || validateResult;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || validateResult;
      }
    },
    validateLength(input) {
      if (!input) {
        return true;
      }
      const length = this.length;
      var validMin =
        length.min === 0 || requireTextMinLength(input, length.min);
      var validMax =
        length.max === null || requireTextMaxLength(input, length.max);
      var validLimitation =
        (length.min === null || length.min <= length.limitation) &&
        (length.max === null || length.max <= length.limitation);
      if (validMin && validMax && validLimitation) {
        return true;
      } else {
        var message = "";

        if (
          length.limitation < length.min ||
          length.limitation < length.max ||
          length.limitation < input.length
        ) {
          message += length.limitation + "文字以下で";
          if (length.limitation < length.min) {
            message += "最小文字数を設定して下さい。";
          } else if (length.limitation < length.max) {
            message += "最大文字数を設定して下さい。";
          } else if (length.limitation < input.length) {
            message += "入力して下さい。";
          }
        } else {
          if (length.min) {
            message += length.min + "文字以上";
          }
          if (length.max) {
            if (message.length > 0) {
              message += "かつ";
            }
            message += length.max + "文字以下";
          }
          message += "で入力してください";
        }
        return message;
      }
    },
    validateType(input) {
      if (!input) {
        return true;
      }
      const validCharType =
        this.characterTypeList.indexOf("all") >= 0 ||
        requireTextCharacterType(input, this.characterTypeList);
      if (validCharType) {
        return true;
      } else {
        return "不正な文字が入力されました。";
      }
    },
    validateFormat(input) {
      if (!input) {
        return true;
      }
      const validFormat =
        this.inputFormat === "any" || requireFormat(input, this.inputFormat);
      if (validFormat) {
        return true;
      } else {
        return "不正なフォーマットが入力されました。";
      }
    },
    validateImeInput(input) {
      if (!input) {
        return true;
      }

      let result = true;
      switch (this.imeType) {
        case IME_TYPE.hankaku_only.value:
          result = isHankakuOnly(input);
          if (!result) {
            result = "半角のみを入力してください。";
          }
          break;
      }

      return result;
    },
  },
});
</script>
