var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
          (_vm.isLiffMode &&
            (_vm.parameters.reservationCheckBox === undefined ||
              !_vm.parameters.reservationCheckBox)) ||
            !_vm.isLiffMode
        )?_c('v-autocomplete',{attrs:{"rules":[_vm.validateDaibunruiRequired],"items":_vm.daibunruiOptions,"item-value":"name","item-text":_vm.itemText,"label":_vm.setLargeCategoryTitle
            ? _vm.setLargeCategoryTitle
            : _vm.categories_tree.display.tag1,"required":_vm.required,"clearable":!_vm.isReadonlyLargeCategory,"disabled":_vm.isReadonlyLargeCategory || _vm.isDisableForPayment},on:{"change":_vm.onChangeDaiBunrui},model:{value:(_vm.input.daibunrui.name),callback:function ($$v) {_vm.$set(_vm.input.daibunrui, "name", $$v)},expression:"input.daibunrui.name"}}):_vm._e(),(
          (_vm.isLiffMode &&
            (_vm.parameters.reservationSupCheckBox === undefined ||
              !_vm.parameters.reservationSupCheckBox)) ||
            !_vm.isLiffMode
        )?_c('v-autocomplete',{attrs:{"rules":[_vm.validateChubunruiRequired],"items":_vm.input.chubunrui.options,"item-value":"name","item-text":_vm.itemText,"label":_vm.setMediumCategoryTitle
            ? _vm.setMediumCategoryTitle
            : _vm.categories_tree.display.tag2,"required":_vm.required,"disabled":!_vm.input.daibunrui.name ||
            _vm.isReadonlyMediumCategory ||
            _vm.isDisableForPayment,"clearable":!_vm.isReadonlyMediumCategory},on:{"change":_vm.onChangeChuBunrui},model:{value:(_vm.input.chubunrui.name),callback:function ($$v) {_vm.$set(_vm.input.chubunrui, "name", $$v)},expression:"input.chubunrui.name"}}):_vm._e(),(
          _vm.input.daibunrui.name &&
            _vm.input.chubunrui.name &&
            _vm.input.shobunrui.options &&
            _vm.input.shobunrui.options.length > 0
        )?_c('v-autocomplete',{attrs:{"disabled":!_vm.input.daibunrui.name ||
            !_vm.input.chubunrui.name ||
            _vm.isFixedSmallCategory ||
            _vm.isDisableForPayment,"rules":[_vm.validateShobunruiRequired],"items":_vm.input.shobunrui.options,"item-value":"id","item-text":_vm.itemText,"label":_vm.setSmallCategoryTitle
            ? _vm.setSmallCategoryTitle
            : _vm.categories_tree.display.tag3,"required":_vm.required,"clearable":""},on:{"change":_vm.onChangeShoBunrui},model:{value:(_vm.input.shobunrui.id),callback:function ($$v) {_vm.$set(_vm.input.shobunrui, "id", $$v)},expression:"input.shobunrui.id"}}):_vm._e(),(
          ((_vm.input.chubunrui.id || _vm.input.shobunrui.id) &&
            _vm.reservationItem.length > 0) &&
            !_vm.isShowOrderDetails
        )?_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(_vm.reservationItem.length > 0),expression:"reservationItem.length > 0"}],attrs:{"items":_vm.reservationItem,"item-value":"itemId","item-text":"itemName","label":"予約項目","clearable":"","rules":[_vm.validateYoyakuKomoku]},model:{value:(_vm.selectReservationItem),callback:function ($$v) {_vm.selectReservationItem=$$v},expression:"selectReservationItem"}}):_vm._e()],1)],1),(
      _vm.input.calendarLabelDisplay &&
        this.parameters.reservationDate !== undefined
    )?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{class:_vm.readOnlyClass,attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("予約時間"))])]),_c('div',[_vm._v(" "+_vm._s(this.yoyaku_nichiji_label())+" ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }