<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <!-- <InsertItemImgModal
      :visible="showInsertItemImgModal"
      @close="showInsertItemImgModal = false"
      @saveItem="saveItemImage"
      :itemLocalProps="itemLocal"
    /> -->
    <v-hover v-slot:default="{ hover }">
      <v-alert
        border="left"
        colored-border
        :color="isActive ? 'primary' : 'white'"
        elevation="0"
        class="pt-0 pl-0 pr-6 pb-6"
        :id="item.itemKey"
        dense
      >
        <div @click="onActiveItem">
          <div class="item-drag-handle text-center">
            <v-icon v-if="hover">mdi-drag-horizontal</v-icon>
            <v-icon v-else></v-icon>
          </div>
          <v-row no-gutters>
            <!-- アイテムタイトル -->
            <v-col :cols="isActive ? 8 : 12" class="pl-6">
              <v-text-field
                v-if="isActive"
                outlined
                solo
                dense
                filled
                :label="itemLocal.type === 'linkbutton' ? linkbuttonTitleQuestion : defaultTitleQuestion"
                autofocus
                v-model="itemLocal.title"
                :rules="[rules.required]"
                @change="onGroupHeaderChange"
                :disabled="!isUnPublished"
              />
              <div v-else :class="itemLocal.title ? 'subtitle d-flex align-center' : 'subtitle d-flex align-center title-not-exist'">
                <span class="red--text red lighten-5 pa-1 mr-2" style="font-size:12px;" v-if="itemLocal.isRequired.value" block>必須</span>
                
                {{
                  itemLocal.title || (itemLocal.type === "linkbutton" ? linkbuttonTitleQuestion : defaultTitleQuestion)
                }}
              </div>
            </v-col>
            <!-- アイテムリスト -->
            <v-col cols="4" class="pl-4" v-if="isActive">
              <v-menu offset-y min-width="224" z-index="10" v-model="typeMenu">
                <template v-slot:activator="{ on }">
                  <v-list-item
                    :disabled="!isUnPublished"
                    v-on="on"
                    class="btn-type-select"
                    dense
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon v-text="selectedTypeIcon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="selectedTypeTitle"></v-list-item-title>
                    </v-list-item-content>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-list-item>
                </template>
                <v-list>
                  <template v-for="(itemType, index) in itemTypeList">
                    <v-divider v-if="itemType === 'divider'" class="my-2" :key="itemType + index"></v-divider>
                    <template v-else>
                      <v-menu
                        open-on-hover
                        :key="itemType + index"
                        offset-x
                        v-if="itemType.childs"
                        nudge-right="2"
                        nudge-top="7"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on" :key="itemType.value" @click="onSelectType(itemType.value)">
                            <v-list-item-icon class="mr-4 my-3">
                              <v-icon v-text="itemType.icon"></v-icon
                            ></v-list-item-icon>
                            <v-list-item-title>{{ itemType.title }}</v-list-item-title>
                            <v-icon>mdi-menu-right</v-icon>
                          </v-list-item>
                        </template>

                        <v-list>
                          <v-list-item
                            @click="onSelectType(childItem.value)"
                            v-for="childItem in itemType.childs"
                            :key="`child_${childItem.value}`"
                          >
                            <template>
                              <v-list-item-icon class="mr-4 my-3">
                                <v-icon v-text="childItem.icon"></v-icon
                              ></v-list-item-icon>
                              <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                            </template>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-list-item
                        v-else-if="itemType.visable !== false"
                        :disabled="itemType.value === ITEM_TYPE.SELECT_PRODUCTS && !usePayment"
                        :key="itemType.value"
                        @click="onSelectType(itemType.value)"
                      >
                        <v-list-item-icon class="mr-4 my-3"> <v-icon v-text="itemType.icon"></v-icon></v-list-item-icon>
                        <v-list-item-title>{{ itemType.title }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </template>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <!-- アイテム説明 -->
          <v-row no-gutters>
            <v-col class="pl-6 pr-0" v-if="itemLocal.type !== 'linkbutton'">
              <v-textarea
                v-if="isActive"
                outlined
                solo
                auto-grow
                rows="1"
                dense
                hide-details="true"
                style="font-size: 14px"
                label="説明"
                v-model="itemLocal.description"
                :disabled="!isUnPublished"
              ></v-textarea>
              <div v-else 
                class="mt-4"
                :class="itemLocal.description ? '' : 'title-not-exist'"
                style="white-space: pre-line;font-size:14px;"
              >
                {{ itemLocal.description || "説明" }}
              </div>
              <v-divider class="mt-4 mb-0" v-if="!isActive" ></v-divider>
            </v-col>
          </v-row>
          <!-- <v-row v-if="!isActive&&imageUrl.itemUrl" class="align-center justify-space-between ml-6 mr-0 mt-4 mb-0 pt-4 itemImgArea">
            <template>
                <v-img
                  :src="imageUrl.itemUrl ? imageUrl.itemUrl : ''"
                  contain
                  v-if="imageUrl.itemUrl"
                >
                </v-img>
            </template>
          </v-row> -->
          <!-- <v-row v-if="isActive&&imageUrl.itemUrl" class="align-center justify-space-between ml-6 mr-0 mt-4 mb-0 pt-4 itemImgArea">
            <template>
                <v-img
                  :src="imageUrl.itemUrl ? imageUrl.itemUrl : ''"
                  contain
                  v-if="imageUrl.itemUrl"
                >
                  <v-btn
                    v-if="itemLocal.type!='linkbutton'||itemLocal.type!='reservation'||itemLocal.type!='groupheader'"
                    elevation="0"
                    color="blue-grey"
                    class=" white--text"
                    style="min-width:115px!important;"
                    block
                    dense
                    :disabled="itemLocal.type === 'linkbutton'"
                    @click="showInsertItemImgModal=true"
                  >
                    <v-icon left>mdi-image-plus</v-icon>画像変更
                  </v-btn>
                </v-img>
            </template>
          </v-row> -->
          <v-row 
          style="background-color:#F5F7F8;margin-top:16px!important;" 
          class="align-center justify-space-between ml-6 mr-0 mt-4 mb-0" 
          v-if="
            itemLocal.type !== 'groupheader' &&
            itemLocal.type !== 'guide' &&
            itemLocal.type !== 'linkbutton' &&
            itemLocal.type !== 'reservation' &&
            itemLocal.type !== 'memberNumber' &&
            isActive
          ">
          <!-- <v-col
            class="d-flex align-center pr-4 pl-2 fs-14"
              v-if="!isMemberForm&&
              ((itemLocal.type === 'text' ||
              itemLocal.type === 'email' ||
              itemLocal.type === 'phone' ||
              itemLocal.type === 'number' ||
              itemLocal.type === 'postcode' ||
              itemLocal.type === 'address' ||
              itemLocal.type === 'textarea' ||
              itemLocal.type === 'radio' ||
              itemLocal.type === 'sesshuVaccineMaker' ||
              itemLocal.type === 'checkboxes' ||
              itemLocal.type === 'dropdown' ||
              itemLocal.type === 'date' ||
              itemLocal.type === 'birthday' ||
              itemLocal.type === 'choicegroupheader' ||
              itemLocal.type === 'sesshuJisshiDate' ||
              itemLocal.type === 'previousVaccineMaker' ||
              itemLocal.type === 'previousVaccineDate')||
              (itemLocal.type !== 'groupheader' &&
              itemLocal.type !== 'guide' &&
              itemLocal.type !== 'linkbutton' &&
              itemLocal.type !== 'reservation' &&
              !isMemberNumberItem))"
          >
            <div class="fs-14 pr-2" style="color:rgba(0,0,0,.6);min-width:92px;">ユーザー画面</div>
            <v-select
              :items="userScreenDisplayControlItems"
              item-text="text"
              v-model="setIsNotEditableAndIsAdminItem"
              elevation="0"
              label="表示しない"
              outlined
              height="40"
              max-height="40"
              hide-details="false"
              class="inputHeightCtrl white"
              solo
              dense
              :disabled="
              itemLocal.type === 'selectProducts' ||
              (isFirstVaccination &&
              ['previousVaccineMaker','previousVaccineDate'].includes(itemLocal.type))||
              (isEditMode && !itemLocal.isNewItem && itemLocal.isAdminItem) ||
              ['countVaccines'].includes(itemLocal.type) ||
              (['sesshuJisshiDate','sesshuVaccineMaker'].includes(itemLocal.type) && hasCountVaccinesItem)"
              return-object
              style="max-width:240px;"
              @change="changeIsAdminItem"
            ></v-select>
          </v-col> -->
          <!-- 必須設定 -->
          <v-col class="d-flex align-center pr-2 fs-14">
            <div class="fs-14 pr-2" style="color:rgba(0,0,0,.6);min-width:57px;">必須設定</div>
            <v-select
              :items="isRequired"
              v-model="setIsRequired"
              elevation="0"
              label="必須"
              outlined
              height="40"
              max-height="40"
              hide-details="false"
              class="inputHeightCtrl white"
              solo
              dense
              @change="handleChangeIsRequired"
              :disabled="!isUnPublished"
              return-object
              :style= "isMemberForm ? 'max-width:100%;':'max-width:240px;'"
            ></v-select>
          </v-col>
        </v-row>
          <v-switch-case :value="itemLocal.type">            
            <!-- <template #date>
              <div style="display: none">
                <ItemDatePicker
                  :key="componentKey"
                  :isActive="false"
                  :item="itemLocal"
                  :isEditMode="isEditMode && !itemLocal.isNewItem"
                />
              </div>
            </template> -->
            <!-- <template #sesshuJisshiDate>
              <ItemSesshuJisshiDatePicker
                :key="componentKey"
                :isActive="false"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #dateDropdown>
              <ItemDateDropdown
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #birthday>
              <ItemDatePickerBirthday
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #text>
              <ItemText
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #linkbutton>
              <ItemLink
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #calendar>
              <ItemCalendar
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #email>
              <ItemTextEmail
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #phone>
              <ItemTextPhone
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #postcode>
              <ItemTextPostCode
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #address>
              <ItemTextAddress
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #number>
              <ItemTextNumber
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <template #textarea>
              <!-- <ItemParagraphText
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :isUnPublished="isUnPublished"
              /> -->
            </template>
            <template #radio>
              <ItemRadio
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :isEdited="isEdited"
                :latestOptionIndex="latestOptionIndex"
                :editIndex="canEditAndEditIndex"
                :originalOptionsIndex="0"
                :isUnPublished="isUnPublished"
                @addOption="addOption"
                @addOptionOther="addOptionOther"
                @removeOption="removeOption"
                @moveOption="moveOption"
                @updateOption="updateOption"
              />
            </template>
            <!-- <template #sesshuVaccineMaker>
              <ItemSesshuVaccineMaker
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEdited="isEdited"
                :originalSectionOptionsIndex="originalSectionOptionsIndex"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :editIndex="canEditAndEditIndex"
                :hasCountVaccinesItem="hasCountVaccinesItem"
                @addRadioSectionOption="addRadioSectionOption"
                @removeRadioSectionOption="removeRadioSectionOption"
                @updateRadioSectionOption="updateRadioSectionOption"
                @moveRadioSectionOption="moveRadioSectionOption"
              />
            </template> -->
            <template #choicegroupheader>
              <ItemRadioSection
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :originalSectionOptionsIndex="originalSectionOptionsIndex"
                :editIndex="canEditAndEditIndex"
                :radioGroupHeaderList="section.radioGroupHeaderList"
                :attributes="itemLocal.attributes"
                @addRadioSectionOption="addRadioSectionOption"
                @removeRadioSectionOption="removeRadioSectionOption"
                @updateRadioSectionOption="updateRadioSectionOption"
                @moveRadioSectionOption="moveRadioSectionOption"
              />
            </template>
            <template #checkboxes>
              <ItemCheckboxes
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :editIndex="canEditAndEditIndex"
                :isEdited="isEdited"
                :latestOptionIndex="latestOptionIndex"
                :originalOptionsIndex="0"
                :isUnPublished="isUnPublished"
                @addOption="addOption"
                @addOptionOther="addOptionOther"
                @removeOption="removeOption"
                @moveOption="moveOption"
                @updateOption="updateOption"
              />
            </template>
            <template #dropdown>
              <ItemDropdown
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :editIndex="canEditAndEditIndex"
                :isEdited="isEdited"
                :latestOptionIndex="latestOptionIndex"
                :originalOptionsIndex="originalOptionsIndex"
                @addOption="addOption"
                @removeOption="removeOption"
                @moveOption="moveOption"
                @updateOption="updateOption"
              />
            </template>
            <!-- <template #reservation>
              <ItemBunrui
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                @updateReservationCheckBox="updateReservationCheckBox"
                @updateSelectedCategory="updateSelectedCategory"
                @updateFixedCategory="updateFixedCategory"
                @updateCategoryTitle="updateCategoryTitle"
              />
            </template> -->
            <!-- <template #suggest>
              <ItemSuggest
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :editIndex="canEditAndEditIndex"
                :latestOptionIndex="latestOptionIndex"
                :isEdited="isEdited"
                :originalOptionsIndex="originalOptionsIndex"
                @addOption="addOption"
                @removeOption="removeOption"
                @moveOption="moveOption"
                @updateOption="updateOption"
              />
            </template> -->
            <!-- <template #languageSuggest>
              <ItemLanguageSuggest
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :editIndex="canEditAndEditIndex"
                :latestOptionIndex="latestOptionIndex"
                :isEdited="isEdited"
                :originalOptionsIndex="originalOptionsIndex"
                @addOption="addOption"
                @removeOption="removeOption"
                @moveOption="moveOption"
                @updateOption="updateOption"
              />
            </template> -->
            <!-- <template #guide> </template> -->
            <!-- <template #groupheader> </template> -->
            <!-- <template #memberNumber>
              <ItemMemberNumber
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
              />
            </template> -->
            <!-- <template #countVaccines>
              <ItemCountVaccines
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                @onChange="changeCountVaccines"
              />
            </template> -->
            <!-- <template #previousVaccineDate>
              <div style="display: none">
                <ItemDatePicker
                  :key="componentKey"
                  :isActive="false"
                  :item="itemLocal"
                  :isEditMode="isEditMode && !itemLocal.isNewItem"
                  :isFirstVaccination="isFirstVaccination"
                />
              </div>
            </template> -->
            <!-- <template #previousVaccineMaker>
              <ItemPreviousVaccineMaker
                :key="componentKey"
                :isActive="isActive"
                :item="itemLocal"
                :isEditMode="isEditMode && !itemLocal.isNewItem"
                :isFirstVaccination="isFirstVaccination"
                :originalSectionOptionsIndex="originalSectionOptionsIndex"
                :vaccinationIntervalType="step1SurveyFormat.vaccinationIntervalType"
                :editIndex="canEditAndEditIndex"
                @addRadioSectionOption="addRadioSectionOption"
                @removeRadioSectionOption="removeRadioSectionOption"
                @updateRadioSectionOption="updateRadioSectionOption"
                @moveRadioSectionOption="moveRadioSectionOption"
                @setVaccinationIntervalType="setVaccinationIntervalType"
              />
            </template> -->
            <!-- <template #selectProducts>
              <ItemSelectProducts
                :key="componentKey"
                :is-edit-mode="isEditMode"
                :item="itemLocal"
              />
            </template> -->
            <!-- <template #selectPayType>
              <ItemSelectPayType
                :key="componentKey"
                :isEditMode="isEditMode"
                :isActive="isActive"
                :item="itemLocal"
                @updatePayTypeOption="updatePayTypeOption"
                @moveOption="movePayTypeOption"
              />
            </template> -->

            <template #default>
              <div class="my-5 pl-6">{{ selectedTypeTitle }} : 開発中。。。</div>
            </template>
          </v-switch-case>
        </div>

        <template v-if="isActive && !isMemberNumberItem">
          <v-divider class="ml-6 my-4"></v-divider>
          <v-row class="d-flex justify-space-between mx-0 pl-6 pr-0">
            <v-col class="px-0 pb-0" style="max-width:84px;">
              <v-btn
                elevation="0"
                color="red white--text"
                block
                :disabled="!isUnPublished"
                @click="onDeleteItem"
                >
                  <v-icon left>mdi-trash-can-outline</v-icon>削除
              </v-btn>
            </v-col>
            <v-col class="d-flex px-0 pb-0 justify-end" style="max-width:220px;">
              <!-- <v-btn
                v-if="itemLocal.type!='linkbutton'"
                elevation="0"
                class="blue-grey--text mr-2"
                style="min-width:115px!important;border:solid 1px #607D8B;max-width:115px;"
                block
                outlined
                :disabled="[ITEM_TYPE.LINK, ITEM_TYPE.SELECT_PRODUCTS, ITEM_TYPE.SELECT_PAY_TYPE].includes(itemLocal.type)"
                @click="showInsertItemImgModal=true"
              >
                <v-icon left>mdi-image-plus</v-icon>画像挿入
              </v-btn> -->
              <v-btn
                v-if="itemLocal.type != 'languageSuggest'"
                elevation="0"
                class="primary white--text"
                style="min-width:84px!important;max-width:115px;"
                block
                :disabled="!isUnPublished"
                @click="onDuplicateItem"
              >
                <v-icon left>mdi-checkbox-multiple-blank-outline</v-icon>複製
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-alert>
    </v-hover>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import moment from "moment";
import {
  SET_ACTIVE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_SECTION,
  DELETE_FORM_SCHEMA_ITEM,
  DUPLICATE_FORM_SCHEMA_ITEM,
  UPDATE_FORM_SCHEMA_ITEM,
  UPDATE_VACCINATION_INTERVAL_TYPE,
  SET_EDITED_MULTIPLE_ITEM,
} from "@/store/mutation-types";
import { cloneDeep, isEmpty } from "lodash";
import { trim } from "@/utils/stringUtils";
// import ItemText from "./ItemText.vue";
// import ItemCalendar from "@/components/FormEditor/ItemCalendar.vue";
// import ItemLink from "@/components/FormEditor/ItemLink.vue";
// import ItemTextPhone from "@/components/FormEditor/ItemTextPhone.vue";
// import ItemTextAddress from "@/components/FormEditor/ItemTextAddress.vue";
// import ItemTextPostCode from "@/components/FormEditor/ItemTextPostCode.vue";
// import ItemTextNumber from "@/components/FormEditor/ItemTextNumber.vue";
// import ItemTextEmail from "@/components/FormEditor/ItemTextEmail.vue";
// import ItemParagraphText from "./ItemParagraphText.vue";
import ItemRadio from "./ItemRadio.vue";
// import ItemSesshuVaccineMaker from "@/components/FormEditor/ItemSesshuVaccineMaker.vue";
// import ItemRadioSection from "@/components/FormEditor/ItemRadioSection.vue";
import ItemCheckboxes from "./ItemCheckboxes.vue";
import ItemDropdown from "./ItemDropdown.vue";
// import ItemSuggest from "@/components/FormEditor/ItemSuggest.vue";
// import ItemLanguageSuggest from "@/components/FormEditor/ItemLanguageSuggest.vue";
// import ItemDatePicker from "@/components/FormEditor/ItemDatePicker.vue";
// import ItemSesshuJisshiDatePicker from "@/components/FormEditor/ItemSesshuJisshiDatePicker.vue";
// import ItemDateDropdown from "@/components/FormEditor/ItemDateDropdown.vue";
// import ItemDatePickerBirthday from "@/components/FormEditor/ItemDatePickerBirthday.vue";
// import ItemMemberNumber from "@/components/FormEditor/ItemMemberNumber.vue";
// import ItemCountVaccines from "@/components/FormEditor/ItemCountVaccines.vue";
// import ItemPreviousVaccineMaker from "@/components/FormEditor/ItemPreviousVaccineMaker.vue";
import VSwitchCase from "@/components/common/VSwitchCase.vue";
import { ITEM_TYPE } from "@/store/modules/formEditor/formEditor.constants";
import MultipleChoiceItem from "@/model/Form/MultipleChoiceItem";
import SingleChoiceItem from "@/model/Form/SingleChoiceItem";
import SingleChoiceSectionItem from "@/model/Form/SingleChoiceSectionItem";
import TextItem from "@/model/Form/TextItem";
// import ReservationItem from "@/model/Form/ReservationItem";
// import TextNumberItem from "@/model/Form/TextNumberItem";
import Item from "@/model/Form/Item";
import TextAreaItem from "@/model/Form/TextAreaItem";
// import ItemBunrui from "@/components/FormEditor/ItemBunrui.vue";
// import InsertItemImgModal from "@/components/FormEditor/InsertItemImgModal.vue";
// import MemberNumberItemModel from "@/model/Form/MemberNumberItem";
import { eventBus } from '@/main.js'
// import ItemSelectProducts from "@/components/FormEditor/ItemSelectProducts.vue";
// import SelectProductsItem from "@/model/Form/SelectProductsItem";
// import ItemSelectPayType from "@/components/FormEditor/ItemSelectPayType.vue";
// import SelectPayTypeItem from "@/model/Form/SelectPayTypeItem";

export default Vue.extend({
  name: "FormItem",
  props: {
    item: Object,
    sectionKey: String,
    section: {
      type: Object,
      required: true,
    },
    editedItemKey: String,
    initialItem: Object,
  },
  components: {
    // ItemSelectProducts,
    VSwitchCase,
    // ItemText,
    // ItemTextEmail,
    // ItemTextPhone,
    // ItemTextNumber,
    // ItemTextPostCode,
    // ItemTextAddress,
    // ItemParagraphText,
    ItemRadio,
    // ItemSesshuVaccineMaker,
    // ItemRadioSection,
    ItemCheckboxes,
    ItemDropdown,
    // ItemSuggest,
    // ItemLanguageSuggest,
    // ItemDatePicker,
    // ItemSesshuJisshiDatePicker,
    // ItemDateDropdown,
    // ItemDatePickerBirthday,
    // ItemBunrui,
    // ItemCalendar,
    // ItemLink,
    // ItemMemberNumber,
    // ItemCountVaccines,
    // ItemPreviousVaccineMaker,
    // InsertItemImgModal,
    // ItemSelectPayType,
  },
  data() {
    return {
      imageUrl: {},
      rules: {
        required: (value) => !!trim(value) || "*必須です",
      },
      typeMenu: false,
      itemLocal: null,
      defaultTitleQuestion: "タイトル",
      linkbuttonTitleQuestion: "ボタンテキスト",
      setIsNotEditableAndIsAdminItem: '',
      ITEM_TYPE,
      isNotEditableAndIsAdminItem: [
        {
          value: 'bothFalse',
          text: '表示する（編集可)',
        },
        {
          value: 'isNotEditableOnlyTrue',
          text: '表示する (編集不可)',
        },
        {
          value: 'isAdminItemOnlyTrue',
          text: '表示しない',
        },
      ],
      setIsRequired: '',
      isRequired: [
        {
          value: 'isRequiredOnlyTrue',
          text: '必須',
        },
        // {
        //   value: 'isRequiredForUserOnlyTrue',
        //   text: 'ユーザー画面のみ必須',
        // },
        {
          value: 'bothFalse',
          text: '任意',
        },
      ],
      itemTypes: [
        // {
        //   icon: "mdi-format-text-rotation-none",
        //   value: ITEM_TYPE.TEXT,
        //   title: "一行テキスト",
        // },
        {
          icon: "mdi-text",
          value: ITEM_TYPE.PARAGRAPH_TEXT,
          title: "テキスト",
        },
        {
          icon: "mdi-radiobox-marked",
          value: ITEM_TYPE.RADIO,
          title: "ラジオボタン",
        },
        {
          icon: "mdi-checkbox-marked",
          value: ITEM_TYPE.CHECKBOX,
          title: "チェックボックス",
        },
        // {
        //   icon: "mdi-arrow-down-drop-circle",
        //   value: ITEM_TYPE.LIST,
        //   title: "プルダウン",
        // },
        // {
        //   icon: "mdi-magnify-plus",
        //   value: ITEM_TYPE.SUGGEST,
        //   title: "サジェスト",
        // },
      ],
      componentKey: 0,
      editIndex: -1,
      isEdited: false,
      latestOptionIndex: -1,

      fixed_item_titles: [
        "接種回数",
        "前回の接種実施日",
        "前回の接種ワクチンのメーカー",
        "今回の接種実施日",
        "今回の接種ワクチンのメーカー",
        "前回接種のメーカー",
      ],
      showInsertItemImgModal:false,
    };
  },
  computed: {
    ...mapState({
      activeItem: (state) => (state).formEditor.activeItem,
      showCodeEditor: (state) => (state).formEditor.showCodeEditor,
      activeTab: (state) => (state).formEditor.activeTab,
      isEditMode: (state) => (state).formEditor.isEditMode,
      isMemberForm(state) {
        return (state).formEditor.formType === "member";
      },
      editedMultipleItem: (state) => (state).forms.editedMultipleItem,
      isUnPublished: state => state.isUnPublished,
    }),
    ...mapGetters(["step1SurveyFormat"]),
    isActive() {
      return this.activeItem === this.itemLocal.itemKey;
    },
    selectedTypeObj() {
      let _itemType = this.itemTypes.reduce((flatResult, obj) => {
        let _flat = [obj];
        if (obj.childs) {
          _flat = _flat.concat(obj.childs);
        }
        return flatResult.concat(_flat);
      }, []);
      console.log("_itemType", _itemType)
      console.log("this.itemLocal", this.itemLocal)

      return _itemType.find((obj) => obj.value === this.itemLocal.type);
    },
    selectedTypeIcon() {
      return this.selectedTypeObj.icon;
    },
    selectedTypeTitle() {
      return this.selectedTypeObj.title;
    },
    isMemberNumberItem() {
      return this.itemLocal.type === ITEM_TYPE.MEMBER_NUMBER;
    },
    isMemberFormButNoMemberNumberItem() {
      return this.isMemberForm && !this.isMemberNumberItem;
    },
    itemTypeList() {
      let typeList = this.itemTypes;

      if (this.isMemberForm) {
        typeList = typeList.filter((t, index, arr) => {
          let excluded_items = [ITEM_TYPE.LINK, ITEM_TYPE.BUNRUI];
          let isValid = true;

          if (t === "divider" && excluded_items.includes(arr[index + 1].value)) {
            isValid = false;
          } else {
            isValid = !excluded_items.includes(t.value);
          }

          if (t.value === ITEM_TYPE.LANGUAGE_SUGGEST) {
            isValid = false;
          }

          return isValid;
        });
      }
      if (!this.usePayment) {
        typeList = typeList.filter(t => ![ITEM_TYPE.SELECT_PRODUCTS, ITEM_TYPE.SELECT_PAY_TYPE].includes(t.value));
      }
      return typeList;
    },
    surveyType: {
      get() {
        return this.section.surveyType;
      },
    },
    hasCountVaccinesItem() {
      const countVaccinesItem = this.section.surveySchema.find(item => item.type === 'countVaccines');
      return !!countVaccinesItem;
    },
    isFirstVaccination() {
      const surveySchema = this.step1SurveyFormat.surveySchema;
      const countVaccinesValue = surveySchema.find(item => item.type === 'countVaccines')?.default;
      const isFirst = Number(countVaccinesValue) === 1;
      return isFirst;
    },
    canEditAndEditIndex() {
      if (!this.editedItemKey || this.editedItemKey === this.itemLocal.itemKey) {
        return this.editIndex;
      }

      // NOTE: 正の値は実際のindex、-1だと編集可、-2だとロックがかかる
      return -2;
    },
    originalOptionsIndex() {
      const optionsLength = this.initialItem.options ? this.initialItem.options.length : 0;
      return optionsLength > 0 ? optionsLength - 1 : 0;
    },
    originalSectionOptionsIndex() {
      const optionsLength = this.initialItem.sectionOptions ? this.initialItem.sectionOptions.length : 0;
      return optionsLength > 0 ? optionsLength - 1 : 0;
    },
    usePayment() {
      return process.env.VUE_APP_USE_PAYMENT === '1';
    },
    userScreenDisplayControlItems() {
      return this.isNotEditableAndIsAdminItem.filter(i => {
        if (this.itemLocal.type === ITEM_TYPE.SELECT_PAY_TYPE && i.value === 'isAdminItemOnlyTrue') {
          return false;
        }
        return true;
      });
    }
  },
  watch: {
    item(){
      if(this.itemLocal.isSearchable){
        this.itemLocal.isSearchable.value = this.item.isSearchable.value;
        this.handleChangeIsSearchableItem();
      }
      if(this.itemLocal.duplicateKeyCheck){
        this.itemLocal.duplicateKeyCheck = this.item.duplicateKeyCheck;
        this.handleChangeDuplicateKeyCheck();
      }
    },
    itemLocal(){
      if(this.itemLocal.type==='linkbutton'){
        this.itemLocal.imageUrl = {};
        this.imageUrl = {};
      }
    },
    setIsRequired(selectedItem){
      if(selectedItem){
        switch(selectedItem.value){
          case 'isRequiredOnlyTrue':
            this.itemLocal.isRequired.value = true;
            this.itemLocal.isRequiredForUser.value = false;
            break;
          case 'bothFalse':
            this.itemLocal.isRequired.value = false;
            if(this.isMemberFormButNoMemberNumberItem){
              this.itemLocal.duplicateKeyCheck = false;
              this.itemLocal.isRequiredForUser.value = false;
            }else{
              this.itemLocal.isRequiredForUser.value = false;
            }
            if(this.itemLocal.isIndexable&&this.isMemberForm){
              this.itemLocal.isIndexable.value = false;
            }
            break;
          case 'isRequiredForUserOnlyTrue':
            this.itemLocal.isRequired.value = false;
            this.itemLocal.isRequiredForUser.value = true;
            if(this.isMemberFormButNoMemberNumberItem){
              this.itemLocal.duplicateKeyCheck = false;
            }
            if(this.itemLocal.isIndexable&&this.isMemberForm){
              this.itemLocal.isIndexable.value = false;
            }
            break;
          default:
            break;
      }
    }
  },
  setIsNotEditableAndIsAdminItem(selectedItem){
    if(selectedItem){
      let index;
      switch(selectedItem.value){
        case 'bothFalse':
          this.itemLocal.isNotEditable = false;
          this.itemLocal.isAdminItem = false;
          break;
        case 'isNotEditableOnlyTrue':
          this.itemLocal.isNotEditable = true;
          this.itemLocal.isAdminItem = false;
          break;
        case 'isAdminItemOnlyTrue':
          this.itemLocal.isNotEditable = false;
          this.itemLocal.isAdminItem = true;
          if(this.isMemberFormButNoMemberNumberItem){
            this.itemLocal.duplicateKeyCheck = false;
          }
          index = this.isRequired.findIndex(el => el.value == "bothFalse");
          break;
        default:
          break;
        }
        if(index!=undefined){
          this.setIsRequired = this.isRequired[index];
        }
      }
    },
    activeItem(newActiveKey, oldActiveKey) {
      if (oldActiveKey === this.itemLocal.itemKey) {
        //update item data from local to store in here
        this.updateItemLocalToStore();
      }
    },
    showCodeEditor(val) {
      if (val && this.isActive) {
        //update item data from local to store in here
        this.updateItemLocalToStore();
      }
    },
    isFirstVaccination(value) {
      if (
        value &&
        this.isFirstVaccination &&
        ['previousVaccineMaker',
        'previousVaccineDate'].includes(this.itemLocal.type)
      ) {
        this.itemLocal.isRequired.value = false;
        this.itemLocal.isNotEditable = false;

        if (this.itemLocal.type === 'previousVaccineMaker') {
          this.initializePreviousVaccineMakerOptions();
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      setActiveItem: SET_ACTIVE_FORM_SCHEMA_ITEM,
      setActiveSection: SET_ACTIVE_FORM_SCHEMA_SECTION,
      deleteItem: DELETE_FORM_SCHEMA_ITEM,
      duplicateItem: DUPLICATE_FORM_SCHEMA_ITEM,
      updateItemToStore: UPDATE_FORM_SCHEMA_ITEM,
      updateVaccinationIntervalType: UPDATE_VACCINATION_INTERVAL_TYPE,
      updateEditedMulitipleItem: SET_EDITED_MULTIPLE_ITEM,
    }),
    resetIsRequired(){
      let index ;
      if(this.item.isRequiredForUser){
        if(this.item.type=='countVaccines'){
          index = this.isRequired.findIndex(el => el.value == "isRequiredOnlyTrue");
        }
        else if(this.item.isAdminItem){
          index = this.isRequired.findIndex(el => el.value == "bothFalse");
        }
        else if(this.item.isRequired.value&&!this.item.isRequiredForUser.value){
          index = this.isRequired.findIndex(el => el.value == "isRequiredOnlyTrue");
        }else if(!this.item.isRequired.value&&!this.item.isRequiredForUser.value){
          index = this.isRequired.findIndex(el => el.value == "bothFalse");
        }else if(!this.item.isRequired.value&&this.item.isRequiredForUser.value){
          index = this.isRequired.findIndex(el => el.value == "isRequiredForUserOnlyTrue");
        }
        this.setIsRequired = this.isRequired[index];
      }else{
        if(this.item.type=='countVaccines'){
          index = this.isRequired.findIndex(el => el.value == "isRequiredOnlyTrue");
        }else if(!this.item.isRequired.value||this.itemLocal.isAdminItem){
          index = this.isRequired.findIndex(el => el.value == "bothFalse");
        }else{
          index = this.isRequired.findIndex(el => el.value == "isRequiredOnlyTrue");
        }
        this.setIsRequired = this.isRequired[index];
      }
    },
    resetIsNotEditableAndIsAdminItem(){
      let index ;
      if(this.isMemberForm){
        this.item.isAdminItem = false;
        this.item.isNotEditable = false;
      }
      if(this.item.isAdminItem&&!this.item.isNotEditable){
        index = this.isNotEditableAndIsAdminItem.findIndex(el => el.value == "isAdminItemOnlyTrue");
      }else if(!this.item.isAdminItem&&!this.item.isNotEditable){
        index = this.isNotEditableAndIsAdminItem.findIndex(el => el.value == "bothFalse");
      }else if(!this.item.isAdminItem&&this.item.isNotEditable){
        index = this.isNotEditableAndIsAdminItem.findIndex(el => el.value == "isNotEditableOnlyTrue");
      }
      this.setIsNotEditableAndIsAdminItem = this.isNotEditableAndIsAdminItem[index];
    },
    saveItemImage(itemImage){
      this.itemLocal.imageUrl = itemImage;
      this.imageUrl = itemImage;
    },
    changeIsAdminItem(event) {
      if (event.value=="isAdminItemOnlyTrue") {
        this.itemLocal.isSearchable.value = false;
        this.itemLocal.isRequired.value = false;
      }
      if (event.value!="isAdminItemOnlyTrue"&&this.itemLocal.isAdminItem) {
        // OFF
        this.$dialog.show({
          text: "ユーザーに表示されるため、管理者が個人情報を入力しないようにしてください。",
          type: "info",
          hideBtnCancel: true,
        });
      }
    },
    handleChangeIsSearchableItem() {
      if (this.itemLocal.isSearchable.value) {
        this.itemLocal.isRequired.value = true;
        this.itemLocal.isRequiredForUser.value = false;
      }
    },
    handleChangeDuplicateKeyCheck() {
      if (this.itemLocal.duplicateKeyCheck) {
        this.itemLocal.isRequired.value = true;
        this.itemLocal.isRequiredForUser.value = false;
      }
    },
    handleChangeIsRequired() {
      if (!this.itemLocal.isRequired.value && this.isMemberFormButNoMemberNumberItem) {
        this.itemLocal.duplicateKeyCheck = false;
      }
    },
    isTextType(localType) {
      try {
        let textTypeChildren = this.itemTypes.find((obj) => obj.title == "記述式").childs.map((obj) => obj.value);
        return textTypeChildren.includes(localType);
      } catch (err) {
        return false;
      }
    },
    createLocalItem() {
      let tempItem = cloneDeep(this.item);
      if (!("isSearchable" in tempItem)) {
        tempItem["isSearchable"] = {
          value: false,
        };
      }
      if (!("isIndexable" in tempItem)) {
        tempItem["isIndexable"] = {
          value: false,
        };
      }
      if (!("isRequiredForUser" in tempItem)) {
        tempItem["isRequiredForUser"] = {
          value: false
        };
      }
      return tempItem;
    },
    updateItemLocalToStore() {
      console.log("updateItemLocalToStore")
      if (
        this.itemLocal.type === "reservation" ||
        this.itemLocal.type === "groupheader" ||
        this.itemLocal.type === "linkbutton" ||
        this.itemLocal.type === "guide"
      ) {
        this.itemLocal.isAdminItem = false;
      }
      this.updateItemToStore({
        sectionKey: this.sectionKey,
        item: this.itemLocal,
      });
    },
    onSelectType(value) {
      var item = new Item();
      const { title, description } = this.itemLocal;
      //setup item type property if needed
      switch (value) {
        case ITEM_TYPE.TEXT:
        // case ITEM_TYPE.TEXT_EMAIL:
        // case ITEM_TYPE.TEXT_PHONE:
        // case ITEM_TYPE.TEXT_POSTCODE:
        // case ITEM_TYPE.TEXT_ADDRESS:
        // case ITEM_TYPE.DATE_PICKER_BIRTHDAY:
          item = new TextItem(value, title, description);
          this.itemLocal.default = "";
          break;
        case ITEM_TYPE.GUIDE:
        // case ITEM_TYPE.GROUPHEADER:
          item = new TextItem(value, title, description);
          this.itemLocal.default = "";
          this.itemLocal.isRequired = { value: false };
          this.itemLocal.isSearchable = { value: false };
          break;
        // case ITEM_TYPE.BUNRUI:
        //   item = new ReservationItem(value, title, description);
        //   this.itemLocal.default = "";
        //   break;
        // case ITEM_TYPE.TEXT_NUMBER:
        //   item = new TextNumberItem(value, title, description);
        //   break;
        case ITEM_TYPE.PARAGRAPH_TEXT:
          item = new TextAreaItem(title, description);
          break;
        case ITEM_TYPE.RADIO:
        case ITEM_TYPE.SESSHU_VACCINE_MAKER:
          item = new SingleChoiceItem(value, title, description);
          break;
        case ITEM_TYPE.CHOICE_GROUPHEADER:
          item = new SingleChoiceSectionItem(value, title, description);
          item.attributes = { isSesshuKaisuu: false };
          break;
        case ITEM_TYPE.LIST:
          item = new SingleChoiceItem(value, title, description);
          break;
        case ITEM_TYPE.SUGGEST:
        case ITEM_TYPE.LANGUAGE_SUGGEST:
          item = new SingleChoiceItem(value, title, description);
          break;
        case ITEM_TYPE.CHECKBOX:
          item = new MultipleChoiceItem(title, description);
          break;
        case ITEM_TYPE.DATE:
          this.itemLocal.default = moment().format("YYYY-MM-DD");
          this.itemLocal.includeYear = true;
          break;
        case ITEM_TYPE.LINK:
          this.itemLocal.title = "予約日時指定に進む";
          // item.title = this.itemLocal.title;
          item = new TextItem(value, this.itemLocal.title, description);
          this.itemLocal.isRequired = { value: false };
          this.itemLocal.isSearchable = { value: false };
          break;
        // case ITEM_TYPE.MEMBER_NUMBER:
        //   item = new MemberNumberItemModel(title, description);
        //   break;
        // case ITEM_TYPE.SELECT_PRODUCTS:
        //   item = new SelectProductsItem(value, '商品選択', "購入商品を選択してください")

        //   this.itemLocal.isRequired = { value: true };
        //   this.itemLocal.isSearchable = { value: false };
        //   this.itemLocal.isAdminItem = false ;
        //   this.setIsRequired = this.isRequired[0]

        //   break;
        // case ITEM_TYPE.SELECT_PAY_TYPE:
        //   item = new SelectPayTypeItem();
        //   this.itemLocal.isRequired = { value: true };
        //   this.itemLocal.isSearchable = { value: false };
        //   this.itemLocal.isAdminItem = false ;
        //   this.setIsRequired = this.isRequired[0];
        //   break;

        // default:
        //   break;
      }

      if (this.isEditMode) {
        if (value === ITEM_TYPE.BUNRUI) {
          this.itemLocal.isRequired.value = true;
        } else {
          this.itemLocal.isRequired.value = false;
        }
        this.itemLocal.isRequiredForUser.value = false;
      }

      let new_item = { ...item.init() };
      if (this.itemLocal.hasOwnProperty("options")) {
        delete new_item.options;
      }
      this.itemLocal = { ...this.itemLocal, ...new_item };

      this.itemLocal.type = value;

      this.typeMenu = false;

      {
        // groupheaderList
        if (!this.section.groupheaderList) {
          this.section.groupheaderList = {};
        }

        if (this.itemLocal.type === ITEM_TYPE.GROUPHEADER) {
          if (!this.section.groupheaderList[this.itemLocal.itemKey]) {
            this.section.groupheaderList[this.itemLocal.itemKey] = this.itemLocal.title;
          }
        } else {
          delete this.section.groupheaderList[this.itemLocal.itemKey];
        }
      }
    },
    onActiveItem() {
      console.log("Item.vue:onActiveItem")
      this.setActiveItem(this.item.itemKey);

      this.setActiveSection(this.sectionKey);

      if (
        this.item.type === ITEM_TYPE.CHOICE_GROUPHEADER ||
        (this.item.itemKey === this.itemLocal.itemKey && this.itemLocal.type === ITEM_TYPE.CHOICE_GROUPHEADER)
      ) {
        this.setRadioGroupHeaderList(this.item.itemKey);
      }
    },
    setRadioGroupHeaderList(itemKey) {
      if (!isEmpty(itemKey)) {
        this.section.radioGroupHeaderList = {};
        let disabledGroupHeaderList = {};
        for (let index = 0; index < this.section.surveySchema.length; index++) {
          const element = this.section.surveySchema[index];

          if (element.itemKey === itemKey) {
            break;
          }
          if (element.type === ITEM_TYPE.GROUPHEADER) {
            disabledGroupHeaderList[element.itemKey] = element.title;
          }
        }

        this.section.radioGroupHeaderList = {};
        for (const [key, value] of Object.entries(this.section.groupheaderList)) {
          if (!disabledGroupHeaderList[key]) {
            this.section.radioGroupHeaderList[key] = value;
          }
        }
      }
    },
    onDeleteItem() {
      this.$dialog.show({
        title: "この項目を削除してもよろしいですか？",
        text: "この項目はすぐ削除されます。この操作は取り消せません。",
        type: "error",
        btnConfirmTitle: "この項目を削除",
        onConfirm: () => {
          if (this.itemLocal.type === ITEM_TYPE.GROUPHEADER) {
            delete this.section.groupheaderList[this.itemLocal.itemKey];
          }

          this.deleteItem({
            sectionKey: this.sectionKey,
            itemKey: this.item.itemKey,
          });
          this.$snackbar.show({ text: "この項目を削除しました。" });
        },
      });
    },
    onDuplicateItem() {
      this.duplicateItem({ sectionKey: this.sectionKey, item: this.itemLocal });
    },
    //addOption radio
    addOption() {
      let _newOption;
      // その他オプションが存在するか
      const isExist = this.itemLocal.options.includes("その他");
      if (isExist) {
        _newOption = MultipleChoiceItem.createChoice(this.itemLocal.options.length);
      } else {
        _newOption = MultipleChoiceItem.createChoice(this.itemLocal.options.length + 1);
      }
      
      _newOption = this.checkOption(_newOption);
      this.itemLocal.options.push(_newOption);

      this.latestOptionIndex = this.itemLocal.options.indexOf(_newOption)

      this.isEdited = this.isEditMode && !this.itemLocal.isNewItem ? true : false;
      this.moveOtherOption();
    },
    addOptionOther() {
      // その他オプションの追加
      let _newOption = MultipleChoiceItem.createChoiceOther();
      
      _newOption = this.checkOption(_newOption);
      this.itemLocal.options.push(_newOption);

      this.latestOptionIndex = this.itemLocal.options.indexOf(_newOption)

      this.isEdited = this.isEditMode && !this.itemLocal.isNewItem ? true : false;
    },
    removeOption(index) {
      // 選択肢がONの選択肢を削除する場合はdefault, inputの選択肢を削除する
      if (this.itemLocal.default) {
        const targetOption = this.itemLocal.options[index];
        // checkbox
        const isSelectedCheckBoxOption = Array.isArray(this.itemLocal.default) && this.itemLocal.default.includes(targetOption);
        // othere
        const isSelectedMultipleOption = !Array.isArray(this.itemLocal.default) && this.itemLocal.default === targetOption;
        if (isSelectedCheckBoxOption) {
          const removedOptions = this.itemLocal.default.filter(option => option !== targetOption);
          this.itemLocal.default = removedOptions;
          this.itemLocal.input = removedOptions;
        } else if (isSelectedMultipleOption) {
          this.itemLocal.default = null;
          this.itemLocal.input = null;
        }
      }
      this.itemLocal.options.splice(index, 1);

      this.isEdited = this.isEditMode && !this.itemLocal.isNewItem ? true : false;
    },
    updateOption(index, value) {
      var _updateValue = value.trim();
      if (isEmpty(_updateValue)) {
        _updateValue = MultipleChoiceItem.createChoice(index + 1);
      }
      _updateValue = this.checkOption(_updateValue);
      this.itemLocal.options.splice(index, 1, _updateValue);
      this.forceRerender();

      let editIndex = -1;
      if (this.initialItem.options && this.initialItem.options[index] !== value) {
        editIndex = index;
      }
      if (this.isEditMode && !this.itemLocal.isNewItem && index <= this.originalOptionsIndex) {
        this.updateEditIndex(editIndex);
      }
      this.moveOtherOption();
    },
    updateReservationCheckBox(event) {
      var value = event.value;
      if (event.key === "Large") {
        this.itemLocal.reservationCheckBox = value;
        if (value === false) {
          this.itemLocal.selectedLargeCategory = null;
        }
      } else {
        this.itemLocal.reservationSupCheckBox = value;
        if (value === false) {
          this.itemLocal.selectedMediumCategory = null;
        }
      }
    },
    updateSelectedCategory(event) {
      var value = event.value;
      if (event.key === "Large") {
        this.itemLocal.selectedLargeCategory = value;
      } else {
        this.itemLocal.selectedMediumCategory = value;
      }
    },
    updateFixedCategory(val) {
      let _event = val.event;
      let _value = val.value;
      let _category = `isFixed${_event}Category`;
      if (!_value) {
        if (_event === "Large") {
          let _categoryMedium = `isFixedMediumCategory`;
          this.itemLocal[_categoryMedium] = _value;

          let _categorySmall = `isFixedSmallCategory`;
          this.itemLocal[_categorySmall] = _value;
        } else if (_event === "Medium") {
          let _categorySmall = `isFixedSmallCategory`;
          this.itemLocal[_categorySmall] = _value;
        }
      }
      this.itemLocal[_category] = _value;
    },
    updateCategoryTitle(event) {
      let _event = event.key;
      let _value = event.value;
      let _category = `set${_event}CategoryTitle`;

      this.itemLocal[_category] = _value;
    },
    updateEditIndex(index) {
      this.editIndex = index;

      let editItemKey = null;
      if (index !== -1) {
        editItemKey = this.itemLocal.itemKey;
      }
      this.$emit("updateEditItemKey", editItemKey);
    },
    createEditedMultipleItemPayload(itemKey, editedValue, index) {
      const originalOption = this.initialItem.sectionOptions[index];
      if (!originalOption) {
        return;
      }
      const originalValue = originalOption.option.value;

      return {
        itemKey,
        editedValue,
        originalValue,
      };
    },
    checkOption(value, index = 0) {
      let _newOption = index ? `${value} (${index})` : value;
      let _checkOptionCnt = this.itemLocal.options.filter((option) => option === _newOption).length;
      if (_checkOptionCnt === 0) {
        return _newOption;
      }

      return this.checkOption(value, index + 1);
    },
    moveOption(newIndex, oldIndex) {
      //remove option at oldIndex's index
      let option = this.itemLocal.options.splice(oldIndex, 1)[0];
      //move to new index
      this.itemLocal.options.splice(newIndex, 0, option);
      this.moveOtherOption();
    },
    moveOtherOption() {
      console.log("並び替え")
      // その他オプションが存在する場合は、一番下に並び替える
      const isExist = this.itemLocal.options.includes("その他");
      if (isExist) {
        console.log("その他あり")
        const index = this.itemLocal.options.indexOf("その他");
        if (index !== -1) {
          this.itemLocal.options.splice(index, 1); // 指定した要素を削除
          this.itemLocal.options.push("その他"); // 要素を配列の最後に追加
          console.log("this.item.options", this.itemLocal.options)
          this.forceRerender();
        }
      }
    },
    // GroupHeader
    onGroupHeaderChange() {
      if (this.itemLocal.type === ITEM_TYPE.GROUPHEADER) {
        this.section.groupheaderList[this.itemLocal.itemKey] = this.itemLocal.title;
      }
    },

    // RadioSection
    addRadioSectionOption() {
      if (!this.itemLocal.sectionOptions) {
        this.itemLocal.sectionOptions = [];
      }
      let _newOption = SingleChoiceSectionItem.newSectionOption(this.itemLocal.sectionOptions.length + 1);
      this.itemLocal.sectionOptions.push(_newOption);
      this.latestOptionIndex = this.itemLocal.sectionOptions.indexOf(_newOption);
      this.checkRadioSectionOption(_newOption);
    },
    removeRadioSectionOption(index) {
      // ラジオボタンがONの選択肢を削除する場合はdefault, inputを初期化する
      const targetOption = this.itemLocal.sectionOptions[index].option.value;
      if (this.itemLocal.default && this.itemLocal.default === targetOption) {
        this.itemLocal.default = null;
        this.itemLocal.input = null;
      }
      this.itemLocal.sectionOptions.splice(index, 1);
    },
    updateRadioSectionOption(index, key, value) {
      let _updateValue = value.trim();
      let _sectionOption = { option: { value: null }, groupheader: { value: null } };

      if (index >= 0 && this.itemLocal.sectionOptions[index]) {
        _sectionOption = this.itemLocal.sectionOptions[index];
      }

      if (_sectionOption[key]) {
        _sectionOption[key]["value"] = _updateValue;
      }

      switch (key) {
        case "option":
          if (isEmpty(_updateValue)) {
            _updateValue = SingleChoiceSectionItem.newOptionValue(index + 1);
          }
          _updateValue = this.checkRadioSectionOptionForUpdate(_updateValue);

          _sectionOption[key]["value"] = _updateValue;
          break;

        case "groupheader":
          break;

        default:
          break;
      }

      this.forceRerender();
      if (this.isEditMode && !this.itemLocal.isNewItem && index <= this.originalSectionOptionsIndex) {
        this.updateEditIndex(index);
        const editedMultipleItem = this.createEditedMultipleItemPayload(
          this.itemLocal.itemKey,
          _updateValue,
          index
        );
        editedMultipleItem && this.updateEditedMulitipleItem(editedMultipleItem);
      }
    },
    checkRadioSectionOption(option, index = 0) {
      let _newOption = { ...option };
      _newOption.option.value += index ? ` (${index})` : "";

      let _checkOptionCnt = this.itemLocal.sectionOptions.filter(
        (filter_option) => filter_option.option.value === _newOption.option.value
      ).length;
      if (_checkOptionCnt === 1) {
        return _newOption;
      }

      return this.checkRadioSectionOption(option, index + 1);
    },
    checkRadioSectionOptionForUpdate(optionValue, index = 0) {
      let _newOptionValue = optionValue.trim();
      _newOptionValue += index ? ` (${index})` : "";

      let _checkOptionCnt = this.itemLocal.sectionOptions.filter(
        (filter_option) => filter_option.option.value === _newOptionValue
      ).length;

      if (_checkOptionCnt <= 1) {
        return _newOptionValue;
      }

      return this.checkRadioSectionOptionForUpdate(optionValue, index + 1);
    },
    moveRadioSectionOption(newIndex, oldIndex) {
      //remove option at oldIndex's index
      let option = this.itemLocal.sectionOptions.splice(oldIndex, 1)[0];
      //move to new index
      this.itemLocal.sectionOptions.splice(newIndex, 0, option);
    },
    changeCountVaccines(newValue) {
      this.itemLocal.default = newValue;
      this.itemLocal.input = newValue;
      this.updateItemLocalToStore();
    },
    initializePreviousVaccineMakerOptions() {
      this.itemLocal.sectionOptions = [
        {
          "groupheader": {
            "value": 20
          },
          "option": {
            "value": "ファイザー"
          }
        }
      ];
      this.itemLocal.input = null;
      this.itemLocal.default = null;
    },
    setVaccinationIntervalType(newValue) {
      this.updateVaccinationIntervalType({
        sectionKey: this.sectionKey,
        vaccinationIntervalType: newValue,
      })
    },
    forceRerender() {
      this.componentKey += 1;
    },
    setPreviousVaccineMakerAndDateOrEditModeSelect(){
      if(this.itemLocal.type=="previousVaccineMaker"||this.itemLocal.type=="previousVaccineDate"){
        this.removeIsAdminItemOnlyTrueSelectionFromUserUIOption();
        this.removeRequiredOnlyUserSelectionFromRequiredSettingOption();
      }else if(this.isEditMode && !this.itemLocal.isAdminItem && !this.itemLocal.isNewItem){
        this.removeIsAdminItemOnlyTrueSelectionFromUserUIOption();
      }
    },
    removeRequiredOnlyUserSelectionFromRequiredSettingOption() {
      this.isRequired.splice(1, 1);
    },
    removeIsAdminItemOnlyTrueSelectionFromUserUIOption() {
      this.isNotEditableAndIsAdminItem.pop();
    },
    updatePayTypeOption(index, value) {
      (this.itemLocal).payTypeOptions[index].input = value;
    },
    movePayTypeOption(newIndex, oldIndex) {
      let option = this.itemLocal.payTypeOptions.splice(oldIndex, 1)[0];
      this.itemLocal.payTypeOptions.splice(newIndex, 0, option);
    },
  },
  created() {
    this.itemLocal = this.createLocalItem();
    if (this.section.surveyType === "corona") {
      if (this.fixed_item_titles.includes(this.itemLocal.title)) {
        this.itemLocal.title_disabled = true;
      } else {
        this.itemLocal.title_disabled = null;
      }
    }
    this.imageUrl.itemUrl = this.itemLocal.imageUrl ? this.itemLocal.imageUrl :{};
    this.resetIsRequired();
    this.resetIsNotEditableAndIsAdminItem();
    this.setPreviousVaccineMakerAndDateOrEditModeSelect();
  },
  mounted(){
    if(eventBus){
      eventBus.$on("onDuplicateItem", (params) => {
        this.onDuplicateItem(params);
      });
    }
  },
});
</script>
<style scoped>
.subtitle{
  font-size: 17px;
  font-weight:700;
}
.title-not-exist{
  color: #a9a9a9!important;;
}

</style>
<style>
.v-input.v-input--checkbox .v-label{
  font-size: 14px!important;
}
.itemImgArea .v-responsive__content{
  width: 100%!important;
  position:relative;
}
.itemImgArea{
  position: relative;
}
.itemImgArea .v-image__image{
  max-height:300px;
}

.itemImgArea .v-image.v-responsive{
  max-height:300px;
}
.itemImgArea .itemDeleteBtn{
    position:absolute;
    right:8px;
    top:24px;
    z-index:999;
    display:none!important;
    transition:all 0.6s;
}
.itemImgArea:hover .itemDeleteBtn{
  display:inline-flex!important;
}

.itemImgArea .v-responsive__content .v-btn{
    position: absolute;
    display: inline-flex;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: auto;
    display: none;
    transition:all 0.6s;
}
.itemImgArea .v-responsive__content:hover .v-btn{
  display:inline-flex!important;
}
.v-input .v-input__control .v-input--switch__thumb{
  box-shadow:0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)!important;
}
</style>