<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div :id="sectionLocal.key" @click="onActiveItem">
    <v-row no-gutters>
      <v-col class="text-right">
        <!-- <InsertHeaderImgModal
          :visible="showInsertHeaderImgModal"
          @close="showInsertHeaderImgModal = false"
          @saveHeader = "saveHeaderImage"
        /> -->
        <!-- <TeamSelectDialog
          :visible="showTeamSelectDialog"
          :sectionKey="sectionLocal.key"
          @close="showTeamSelectDialog = false"
          @updateTeams="onUpdateTeams"
        /> -->
        <SurveyRegisterDialog
          v-if="isEditMode"
          type="info"
          title="このテンプレートへの変更を保存しますか？"
          btnConfirmTitle="テンプレート更新"
          :visible="showSurveyRegisterDialog"
          :hideBunrui="false"
          @close="showSurveyRegisterDialog = false"
          @onConfirm="onSurveyRegisterConfirm"
        />
        <SurveyRegisterDialog
          v-else
          type="info"
          title="このテンプレートを登録しますか？"
          btnConfirmTitle="テンプレート登録"
          :visible="showSurveyRegisterDialog"
          :hideBunrui="false"
          @close="showSurveyRegisterDialog = false"
          @onConfirm="onSurveyRegisterConfirm"
        />
      </v-col>
    </v-row>
    <v-alert
      border="left"
      :color="isActive ? 'primary' : 'white'"
      colored-border
      elevation="0"
      dense
      class="pb-6 mt-0 pt-4 pr-4"
      :style="stylePageBreak"
    >
      <div class="px-2">
        <!-- <v-row v-if="sectionLocal.surveyType!='member'">
          <v-col v-if="!isEditMode">
            <v-select
            :elevation="0"
            :items="selectTemplate"
            items-text="text"
            items-value="value"
            label= "テンプレートを選択"
            outlined
            style="width:360px;"
            height="40"
            max-height="40"
            hide-details="false"
            class="inputHeightCtrl py-0"
            solo
            @change="chgTemplateChild($event);"
            return-object
          ></v-select>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col>
            <v-textarea
              placeholder="無題のアンケート"
              single-line
              auto-grow
              rows="1"
              min-height="45px"
              style="font-size: 29px"
              hide-details
              :autofocus="isActive"
              v-model="sectionLocal.surveyTitle"
              :class="{ deactive: !isActive }"
              :readonly="!isActive"
              :disabled="!isUnPublished"
              @keydown.enter.prevent
            ></v-textarea
          ></v-col>

          <v-col cols="auto" v-if="isActive && sectionLength > 1">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" large class="mt-3">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="onDuplicateSection">
                  <v-list-item-title>Duplicate section</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onShowMoveSections">
                  <v-list-item-title>Move section</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onDeleteSection">
                  <v-list-item-title>Delete section</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-textarea
          :placeholder="'アンケートの説明'"
          single-line
          auto-grow
          rows="1"
          hide-details
          v-model="sectionLocal.description"
          :items="sectionLocal.description"
          :class="{ deactive: !isActive }"
          :readonly="!isActive"
          :disabled="!isUnPublished"
        ></v-textarea>
        <!-- <v-row v-if="isShowUsePayment&&usingNotAppendintTypeTemplate&&!isCoronaTemplate">
          <v-switch
            :readonly="isEditMode"
            :disabled="isEditMode"
            v-model="sectionLocal.usePayment"
            class="ml-5 d-flex"
          >
            <template v-slot:label>
              <span class="ml-5">決済機能を利用する</span>
            </template>
          </v-switch>
        </v-row> -->
        <!-- <NoticeInfoOfPayment v-show="sectionLocal.usePayment"/> -->

        <!-- <v-row v-if="isEditMode">
          <v-col class="pt-4">
            <v-radio-group hide-details="true" class="pa-0 ma-0 radioFlex" v-model="sectionLocal.surveyStatus">
              <v-radio label="帳票を有効にする" value="enable" class="d-flex mb-1 mr-3"></v-radio>
              <v-radio label="帳票を無効にする" value="disable" class="d-flex"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row> -->
        <!-- <v-divider
          v-if="sectionLocal.isAppending && sectionLocal.isAppending.value"
        ></v-divider> -->
        <!-- <KeySettingModal
          v-if="step1SurveyFormat"
          :visible="showKeySettingModal"
          :questions="step1SurveyFormat.surveySchema"
          :isAppending="step1SurveyFormat.isAppending && step1SurveyFormat.isAppending.value ? step1SurveyFormat.isAppending.value : false"
          @close="showKeySettingModal = false"
        /> -->
      </div>
    </v-alert>
  </div>
</template>

<script>
import Vue from "vue";
import { eventBus } from '@/main.js'
import style from "@/mixins/style";
import { cloneDeep } from "lodash";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import {
  SET_ACTIVE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_SECTION,
  DUPLICATE_FORM_SCHEMA_SECTION,
  DELETE_FORM_SCHEMA_SECTION,
  TOGGLE_MOVE_SECTION_MODAL,
  UPDATE_FORM_SCHEMA_PAGE_HEADER,
  UPDATE_FORM_SCHEMA_TEAM_LIST,
} from "@/store/mutation-types";
import {
  REGISTER_SURVEY_CONFIG,
  UPDATE_SURVEY_CONFIG,
  REGISTER_MEMBER_SURVEY_CONFIG,
  UPDATE_MEMBER_SURVEY_CONFIG,
  FETCH_SURVEY_RESULTS_COUNT,
} from "@/store/action-types";
// import TeamSelectDialog from "@/components/FormEditor/TeamSelectDialog.vue";
import SurveyRegisterDialog from "./SurveyRegisterDialog.vue";
// import KeySettingModal from "@/components/FormEditor/KeySettingModal.vue";
// import InsertHeaderImgModal from "@/components/FormEditor/InsertHeaderImgModal.vue";
// import NoticeInfoOfPayment from "./NoticeInfoOfPayment.vue";

export default Vue.extend({
  mixins: [style],
  props: {
    index: Number,
    section: Object,
    duplicateFlg: Boolean,
  },
  components: {
    // TeamSelectDialog,
    SurveyRegisterDialog,
    // KeySettingModal,
    // InsertHeaderImgModal,
    // NoticeInfoOfPayment
  },
  data() {
    return {
      headerImageUrl: {},
      sectionLocal: null,
      showTeamSelectDialog: false,
      showSurveyRegisterDialog: false,
      selectTemplate: [
        {
          value: '新型コロナワクチン接種（N回目接種）',
          text: '新型コロナワクチン接種（N回目接種）',
          id: 'corona-NTimesVaccination',
        },
        {
          value: '新型コロナワクチン接種（1・2回目接種）',
          text: '新型コロナワクチン接種（1・2回目接種）',
          id: 'corona-1-2TimesVaccination',
        },
        {
          value: '受信情報設定',
          text: '受信情報設定',
          id: 'delivery-setting',
        },
        {
          value: 'ご連絡フォーム',
          text: 'ご連絡フォーム',
          id: 'contact-form',
        },
        {
          value: 'アンケート',
          text: 'アンケート',
          id: 'survey',
        },
      ],
      isAppending: [
        {
          value: true,
          text: '追加型',
        },
        {
          value: false,
          text: '更新型',
        },
      ],
      showKeySettingModal: false,
      showInsertHeaderImgModal: false,
    };
  },
  watch: {
    activeItem(newKey, oldKey) {
      if (oldKey === this.sectionLocal.key) {
        this.updateDataLocalToStore();
      }
    },
    "sectionLocal.usePayment": {
      handler() {
        if (this.sectionLocal.usePayment) {
          this.sectionLocal.isAppending = { value: true }
        }
      }
    },
    "sectionLocal.isAppending": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && !val.value) {
          this.sectionLocal.isDeleteAllEnabled.value = false;
        }
      },
    },
    "formSchema": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val[0] && val[0].deliveryMessageSetting && this.sectionLocal) {
          this.sectionLocal.deliveryMessageSetting = val[0].deliveryMessageSetting;
        }
        if (val && val[0] && this.sectionLocal && (val[0].headerImageUrl != this.sectionLocal.headerImageUrl)) {
          this.sectionLocal.headerImageUrl = '';
          this.headerImageUrl = {};
          
          const newHeaderImageUrl = val[0].headerImageUrl;
          if (newHeaderImageUrl) {
            this.sectionLocal.headerImageUrl = newHeaderImageUrl;
            this.headerImageUrl.headerUrl = newHeaderImageUrl
          }
        }
      },
    },
  },
  computed: {
    ...mapState({
      loginUser: (state) => state.loginUser,
      fiscalYear: (state) => state.fiscalYear,
      activeItem: (state) => (state).formEditor.activeItem,
      formSchema: (state) => (state).formEditor.formSchema,
      formType: (state) => (state).formEditor.formType,
      isMemberForm(state) {
        return (state).formEditor.formType === "member" ? true : false;
      },
      isEditMode: (state) => (state).formEditor.isEditMode,
      showBackButton: (state) => (state).formEditor.showBackButton,
      registerSurveyConfigError(state) {
        return this.isMemberForm ? (state).member.registerMemberConfigError : (state).forms.registerSurveyConfigError;
      },
      updateSurveyConfigError(state) {
        return this.isMemberForm ? (state).member.updateMemberConfigError : (state).forms.updateSurveyConfigError;
      },
      allStates: (state) => state,
      isExistSurveyResutls: (state) => (state).forms.isExistSurveyResutls,
      isTimedOutForCountSurveyResults: (state) => (state).forms.surveyResultsCountError,
      surveyResultsCountError: (state) => (state).forms.surveyResultsCountError,
      editedMultipleItem: (state) => (state).forms.editedMultipleItem,
      isUnPublished: state => state.isUnPublished,
    }),
    ...mapGetters(["sectionLength", "step1SurveyFormat"]),
    isActive() {
      return this.activeItem === this.sectionLocal.key;
    },
    stylePageBreak() {
      return {
        borderTop: this.index === 1 && `10px solid ${this.primaryColor}`,
      };
    },
    previousPageTab() {
      return this.isMemberForm ? "member-form-list" : "form-list";
    },
    previousPageLocation() {
      return {
        name: "EnqueteList",
        // params: { tab: this.previousPageTab },
      };
    },
    isShowUsePayment() {
      return process.env.VUE_APP_USE_PAYMENT === '1';
    },
    usingNotAppendintTypeTemplate() {
      // 明示的な判別方式ではないが、実行上の判別方式として利用しているため関数化
      return this.sectionLocal.isSearchable && this.sectionLocal.isAppending;
    },
    isCoronaTemplate() {
      return this.sectionLocal.surveyType === 'corona';
    },
  },
  methods: {
    ...mapMutations({
      setActiveItem: SET_ACTIVE_FORM_SCHEMA_ITEM,
      setActiveSection: SET_ACTIVE_FORM_SCHEMA_SECTION,
      duplicateSection: DUPLICATE_FORM_SCHEMA_SECTION,
      deleteSection: DELETE_FORM_SCHEMA_SECTION,
      openMoveSectionsModal: TOGGLE_MOVE_SECTION_MODAL,
      updatePageHeader: UPDATE_FORM_SCHEMA_PAGE_HEADER,
      updateSurveyTeams: UPDATE_FORM_SCHEMA_TEAM_LIST,
    }),
    ...mapActions({
      registerSurveyConfig: REGISTER_SURVEY_CONFIG,
      updateSurveyConfig: UPDATE_SURVEY_CONFIG,
      registerMemberConfig: REGISTER_MEMBER_SURVEY_CONFIG,
      updateMemberConfig: UPDATE_MEMBER_SURVEY_CONFIG,
      fetchSurveyResutlsCount: FETCH_SURVEY_RESULTS_COUNT,
    }),
    moveKeySetting() {
      this.setActiveItem(this.sectionLocal.key);
      this.showKeySettingModal = true;
    },
    async chgTemplateChild(event){
      this.$emit('chgTemplateParent', event.id);
    },
    async onSurveyRegisterConfirm() {
      if (this.isEditMode) {
        let _result = undefined;
        this.updateDataLocalToStore();

        const validateResults = await this.validateExistEditedItemSurveyResults();
        if (validateResults) {
          this.$snackbar.show({ text: validateResults, type: "error" });
          return;
        }
        const data = {
          survey: this.step1SurveyFormat,
          loginUser: this.loginUser,
          fiscalYear: this.fiscalYear
        }
        _result = await this.updateSurveyConfig(data);

        if (_result) {
          this.$snackbar.show({ text: "テンプレート更新が成功しました。" });
          this.$router.push(this.previousPageLocation);
        } else {
          if (this.updateSurveyConfigError) {
            this.$snackbar.show({ text: this.updateSurveyConfigError, type: "error" });
          }
        }
      } else {
        let _result = undefined;
        this.updateDataLocalToStore();
        
        if(!this.step1SurveyFormat.surveyTeams){
          this.updateSurveyTeams({
            key: this.sectionLocal.key,
            surveyTeams: [],
          });
        }
        const data = {
          survey: this.step1SurveyFormat,
          loginUser: this.loginUser,
          fiscalYear: this.fiscalYear
        }
        _result = await this.registerSurveyConfig(data);
        console.log("_result", _result)
        if (_result) {
          this.$snackbar.show({ text: "テンプレート登録が成功しました。" });
          this.$router.push(this.previousPageLocation);
        } else {
          if (this.registerSurveyConfigError) {
            this.$snackbar.show({ text: this.registerSurveyConfigError, type: "error" });
          }
        }
      }
    },
    saveHeaderImage(headerImage){
      this.sectionLocal.headerImageUrl = headerImage;
      this.headerImageUrl = headerImage;
    },
    updateDataLocalToStore() {
      const {
        key, surveyTitle, description,
        isAppending, isSearchable, isEditDisabled,
        isDeleteAllEnabled, surveyStatus, headerImageUrl,
        usePayment,
      } = this.sectionLocal;
      this.updatePageHeader({
        key,
        surveyTitle: surveyTitle.trim(),
        description: description === null ? "" : description.trim(),
        isAppending: isAppending,
        isSearchable: isSearchable,
        isEditDisabled: isEditDisabled,
        isDeleteAllEnabled: isDeleteAllEnabled,
        surveyStatus: surveyStatus,
        headerImageUrl: headerImageUrl ? headerImageUrl : '',
        usePayment: usePayment ? 1 : 0,
      });
    },
    onActiveItem() {
      console.log("onActiveItem")
      this.setActiveItem(this.sectionLocal.key);
      this.setActiveSection(this.sectionLocal.key);
    },
    onDuplicateSection() {
      this.duplicateSection(this.sectionLocal);
    },
    onDeleteSection() {
      this.deleteSection(this.sectionLocal.key);
      this.$snackbar.show({ text: "Section deleted" });
    },
    onShowMoveSections() {
      this.updateDataLocalToStore();
      this.openMoveSectionsModal(true);
    },
    async validateExistEditedItemSurveyResults() {
      const { originalValue, editedValue, itemKey } = this.editedMultipleItem;
      if (
        !itemKey || // 編集されていない
        originalValue === editedValue // 編集前後で値が同じ
      ) {
        return null;
      }

      await this.fetchSurveyResutlsCount(this.step1SurveyFormat.surveyId);
      if (this.isTimedOutForCountSurveyResults) {
        return 'タイムアウトしました。もう一度更新をお試しください。';
      }
      if (this.surveyResultsCountError) {
        console.error(this.surveyResultsCountError);
        return '更新中にエラーが発生しました。もう一度更新をお試しください。'
      }
      if (this.isExistSurveyResutls) {
        const editedItem = this.step1SurveyFormat.surveySchema.find(item => item.itemKey === itemKey);
        return `回答データが既に存在するため「${editedItem.title}」の「${originalValue}」を「${editedValue}」に変更できません。`;
      }
      return null;
    },
    onshowSurveyRegisterDialog() {
      this.showSurveyRegisterDialog = true;
    },
    onUpdateTeams() {
      this.showTeamSelectDialog = false;
      this.onshowSurveyRegisterDialog();
    },
  },
  created() {
    this.sectionLocal = cloneDeep(this.section);
    this.headerImageUrl.headerUrl = this.step1SurveyFormat.headerImageUrl ?this.step1SurveyFormat.headerImageUrl : {};
  },
  mounted(){
    if(eventBus){
      eventBus.$on("moveKeySetting", () => {
        console.log("moveKeySettingが呼ばれる")
        this.moveKeySetting();
        this.onshowSurveyRegisterDialog();
      });
      eventBus.$on("onActiveItem", () => {
        this.onActiveItem();
      });
      eventBus.$on("onUpdateTeams", () => {
        this.onUpdateTeams();
      });
      eventBus.$on("updateDataLocalToStore", () => {
        this.updateDataLocalToStore();
      });
    }
  }
});
</script>

<style lang="less">
.deactive {
  margin-top: 0 !important;
  .v-input__slot {
    &:hover {
      &:before {
        border-color: rgba(0, 0, 0, 0.12) !important;
      }
    }
    &:before {
      border-color: white !important;
    }
  }
}
.plus-button {
  margin-left: 15px;
  border-radius: 50% !important;
}
</style>
<style>
  .inputHeightCtrl fieldset,.inputHeightCtrl .v-input__control,.inputHeightCtrl .v-input__slot,.inputHeightCtrl .v-text-field__slot{
    height:40px!important;
    min-height:40px!important;
  }
  .inputHeightCtrl legend{
    display:none;
  }
  .inputHeightCtrl .v-input__append-inner{
    margin-top:6px!important;
  }
  .inputHeightCtrl .v-label,.inputHeightCtrl fieldset {
    top:0px;
    font-size:14px;
  }
  .fs-14 .v-label,.fs-14,.fs-14 input,.inputHeightCtrl input,.inputHeightCtrl .v-select__selection{
    font-size: 14px!important;
  }
  .v-input .v-input__control div{
    box-shadow:none!important;
  }
  .v-input--radio-group.radioFlex .v-input--radio-group__input{
    flex-direction: row;
    align-items: flex-start;
  }
  .radioFlex .v-radio label{
    font-size: 14px;
    left: 8px!important;
    /* top: 3px; */
  }
  .radioFlex .mdi-radiobox-blank::before,.radioFlex .mdi-radiobox-marked::before,.radioFlex .v-input--selection-controls__input{
    width:20px;
    height:20px;
  }
  .radioFlex .mdi-radiobox-blank::after,.radioFlex .mdi-radiobox-marked::after{
    width:22px;
    height:22px;
  }

  .headerImgArea .v-image__image{
    border-radius: 4px;
  }
  .headerImgArea .v-responsive__content{
    width: 100%!important;
    position:relative;
  }
  .headerImgArea .v-alert__content{
    position:relative;
  }
  .headerImgArea .v-alert__content .headerDeleteBtn{
    position:absolute;
    right:8px;
    top:8px;
    z-index:999;
    display:none!important;
    transition:all 0.6s;
  }
  .headerImgArea .v-alert__content:hover .headerDeleteBtn{
    display:inline-flex!important;
  }
  .editHeaderBtn{
    display:none;
  }
  .headerImgArea .v-responsive__content:hover .editHeaderBtn{
    position: absolute;
    display: inline-flex;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: auto;
  }
</style>
