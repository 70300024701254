<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-container>
      <v-radio-group v-model="radio">
        <v-row
          no-gutters
          v-for="(sectionOption, index) in sectionOptions"
          :key="index"
        >
          <v-col :cols="cols.col1">
            <v-radio
              :class="isLiffMode ? 'my-1' : ''"
              :label="sectionOption.option.value"
              :value="sectionOption.option.value"
              style="display: flex"
              @click="radioClick"
              :disabled="disabled"
            ></v-radio>
          </v-col>
          <v-col v-if="isAdminMode && !hasCountVaccines" :cols="cols.col2">
            <v-text-field
              v-model="sectionOption.groupheader.value"
              :label="label(index)"
              dense
              flat
              type="number"
              suffix="日"
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import InputChoiceGroupheader from "./InputChoiceGroupheader.vue";

export default Vue.extend({
  name: "InputSesshuVaccineMaker",
  mixins: [InputChoiceGroupheader],
  props: {
    mlLibJson:Object,
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    isLiffMode: {
      type: Boolean,
      default: false,
    },
    hasCountVaccines: {
      type: Boolean,
    }
  },
  computed: {},
  data() {
    return {
      cols: {
        col1: 8,
        col2: 4,
      },
    };
  },
  created() {
    if (this.isAdminMode) {
      this.cols.col1 = 8;
    } else {
      this.cols.col1 = 12;
    }
  },
  methods: {
    label(index) {
      return index === 0 ? "1回目接種翌日からの接種間隔日数" : null;
    },
  },
});
</script>
