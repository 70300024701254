<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-if="
            (isLiffMode &&
              (parameters.reservationCheckBox === undefined ||
                !parameters.reservationCheckBox)) ||
              !isLiffMode
          "
          v-model="input.daibunrui.name"
          :rules="[validateDaibunruiRequired]"
          :items="daibunruiOptions"
          item-value="name"
          :item-text="itemText"
          :label="
            setLargeCategoryTitle
              ? setLargeCategoryTitle
              : categories_tree.display.tag1
          "
          :required="required"
          :clearable="!isReadonlyLargeCategory"
          :disabled="isReadonlyLargeCategory || isDisableForPayment"
          @change="onChangeDaiBunrui"
        ></v-autocomplete>

        <v-autocomplete
          v-if="
            (isLiffMode &&
              (parameters.reservationSupCheckBox === undefined ||
                !parameters.reservationSupCheckBox)) ||
              !isLiffMode
          "
          v-model="input.chubunrui.name"
          :rules="[validateChubunruiRequired]"
          :items="input.chubunrui.options"
          item-value="name"
          :item-text="itemText"
          :label="
            setMediumCategoryTitle
              ? setMediumCategoryTitle
              : categories_tree.display.tag2
          "
          :required="required"
          :disabled="
            !input.daibunrui.name ||
              isReadonlyMediumCategory ||
              isDisableForPayment
          "
          :clearable="!isReadonlyMediumCategory"
          @change="onChangeChuBunrui"
        ></v-autocomplete>

        <v-autocomplete
          v-if="
            input.daibunrui.name &&
              input.chubunrui.name &&
              input.shobunrui.options &&
              input.shobunrui.options.length > 0
          "
          :disabled="
            !input.daibunrui.name ||
              !input.chubunrui.name ||
              isFixedSmallCategory ||
              isDisableForPayment
          "
          v-model="input.shobunrui.id"
          :rules="[validateShobunruiRequired]"
          :items="input.shobunrui.options"
          item-value="id"
          :item-text="itemText"
          :label="
            setSmallCategoryTitle
              ? setSmallCategoryTitle
              : categories_tree.display.tag3
          "
          :required="required"
          clearable
          @change="onChangeShoBunrui"
        ></v-autocomplete>
        <v-autocomplete
          v-if="
            ((input.chubunrui.id || input.shobunrui.id) &&
              reservationItem.length > 0) &&
              !isShowOrderDetails
          "
          v-show="reservationItem.length > 0"
          v-model="selectReservationItem"
          :items="reservationItem"
          item-value="itemId"
          item-text="itemName"
          label="予約項目"
          clearable
          :rules="[validateYoyakuKomoku]"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      align="center"
      v-if="
        input.calendarLabelDisplay &&
          this.parameters.reservationDate !== undefined
      "
    >
      <v-col cols="12" v-bind:class="readOnlyClass">
        <div>
          <span class="font-weight-bold">{{"予約時間"}}</span>
        </div>
        <div>
          {{ this.yoyaku_nichiji_label() }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import InputBase from "./InputBase.vue";
import { cloneDeep, isEmpty, get } from "lodash";


export default Vue.extend({
  name: "InputBunrui",
  mixins: [InputBase],
  props: {
    mlLibJson:Object,
    isLanguageSettingsActive: {
      type: Boolean,
      default: false,
    },
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    isLiffMode: {
      type: Boolean,
      default: false,
    },
    categoriesTree: {
      type: Object,
      default: null,
      required: false,
    },
    configJson: {
      type: Object
    },
    // for payment
    paymentResult: {
      type: Object,
      required: false,
    },
    taxRate: {
      type: Number,
      required: true,
    },
    paymentService: {
      type: Object,
      required: false,
      default: null,
    },
    products: {
      type: Array,
      required: false,
      default: () => []
    },
    isFetchingPaymentInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isShowOrderDetails() {
      return this.configJson.usePayment === 1;
    },
    isDisableForPayment() {
      // 決済データが存在する場合は編集不可
      return !!this.paymentResult;
    },
    readOnlyClass() {
      return {
        "v-input--is-disabled": true,
      };
    },
    categories_tree: {
      get() {
        let categories_tree = this.categories_tree_default
        if(!isEmpty(this.categoriesTree)){
          categories_tree = this.categoriesTree
        }else{
          if(!isEmpty(this.params.categories_tree)){
            categories_tree = this.params.categories_tree
          }
        }

        categories_tree = cloneDeep(categories_tree)
        return categories_tree
      }
    },
    daibunruiOptions: {
      get() {
        return cloneDeep(this.categories_tree.tree);
      }
    },
    itemText: {
      get() {
        if (this.isLanguageSettingsActive) {
          return "nameText";
        } else {
          return "name";
        }
      }
    },
  },
  data() {
    return {
      categoriesInfo: [{ id: null, name:''}],
      categorySelectedID : '',
      parameters: null,
      isFixedLargeCategory: false,
      isFixedMediumCategory: false,
      isFixedSmallCategory: false,
      isReadonlyLargeCategory: false,
      isReadonlyMediumCategory: false,
      setLargeCategoryTitle: null,
      setMediumCategoryTitle: null,
      setSmallCategoryTitle: null,
      categories_tree_default: {
        display: {
          tag1: "大分類",
          tag2: "中分類",
          tag3: "小分類",
          title: "LINE診察予約受付枠",
        },
        tree: [
          {
            name: "大分類１",
            children: [
              {
                name: "中分類１",
                children: [
                  { id: "1", name: "小分類１" },
                  {
                    id: "2",
                    name: "小分類２",
                    calendarId: "calendar#hospital#b-sensei",
                  },
                  {
                    id: "3",
                    name: "小分類３",
                    calendarId: "calendar#hospital#c-sensei",
                  },
                ],
              },
              {
                name: "中分類２",
                children: [
                  {
                    id: "4",
                    name: "小分類４",
                    calendarId: "calendar#hospital#d-sensei",
                  },
                ],
              },
              {
                name: "中分類３",
                children: [
                  { id: "5", name: "小分類５" },
                  {
                    id: "6",
                    name: "小分類６",
                    calendarId: "calendar#hospital#f-sensei",
                  },
                ],
              },
            ],
          },
          {
            name: "大分類２",
            children: [
              {
                id: "7",
                name: "中分類４",
                children: [],
                calendarId: "calendar#hospital#zray",
              },
              {
                id: "8",
                name: "中分類５",
                children: [],
                calendarId: "calendar#hospital#ekou",
              },
              {
                id: "9",
                name: "中分類６",
                children: [],
              },
              {
                id: "10",
                name: "中分類７",
                children: [],
              },
            ],
          },
        ],
      },

      input: {
        id: null,
        daibunrui: { name: null },
        chubunrui: { id: null, name: null, options: [] },
        shobunrui: { id: null, name: null, options: [] },
        categoryRequiredFlag: false,
        calendarLabelDisplay: false,
      },
      reservationItemData: [],
      reservationItem: [],
      selectReservationItem: null,
    };
  },
  created() {
    this.parameters = cloneDeep(this.params);
    this.categorySelectedID = this.parameters?.input || '';
    !!this.categorySelectedID && this.onChangeShoBunrui(this.categorySelectedID);
    // title setup
    if (
      this.parameters.setLargeCategoryTitle &&
      this.parameters.setLargeCategoryTitle !== ""
    ) {
      this.setLargeCategoryTitle = this.parameters.setLargeCategoryTitle;
    }
    if (
      this.parameters.setMediumCategoryTitle &&
      this.parameters.setMediumCategoryTitle !== ""
    ) {
      this.setMediumCategoryTitle = this.parameters.setMediumCategoryTitle;
    }
    if (
      this.parameters.setSmallCategoryTitle &&
      this.parameters.setSmallCategoryTitle !== ""
    ) {
      this.setSmallCategoryTitle = this.parameters.setSmallCategoryTitle;
    }

    // set value of categories
    if (this.disabled) {
      this.isReadonlyLargeCategory = true;
      this.isReadonlyMediumCategory = true;
      this.isFixedSmallCategory = true;
    } else if (this.isAdminMode !== undefined && this.isAdminMode) {
      if (
        this.parameters.reservationCheckBox !== undefined &&
        this.parameters.reservationCheckBox &&
        this.parameters.selectedLargeCategory !== undefined &&
        this.parameters.selectedLargeCategory.name !== undefined &&
        this.parameters.selectedLargeCategory.name !== null
      ) {
        this.isReadonlyLargeCategory = true;
      }
      if (
        this.parameters.reservationSupCheckBox !== undefined &&
        this.parameters.reservationSupCheckBox &&
        this.parameters.selectedMediumCategory !== undefined &&
        this.parameters.selectedMediumCategory.name !== undefined &&
        this.parameters.selectedMediumCategory.name !== null
      ) {
        this.isReadonlyMediumCategory = true;
      }
    } else {
      this.isFixedLargeCategory = this.parameters.isFixedLargeCategory
        ? this.parameters.isFixedLargeCategory
        : false;
      this.isFixedMediumCategory = this.parameters.isFixedMediumCategory
        ? this.parameters.isFixedMediumCategory
        : false;
      this.isFixedSmallCategory = this.parameters.isFixedSmallCategory
        ? this.parameters.isFixedSmallCategory
        : false;

      if (this.isLiffMode) {
        if (
          this.isFixedLargeCategory ||
          (this.parameters.reservationCheckBox !== undefined &&
            this.parameters.reservationCheckBox)
        ) {
          this.isReadonlyLargeCategory = true;
        }
        if (
          this.isFixedMediumCategory ||
          (this.parameters.reservationSupCheckBox !== undefined &&
            this.parameters.reservationSupCheckBox)
        ) {
          this.isReadonlyMediumCategory = true;
        }
      }
    }

    var params = Object.assign(
      {
        options: [],
        isRequired: { value: false },
        input: this.input,
      },
      this.params
    );

    let bunrui_id_original = params.input;
    let bunrui_id = params.input;
    params.isRequired.value = this.params.categoryRequiredFlag;

    if (
      bunrui_id !== undefined &&
      bunrui_id !== null &&
      bunrui_id !== "" &&
      typeof bunrui_id === "string"
    ) {
      let index_of = bunrui_id_original.indexOf("|");
      if (index_of > 0) {
        bunrui_id = bunrui_id_original.substr(0, index_of);
      }
    }

    if (typeof bunrui_id === "string") {
      let ids = bunrui_id.split("_");
      this.input.id = ids[0];
      // 予約項目がある場合、初期選択値セット
      if (ids.length > 1) {
        this.selectReservationItem = Number(ids[1]);
      }
    }

    if (params.calendarLabelDisplay) {
      this.input.calendarLabelDisplay = params.calendarLabelDisplay;
    }

    if (params.reservationItemData) {
      this.reservationItemData = params.reservationItemData;
    }

    let dataById = this.getDataById(this.input.id);
    this.input = { ...dataById };

    // set default MainCategory(largeCatergory)
    if (
      this.parameters.reservationCheckBox !== undefined &&
      this.parameters.reservationCheckBox &&
      this.parameters.selectedLargeCategory !== undefined &&
      this.parameters.selectedLargeCategory != null &&
      this.parameters.selectedLargeCategory.name !== undefined &&
      this.parameters.selectedLargeCategory.name !== null
    ) {
      this.input.daibunrui = {
        name: this.parameters.selectedLargeCategory.name,
      };
      var daibunrui_name = this.parameters.selectedLargeCategory.name;
      let daibunrui_filter = this.categories_tree.tree.filter(function (
        daibunrui
      ) {
        return daibunrui.name === daibunrui_name;
      });
      if (daibunrui_filter.length > 0) {
        this.input.chubunrui.options = cloneDeep(daibunrui_filter[0].children);
      }
    }

    // 中分類
    if (
      this.parameters.reservationSupCheckBox !== undefined &&
      this.parameters.reservationSupCheckBox &&
      this.parameters.selectedMediumCategory !== undefined &&
      this.parameters.selectedMediumCategory.name !== undefined &&
      this.parameters.selectedMediumCategory.name !== null
    ) {
      this.input.chubunrui.name = this.parameters.selectedMediumCategory.name;
      let chubunrui_name = this.parameters.selectedMediumCategory.name;
      let chubunrui_filter = this.input.chubunrui.options.filter(function (
        chubunrui
      ) {
        return chubunrui.name === chubunrui_name;
      });
      // 多言語化が有効であるかチェック
      if (this.isLanguageSettingsActive) {
        // 表示用keyを追加する
        chubunrui_filter = chubunrui_filter.map(chubunrui => {
          const mediumCategoryChildren = this.parameters.selectedMediumCategory.children;
          const tmpChubunrui = chubunrui.children.map(children => {
            const tempChildren = mediumCategoryChildren.map(mediumChildren => {
              if (children.calendarId === mediumChildren.calendarId) {
                return {
                  calendarId: mediumChildren.calendarId,
                  id: mediumChildren.id,
                  name: mediumChildren.name,
                  nameText: mediumChildren.nameText,
                  order: mediumChildren.order,
                }
              }
            });
            return tempChildren;
          });
          return {
            children: tmpChubunrui,
            name: chubunrui.name,
            order: chubunrui.order,
          }
        });
      }

      if (chubunrui_filter.length > 0) {
        if (
          chubunrui_filter[0].id !== undefined &&
          chubunrui_filter[0].id != null &&
          chubunrui_filter[0].id != ""
        ) {
          this.input.chubunrui.id = chubunrui_filter[0].id;
        } else {
          this.input.chubunrui.id = null;
        }
        this.input.shobunrui.options = cloneDeep(chubunrui_filter[0].children);
      }
    }
    if (this.input.chubunrui.name) {
      if (this.input.daibunrui.name) {
        let daibunrui_name = this.input.daibunrui.name;

        let daibunrui_filter = this.categories_tree.tree.filter(function (
          daibunrui
        ) {
          return daibunrui.name === daibunrui_name;
        });

        if (daibunrui_filter.length > 0) {
          this.input.chubunrui.options = cloneDeep(
            daibunrui_filter[0].children
          );
        }
      }
    }

    // 小分類
    if (this.input.shobunrui.id) {
      if (this.input.chubunrui.name) {
        let chubunrui_name = this.input.chubunrui.name;

        let chubunrui_filter = this.input.chubunrui.options.filter(function (
          chubunrui
        ) {
          return chubunrui.name === chubunrui_name;
        });

        if (chubunrui_filter.length > 0) {
          this.input.shobunrui.options = cloneDeep(
            chubunrui_filter[0].children
          );
        }
      }
    } else {
      if (this.input.chubunrui.name) {
        this.input.shobunrui.id = null;
        let chubunrui_name = this.input.chubunrui.name;

        let chubunrui_filter = this.input.chubunrui.options.filter(function (
          chubunrui
        ) {
          return chubunrui.name === chubunrui_name;
        });

        if (chubunrui_filter.length > 0) {
          this.input.shobunrui.options = cloneDeep(
            chubunrui_filter[0].children
          );
        }
      }
    }

    // 分類IDがあって、それに対する予約項目がある場合セット
    let shoBunruiId = this.input.shobunrui.id;
    let chuBunruiId = this.input.chubunrui.id;
    let selectBunruiId = shoBunruiId ? shoBunruiId : chuBunruiId;
    if (selectBunruiId) {
      let setReservationItem = this.reservationItemData.filter(function (item) {
        return item.categoryId === selectBunruiId;
      });
      this.reservationItem = setReservationItem.sort(this.orderByItemIdAsk);
    } else {
      this.reservationItem = [];
    }

    this.itemKey = this.params.itemKey;
    this.required = params.isRequired.value;
    
    if (this.isShowOrderDetails && (this.categorySelectedID || selectBunruiId)) {
      // 商品選択アイテムをセット
      this.handleSetPaymentInfo(this.categorySelectedID || selectBunruiId);
    }
  },
  methods: {
    getValue() {
      let categoryId = null;
      let itemId = 0;
      if (this.selectReservationItem) {
        itemId = this.selectReservationItem;
      }

      if (
        this.isLiffMode &&
        this.input.shobunrui.options &&
        this.input.shobunrui.options.length > 0
      ) {
        categoryId = this.input.shobunrui.id || null;
      } else {
        categoryId = this.input.shobunrui.id || this.input.chubunrui.id || null;
      }

      let returnValue = null;

      if (categoryId) {
        returnValue = `${categoryId}_${itemId}`;
      }

      return returnValue;
    },
    getPaymentData() {
      return this.$refs.inputPaymentData.getValue();
    },
    initOrder() {
      return this.$refs.inputPaymentData.initOrder();
    },
    validateDaibunruiRequired() {
      return (
        !this.required ||
        (this.input !== undefined &&
          this.input.daibunrui != null &&
          this.input.daibunrui.name != null)
      );
    },
    validateChubunruiRequired() {
      return (
        !this.required ||
        (this.input !== undefined &&
          this.input.chubunrui != null &&
          (this.input.chubunrui.id != null ||
            this.input.chubunrui.name != null))
      );
    },
    validateShobunruiRequired() {
      let is_empty = (this.getValue() == null || this.getValue() == "" || this.getValue() == undefined)
      return !this.required || !is_empty;
    },
    validateYoyakuKomoku() {
      let is_empty = (this.selectReservationItem == null || this.selectReservationItem == "" || this.selectReservationItem == undefined)
      return !this.required || !is_empty;
    },
    onChangeDaiBunrui(daibunrui_name) {
      this.input.daibunrui = { name: daibunrui_name };
      this.input.chubunrui = {
        id: null,
        name: null,
        options: this.input.chubunrui.options,
      };

      this.input.shobunrui.id = null;
      if (!daibunrui_name) {
        return null;
      }

      {
        let daibunrui_filter = this.categories_tree.tree.filter(function (
          daibunrui
        ) {
          return daibunrui.name === daibunrui_name;
        });

        if (daibunrui_filter.length > 0) {
          this.input.chubunrui.options = cloneDeep(
            daibunrui_filter[0].children
          );

          return this.input.chubunrui;
        }
      }

      // 商品選択アイテムを初期化
      this.handleSetPaymentInfo(null);

      return null;
    },
    async onChangeChuBunrui(chubunrui_name) {
      this.$emit("handleCategoryChange", true);
      this.input.chubunrui = { ...this.input.chubunrui, name: chubunrui_name };
      this.input.shobunrui = {
        id: null,
        name: null,
        options: this.input.shobunrui.options,
      };

      this.input.chubunrui.id = null;
      if (!chubunrui_name) {
        this.handleSetPaymentInfo(null);
        return null;
      }

      {
        let chubunrui_filter = this.input.chubunrui.options.filter(function (
          chubunrui
        ) {
          return chubunrui.name === chubunrui_name;
        });

        if (chubunrui_filter.length > 0) {
          if (
            chubunrui_filter[0].id !== undefined &&
            chubunrui_filter[0].id != null &&
            chubunrui_filter[0].id != ""
          ) {
            this.input.chubunrui.id = chubunrui_filter[0].id;
          } else {
            this.input.chubunrui.id = null;
          }
          this.input.shobunrui.options = cloneDeep(
            chubunrui_filter[0].children
          );

          // 商品選択用の予約項目アイテムをセット
          if (this.isShowOrderDetails) {
            this.handleSetPaymentInfo(this.input.chubunrui.id);
          }

          // 分類IDがあって、それに対する予約項目がある場合セット
          if (this.input.chubunrui.id) {
            const categoryId = this.input.chubunrui.id;
            let setReservationItem = this.reservationItemData.filter(function (
              item
            ) {
              return item.categoryId === categoryId;
            });
            this.reservationItem = setReservationItem.sort(
              this.orderByItemIdAsk
            );
          } else {
            this.reservationItem = [];
          }
          this.selectReservationItem = 0;

          return this.input.shobunrui;
        }
      }

      return null;
    },
    onChangeShoBunrui(shobunrui_id) {
      // 分類IDがあって、それに対する予約項目がある場合セット
      this.$emit("handleCategoryChange", true);
      
      // 多言語化が有効であるかチェック
      if (this.isLanguageSettingsActive) {
        // 選択された分類に該当するcategories_treeをセット
        this.$emit("handleSelectCategoriesTreeChange", this.params.categories_tree);
      }
      
      this.categorySelectedID = shobunrui_id;
      if (shobunrui_id) {
        let setReservationItem = this.reservationItemData.filter(
          function(item) {
            return item.categoryId === shobunrui_id;
          }
        );
        this.reservationItem = setReservationItem;
      } else {
        this.reservationItem = [];
      }
      this.selectReservationItem = 0;
      // 商品選択用の予約項目アイテムをセット
      if (this.isShowOrderDetails) {
        this.handleSetPaymentInfo(shobunrui_id || this.input.chubunrui.id);
      }
    },
    getDataById(bunrui_id) {
      let result = this.input;
      result.id = bunrui_id;

      if (bunrui_id === null) {
        return result;
      }

      for (let i = 0; i < this.categories_tree.tree.length; i++) {
        let daibunrui = cloneDeep(this.categories_tree.tree[i]);
        for (
          let chubunrui_index = 0;
          chubunrui_index < daibunrui.children.length;
          chubunrui_index++
        ) {
          let chubunrui = cloneDeep(daibunrui.children[chubunrui_index]);
          if (chubunrui.id !== undefined) {
            if (chubunrui.id === bunrui_id) {
              result.chubunrui = chubunrui;
              result.daibunrui = daibunrui;
              return result;
            } else if (chubunrui.children.length >= 0) {
              for (
                let shobunrui_index = 0;
                shobunrui_index < chubunrui.children.length;
                shobunrui_index++
              ) {
                let shobunrui = chubunrui.children[shobunrui_index];
                if (shobunrui.id !== undefined) {
                  if (shobunrui.id === bunrui_id) {
                    result.shobunrui = shobunrui;
                    result.chubunrui = chubunrui;
                    result.daibunrui = daibunrui;
                    return result;
                  }
                }
              }
            }
          } else {
            for (
              let shobunrui_index = 0;
              shobunrui_index < chubunrui.children.length;
              shobunrui_index++
            ) {
              let shobunrui = cloneDeep(chubunrui.children[shobunrui_index]);
              if (shobunrui.id !== undefined) {
                if (shobunrui.id === bunrui_id) {
                  result.shobunrui = shobunrui;
                  result.chubunrui = chubunrui;
                  result.daibunrui = daibunrui;
                  return result;
                }
              }
            }
          }
        }
      }
      return result;
    },
    yoyaku_nichiji_label() {
      let result = "";
      if (this.parameters.reservationDate !== undefined) {
        result = this.parameters.reservationDate;
      }
      return result;
    },
    orderByItemIdAsk(a, b) {
      const idA = Number(a.itemId);
      const idB = Number(b.itemId);

      let comparison = 0;
      if (idA > idB) {
        comparison = 1;
      } else if (idA < idB) {
        comparison = -1;
      }
      return comparison;
    },
    async handleSetPaymentInfo(categoryId) {
      let id = categoryId;
      if (id) {
        const underLine = '_';
        id = categoryId.split(underLine)[0];
      }
      this.$emit('setPaymentInfo', id);
    },
  },
});
</script>
