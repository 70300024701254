<template>
  <div>
    <v-dialog
      v-model="show"
      eager
      persistent
      width="800"
    >
      <v-card>
        <v-card-title>
          <div>アレルゲン設定</div>
        </v-card-title>
        <v-card-text>
          <v-chip-group
            v-model="allergenSettingInfo.allergenSettingList"
            column
            multiple
          >
            <v-chip
              v-for="schoolLunchAllergenInfo in schoolLunchAllergenInfoList"
              :key="schoolLunchAllergenInfo.school_lunch_allergen_id"
              :value="schoolLunchAllergenInfo.school_lunch_allergen_id"
              outlined
              filter
              large
              class="custom-chip px-9"
              style="height: 42px;"
            >{{ schoolLunchAllergenInfo.school_lunch_allergen_name }}</v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn
                  elevation="0"
                  :loading="loading"
                  @click="close"
                >キャンセル</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  elevation="0"
                  :loading="loading"
                  @click="confirmToSave"
                >保存</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapState,
  mapActions
} from "vuex";
import {
  UPDATE_SCHOOL_LUNCH_ALLERGEN_SETTING,
  FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST
} from "@/store/action-types";
import { cloneDeep } from "lodash";

export default Vue.extend({
  props: {
    value: Boolean,
    studentId: String,
    allergenSettingList: Array
  },
  data() {
    return {
      loading: false,
      allergenSettingInfo: {
        allergenSettingList: []
      }
    };
  },
  computed: {
    ...mapState({
      schoolLunchAllergenInfoList: state => state.schoolLunchAllergenInfoList,
      schoolId: state => state.loginUser.school_id,
      fiscalYear: state => state.fiscalYear,
      authority: state => state.loginUser.authority,
      managed_grade: state => state.loginUser.managed_grade,
      managed_class: state => state.loginUser.managed_class,
      loginUser: state => state.loginUser
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    show() {
      /* Propsの情報をDataに反映する */
      this.applyPropsToData();
    }
  },
  methods: {
    ...mapActions({
      updateSchoolLunchAllergenSetting: UPDATE_SCHOOL_LUNCH_ALLERGEN_SETTING,
      fetchSchoolLunchAllergenSettingStatus: FETCH_SCHOOL_LUNCH_ALLERGEN_SETTING_STATUS_LIST
    }),
    applyPropsToData() {
      this.allergenSettingInfo.allergenSettingList = cloneDeep(this.allergenSettingList).map(allergenSetting => allergenSetting.id);
    },

    /* 保存 */
    async save() {
      /* ローディング開始 */
      this.loading = true;
      /* アレルゲン設定を更新 */
      await this.updateSchoolLunchAllergenSetting({
        studentId: this.studentId,
        schoolLunchAllergenIdList: this.allergenSettingInfo.allergenSettingList,
        updatedBy: (this.loginUser.last_name + this.loginUser.first_name)
      });
      /* アレルゲン通知設定状況を取得 */
      await this.fetchSchoolLunchAllergenSettingStatus({
        schoolId: this.schoolId,
        fiscalYear: this.fiscalYear,
        authority: this.authority,
        gradeIdManaged: this.managed_grade,
        classIdManaged: this.managed_class
      });
      /* ダイアログを閉じる */
      this.close();
      /* ローディング終了 */
      this.loading = false;
    },
    confirmToSave() {
      this.$dialog.show({
        title: "アレルゲン設定を更新してもよろしいですか？",
        text: "この操作は取り消せません。",
        type: "info",
        btnConfirmTitle: "更新",
        onConfirm: this.save
      });
    },

    /* 閉じる */
    close() {
      this.show = false;
    }
  }
})
</script>

<style scoped>
.custom-chip {
  color: #666666;
  border-color: #666666;
}
.custom-chip.v-chip--active {
  color: #11993C;
  border-color: #11993C;
}
</style>