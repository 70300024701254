<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-row no-gutters class="mb-4 mt-4">
      <v-col cols="12" >
        <ul ref="itemRadioDrag" class="form-editor-drag">
          <li v-for="(option, index) in item.options" :key="option + index" >
            <v-hover v-slot:default="{ hover }">
              <v-text-field
                :key="option + index"
                class="deactive pt-1"
                hide-details
                :value="option"
                @change="(value) => onChangeOption(value, index)"
                :readonly="isReadOnly(index, option)"
                :disabled="!isUnPublished"
                maxlength="64"
              >
                <template v-slot:prepend>
                  <span style="width: 25px" v-if="isEditMode === false">
                    <v-icon v-if="hover && isActive && option != 'その他'" class="radio-drag-handle pl-1">mdi-drag </v-icon>
                  </span>
                  <span style="width: 25px" v-else> </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon @click="toggleDefaultValue(option)" v-bind="attrs" v-on="on">
                        {{ isDefaultValue(option) ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </template>
                    <span>新規入力時の初期値</span>
                  </v-tooltip>
                </template>
                <template v-slot:append-outer v-if="isActive">
                  <v-btn 
                    icon 
                    :disabled="!isUnPublished"
                    @click="removeOption(index)" 
                    v-if="isShowRemoveButton(index)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-hover>
          </li>
        </ul>
        <v-row no-gutters v-if="isUnPublished" :class="{ 'mt-2': !isMultipleOptions }">
          <v-col>
            <div class="pt-2 pl-6" v-if="isExistOtherOption && !isMaxOption">
              <a @click="addOption">
                <span style="width: 25px"> </span>
                <v-icon left>mdi-checkbox-blank-outline</v-icon>選択肢を追加
              </a>
            </div>
            <div class="pt-2 pl-6" v-else-if="!isMaxOption">
              <a @click="addOption">
                <span style="width: 25px"> </span>
                <v-icon left>mdi-checkbox-blank-outline</v-icon>選択肢を追加
              </a>
               または
              <a @click="addOptionOther" style="color: #548CEC">
                <span style="width: 25px"> </span>
                「その他」を追加
              </a>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <template v-if="isActive">
      <v-divider class="my-3"></v-divider>
      <v-subheader class="pl-6">回答の検証を設定（任意）</v-subheader>
      <Selection :item="item" :isEditMode="isEditMode" :isUnPublished="isUnPublished"/>
    </template> -->
  </div>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";
// import Selection from "./ValidatorComponents/Selection.vue";

export default Vue.extend({
  name: "ItemMultipleChoice",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
    isUnPublished: {
      type: Boolean,
      required: true,
    },
    editIndex: {
      type: Number,
      required: false,
    },
    originalOptionsIndex: {
      type: Number,
      required: true,
    },
  },
  components: { 
    // Selection 
  },
  computed: {
    isMultipleOptions() {
      return this.item.options.length > 1;
    },
    isShowAddOption() {
      if (!this.isActive) {
        return false;
      }

      const originalOptionsLength = this.originalOptionsIndex + 1;
      if (this.isEditMode) {
        return this.item.isNewItem ? true : this.item.options.length === originalOptionsLength;
      }

      return true;
    },
    isExistOtherOption() {
      // その他オプションが存在するか
      const isExist = this.item.options.includes("その他");
      return isExist;
    },
    isMaxOption() {
      const isMax = this.item.options.length >= 10;
      return isMax;
    },
  },
  methods: {
    setupDrag() {
      let list = this.$refs.itemRadioDrag;
      const _self = this;
      if (list) {
        Sortable.create(list, {
          handle: ".radio-drag-handle", // Use handle so user can select text
          animation: 150,
          chosenClass: "white",
          onEnd({ newIndex, oldIndex }) {
            _self.$emit("moveOption", newIndex, oldIndex);
          },
        });
      }
    },
    addOption() {
      this.$emit("addOption");
    },
    addOptionOther() {
      console.log("addOptionOther")
      this.$emit("addOptionOther");
    },
    removeOption(index) {
      this.$emit("removeOption", index);
    },
    onChangeOption(value, index) {
      this.$emit("updateOption", index, value);
    },
    toggleDefaultValue(value) {
      let _index = this.item.default ? this.item.default.findIndex((obj) => obj === value) : -1;
      if (_index >= 0) {
        this.item.default.splice(_index, 1);
      } else if (Array.isArray(this.item.default)) {
        this.item.default.push(value);
      } else {
        this.item.default = [...value];
      }
    },
    isDefaultValue(value) {
      return this.item.default && Array.isArray(this.item.default) && this.item.default.includes(value);
    },
    isReadOnly(index, option) {
      const isNotEditableIndex = this.editIndex !== index && this.originalOptionsIndex >= index && this.editIndex !== -1;
      return this.isEditMode && !this.item.isNewItem && isNotEditableIndex || option == "その他";
    },
    isShowRemoveButton(index) {
      if (this.isEditMode) {
        return this.isNewItem ? this.isMultipleOptions : index > this.originalOptionsIndex;
      } else {
        return this.isMultipleOptions;
      }
    },
  },
  mounted() {
    this.setupDrag();
  },
});
</script>

<style lang="less" scoped>
.radio-drag-handle {
  cursor: move;
}
</style>
