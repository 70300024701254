import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { Mixin } from '@/mixins/index.js'
import Snackbar from '@/plugins/snackbar'
import Dialog from '@/plugins/dialog'

// API.configure(config);
Vue.use(Snackbar)
Vue.use(Dialog)

const ContentLoading = () => import('@/components/common/ContentLoading.vue')
Vue.component('content-loading', ContentLoading);

export const eventBus = new Vue();

Vue.mixin(Mixin);
Vue.use(Dialog)
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
