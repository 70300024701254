<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <HorizontalBarChartBase :chartData="dataSet" :options="options" :height="250" />
</template>

<script>
import Vue from "vue";
import HorizontalBarChartBase from "@/charts/HorizontalBarChartBase";
import style from "@/mixins/style";
import 'chartjs-plugin-datalabels';

export default Vue.extend({
  name: "BarChart",
  components: { HorizontalBarChartBase },
  props: {
    dataSet: {
      type: Object,
    },
  },
  mixins: [style],
  data() {
    return {
      chartData: {},
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const kazu = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
              const kazuList = data.datasets[tooltipItem.datasetIndex].data
              let sum = 0
              for(let i = 0; i < kazuList.length; i++) {
                sum += kazuList[i]
              }
              let warizan = 0
              if (sum != 0 && kazu !=0){
                warizan = kazu/sum
              }
              return data.labels[tooltipItem.index] + ' ' + kazu + '件 ' + Math.round(warizan*100) + '%';
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            }
          }],
          x: {
            beginAtZero: true,
          }
        },
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            color: "#000",
            font: {
              weight: 'bold',
              // size: 20,
            },
            anchor: "center", // 値を出す位置
            align: "end",
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = value + "（" + ((value * 100) / sum).toFixed(2) + "%）";
              // return percentage;
              return '';
            },
          },
        },
      },
    };
  },
  created() { },
});
</script>
