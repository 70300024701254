<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div class="checkbox-container">
    <template v-for="(option, index) in options">
      <template v-if="option != 'その他'">
        <v-checkbox
          v-model="checkboxes"
          :key="option"
          :label="option"
          :value="option"
          :rules="[
            validateRequired,
            validateMinSelection,
            validateMaxSelection,
          ]"
          :disabled="isDisabled(option) || disabled"
          hide-details
        ></v-checkbox>
      </template>
      <template v-else>
        <v-row class="other-checkbox" :key="index" style="margin-left: 0px">
          <v-checkbox
            v-model="checkboxes"
            :value="otherTextValue"
            :rules="[
              validateRequired,
              validateMinSelection,
              validateMaxSelection,
            ]"
            :disabled="isDisabled(option) || disabled"
            hide-details
          ></v-checkbox>
          <v-text-field
            :placeholder="option"
            v-model="otherTextValue"
            @input="handleChange"
          >
          </v-text-field>
        </v-row>
      </template>
    </template>
    
  </div>
</template>

<script>
import Vue from "vue";
import InputBase from "./InputBase.vue";

export default Vue.extend({
  name: "InputCheckbox",
  mixins: [InputBase],
  props: {
    isLiffMode: Boolean,
    isAdminMode: Boolean,
  },
  computed: {},
  data() {
    return {
      checkboxes: null,
      otherTextValue: '',
    };
  },
  created() {
    // eslint-disable-next-line no-extra-boolean-cast
    this.checkboxes = !!this.params.input ? this.params.input : [];
    this.itemKey = this.params.itemKey;

    var params = Object.assign(
      {
        options: [],
        isRequired: { value: false },
        isRequiredForUser: { value: false },
        selection: { min: 0, max: Infinity },
        input: [],
      },
      this.params
    );

    this.options = params.options;
    this.required = params.isRequired.value;
    this.isRequiredForUser = params.isRequiredForUser.value;
    this.selection = params.selection;
    if (this.selection.min == null) {
      this.selection.min = 0;
    }
    if (this.selection.max == null) {
      this.selection.max = Infinity;
    }
    if (params.input === undefined || params.input === null) {
      this.checkboxes = [];
    } else if (Array.isArray(params.input)) {
      this.checkboxes = [];
      for (let input_index in params.input) {
        let input = params.input[input_index];
        for (let option_index in params.options) {
          let option = params.options[option_index];
          if (input == option) {
            this.checkboxes.push(input);
          }
        }
      }
    } else {
      this.checkboxes = [params.input];
    }
  },
  methods: {
    getValue() {
      return this.checkboxes;
    },
    validateRequired() {
      const isValid = this.checkboxes.length != 0;
      if (this.isAdminMode) {
        return !this.required || isValid;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || isValid;
      }
    },
    validateMinSelection() {
      return this.checkboxes.length >= this.selection.min;
    },
    validateMaxSelection() {
      return this.checkboxes.length <= this.selection.max;
    },
    isDisabled(input) {
      if (this.checkboxes === undefined) {
        return false;
      }
      return (
        this.checkboxes.indexOf(input) === -1 &&
        this.checkboxes.length >= this.selection.max
      );
    },
    handleChange() {
      if (this.otherTextValue === "") {
        // その他項目の入力値がない場合は、チェックしたリストから削除する
        const filteredoOptions = this.checkboxes.filter(item => this.options.includes(item));
        // 重複削除
        const uniqueArray = [...new Set(filteredoOptions)];
        this.checkboxes = uniqueArray;
      } else {
        // その他項目の入力値がある場合は、チェックしたリストに追加する
        let filteredoOptions = this.checkboxes.filter(item => this.options.includes(item));
        filteredoOptions.push(this.otherTextValue);
        // 重複削除
        const uniqueArray = [...new Set(filteredoOptions)];
        this.checkboxes = uniqueArray;
        
        // 存在している値を入力した場合はチェック状態を解除して、入力値も初期化する
        const isExist = this.options.includes(this.otherTextValue);
        if (isExist) {
          this.otherTextValue = "";
        }
      }
    }
  },
});
</script>
<style>
.checkbox-container .v-input--checkbox .v-input__control .v-input__slot .v-label {
  font-size: 16px!important;
}
</style>

<style scoped>
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-container .v-input--checkbox {
  margin-right: 3px;
}
.v-input--selection-controls {
  margin-top: 3px;
}
.other-checkbox .v-input--selection-controls {
  padding-top: 18px;
}
</style>