<template>
    <div>
        <DeliveryCreation 
        :isEnquete="true"
        :enqueteTabType="tabType"
        :isCopy="isCopy"
        :enqueteActiveTab="enqueteActiveTab"
        @onChangeEnqueteType="onChangeEnqueteType($event)"
        />

        <template v-if="isLineEnquete">
            <v-row class="mb-2">
                <v-col cols="2">
                <v-subheader>テンプレート名</v-subheader>
                </v-col>
                <v-col cols="8">
                <v-autocomplete 
                    :items="items" 
                    item-text="surveyTitle" 
                    item-value="surveyId"
                    v-model="selectEnquete" 
                    flat 
                    outlined 
                    dense 
                    hide-details
                    return-object
                    :disabled="!isUnPublished"
                    >
                    <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                    </template>
                </v-autocomplete>
                </v-col>
            </v-row>
            <EnqueteCreation 
                v-if="!isEmpty(selectEnquete)"
                template="blank"
                :isEnquete="true"
                :surveyId="selectEnquete.surveyId"
            />
        </template>
        <template v-else>
            <v-row class="mb-2">
                <v-col cols="2">
                <v-subheader>外部アンケートURL</v-subheader>
                </v-col>
                <v-col cols="8">
                <v-text-field 
                  outlined dense 
                  hide-details="auto"
                  placeholder="url"
                  v-model="url"
                  :disabled="!isUnPublished">
                </v-text-field>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
} from "vuex";
import { 
  SET_EXTERNAL_ENQUETE_URL,
} from "@/store/mutation-types";
import {
  FETCH_SURVEY_CONFIGS,
} from "@/store/action-types";
import { cloneDeep } from "lodash";
import DeliveryCreation from "@/components/SegmentDelivery/DeliveryCreation.vue"
import EnqueteCreation from "@/components/Enquete/EnqueteCreation/index.vue";

export default {
  name: "EnqueteTab",
  props: {
    isCopy: {
      type: Boolean,
      default: false,
    },
    enqueteActiveTab:{
      type:String,
      default: null,
    }
  },
  data() {
    return {
      isLineEnquete: true,
      defaultItem: {
        surveyTitle: "テンプレートなしで作成",
        surveyId: "blank",
      },
      items: [],
      selectEnquete: {},
      tabType: 0,
      url: '',
    };
  },
  components: {
    DeliveryCreation,
    EnqueteCreation,
  },
  computed: {
    ...mapState({
      surveyConfigsListType: (state) => state.forms.surveyConfigsListType,
      loginUser: (state) => state.loginUser,
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      isUnPublished: state => state.isUnPublished,
      externalEnqueteURL: state => state.externalEnqueteURL,
      fiscalYear: (state) => state.fiscalYear,
    }),
  },
  watch: {
    surveyConfigsListType(value) {
      // リスト初期化
      this.items = [];
      // テンプレートなしで作成の選択肢を追加
      this.items.push(this.defaultItem);
      // 取得したテンプレートのリストを結合
      this.items = this.items.concat(value);

      // 編集であるかチェック
      if (this.enqueteOpenInfo.deliveryInfo) {
        // テンプレートなしで作成されたアンケート開催情報であるかチェック
        const templateInfo = this.enqueteOpenInfo.templateInfo;
        const item = this.items.find(item => item.surveyId == templateInfo.surveyId);
        if (!item) {
          // テンプレートなしで作成されたテンプレートは、テンプレート名の一覧に存在しない為、追加する
          // 先頭から2番目に要素を挿入
          this.items.splice(1, 0, templateInfo);
        }
      }
    },
    url(value) {
      if (value) {
        this.setExternalEnqueteURL(value);
      }
    },
    async fiscalYear() {
      await this.reload();
    },
  },
  methods: {
    ...mapActions({
      fetchSurveyConfigs: FETCH_SURVEY_CONFIGS,
    }),
    ...mapMutations({
      setExternalEnqueteURL: SET_EXTERNAL_ENQUETE_URL,
    }),
    async reload() {
      const payload = {
        schoolId: this.loginUser.school_id,
        fiscalYear: this.fiscalYear,
      }
      await this.fetchSurveyConfigs(payload);
    },
    onChangeEnqueteType(enqueteType) {
      if (enqueteType == 0 ) {
        this.isLineEnquete = true;
      } else {
        this.isLineEnquete = false;
      }
    },
    isEmpty(obj){
    return !Object.keys(obj).length;
    },
  },
  async created() {
    await this.reload();
    // 編集の場合であるか判定する
    if (this.enqueteOpenInfo.deliveryInfo) {
      const enqueteType = this.enqueteOpenInfo.deliveryInfo.enqueteType;
      if (enqueteType == 0) {
        this.selectEnquete = cloneDeep(this.enqueteOpenInfo.templateInfo);
      } else {
        this.url = cloneDeep(this.enqueteOpenInfo.externalEnqueteUrl);
      }
    }
  },
  async mounted() {},
};
</script>