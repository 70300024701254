/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

export default {

  surveyConfigsListType: [],
  isFetchingSurveyConfigsListType: false,
  fetchSurveyConfigsListTypeError: null,

  isFetchingSurveyConfigsById: false,
  fetchSurveyConfigsByIdError: null,

  formConfigs: [],
  isFetchingFormConfigs: false,
  fetchFormConfigsError: null,

  formConfigDetail: {},
  isFetchingFormConfigDetail: false,
  fetchFormConfigDetailError: null,

  isRegistingSurveyConfig: false,
  registerSurveyConfigError: null,
  registeredSurveyConfigId: null,

  isUpdatingSurveyConfig: false,
  updateSurveyConfigError: null,

  isRegisteringSurveyConfigs: false,
  registerSurveyConfigsError: null,

  isDeletingSurveyConfigs: false,
  deleteSurveyConfigsError: null,

  isExistSurveyResutls: false,
  isTimedOutForCountSurveyResults: false,
  surveyResultsCountError: null,
  editedMultipleItem: {
    itemKey: null,
    originalValue: null,
    editedValue: null,
  }
};
