<template>
  <div>
    <!-- テスト配信をするときの最初のダイアログ -->
    <v-dialog max-width="600px" v-model="testDeliveryDialogFlag" persistent>
      <v-card>
        <v-card-title>テスト配信</v-card-title>
        <v-card-text>配信先の職員グループを選択してください。</v-card-text>
        <v-card-text>
          <v-autocomplete no-data-text="データがありません" dense hide-details="auto" v-model="selected_staff_group" :items="staff_group"
            item-text="group_name" item-value="staff_group_id"  multiple chips></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="selected_staff_group.length == 0" :loading="loading" outlined color="primary" @click="sendTest()">テスト配信</v-btn>
          <v-btn :loading="loading" color="blue" outlined @click="endTestDel()">閉じる</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TestDeliveryDialog",
  props: ['staff_group', 'loading'],
  data() {
    return {
      testDeliveryDialogFlag: true,
      selected_staff_group:[]
    };
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    endTestDel(){
      this.$emit('endTestDel')
    },
    sendTest(){
      console.log(this.selected_staff_group)
      this.$emit('sendTestDel', this.selected_staff_group)
    },
  }
};
</script>
<style scoped></style>