<template>
  <div class="contentsArea">
    <v-card>
      <v-card-actions style="max-height:50px;">
        <div class="text-right ma-4 row">
          <v-btn color="blue" text @click="$router.push({ name: 'DeliveryList' })">
              <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div v-if="canEdit">
            <!-- <v-btn color="grey lighten-2" elevation="1" class="mx-2" @click="saveDraft()">下書き保存</v-btn> -->
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)" color="grey lighten-2" elevation="1" class="mx-2" @click="testDelFlag = true">テスト配信</v-btn>
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)" color="blue" class="white--text mx-2"
              elevation="0" @click="send()">配信</v-btn>
          </div>
          <div v-if="!canEdit">
            <v-btn v-if="!isReserve" color="blue" class="mx-2 white--text" elevation="0" @click="canEdit = true">複製して編集</v-btn>
            <v-btn v-if="isReserve" color="red" class="mx-2" elevation="0" @click="del()">予約を取り消し</v-btn>
            <v-btn v-if="isReserve" color="blue" class="mx-2 white--text" elevation="0" @click="canEdit = true">編集</v-btn>
          </div>
        </div>
      </v-card-actions>
      <v-divider></v-divider>
      <v-form @submit.prevent v-model="isValid">
        <v-container fluid>
          <v-row no-gutters>
            <v-col :cols="fieldsCols.label">
              <v-subheader>配信名</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-text-field color="blue" :disabled="!canEdit" v-model="deliveryDetail.deliveryName" outlined dense hide-details="auto"
                placeholder="配信名" :rules="[required('配信名'), max_length_30]"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col :cols="fieldsCols.label">
              <v-subheader>配信先</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group color="blue" hide-details="auto" row :disabled="!canEdit" v-model="deliveryDetail.segmentType"
                class="mt-2 radioFlex">
                <v-radio v-if="loginUser.authority == 1 || loginUser.authority == 2" color="blue" value="ALL_STAFF">
                  <template v-slot:label><v-card-text class="pa-0">全職員</v-card-text></template>
                </v-radio>
                <v-radio color="blue" value="STAFF_GROUP">
                  <template v-slot:label><v-card-text class="pa-0">職員グループ</v-card-text></template>
                </v-radio>
                <v-radio color="blue" value="PERSONAL_STAFF">
                  <template v-slot:label><v-card-text class="pa-0">個別職員</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="deliveryDetail.segmentType == 'STAFF_GROUP' || deliveryDetail.segmentType == 'PERSONAL_STAFF'">

            <v-col :cols="fieldsCols.label">
            </v-col>

            <v-col :cols="fieldsCols.input" v-if="deliveryDetail.segmentType == 'STAFF_GROUP'">
              <v-autocomplete color="blue" item-color="blue"
                :no-data-text="noDataText" :disabled="!canEdit" hide-details="auto"
                v-model="deliveryDetail.staff_group" 
                :items="allStaffGroupList"
                item-text="group_name" item-value="staff_group_id" 
                label="職員グループ" multiple chips
                :rules="deliveryDetail.segmentType == 'STAFF_GROUP' ? [multiAutocomplete] : []"></v-autocomplete>
            </v-col>

            <v-col :cols="fieldsCols.input" v-else-if="deliveryDetail.segmentType == 'PERSONAL_STAFF'">
              <v-autocomplete color="blue" item-color="blue"
               :no-data-text="noDataText" :disabled="!canEdit" hide-details="auto" 
               v-model="deliveryDetail.personal_staff"
               :items="nonAdminStaff(staff)" 
               :item-text="item => item.last_name + item.first_name" item-value="staff_id"
               label="個別職員" multiple chips
               :rules="deliveryDetail.segmentType == 'PERSONAL_STAFF' ? [multiAutocomplete] : []"></v-autocomplete>
            </v-col>

          </v-row>

          <v-row no-gutters>
            <v-col :cols="fieldsCols.label">
              <v-subheader>配信日時</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group :disabled="!canEdit" v-model="deliveryDetail.deliveryTiming" class="mt-2">
                <v-radio color="blue" label="今すぐ配信" value="IMMEDIATE" class="fit-content-width"></v-radio>
                <v-radio color="blue" label="予約配信" value="ONETIME" class="fit-content-width"></v-radio>
                <v-row no-gutters class="pl-8">
                  <v-col :cols="fieldsCols.input">
                    <v-row no-gutters>
                      <v-col cols="12" sm="auto" class="pr-2">
                        <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition"
                          offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field color="blue" :disabled="!canEdit || deliveryDetail.deliveryTiming == 'IMMEDIATE'"
                              v-model="deliveryDetail.oneTimeDetail.date" readonly outlined dense
                              prepend-inner-icon="mdi-calendar-outline"
                              :rules="deliveryDetail.deliveryTiming === 'ONETIME' ? [required('配信日')] : []"
                              placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker :min="String(Number(fiscalYear)) + '-04-01'" :max="String(Number(fiscalYear) + 1) + '-03-31'" v-if="fromDateMenu" v-model="deliveryDetail.oneTimeDetail.date" no-title color="blue"
                            locale="jp-ja" :day-format="(date) => new Date(date).getDate()">
                          </v-date-picker>
                        </v-menu>
                        <v-text-field hide-details="auto" color="blue"
                          :disabled="!canEdit || deliveryDetail.deliveryTiming == 'IMMEDIATE'"
                          v-model="deliveryDetail.oneTimeDetail.time" type="time"
                          :rules="deliveryDetail.deliveryTiming === 'ONETIME' ? [required('配信時刻')] : []"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="auto">
                        <div
                          v-if="(!isdeliveryScheduleAfterNow() && deliveryDetail.deliveryTiming == 'ONETIME' && deliveryDetail.oneTimeDetail.date != '' && deliveryDetail.oneTimeDetail.time != '' && canEdit)"
                          style="color:red">
                          予約時刻は現在より後の時刻に設定してください
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-row no-gutters class="my-2">
          <v-col :cols="2" class="d-flex align-center">
            <v-subheader>装飾モードON</v-subheader>
            <v-switch
              class="mt-0"
              color="blue"
              v-model="deliveryDetail.flexUseFlg"
              :input-value=false
              hide-details
              :disabled="!canEdit"
              @change="showDialog"
            ></v-switch>
          </v-col>
        </v-row>
        <div v-for="(message, messageIndex) in deliveryDetail.messages" :key="message.id">
          <v-card class="ma-4" outlined tile>
            <v-toolbar color="grey lighten-2" dense elevation="0">
              <v-btn-toggle v-if="canEdit" dense group>
                <v-btn :disabled="deliveryDetail.messages.length == 1" color="black" class="my-3" elevation="0"
                  @click="deleteTextArea(message.id, messageIndex)">削除</v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>

              <div v-if="canEdit">
                <v-btn-toggle dense>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="blue" elevation="0" @click="message.type = 'text'">
                        <v-icon color="white">
                          mdi-format-title
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>テキスト</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="blue" elevation="0" @click="message.type = 'image'">
                        <v-icon color="white">
                          mdi-image
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>画像</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="blue" elevation="0" @click="message.type = 'sticker'">
                        <v-icon color="white">
                          mdi-emoticon-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>スタンプ</span>
                  </v-tooltip>

                </v-btn-toggle>
              </div>

            </v-toolbar>
            <!-- <v-container v-if="message.type == 'text'">
              <v-card class="mx-1 my-4" outlined hide-details>
                <v-textarea color="blue" :disabled="!canEdit" class="mx-1 mt-4" placeholder="メッセージを入力して下さい" single-line outlined
                  rows="4" hide-details="auto" v-model="message.text"
                  :rules="[required('メッセージ'), max_length_3000]"></v-textarea>
              </v-card>
            </v-container> -->
            <v-container v-if="message.type == 'text'">
              <item-text
                :value="message.text"
                :rules="textMessageRules"
                :index="messageIndex"
                :passedByParentsPositionInfo="deliveryDetail.messages"
                :messages="deliveryDetail.messages"
                :flexUseFlg="deliveryDetail.flexUseFlg"
                textcolor="blue"
                :canEdit="canEdit"
                :title="deliveryDetail.deliveryName"
                @onChangeTextMessage="onChangeTextMessage($event, messageIndex)"
              />
            </v-container>

            <v-container fluid v-if="message.type == 'flex' && url[message.id] == null">
              <div>
                <v-row>
                  <v-col cols="8" align="left">
                    <template>
                      <item-flex
                        :value="message.text"
                        :rules="textMessageRules"
                        :index="messageIndex"
                        :messages="deliveryDetail.messages"
                        textcolor="blue"
                        :canEdit="canEdit"
                        @onChangeMarkdownMessage="onChangeMarkdownMessage($event, messageIndex)"
                      />
                    </template>
                  </v-col>
                  <v-col cols="4">
                      <div class="line-preview-box mt-16">
                          <div class="line-preview-elements">
                            <template>
                              <BubbleFlexPreview :isFirstFlex="isFirstFlex(message, index)" :title="deliveryDetail.deliveryName" :message="deliveryDetail.messages[messageIndex].flexMessage"/>
                            </template>
                          </div>
                      </div>
                  </v-col>
                </v-row>
              </div>
            </v-container>

            <v-container v-if="message.type == 'flex' && url[message.id] != null">
              <div class="preview_zone" v-if="!imageLoading">
                <img style="max-width: 500px; max-height: 500px;" :src="url[message.id]" v-if="url" />
              </div>
              <div v-else>
                <v-card-text>
                  読み込んでいます。しばらくお待ちください。<br>
                  画像サイズが大きいなど、時間がかかる場合があります。
                </v-card-text>
              </div>
              <div class="detailPair">
                <div class="elem">
                  <div class="upload_zone">
                    <v-file-input v-if="canEdit" :clearable="false" v-model="inputImageValue[message.id]"
                      @change="inputImage(message.id, messageIndex)" :rules="message.type === 'image' ? [validateFile] : []"
                      accept=".png,.jpg,.jpeg,.pdf" label="写真をアップロード" outlined></v-file-input>
                  </div>
                </div>
              </div>
            </v-container>

            <v-container v-if="message.type == 'image'">
              <div class="preview_zone" v-if="!imageLoading">
                <img style="max-width: 500px; max-height: 500px;" :src="url[message.id]" v-if="url" />
              </div>
              <div v-else>
                <v-card-text>
                  読み込んでいます。しばらくお待ちください。<br>
                  画像サイズが大きいなど、時間がかかる場合があります。
                </v-card-text>
              </div>
              <div class="detailPair">
                <div class="elem">
                  <div class="upload_zone">
                    <v-file-input color="blue" v-if="canEdit" :clearable="false" v-model="inputImageValue[message.id]"
                      @change="inputImage(message.id, messageIndex)" :rules="message.type === 'image' ? [validateFile] : []"
                      accept=".png,.jpg,.jpeg,.pdf" label="写真をアップロード" outlined></v-file-input>
                  </div>
                </div>
              </div>
            </v-container>

            <v-container v-if="message.type == 'sticker'">
              <v-row>
                <v-col :cols="canEdit ? 3 : 12">
                  <v-card style="background-color: #8cabd9;max-width: 100%; height: 350px;" hide-details>
                    <div class="selectStickerArea" v-if="message.packageId">
                      <v-img :rules="[false]" :src="serchImgUrl(message.packageId, message.stickerId)"
                        style="margin-top: auto; height: 100%; width: 100%;"></v-img>
                    </div>
                    <!-- <v-img height="100" width="100" :src=></v-img> -->
                  </v-card>
                </v-col>
                <v-col v-if="canEdit" cols="9">
                  <v-card style="height: 350px;" class="stickerListArea" outlined hide-details height="300px">
                    <v-row no-gutters>
                      <v-col>
                        <v-card style="position: sticky; top: 0; margin-top: 0%; z-index: 7;" elevation="4">
                          <v-tabs v-model="tab" icons-and-text>
                            <div v-for="(stickers, index) in lineStickerList()" :key="index">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-tab style="height: 100%;" v-bind="attrs" v-on="on">
                                    <v-img style="height: 100%;" :src=stickers.child[0].url max-height="72"
                                      max-width="72"></v-img>
                                  </v-tab>
                                </template>
                                <span>
                                  {{ stickers.nameJP }}
                                </span>
                              </v-tooltip>
                            </div>
                          </v-tabs>
                        </v-card>
                        <v-tabs-items v-model="tab">
                          <v-tab-item v-for="(stickers, index) in lineStickerList()" :key="index">
                            <v-row>
                              <div v-for="(sticker, index) in stickers.child" :key="index">
                                <v-col
                                  @click="stickerClick(messageIndex, stickers.packageId, sticker.stickerId)">
                                  <v-img :contain=true max-height="72" max-width="72" :src=sticker.url></v-img>
                                </v-col>
                              </div>
                            </v-row>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

          </v-card>
        </div>
      </v-form>
      <v-container fluid>
        <v-btn v-if="canEdit" color="blue" class="mx-1 mb-4" elevation="0" outlined :disabled="isMax"
          @click="addMessage">
          <v-icon left>mdi-plus</v-icon>追加
        </v-btn>
        <v-divider></v-divider>
        <div class="text-center">
          <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)" v-if="canEdit"
            color="grey lighten-2" elevation="1" class="mt-4 mr-2" @click="testDelFlag = true">テスト配信</v-btn>
          <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)" v-if="canEdit"
            color="blue" class="mt-4 white--text" elevation="0" @click="send()">配信</v-btn>
          <v-btn v-if="!canEdit && !isReserve" color="blue" class="mt-4 white--text" elevation="0" @click="canEdit = true">複製して編集</v-btn>
          <v-btn v-if="isReserve" color="blue" class="mx-2 white--text" elevation="0" @click="canEdit = true">編集</v-btn>
        </div>
      </v-container>
      <div class="message-preview">
        <v-menu top offset-y nudge-top="5px" opacity="0" :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-4 white--text" color="blue"  elevation="0" min-width="320" v-bind="attrs" v-on="on" @click="preview = !preview">
              <v-icon left> {{ preview ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
              プレビュー
            </v-btn>
          </template>
          <div>
            <MessagePreview :title="deliveryDetail.deliveryName" :messages="deliveryDetail.messages" :positionInfo="emojisPositionInfo" :preview="preview"/>
          </div>
        </v-menu>
      </div>
      <DisplayFlexSegmentDeliveryModal
        v-model="isShowDialog"
        color="blue"
        @colse="closeDialog"
        @onChangeDecorationMode="onChangeDecorationMode"
      />
      <v-snackbar v-model="snackbar" multi-line>
        {{ snackbarText }}
      </v-snackbar>
    </v-card>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TestDeliveryDialog v-if="testDelFlag" :loading="loading" :staff_group="allStaffGroupList" @sendTestDel="sendTestDel" @endTestDel="endTestDel"/>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_STAFF_GROUP_ALL,
  GET_STAFF_ALL,
  SAVE_DELIVERY_IMG_TO_S3,
  SEND_STAFF_DELIVERY,
  UPDATE_STAFF_DELIVERY,
  DELETE_DELIVERY,
  GET_STAFF_DELIVERY_TARGET,
  GET_STUDENT_BY_CLASSROOM_ID,
  SEND_TEST_DELIVERY,
  SAVE_DELIVERY_PDF_TO_S3,
} from "@/store/action-types";
import {
  SET_DELIVERY_IMGS,
  CLEAR_DELIVERY_IMGS,
  SET_DELIVERY_IMGS_NAME,
  SET_DELIVERY_DETAIL,
  SET_ENQUETE_DELIVERY_DETAIL,
  SET_ENQUETE_SETTINGS,
  SET_DELIVERY_PDFS,
  CLEAR_DELIVERY_PDFS,
  SET_DELIVERY_PDFS_NAME,
} from "@/store/mutation-types";
import {
  REQUIRED_ERROR_MSG,
  NO_DATA_TEXT,
  LINE_STICKER
} from "@/constants";
import { cloneDeep } from "lodash";
import ItemText from "./deliveryMessages/ItemText.vue";
import ItemFlex from "./deliveryMessages/ItemFlex.vue";
import MessagePreview from "./deliveryMessages/MessagePreview.vue";
import BubbleFlexPreview from "./fragments/BubbleFlexPreview.vue"
import DisplayFlexSegmentDeliveryModal from "./fragments/DisplayFlexSegmentDeliveryModal.vue";
import { parseProductIdEmojiIdFromUrlOrFalse } from "@/store/modules/segments/segments-utils";
import TestDeliveryDialog from "./TestDeliveryDialog.vue";
import * as PDFJS from 'pdfjs-dist'
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].[contenthash].js!pdfjs-dist/legacy/build/pdf.worker.js");
PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();

const MAX_MESSAGES_LENGTH = 4;

export default {
  name: "StaffDeliveryCreation",
  props: ["item"],
  data() {
    return {
      testDelFlag: false,
      noDataText: NO_DATA_TEXT,
      staff: null,
      tab: null,
      allStaffGroupList: null,
      imageLoading: false,
      snackbar: false,
      snackbarText: null,
      loading: false,
      inputImageValue: [],
      isValid: false,
      isEditModeValue: false,
      canEdit: true,
      isReserve: false,
      image: [],
      url: [],
      radioGroup: 1,
      segmentDialog: false,
      delivery: this.item || null,
      positionInfo: 1,
      selectedGrade: null,
      selectedClass: null,
      selectedStudent: null,
      classroomItems: null,
      fromDateMenu: false,
      fieldsCols: {
        label: 2,
        input: 8
      },
      grades: [],
      segmentTypes: [],
      deliveryDetail: {
        deliveryName: "",
        segmentType: "",
        targets: "",
        segmentDetail: {},
        deliveryTiming: "IMMEDIATE",
        oneTimeDetail: {
          date: "",
          time: ""
        },
        recurringDetail: {
          startDate: "2023/1/30",
          endDate: "2023/2/28",
          periodicType: "毎日",
          eliminateStatus: "除外日あり",
          eliminateSetting: ["2023/1/31", "2023/2/15"]
        },
        flexUseFlg: false,
        messages: [],
      },
      /**
       * 絵文字関連data
       *  */
      textMessageRules: [(v) => !!v || "テキストは必須入力です。"],
      emojisPositionInfo: [
        {
          emojis: [],
          text: "",
        }
      ],
      preview: false,
      textLengthError: false,
      isShowDialog: false,
      select: (v) => (v != null && v != -1) || "選択してください。",
      multiAutocomplete:(v) => this.multiAutocompleteValid(v) || "選択してください。",
      max_length_30: value => value.length <= 30 || "30文字以内で入力してください。",
      max_length_255: value => value.length <= 255 || "255文字以内で入力してください。",
      max_length_3000: value => value.length <= 3000 || "3000文字以内で入力してください。",
    };
  },
  components: {
    ItemText,
    ItemFlex,
    MessagePreview,
    BubbleFlexPreview,
    DisplayFlexSegmentDeliveryModal,
    TestDeliveryDialog,
  },
  computed: {
    isMax: {
      get() {
        return this.deliveryDetail.messages.length > MAX_MESSAGES_LENGTH;
      }
    },
    ...mapState({
      staffAll: state => state.staffAll,
      deliveryImgs: state => state.deliveryImgs,
      selectedClassStudent: state => state.selectedClassStudent,
      selectedClassroom: state => state.selectedClassroom,
      deliveryImgsUrl: state => state.deliveryImgsUrl,
      loginUser: state => state.loginUser,
      fiscalYear: state => state.fiscalYear,
      emojiOver20Error: state => state.emojiOver20Error,
      // アンケート開催情報
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      isUnPublished: state => state.isUnPublished,

      deliveryPdfs: state => state.deliveryPdfs,
      deliveryPdfsUrl: state => state.deliveryPdfsUrl,
    }),
    deliveryModeTitle() {
      return this.isEditModeValue ? "配信編集" : "新規配信"
    },
  },
  watch: {
    'deliveryDetail.oneTimeDetail': {
      handler: function () {
        this.deliveryScheduleText = this.deliveryDetail.oneTimeDetail.date + ' ' + this.deliveryDetail.oneTimeDetail.time
      },
      deep: true,
    },
    'deliveryDetail.oneTimeDetail.date' : {
      handler: function () {
        this.fromDateMenu = false;
      },
    },
    'deliveryDetail.flexUseFlg': {
      handler: function (val) {
        // メッセージタイプをflexUseFlgを元にtextまたは、flexに変更する
        this.deliveryDetail.messages.map(message => {
          if (message.type === "text" || message.type === "flex") {
            // flexUseFlgの値を元に変更する
            if (val) {
              // flexUseFlgがtrueの場合は、flexに変更
              message.type = "flex";
            } else {
              // pdfメッセージではない場合
              if(!message.pdfUrl) {
                // flexUseFlgがfalseの場合は、textに変更
                message.type = "text";
              }
            }
          }
          return message;
        });
      },
      deep: true,
    },
    emojiOver20Error(val) {
      // テキスト入力エリアのエラーを反映
      this.textLengthError = val;
    },
  },
  methods: {
    isFirstFlex(message, index) {
      let firstMsg = null
      for(let i = 0; i < message.length; i++) {
        if (message[i].type == 'text' || message[i].type == 'flex') {
          firstMsg = i
          break
        } 
      }
      return firstMsg === Number(index) ? true : false
    },
    async sendTestDel(selected_staff_group) {
      console.log('sendTestDel')
      this.loading = true

      await this.saveDeliveryImgToS3()
      await this.saveDeliveryPdfToS3()
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (i in (this.deliveryImgs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
          }
        }
        if (i in (this.deliveryPdfs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
            this.deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
            this.deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
          }
        }
      }

      this.setDeliveryDetail(this.deliveryDetail)

      let res = null
      console.log('配信を送信します')
      res = await this.sendTestDelibery(selected_staff_group);
      console.log('配信の送信を終えました')
      
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = 'テスト配信でエラーが発生しました。ページを再読み込みしてください。'
      } else {
        this.snackbar = true
        this.snackbarText = 'テスト配信が完了しました。'
      }
      this.loading = false
      this.testDelFlag = false
    },
    endTestDel() {
      this.testDelFlag = false;
    },
    nonAdminStaff(staff) {
      return staff.filter(s => s.authority != 1 && s.is_delete != true);
    },
    multiAutocompleteValid(v){
      return (v.length == 0 ? false : true)
    },
    lineStickerList() {
      return LINE_STICKER
    },
    ...mapActions({
      getStaffGroupAll: GET_STAFF_GROUP_ALL,
      getStaffAll: GET_STAFF_ALL,
      saveDeliveryImgToS3: SAVE_DELIVERY_IMG_TO_S3,
      sendStaffDelivery: SEND_STAFF_DELIVERY,
      updateStaffDelivery: UPDATE_STAFF_DELIVERY,
      deleteDelivery: DELETE_DELIVERY,
      get_staff_delivery_target: GET_STAFF_DELIVERY_TARGET,
      getStudentByClassId: GET_STUDENT_BY_CLASSROOM_ID,
      sendTestDelibery: SEND_TEST_DELIVERY,
      saveDeliveryPdfToS3: SAVE_DELIVERY_PDF_TO_S3,
    }),
    ...mapMutations({
      setDeliveryImgs: SET_DELIVERY_IMGS,
      clearDeliveryImgs: CLEAR_DELIVERY_IMGS,
      setDeliveryImgsName: SET_DELIVERY_IMGS_NAME,
      setDeliveryDetail: SET_DELIVERY_DETAIL,
      setDeliveryPdfs: SET_DELIVERY_PDFS,
      clearDeliveryPdfs: CLEAR_DELIVERY_PDFS,
      setDeliveryPdfsName: SET_DELIVERY_PDFS_NAME,
    }),
    async fetchStudentByClassId() {
      await this.getStudentByClassId();
    },
    async isEditMode() {
      // deliveryがnullじゃない場合は既存配信
      if (this.delivery != null) {
        // 既存配信フラグ
        this.isEditModeValue = true;
        // 編集可否フラグ
        this.canEdit = false;
        if (this.item.delivery_status == "RESERVED") {
          //予約配信編集フラグ
          this.isReserve = true
          this.deliveryDetail.oldDetail = this.item
        }
        //delivery_schedule 予約配信の時のみnullじゃない
        if (this.item.delivery_schedule != null) {
          const date = moment(this.item.delivery_schedule).subtract(9, 'h')
          this.deliveryDetail.deliveryTiming = "ONETIME"
          this.deliveryDetail.oneTimeDetail = {
            date: date.format("YYYY-MM-DD"),
            time: date.format("HH:mm")
          }
        }
        // 職員配信の場合、配信区分・配信先をAPIからとってくる
        const response = await this.get_staff_delivery_target(this.item.delivery_id)
        if (response.status == 200){
          this.deliveryDetail.segmentType = response.body.segmentType
          this.deliveryDetail.personal_staff = response.body.personal_staff
          this.deliveryDetail.staff_group = response.body.staff_group
        }
        // propsの値をdetailに入れる
        this.deliveryDetail.deliveryName = this.item.delivery_name;
        this.deliveryDetail.messages = this.item.delivery_contents;
        this.selectedGrade = String(this.item.target_grade);
        this.selectedClass = parseInt(this.item.target_class);
        this.selectedStudent = this.item.target_student;
        this.deliveryDetail.flexUseFlg = this.item.flex_use_flg;
        // detail用にIDを振る
        
        for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
          this.deliveryDetail.messages[i]["id"] = i;
          if (this.deliveryDetail.messages[i]["type"] == "image") {
            // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
            this.inputImageValue[i] = [{ name: 'image' }]
            this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
            this.url[i] = this.delivery.delivery_contents[i]["originalContentUrl"]
          } else if (this.deliveryDetail.messages[i]["type"] == "flex" && this.delivery?.delivery_contents?.[i]?.contents?.body?.contents?.[0]?.url != null)  {
            // pdfMsgの場合、detailとurl管理用の配列にURLを持たせなおす(DBに保存されたjsonオブジェクトを参照)
            this.inputImageValue[i] = [{ name: 'pdf' }]
            this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
            this.deliveryDetail.messages[i]["pdfUrl"] = this.delivery.delivery_contents[i].contents.body.contents[0].action.uri;
            const aspectRatio = this.delivery.delivery_contents[i].contents.body.contents[0].aspectRatio;
            const splitAspectRatio = aspectRatio.split(':');
            this.deliveryDetail.messages[i]["imgWidth"] = splitAspectRatio[0];
            this.deliveryDetail.messages[i]["imgHeight"] = splitAspectRatio[1];
            this.url[i] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
            // console.log('deliveryDetail', this.deliveryDetail)
          } else if (this.deliveryDetail.messages[i]["type"] == "flex") {
            // FlexMsgの場合、flexMessageキーを追加してcontentsを入れる
            this.deliveryDetail.messages[i].flexMessage = this.deliveryDetail.messages[i].contents;
          }
        }
        this.$forceUpdate();
        // inputImageValue[message.id]
      } else {
        // 新規作成時は要素を追加する
        this.deliveryDetail.messages.push({
          id: 0,
          text: "",
          type: "text",
          url: "",
          previewImageUrl: "",
          packageId: "",
          stickerId: "",
          emojis: [],
        });
      }
    },
    serchImgUrl(packageId, stickerId) {

    const targetPackageId = packageId
    const targetStickerId = stickerId

    const targetPackage = LINE_STICKER.filter(
      linePackage => {
        return linePackage.packageId == targetPackageId;
      }
    );

    const targetStciker = targetPackage[0].child.filter(
      lineSticker => {
        return lineSticker.stickerId == targetStickerId;
      }
    )

    return targetStciker[0].url
    },
    selectText(index) {
      if (this.deliveryDetail.flexUseFlg) {
        this.deliveryDetail.messages[index].type = 'flex';
      } else {
        this.deliveryDetail.messages[index].type = 'text';
      }
    },
    validateFile(file) {
      if (!file) return "ファイルがありません。";
      return true;
    },
    async del() {
      this.loading = true
      await this.setDeliveryDetail(this.deliveryDetail);
      const res = await this.deleteDelivery();
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = '配信の削除に失敗しました。ページを再読み込みしてください。'
      } else {
        this.$router.push({ name: 'DeliveryList' })
      }
      this.loading = false
    },
    async send() {
      this.loading = true
      if (this.isdeliveryScheduleAfterNow() == false) {
        this.loading = false
        return null
      }
      await this.saveDeliveryImgToS3()
      await this.saveDeliveryPdfToS3()
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (i in (this.deliveryImgs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
          }
        }
        if (i in (this.deliveryPdfs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
            this.deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
            this.deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
          }
        }
      }
      this.setDeliveryDetail(this.deliveryDetail)
      let res = null
      if (this.isReserve == false) {
        res = await this.sendStaffDelivery();
      } else if (this.isReserve == true) {
        res = await this.updateStaffDelivery();
      }
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = '配信の登録に失敗しました。ページを再読み込みしてください。'
      } else {
        this.$router.push({ name: 'DeliveryList' })
      }
      this.loading = false
    },
    deleteTextArea(id, index) {
      this.deliveryDetail.messages = this.deliveryDetail.messages.filter(
        messages => {
          return messages["id"] != id;
        }
      );
      this.emojisPositionInfo = this.emojisPositionInfo.filter(
        emojiPositionInfo => {
          return emojiPositionInfo["id"] != id;
        }
      );
      this.setDeliveryImgs(this.deliveryImgs.splice(index, 1));
    },
    addMessage() {
      this.positionInfo += 1;
      this.deliveryDetail.messages.push({
        id: this.positionInfo,
        text: "",
        type: this.deliveryDetail.flexUseFlg ? "flex" : "text",
        url: "",
        previewImageUrl: "",
        packageId: "",
        stickerId: "",
        emojis: [],
      });
      this.emojisPositionInfo.push({
        text: "",
        emojis: [],
      });
    },
    uploadImage(index) {
      this.$refs.preview[index].click();
    },
    onChangeTextMsg(text, position) {
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (this.deliveryDetail.messages[i].id === position) {
          this.deliveryDetail.messages[i].text = text;
        }
      }
    },
    async compressImage(file) {
      //画像圧縮
      // const maxSize = 1024;
      const quality = 1.0;
      const MAX_IMG_SIZE = 500000; // inputされた画像はここまで圧縮されます 100000 = 100KB
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          let width = image.width;
          let height = image.height;

          canvas.width = width;
          canvas.height = height;

          // 画像をcanvasに描画
          ctx.drawImage(image, 0, 0, width, height);

          // canvasからデータURLを取得し、JPEG形式で圧縮
          let dataURL = canvas.toDataURL('image/jpeg', quality);

          // 圧縮された画像のファイルサイズを取得
          let compressedFileSize = Math.round(dataURL.length * 0.75);
          // console.log('初回の画像サイズ', compressedFileSize)

          // 200KBより大きかったらさらに縮小
          let i = 1
          if (compressedFileSize > MAX_IMG_SIZE) {
            const n = MAX_IMG_SIZE / compressedFileSize
            dataURL = canvas.toDataURL('image/jpeg', n.toFixed(1));
            compressedFileSize = Math.round(dataURL.length * 0.75);
            // console.log(0 + '回目のサイズ : ', compressedFileSize)
          }
          if (compressedFileSize > MAX_IMG_SIZE) {
            while (i > 0) {
              width = 0.85 * width;
              height = 0.85 * height;
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(image, 0, 0, width, height);
              dataURL = canvas.toDataURL('image/jpeg', 0.8);
              compressedFileSize = Math.round(dataURL.length * 0.75);
              // console.log(i + '回目のサイズ : ', compressedFileSize)
              i++;
              if (compressedFileSize < MAX_IMG_SIZE) {
                break;
              }
            }
            // console.log('最終サイズ : ', compressedFileSize)
          }
          // Promiseを解決して、圧縮された画像データを返す
          resolve({
            dataURL: dataURL,
            width: width,
            height: height,
          });
        };

        image.onerror = function () {
          reject('画像の読み込みに失敗しました。');
        };
      });
    },
    async inputImage(id, index) {
      this.imageLoading = true;
      this.image[id] = undefined;
      if (this.inputImageValue[id]) {
        const isPdf = (this.getExtByFileName(this.inputImageValue[id].name) === 'pdf');
        if (isPdf) { // 選択されたファイルがPDF

          this.setDeliveryPdfs({
            index: index,
            img: {
              imgObj: this.inputImageValue[id],
              imgExe: '.pdf',
              imgUrl: ''
            }
          })

          // PDFファイルデータをArrayBuffer型で取得
          const fileData = await this.readFileAsync(this.inputImageValue[id])

          // PDFファイルのパース
          const pdf = await PDFJS.getDocument({
            data: fileData,
            cMapUrl: '/cmaps/',
            cMapPacked: true,
          }).promise

          // 1ページ目をcanvasにレンダリング
          const page = await pdf.getPage(1)
          const canvas = document.createElement('canvas')
          const viewport = page.getViewport({ scale: 5 })
          canvas.height = viewport.height
          canvas.width = viewport.width
          const context = canvas.getContext('2d')
          const task = page.render({
            canvasContext: context,
            viewport: viewport,
          })
          await task.promise

          // canvasにレンダリングされた画像をファイル化
          const base64 = canvas.toDataURL('image/png')
          const tmp = base64.split(',')
          const data = atob(tmp[1])
          const mime = tmp[0].split(':')[1].split(';')[0]
          const buf = new Uint8Array(data.length)
          for (let i = 0; i < data.length; i++) {
            buf[i] = data.charCodeAt(i)
          }
          const blob = new Blob([buf], { type: mime })
          const imageFile = new File([blob], 'image.png', {
            lastModified: new Date().getTime(),
          })

          this.image[id] = imageFile;
        } else { // 選択されたファイルがPNG,JPG,JPEG
          this.image[id] = this.inputImageValue[id];
        }

        // ファイルサイズを小さくする
        const compressImg = (await this.compressImage(this.image[id]));

        // ストアに登録(画像配信用)
        const blob = this.dataURLtoBlob(compressImg.dataURL);
        this.setDeliveryImgs({
          index: index,
          img: {
            imgObj: blob,
            imgExe: ('.' + (this.image[id].name).split('.').pop()),
            imgUrl: '',
            imgWidth: Math.round(compressImg.width),
            imgHeight: Math.round(compressImg.height)
          }
        })

        // preview用のURLをセット
        this.deliveryDetail.messages[index].previewImageUrl = URL.createObjectURL(this.image[id])
        this.previewImage(blob, id);

      } else if (this.url != "" && this.url != "None") {
        const dataUrl = (await this.compressImage(this.image[id])).dataURL;
        this.image[id] = URL.createObjectURL(dataUrl);
      } else {
        this.url = "";
      }
      this.imageLoading = false;
    },
    getExtByFileName(fileName) {
      // 最後のドットの位置を取得
      const lastDotPosition = fileName.lastIndexOf('.');

      // ドットが見つかった場合
      if (lastDotPosition !== -1) {
        // ドット以降の文字列を取得（拡張子）
        const extension = fileName.slice(lastDotPosition + 1);
        return extension
      } else {
        return ''
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    },
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async previewImage(blob, id) {
      this.url[id] = URL.createObjectURL(blob);
      this.$forceUpdate();
    },
    isdeliveryScheduleAfterNow() {
      if (this.deliveryDetail.deliveryTiming == 'ONETIME') {
        return moment(this.deliveryScheduleText).isAfter(moment())
      }
      return true
    },
    required(str) {
      return value => !!value && !this.isSpace(value) || str + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    onChangeTextMessage(value, index) {
      const { text, emojis } = value.positionInfo;
      const positionInfo = cloneDeep(this.deliveryDetail.messages[index]);
      
      positionInfo.text = text;
      positionInfo.emojis = emojis.map((emoji) => {
        const parsedIds = parseProductIdEmojiIdFromUrlOrFalse(emoji.path) || { productId: '', emojiId: ''};
        return {
          index: emoji.index,
          productId: parsedIds.productId,
          emojiId: parsedIds.emojiId,
        }
      });
      // メッセージに反映
      this.$set(this.deliveryDetail.messages, index, positionInfo);
      // 絵文字のリストに反映
      this.$set(this.emojisPositionInfo, index, positionInfo);
    },
    onChangeMarkdownMessage(value, index) {
      // マークダウンとフレックスメッセージを取得
      const markdown = value.markdown;
      const flexMessage = value.flexMessage;
      // 現在のメッセージを取得
      let message = cloneDeep(this.deliveryDetail.messages[index]);
      // 更新されたマークダウンとフレックスメッセージをセット
      message.text = markdown;
      message.flexMessage = flexMessage;
      // メッセージに反映
      this.$set(this.deliveryDetail.messages, index, message);
    },
    showDialog() {
      this.isShowDialog = this.deliveryDetail.flexUseFlg;
    },
    closeDialog() {
      this.isShowDialog = false;
      this.deliveryDetail.flexUseFlg = false;
      this.$forceUpdate();
    },
    onChangeDecorationMode() {
      // ダイアログ閉じる
      this.isShowDialog = false;
      // 入力内容を破棄する
      this.deliveryDetail.messages = [];
      // 新しい要素を追加する
      this.deliveryDetail.messages.push({
        id: 0,
        text: "",
        type: "flex",
        url: "",
        previewImageUrl: "",
        packageId: "",
        stickerId: "",
        emojis: [],
        flexMessage: {},
      });
    },
    stickerClick(messageIndex, packageId, stickerId) {
      const message = this.deliveryDetail.messages[messageIndex];
      message.packageId = packageId;
      message.stickerId = stickerId;
    },
  },
  async created() {
  },
  async mounted() {
    this.loading = true;
    (this.loginUser.authority == 1 || this.loginUser.authority == 2) ? this.deliveryDetail.segmentType = 'ALL_STAFF' : this.deliveryDetail.segmentType = 'STAFF_GROUP'
    const res = await this.getStaffGroupAll();
    // ログイン中職員の、所属グループID一覧
    let staff_group_id_list = []
    for(let i=0; i < this.loginUser.staff_group.length; i++) {
      staff_group_id_list.push(this.loginUser.staff_group[i].staff_group_id)
    }
    // グループ一覧に出すグループリストを作る
    // 削除フラグが立ってないこと && 職員の権限が全権および管理者でない場合はログイン中職員の所属グループのみ
    if (res.body == '200') {
      this.allStaffGroupList = res.result.filter(obj => obj.is_delete != true && (this.loginUser.authority == 1 || this.loginUser.authority == 2) ? true : staff_group_id_list.includes(obj.staff_group_id));
    }
    await this.getStaffAll();
    this.loading = true;
    (this.loginUser.authority == 1 || this.loginUser.authority == 2)
      // 全権管理者と管理者の場合、全職員を配信対象に選べる
      ? this.staff = [...this.staffAll]
      // それ以外の場合は、同じstaff_groupに所属している職員のみを選べる
      : this.staff = this.staffAll.filter(obj => [...obj.staff_group, ...staff_group_id_list].filter(item => obj.staff_group.includes(item) && staff_group_id_list.includes(item)).length > 0) 
    await this.isEditMode();
    this.loading = true;
    this.clearDeliveryImgs();
    this.clearDeliveryPdfs();
    this.loading = false;
  }
};
</script>
<style scoped>
.radioFlex .v-input--radio-group__input {
  display: flex;
  flex-direction: row;
}

.radioFlex .v-input--radio-group__input>* {
  min-width: initial;
  max-width: 150px;
  width: initial;
  margin-bottom: 8px;
  margin-right: 24px;
}

.radioFlex.alot .v-input--radio-group__input>* {
  min-width: 100px;
  max-width: 100px;
  width: 100%;
  margin-bottom: 8px;
  margin-right: 24px;
}

.stickerListArea {
  overflow: auto !important;
}

.stickerListArea::-webkit-scrollbar {
  display: none !important;
}

.selectStickerArea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  /* -webkit- transform: translateY(-50%) translateX(-50%); */
}

.message-preview {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  z-index: 11;
}
.fixed-preview {
  position: fixed;
  top: 60px;
}

.preview-row {
  width: fit-content;
  margin-left: 1em;
}
.line-preview-box {
  border-radius: 0.25em;
  background-color: #688bbc;
  width: 100%;
  height: auto;
}
.line-preview-elements {
  display: block;
  justify-content: center;
  overflow: auto;
  overflow-y: auto;
}
.fit-content-width {
  width: fit-content;
}
</style>