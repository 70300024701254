<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-expansion-panels :value="all" :readonly="readonly" multiple tile>
      <template v-for="item in surveyResultsHandle">
        <v-expansion-panel :key="item.itemKey">
          <v-expansion-panel-header disable-icon-rotate>
            {{ item.title }}

            <template v-slot:actions>
              <v-icon color="teal">mdi-check</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li v-for="input in item.value" :key="`${item.itemKey}_${input}`">
                {{ input }}
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      panel: [-1],
      readonly: false,
      dataLocal: null
    };
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.dataLocal = val;
      },
    },
  },
  computed: {
    all() {
      return [...Array(this.surveyResultsHandle.length).keys()].map(
        (k, i) => i
      );
    },
    surveyResultsHandle() {
      let _result = [];
      if (this.dataLocal && this.dataLocal.surveyResults.length > 0) {
        let _questionKey = null;

        this.dataLocal.surveyResults.forEach((obj) => {
          if (obj.itemKey !== _questionKey) {
            _result.push({
              itemKey: obj.itemKey,
              title: obj.title,
              value: [obj.value],
            });
            _questionKey = obj.itemKey;
          } else {
            let _findIndex = _result.findIndex(
              (item) => item.itemKey === obj.itemKey
            );
            _result[_findIndex].value.push(obj.value);
          }
        });
      }

      return _result;
    },
  },
  created() {
    this.dataLocal = this.data;
  },
});
</script>