<template>
  <div>
    <v-data-table
      :items="busRouteListLocal"
      item-key="bus_route_id"
      :headers="headers"
      :single-select="false"
      :loading="loading"
      :loading-text="loadingText"
      :no-data-text="noDataText"
      :no-results-text="noResultText"
      :footer-props="{ 'items-per-page-text': '行/ページ:' }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar height="36px" flat class="mb-4">
          <v-row>
            <v-spacer></v-spacer>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn :loading="loading" color="primary" @click="reload()">
                <v-icon left>mdi-reload</v-icon>
                データ更新
              </v-btn>
              <v-btn :loading="loading" color="green" dark @click="openDialogAdd()">
                新規追加
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.edit_btn`]="{ item }">
        <div v-if="item.is_delete == 0">
          <v-col align="center" class="px-0">
            <v-btn color="green white--text" class="mr-2" @click="openDialogEdit(item)">編集</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`item.nullify_btn`]="{ item }">
        <div>
          <v-col align="center" class="px-0">
            <v-btn color="red white--text" class="mr-2" :disabled="(item.is_delete !== 0)" @click="openDialogNullify(item)">無効</v-btn>
          </v-col>
        </div>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
      </template>
      <template v-slot:no-data>
        データがありません
      </template>
    </v-data-table>
    <v-dialog v-if="dialogAdd" v-model="dialogAdd" persistent scrollable max-width="700">
      <v-card max-width="100%">
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="text-h5 font-weight-bold">バスルート新規追加</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="max-height: 400px;">
          <v-form @submit.prevent v-model="isValidAdd">
            <v-container>
              <v-row>
                <v-col class="px-0">
                  <v-text-field
                    v-model="busRouteAdd.bus_route_name"
                    label="バスルート名"
                    :rules="[required, max_length_10]"
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <RouteEditor v-model="busRouteAdd.route_info" :errorMessage="routeEditorErrorMessageAdd"/>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="closeDialogAdd()">キャンセル</v-btn>
          <v-btn :loading="loading" color="green" class="white--text" :disabled="!isValidAdd || !isValidRouteEditorAdd" @click="addBusRoute()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent scrollable max-width="700">
      <v-card max-width="100%">
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="text-h5 font-weight-bold">バスルート編集</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="max-height: 400px;">
          <v-form @submit.prevent v-model="isValidEdit">
            <v-container>
              <v-row>
                <v-col class="px-0">
                  <v-text-field
                    v-model="busRouteEdit.bus_route_name"
                    label="バスルート名"
                    :rules="[required, max_length_10]"
                    counter="10"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <RouteEditor v-model="busRouteEdit.route_info" :errorMessage="routeEditorErrorMessageEdit"/>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="closeDialogEdit()">キャンセル</v-btn>
          <v-btn :loading="loading" color="green" class="white--text" :disabled="!isValidEdit || !isValidRouteEditorEdit" @click="editBusRoute()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNullify" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">バスルート無効化</span>
        </v-card-title>
        <v-card-text>
          「{{ busRouteNullify.bus_route_name }}」を無効化します。本当によろしいですか？<br>
          ※この操作は取り消せません。<br>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark outlined @click="closeDialogNullify()">
            キャンセル
          </v-btn>
          <v-btn :loading="loading" color="green" class="white--text" @click="nullifyBusRoute()">
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" multi-line>
      {{ snackbarText }}ページを再読み込みしてください。
    </v-snackbar>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RouteEditor from './RouteEditor/index.vue';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_BUS_ROUTE_LIST,
  GET_BUS_ROUTE_LENGTH,
  CREATE_BUS_ROUTE,
  UPDATE_BUS_ROUTE,
  INVALIDATE_BUS_ROUTE,
} from "@/store/action-types";
import {
} from "@/store/mutation-types";
import {
  MAX_LENGTH_10_ERROR_MSG,
  REQUIRED_ERROR_MSG,
  LOADING_TEXT,
  NO_DATA_TEXT,
  NO_RESULT_TEXT,
} from "@/constants";

export default {
  name: "BusRouteList",
  components: {
    RouteEditor
  },
  data: () => ({
    // テーブル表示
    busRouteListLocal: [],
    noDataText: NO_DATA_TEXT,
    noResultText: NO_RESULT_TEXT,

    // ダイアログ共通
    busRouteDefault: {
      bus_route_name: "",
      route_info: [
        {
          order_no: 0,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
        {
          order_no: 1,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
      ],
    },

    // 新規追加ダイアログ
    dialogAdd: false,
    busRouteAdd: {
      bus_route_name: "",
      route_info: [
        {
          order_no: 0,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
        {
          order_no: 1,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
      ],
    },
    isValidAdd: false,

    // 編集ダイアログ
    dialogEdit: false,
    busRouteEdit: {
      bus_route_name: "",
      route_info: [
        {
          order_no: 0,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
        {
          order_no: 1,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
      ],
    },
    isValidEdit: false,

    // 無効化ダイアログ
    dialogNullify: false,
    busRouteNullify: {
      bus_route_name: "",
      route_info: [
        {
          order_no: 0,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
        {
          order_no: 1,
          bus_stop_name: "",
          lat: "",
          lng: ""
        },
      ],
    },

    // ローディング
    loading: false,
    loadingText: LOADING_TEXT,

    // スナックバー
    snackbar: false,
    snackbarText: null,
  }),
  computed: {
    ...mapState({
      busRouteList: state => state.busRouteList,
      busRouteLength: state => state.busRouteLength,
    }),
    headers() {
      return [
        { text: "バスルートID", value: "bus_route_id" },
        { text: "バスルート名", value: "bus_route_name" },
        { text: "登録日時", value: "created_at" },
        { text: "", value: "edit_btn", sortable: false, width: '100px' },
        { text: "", value: "nullify_btn", sortable: false, width: '100px' }
      ]
    },
    isValidRouteEditorAdd() {
      const isValidBusStopCount = (this.busRouteAdd.route_info.length >= 2); // バス停が2件以上
      return (isValidBusStopCount);
    },
    routeEditorErrorMessageAdd() {
      const isValidBusStopCount = (this.busRouteAdd.route_info.length >= 2); // バス停が2件以上
      if (!isValidBusStopCount) {
        return "バス停は2件以上登録してください。";
      }

      return "";
    },
    isValidRouteEditorEdit() {
      const isValidBusStopCount = (this.busRouteEdit.route_info.length >= 2); // バス停が2件以上
      return (isValidBusStopCount);
    },
    routeEditorErrorMessageEdit() {
      const isValidBusStopCount = (this.busRouteEdit.route_info.length >= 2); // バス停が2件以上
      if (!isValidBusStopCount) {
        return "バス停は2件以上登録してください。";
      }

      return "";
    },
  },
  watch: {
    busRouteList: {
      handler: function () {
        this.busRouteListLocal = structuredClone(this.busRouteList);
      },
      deep: true
    },
  },
  async created() {
    this.loading = true;

    // バスの件数を取得する
    await this.getBusRouteLength();

    // バスの件数が一致しない場合のみ取得処理を実行
    if ((this.busRouteList.length - this.busRouteLength) !== 0){
      await this.getBusRouteList();
    } else {
      this.busRouteListLocal = structuredClone(this.busRouteList);
    }

    this.loading = false;
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getBusRouteList: GET_BUS_ROUTE_LIST,
      getBusRouteLength: GET_BUS_ROUTE_LENGTH,
      createBusRoute: CREATE_BUS_ROUTE,
      updateBusRoute: UPDATE_BUS_ROUTE,
      invalidateBusRoute: INVALIDATE_BUS_ROUTE,
    }),
    async reload() {
      this.loading = true;
      await this.getBusRouteList();
      this.loading = false;
    },
    async addBusRoute() {
      this.loading = true;
      let response = await this.createBusRoute(this.busRouteAdd);
      if (response.data.statusCode === 200) {
        await this.getBusRouteList();
        this.closeDialogAdd();
      } else {
        this.snackbarText = 'バスの追加に失敗しました。';
        this.snackbar = true;
      }
      this.loading = false;
    },
    async editBusRoute() {
      this.loading = true;
      let response = await this.updateBusRoute(this.busRouteEdit);
      if (response.data.statusCode === 200) {
        await this.getBusRouteList();
        this.closeDialogEdit();
      } else {
        this.snackbarText = 'バスの編集に失敗しました。';
        this.snackbar = true;
      }
      this.loading = false;
    },
    async nullifyBusRoute() {
      this.loading = true;
      let response = await this.invalidateBusRoute(this.busRouteNullify);
      if (response.data.statusCode === 200) {
        await this.getBusRouteList();
        this.closeDialogNullify();
      } else {
        this.snackbarText = 'バスの無効化に失敗しました。';
        this.snackbar = true;
      }
      this.loading = false;
    },
    openDialogAdd() {
      this.busRouteAdd = structuredClone(this.busRouteDefault);
      this.dialogAdd = true;
    },
    closeDialogAdd() {
      this.dialogAdd = false;
      this.busRouteAdd = structuredClone(this.busRouteDefault);
    },
    openDialogEdit(busRoute) {
      this.busRouteEdit = structuredClone(busRoute);
      this.dialogEdit = true;
    },
    closeDialogEdit() {
      this.dialogEdit = false;
      this.busRouteEdit = structuredClone(this.busRouteDefault);
    },
    openDialogNullify(busRoute) {
      this.busRouteNullify = structuredClone(busRoute);
      this.dialogNullify = true;
    },
    closeDialogNullify() {
      this.dialogNullify = false;
      this.busRouteNullify = structuredClone(this.busRouteDefault);
    },
    required(value) {
      const isSpace = (v) => {
        v = String(v);
        if(v === undefined || v === null){
          return true;
        }
        for (let i = 0; i < v.length; i++) {
          const code = v.charCodeAt(i);
          if (code === 0x3000 || code === 0x20) { // Unicodeのコードポイントを使って全角or半角スペースを判定
            continue; // ループを継続
          }
          return false; // それ以外の文字が含まれている場合はfalseを返す
        }
        return true; // 全角スペースor半角スペースしか含まれていない場合はtrueを返す
      };

      return (!!value && !isSpace(value)) || "バスルート名" + REQUIRED_ERROR_MSG;
    },
    max_length_10(value) {
      return (value.length <= 10) || MAX_LENGTH_10_ERROR_MSG;
    },
  }
};
</script>

<style scoped>
</style>