<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <span id="render"></span>
    <DataConfirm v-if="isConfirmMode" :data="dataSurvey" />
    <template v-else>
      <template v-if="isLiffMode">
        <!-- <v-row>
          <v-col>
            <v-card shaped elevation="1">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img
                      v-if="userInfo && userInfo.picture"
                      :src="userInfo.picture"
                    ></v-img>
                    <v-avatar v-else color="primary white--text">
                      <span class="text-uppercase" v-if="userInfo.name">
                        {{ userInfo.name.substr(0, 2) }}
                      </span>                    
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="userInfo">{{ userInfo.name }}</span> 様
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row> -->
        <!-- <v-row v-if="configJson.headerImageUrl&&JSON.stringify(configJson.headerImageUrl) != '{}'">
          <v-col class="py-0">
            <v-card elevation="1" style="border:none;">
              <v-list class="py-0">
                <v-list-item class="px-0">
                  <v-img
                    :src="configJson.headerImageUrl.headerUrl ? configJson.headerImageUrl.headerUrl : configJson.headerImageUrl"
                    style="border-radius:4px;"
                  ></v-img>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <div class="custom-color">
              <h2 class="font-weight-bold mb-2 custom-layout-title">
                {{ configJson.surveyTitle }}
              </h2>
              <div>
                <div 
                  class="survey-description" 
                  v-html="renderToLink(configJson.description)"
                >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <v-form
        v-model="allFormValid"
        ref="allFormValid"
        @submit.prevent="redirect"
      >
        <v-row class="d-none">
          <v-col>{{ surveySchemaUpdatedFlag }}</v-col>
        </v-row>

        <!-- <div v-if="isConfirmSurveyMode && configJson.usePayment === 1">
          <v-row>
            <v-col>
              <v-card class="pa-3">
                <div class="font-weight-bold">
                  {{ "受付番号" }}
                  <span class="v-messages theme--light error--text">*</span>
                </div>
                <div class="caption grey--text text--darken-3">{{"受付番号は領収書ページよりご確認ください。"}}</div>
                <InputOrderId ref="inputOrderId" />
              </v-card>
            </v-col>
          </v-row>
        </div> -->

        <div v-for="(item, sIndex) in surveySchema1" :key="sIndex">
          <v-row v-if="!item.disabled">
            <v-col v-if="item.type !== 'linkbutton'">
              <div v-if="item.type === 'groupheader'">
                <v-expansion-panels
                  accordion
                  tile
                  multiple
                  class="groupheader"
                  v-model="groupheaderPanelValues[item.itemKey]"
                  :disabled="item.disabled"
                  v-if="!item.disabled"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="groupheader-header">
                      <div>
                        <p class="font-weight-bold">{{ item.title}}</p>
                        <p 
                          class="survey-description" 
                          v-html="renderToLink(item.description)"
                        ></p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="(value, index) in item.items"
                      :key="index"
                      eager
                    >
                      <div v-if="value.type !== 'linkbutton'">
                        <div>
                          <span class="font-weight-bold">{{
                            value.title
                          }}</span>
                          <span
                            v-if="
                              (isAdminMode &&
                                value.isRequired.admin === true) ||
                              (isAdminMode &&
                                (!('admin' in value.isRequired) ||
                                  value.isRequired.admin === null) &&
                                value.isRequired.value) ||
                              (!isAdminMode &&
                                (value.isRequired.value ||
                                  (value.isRequiredForUser && value.isRequiredForUser.value)))
                            "
                            class="v-messages theme--light error--text"
                          >
                            *</span
                          >
                        </div>
                        <div v-if="value.description">
                          <span 
                            class="caption grey--text text--darken-3"
                            v-html="renderToLink(value.description)"
                          >
                          </span>
                        </div>

                        <div v-if="value.imageUrl">
                          <v-img
                            class="liffItemImg"
                            :src="value.imageUrl.itemUrl ? value.imageUrl.itemUrl:value.imageUrl"
                            style="border-radius:4px;"
                          ></v-img>
                        </div>

                        <div
                          v-if="
                            value.type !== 'groupheader' &&
                            value.type !== 'guide'
                          "
                          v-bind:class="disabledFormRenderer"
                        >
                          <component
                            :mlLibJson="mlLibJson"
                            :configJson="configJson"
                            :ref="value.itemKey"
                            :is="getComponent(value.type)"
                            :params="value"
                            :isAdminMode="isAdminMode"
                            :isLiffMode="isLiffMode"
                            :isNotEditable="value.isNotEditable"
                            :forceNotEditable="forceNotEditable"
                            :isConfirmSurveyMode="isConfirmSurveyMode"
                            :isSurveyRegistering="isSurveyRegistering"
                            :categoriesTree="categoriesTree"

                            @fill-address="fillAddress"
                            @choice-groupheader-radio-click="
                              choiceGroupheaderRadioClick
                            "
                            @handleCheckSaibanExisting="
                              handleCheckSaibanExisting
                            "
                            @handleCheckSaibanExistingForUpdate="
                              handleCheckSaibanExistingForUpdate
                            "
                            :isSaibanExisted="isSaibanExisted"

                            :paymentResult="paymentResult"
                            :taxRate="taxRate"
                            :paymentService="paymentService"
                            :products="products"
                            :isFetchingPaymentInfo="isFetchingPaymentInfo"
                            @setPaymentInfo="handleSetPaymentInfo"
                            :isLanguageSettingsActive="isLanguageSettingsActive"
                            :isNewAnswer="isNewAnswer"
                          >
                          </component>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-container v-else>
                <v-row align="center">
                  <v-col cols="12">
                    <div>
                      <span class="font-weight-bold custom-color">{{ item.title}}</span>
                      <span
                        v-if="
                          (isAdminMode && item.isRequired.admin === true) ||
                          (isAdminMode &&
                            (!('admin' in item.isRequired) ||
                              item.isRequired.admin === null) &&
                            item.isRequired.value) ||
                          (!isAdminMode &&
                            (item.isRequired.value ||
                              (item.isRequiredForUser && item.isRequiredForUser.value)))
                          || (!isAdminMode && item.type === 'previousVaccineMaker')
                          || (!isAdminMode && item.type === 'previousVaccineDate')
                        "
                        class="v-messages theme--light error--text"
                      >
                        *</span
                      >
                    </div>
                    <div v-if="item.description">
                      <span
                        class="
                          caption
                          grey--text
                          text--darken-3
                          survey-description
                          custom-color
                        "
                        v-html="renderToLink(item.description)"
                        >
                        </span
                      >
                    </div>
                    <div v-if="item.imageUrl">
                      <v-img
                        class="liffItemImg"
                        :src="item.imageUrl.itemUrl ? item.imageUrl.itemUrl:item.imageUrl"
                        style="border-radius:4px;"
                      ></v-img>
                    </div>

                    <div
                      v-if="
                        item.type !== 'groupheader' && item.type !== 'guide'
                      "
                      v-bind:class="disabledFormRenderer"
                    >
                      <component
                        :mlLibJson="mlLibJson"
                        :configJson="configJson"
                        :hasCountVaccines="hasCountVaccines"
                        :ref="item.itemKey"
                        :is="getComponent(item.type)"
                        :params="item"
                        :isAdminMode="isAdminMode"
                        :isLiffMode="isLiffMode"
                        :isNotEditable="item.isNotEditable"
                        :forceNotEditable="forceNotEditable"
                        :isConfirmSurveyMode="isConfirmSurveyMode"
                        :isSurveyRegistering="isSurveyRegistering"
                        :categoriesTree="categoriesTree"
                        :isValidatePreviousVaccineDate="isValidatePreviousVaccineDate"
                        :isValidatePreviousVaccineMaker="isValidatePreviousVaccineMaker"
                        :vaccinationIntervalType="vaccinationIntervalType"
                        
                        @fill-address="fillAddress"
                        @choice-groupheader-radio-click="
                          choiceGroupheaderRadioClick
                        "
                        @handleCheckSaibanExisting="handleCheckSaibanExisting"
                        @handleCheckSaibanExistingForUpdate="
                          handleCheckSaibanExistingForUpdate
                        "
                        :isSaibanExisted="isSaibanExisted"
                        @handleCategoryChange="(value) => $emit('onCategoryChange', value)"

                        :paymentResult="paymentResult"
                        :taxRate="taxRate"
                        :paymentService="paymentService"
                        :products="products"
                        :isFetchingPaymentInfo="isFetchingPaymentInfo"
                        @setPaymentInfo="handleSetPaymentInfo"
                        :isLanguageSettingsActive="isLanguageSettingsActive"
                        @handleSelectCategoriesTreeChange="handleSelectCategoriesTreeChange"
                        :isNewAnswer="isNewAnswer"
                      >
                      </component>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </div>

        <!-- <v-row v-if="linkButtonObject && calendarButtonDisplay === true">
          <v-col>
            <v-container>
              <component
                :mlLibJson="mlLibJson"
                :ref="linkButtonObject.itemKey"
                :is="getComponent(linkButtonObject.type)"
                :params="linkButtonObject"
              >
              </component>
            </v-container>
          </v-col>
        </v-row> -->
      </v-form>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import markdown from "markdown-it";
import markdownLinkAttributes from "markdown-it-link-attributes";
import InputBase from "./components/InputBase.vue";
import DataConfirm from "./components/DataConfirm.vue";
import ShortAnswer from "./components/InputShortAnswer.vue";
import Paragraph from "./components/InputParagraph.vue";
import Email from "./components/InputEmail.vue";
import PhoneNumber from "./components/InputPhoneNumber.vue";
import PostCode from "./components/InputPostCode.vue";
import Address from "./components/InputAddress.vue";
import Choice from "./components/InputChoice.vue";
import Dropdown from "./components/InputDropdown.vue";
import Suggest from "./components/InputSuggest.vue";
import LanguageSuggest from "./components/InputLanguageSuggest.vue";
import DatePicker from "./components/InputDatePicker.vue";
import BirthdayPicker from "./components/InputBirthdayPicker.vue";
import DateDropdown from "./components/InputDateDropdown.vue";
import Checkboxes from "./components/InputCheckbox.vue";
import ChoiceGroupheader from "./components/InputChoiceGroupheader.vue";
import InputNumber from "./components/InputNumber.vue";
import Numbering from "./components/InputNumbering.vue";
import Linearscale from "./components/InputLinearscale.vue";
import Guide from "./components/Guide.vue";
import GroupHeader from "./components/GroupHeader.vue";
import Bunrui from "./components/InputBunrui.vue";
import LinkButton from "./components/InputLinkButton.vue";
import InputSesshuJisshiDatePicker from "./components/InputSesshuJisshiDatePicker.vue";
import SesshuVaccineMaker from "./components/InputSesshuVaccineMaker.vue";
import InputCountVaccines from "./components/InputCountVaccines.vue";
import InputPreviousVaccineMaker from "./components/InputPreviousVaccineMaker.vue";
import InputPreviousVaccineDatePicker from "./components/InputPreviousVaccineDatePicker.vue";
// import InputSelectProducts from './components/InputSelectProducts/index.vue';
// import InputOrderId from './components/InputOrderId.vue';
// import InputSelectPayType from './components/InputSelectPayType.vue';

import { cloneDeep } from "lodash";

import Vuetify, {
  VTextField,
  VSelect,
  VRow,
  VCol,
  VContainer,
  VCard,
  VForm,
  VCheckbox,
  VRadio,
  VApp,
  VAlert,
  VTextarea,
  VRadioGroup,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemTitle,
  VListItemIcon,
  VAvatar,
  VIcon,
  VImg,
  VExpansionPanels,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VExpansionPanel,
  VMenu,
  VDatePicker,
  VAutocomplete,
  VBtn,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VTextField,
    VSelect,
    VRow,
    VCol,
    VContainer,
    VCard,
    VForm,
    VCheckbox,
    VRadio,
    VApp,
    VAlert,
    VTextarea,
    VRadioGroup,
    VList,
    VListItem,
    VListItemAvatar,
    VListItemContent,
    VListItemTitle,
    VListItemIcon,
    VAvatar,
    VIcon,
    VImg,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VExpansionPanel,
    VMenu,
    VDatePicker,
    VAutocomplete,
    VBtn,
  },
});

const RENDER_COMPONENTS = {
  text: "ShortAnswer",
  textarea: "Paragraph",
  email: "Email",
  phone: "PhoneNumber",
  postcode: "PostCode",
  address: "Address",
  radio: "Choice",
  dropdown: "Dropdown",
  suggest: "Suggest",
  languageSuggest: "LanguageSuggest",
  date: "DatePicker",
  birthday: "BirthdayPicker",
  dateDropdown: "DateDropdown",
  checkboxes: "Checkboxes",
  choicegroupheader: "ChoiceGroupheader",
  number: "InputNumber",
  linearscale: "Linearscale",
  guide: "Guide",
  groupheader: "GroupHeader",
  reservation: "Bunrui",
  linkbutton: "LinkButton",
  sesshuJisshiDate: "InputSesshuJisshiDatePicker",
  memberNumber: "Numbering",
  sesshuVaccineMaker: "SesshuVaccineMaker",
  countVaccines: "InputCountVaccines",
  previousVaccineDate: "InputPreviousVaccineDatePicker",
  previousVaccineMaker: "InputPreviousVaccineMaker",
  selectProducts: 'InputSelectProducts',
  selectPayType: 'InputSelectPayType',
};

export default Vue.extend({
  components: {
    ShortAnswer,
    Paragraph,
    Email,
    PhoneNumber,
    PostCode,
    Address,
    Choice,
    Dropdown,
    Suggest,
    LanguageSuggest,
    DatePicker,
    BirthdayPicker,
    DateDropdown,
    Checkboxes,
    ChoiceGroupheader,
    InputNumber,
    Linearscale,
    DataConfirm,
    Guide,
    GroupHeader,
    Bunrui,
    LinkButton,
    InputSesshuJisshiDatePicker,
    Numbering,
    SesshuVaccineMaker,
    InputCountVaccines,
    InputPreviousVaccineMaker,
    InputPreviousVaccineDatePicker,
    // InputSelectProducts,
    // InputOrderId,
    // InputSelectPayType,
  },
  mixins: [InputBase],
  props: {
    mlLibJson: Object,
    isLanguageSettingsActive: {
      type: Boolean,
      default: false,
    },
    configJson: Object,
    userInfo: {
      type: Object,
      default: function () {
        return {
          identities: [
            {
              dateCreated: "1234567890123",
              issuer: null,
              primary: "true",
              providerName: "Line",
              providerType: "OIDC",
              userId: "KdalfhASfljoaidjfaLfljadiojf64656",
            },
          ],
          name: "お客",
        };
      },
    },
    isLiffMode: Boolean,
    isConfirmMode: Boolean,
    userId: String,
    isSurveyRegistering: {
      type: Boolean,
      default: true,
      required: false,
    },
    dataSurvey: Object,
    isAppending: Boolean,
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    isConfirmSurveyMode: {
      type: Boolean,
      default: false,
    },
    forceNotEditable: {
      type: Boolean,
      default: false,
    },

    categoryRequiredFlag: Boolean,
    calendarLabelDisplay: Boolean,
    calendarButtonDisplay: Boolean,

    isSaibanExisted: {
      type: Boolean,
      default: true,
      required: false,
    },

    categoriesTree: {
      type: Object,
      default: null,
      required: false,
    },

    isValidatePreviousVaccineDate: {
      type: Boolean,
      default: true,
    },
    isValidatePreviousVaccineMaker: {
      type: Boolean,
      default: true,
    },
    // for payment
    paymentResult: {
      type: Object,
      default: null,
    },
    paymentService: {
      type: Object,
      default: null,
    },
    taxRate: {
      type: Number,
      default: 0,
    },
    products: {
      type: Array,
      default: () => [],
    },
    isFetchingPaymentInfo: {
      type: Boolean,
      default: false,
    },
    isNewAnswer: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    disabledFormRenderer() {
      return {
        "v-input--is-disabled": false,
      };
    },
    linkButtonObject() {
      return this.surveySchema.find((obj) => obj.type === "linkbutton");
    },
    hasCountVaccines() {
      const countVaccines = this.configJson.surveySchema.find(item => {
        return item.type === 'countVaccines';
      });
      return !!countVaccines;
    },
    vaccinationIntervalType() {
      const vaccinationIntervalType = this.configJson.vaccinationIntervalType?.input;
      return vaccinationIntervalType || '';
    },
    markdownIt() {
      const md = new markdown();
      md.disable([
        "table", 
        "code",
        "smartquotes",
        "normalize",
        "fence",
        "blockquote",
        "hr",
        "list",
        "reference",
        "backticks",
        "html_block",
        "heading",
        "lheading",
        "strikethrough",
        "emphasis"
      ]);
      md.use(markdownLinkAttributes, {
        attrs: {
          target: "_blank",
          rel: "noopener"
        }
      });
      return md
    }
  },
  methods: {
    initOrder() {
      // NOTE: 親コンポーネントから呼ばれる関数
      const doesUserPayment = this.configJson.usePayment === 1;
      if (!doesUserPayment) {
        return;
      }
      const targetItem = this.configJson.surveySchema.find(item => ['reservation', 'selectProducts'].includes(item.type));
      if (!targetItem) {
        return;
      }
      this.$refs[targetItem.itemKey][0].initOrder();
    },
    fillAddress(input, postCodeItemKey) {
      let postCodeItemFlg = false;
      let addressItemKey;
      for (let element of this.configJson.surveySchema) {
        if (element.itemKey === postCodeItemKey) {
          postCodeItemFlg = true;
        }
        if (postCodeItemFlg && element.type === "address") {
          addressItemKey = element.itemKey;
          break;
        }
      }

      if (addressItemKey != null && this.$refs[addressItemKey] != null) {
        this.$refs[addressItemKey][0].setValue(input);
      }
    },
    redirect() {
      this.$emit("redirect");
    },
    getComponent(name) {
      return RENDER_COMPONENTS[name];
    },
    automaticallyOpenInvalidGroupHeaders() {
      for (const item of this.surveySchema1) {
        if (item.type === 'groupheader' && item.items && Array.isArray(item.items) && item.items.length > 0) {
          for (const groupHeaderItem of item.items) {
            const domElementList = this.$refs[groupHeaderItem.itemKey];
            if (domElementList && Array.isArray(domElementList) && domElementList.length > 0) {
              const domElement = domElementList[0];
              try {
                const validateResponse = domElement.validateRequired();
                if (validateResponse !== true) {
                  this.groupheaderPanelValues[item.itemKey] = [0];
                }
              } catch(err) {
                // Ignore the error
              }
            }
          }
        }
      }
    },
    getSelectedPayType(formData) {
      const selectPayTypeItem = this.configJson.surveySchema.find(item => item.type === 'selectPayType');
      if (!selectPayTypeItem) {
        return null;
      }
      const selectedPayTypeInput = formData.get(selectPayTypeItem.itemKey);
      return selectPayTypeItem.payTypeOptions.find(o => o.input === selectedPayTypeInput)?.payType || null;
    },
    getFormData() {
      this.$forceUpdate();

      const isValid = this.$refs.allFormValid.validate();
      if (!isValid) {
        this.automaticallyOpenInvalidGroupHeaders();
        return undefined;
      }
      const formData = new FormData();
      let paymentData = null;
      for (
        let index = 0;
        index < this.configJson.surveySchema.length;
        index++
      ) {
        let item;
        let value;

        item = this.configJson.surveySchema[index];
        if (item.type === "groupheader" || item.type === "guide") {
          continue;
        }
        try {
          value = this.$refs[item.itemKey][0].getValue();
          if (this.configJson.usePayment && ['reservation', 'selectProducts'].includes(item.type)) {
            const isResrevation = item.type === 'reservation';
            const paymentDataJson = isResrevation ? this.$refs[item.itemKey][0].getPaymentData() : value;
            paymentData = JSON.parse(paymentDataJson);
            if (!isResrevation) {
              continue;
            }
          }
        } catch (error) {
          const found = this.surveySchemaTemp.find(
            (element) => element.itemKey === item.itemKey
          );
          value = found.input;
        }
        if (value === undefined || value === "") {
          continue;
        }
        if (value instanceof Array) {
          value.forEach((val) => {
            formData.append(item.itemKey, val);
          });
        } else {
          formData.append(item.itemKey, value);
        }
      }
      if (this.configJson.usePayment && paymentData?.details?.length === 0) {
        return null;
      }
      if (paymentData?.details?.length > 0) {
        const selectedPayType = this.getSelectedPayType(formData);
        if (selectedPayType !== null) {
          paymentData.selectedPayType = selectedPayType;
        }
        formData.append('paymentData', JSON.stringify(paymentData));
      }
      return formData;
    },
    getFormDataJson(userId) {
      const formData = this.getFormData();
      if (formData) {
        let paymentData;
        const surveyResults = [];
        for (const pair of formData.entries()) {
          if (pair[0] === 'paymentData') {
            
            paymentData = JSON.parse(pair[1]);
            continue;
          }
          surveyResults.push({
            itemKey: pair[0],
            title: this.getTitle(pair[0]),
            value: pair[1],
          });
        }
        const orderId = this.isConfirmSurveyMode && this.configJson.usePayment === 1 && this.$refs.inputOrderId.getValue();

        return {
          surveyId: this.configJson.memberSurveyId?
            this.configJson.memberSurveyId : this.configJson.surveyId,
          userId: userId,
          surveyResults,
          paymentData,
          orderId,
        };
      }
      return null;
    },
    getTitle(key) {
      let _item = this.configJson.surveySchema.find(
        (obj) => obj.itemKey === key
      );
      return _item ? _item.title : "";
    },
    choiceGroupheaderRadioClick(input, params) {
      this.surveySchemaUpdatedFlag++;

      let radio_selected_value = input;

      let section_options_keys = Object.keys(params.sectionOptions);

      section_options_keys.forEach((section_options_key) => {
        let sectionOption = params.sectionOptions[section_options_key];
        if (radio_selected_value == sectionOption.option.value) {
          sectionOption.groupheader.disabled = false;
        } else {
          sectionOption.groupheader.disabled = true;
        }
      });

      let surveySchemaKeys = Object.keys(this.surveySchema1);
      surveySchemaKeys.forEach((schema_key) => {
        let schemaElement = this.surveySchema1[schema_key];
        if (schemaElement.type === "groupheader") {
          section_options_keys.forEach((section_options_key) => {
            let sectionOption = params.sectionOptions[section_options_key];
            if (schemaElement.title === sectionOption.groupheader.value) {
              schemaElement.disabled = sectionOption.groupheader.disabled;

              {
                // inside groupheader, there is/are ChoiceGroupheader need to be disabled/enabled
                let subElements = schemaElement.items;
                subElements.forEach((subElement) => {
                  if (subElement.type === "choicegroupheader") {
                    if (
                      subElement.sectionOptions &&
                      Array.isArray(subElement.sectionOptions)
                    ) {
                      subElement.sectionOptions.forEach((subSectionOption) => {
                        subSectionOption.groupheader.disabled =
                          schemaElement.disabled;
                      });
                    }
                  }
                });
              }
            }
          });
        }
      });

      this.updateGroupheader();
    },
    updateGroupheader() {
      this.surveySchema1.forEach((element) => {
        if (element.type === "groupheader") {
          let radioGroupheaderDataArray = Object.values(
            this.radioGroupheaderData
          );
          radioGroupheaderDataArray.forEach((elementGroupheaderData) => {
            let elementGroupheaderDataArray = Object.values(
              elementGroupheaderData
            );
            elementGroupheaderDataArray.forEach((groupheaderData) => {
              if (groupheaderData.value == element.title) {
                groupheaderData.itemKey = element.itemKey;
                element.disabled = groupheaderData.disabled;

                this.groupheaderPanelValues[element.itemKey] = element.disabled
                  ? []
                  : [0];

                return;
              }
            });
          });

          if (element.disabled) {
            element.items.forEach((subElement) => {
              switch (subElement.type) {
                case "checkboxes":
                  subElement.input = [];
                  break;
                case "radio":
                  subElement.input = undefined;
                  break;
                default:
                  subElement.input = "";
                  break;
              }
            });
          }
        }
      });
    },

    handleCheckSaibanExisting(saiban) {
      this.$emit("handleCheckSaibanExisting", saiban);
    },

    handleCheckSaibanExistingForUpdate(payload) {
      this.$emit("handleCheckSaibanExistingForUpdate", payload);
    },
    handleSetPaymentInfo(categoryId) {
      this.$emit('setPaymentInfo', categoryId);
    },
    handleSelectCategoriesTreeChange(categoriesTree) {
      this.$emit("handleSelectCategoriesTreeChange", categoriesTree);
    },
    renderToLink(text) {
      if (null !== text) {
        return this.markdownIt.render(text)
      }
      return ""
    }
  },
  data() {
    return {
      allFormValid: true,
      surveySchema1: [],
      surveySchemaTemp: {},
      surveySchemaUpdatedFlag: 0, // surveySchema1が変更した時に、画面の表示が更新になるため。
      radioGroupheaderData: {},
      groupheaderData: {},
      groupheaderPanelValues: {},
    };
  },
  created() {
    const configJson = this.configJson;
    if (
      configJson.inputs === undefined ||
      configJson.inputs === null ||
      configJson.inputs === []
    ) {
      this.surveySchema = configJson.surveySchema.map(function (item) {
        if (item.default !== undefined && item.default !== null && item.type !== 'selectProducts') {
          item.input = item.default;
        }
        return item;
      });
    } else {
      this.surveySchema = configJson.surveySchema.map(function (item) {
        const input = configJson.inputs[item.itemKey];
        item.input = input;
        if (typeof input === "string" && input.startsWith("category#")) {
          if (configJson.inputs.reservationDate !== undefined) {
            item.reservationDate = configJson.inputs.reservationDate;
          }
        }
        return item;
      });
    }

    this.surveySchemaTemp = cloneDeep(this.surveySchema);

    let groupHeaderFlag = false;
    let elementTemp = {};
    const countVaccineInput = configJson.surveySchema.find(item => item.type === 'countVaccines')?.default;
    const isFirstVaccination = parseInt(countVaccineInput) === 1;
    const previousVaccineItemTypes = ['previousVaccineMaker', 'previousVaccineDate'];

    this.surveySchemaTemp.forEach((element) => {
      if (
        (this.isLiffMode &&
          element.isAdminItem !== undefined &&
          element.isAdminItem) ||
        (isFirstVaccination && previousVaccineItemTypes.includes(element.type))
      ) {
        return;
      }
      switch (element.type) {
        case "reservation":
          element.categoryRequiredFlag = this.categoryRequiredFlag;
          element.calendarLabelDisplay = this.calendarLabelDisplay;
          break;

        case "choicegroupheader":
          if (element.sectionOptions && Array.isArray(element.sectionOptions)) {
            element.sectionOptions.forEach((sectionOption) => {
              let groupheader = sectionOption.groupheader;
              if (groupheader) {
                groupheader.disabled = false;
                groupheader.itemKey = null;
                if (groupheader.value) {
                  groupheader.key = sectionOption.option.value;
                  if (
                    (element.input &&
                      groupheader.key &&
                      element.input == groupheader.key) ||
                    (!element.input &&
                      element.default &&
                      groupheader.key &&
                      element.default == groupheader.key)
                  ) {
                    groupheader.disabled = false;
                  } else {
                    groupheader.disabled = true;
                  }
                } else {
                  groupheader.key = null;
                }

                if (!this.radioGroupheaderData[element.itemKey]) {
                  this.radioGroupheaderData[element.itemKey] = {};
                }
                this.radioGroupheaderData[element.itemKey][
                  sectionOption.option.value
                ] = sectionOption.groupheader;
              }
            });
          }
          break;

        case "groupheader":
          this.groupheaderData[element.itemKey] = element;
          this.groupheaderPanelValues[element.itemKey] = [];

          break;

        default:
          break;
      }

      if (element.type !== "groupheader") {
        if (element.type === "choicegroupheader") {
          groupHeaderFlag = false;
          if (elementTemp.items !== undefined) {
            this.surveySchema1.push(elementTemp);
            elementTemp = {};
          }
        }
        if (groupHeaderFlag == false) {
          this.surveySchema1.push(element);
        } else {
          if (elementTemp.items === undefined) {
            elementTemp.items = [];
          }
          elementTemp.items.push(element);
        }
      } else {
        //groupHeader in the first time;
        if (groupHeaderFlag == false) {
          groupHeaderFlag = true;

          element.disabled = false;

          elementTemp = element;
          elementTemp.items = [];
        } else {
          // groupHeader from second times
          // push the elementTemp to the last surveySchema
          this.surveySchema1.push(elementTemp);

          // reset elementTemp
          elementTemp = {};

          // set element to elementTemp
          elementTemp = element;
          elementTemp.items = [];
        }
      }
    });
    //case: the group header in the last of surveySchema -> add elementTemp to the last of this.surveySchema
    if (elementTemp.items !== undefined) {
      this.surveySchema1.push(elementTemp);
    }

    {
      // Check inside groupheader
      this.surveySchema1.forEach((element) => {
        if (element.type === "choicegroupheader") {
          let input =
            element.input || (!element.input ? element.default : null);
          if (
            input == null &&
            Array.isArray(element.sectionOptions) &&
            element.sectionOptions.length > 0 &&
            element.sectionOptions[0].option
          ) {
            input = element.sectionOptions[0].option.value;
            element.input = input;
          }
          if (input) {
            this.choiceGroupheaderRadioClick(input, {
              sectionOptions: element.sectionOptions,
            });

            if (
              this.radioGroupheaderData[element.itemKey][input].disabled ===
              false
            ) {
              let groupheader_itemKey =
                this.radioGroupheaderData[element.itemKey][input].itemKey;
              this.groupheaderPanelValues[groupheader_itemKey] = [1];
            }
          }
        }
      });
    }

    this.required = this.configJson.surveySchema.some(
      (item) =>
        (this.isAdminMode === false && 
          (item.isRequired.value || (item.isRequiredForUser && item.isRequiredForUser.value))) ||
        (this.isAdminMode === true &&
          (!("admin" in item.isRequired) || item.isRequired.admin === null) &&
          item.isRequired.value) ||
        (this.isAdminMode === true && item.isRequired.admin)
        || (!this.isAdminMode && item.type === 'previousVaccineMaker')
        || (!this.isAdminMode && item.type === 'previousVaccineDate')
    );
  },
});
</script>

<style>
.print-applicant-details-item {
  display: none;
}
.liffItemImg .v-image__image{
  max-height:300px!important;
  background-size:contain!important;
}
.liffItemImg .v-responsive__content,.v-image.v-responsive.liffItemImg{
  max-height:300px!important;
}
</style>

<style scoped>
.custom-color {
  color: #4D9B50!important;
}
.v-application .grey--text.text--darken-3 {
  color: #4D9B50!important;
}
.custom-layout-title {
  text-align: left;
  font-size: large;
}
</style>
