<template>
  <div class="contentsArea">
    <v-card-actions style="max-height:50px;">
      <div class="text-right ma-4 row">
        <v-btn color="primary" text @click="toEnqueteDeliveryList">
          <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            color="error"
            elevation="1"
            class="mx-2"
            :disabled="canPublicEnd()"
            @click="onPublicEndItem()">アンケートを終了する</v-btn>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            :loading="loading"
            color="primary"
            class="mx-2"
            elevation="0"
            :disabled="isPublicEnd()"
            @click="clickRemind()">リマインド配信</v-btn>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            :loading="loading"
            color="primary"
            class="mx-2"
            elevation="0"
            @click="onCopyEnqueteInfo()">複製して新規作成</v-btn>
          <v-btn 
            v-if="!isEdit"
            :loading="loading"
            color="primary"
            class="mx-2"
            elevation="0"
            @click="saveEnqueteInfo()">アンケートを実施する</v-btn>
          <v-btn 
            v-if="isEdit && isUnPublished && !isCopy"
            :loading="loading"
            color="primary"
            class="mx-2"
            elevation="0"
            @click="updateEnqueteInfo()">保存</v-btn>
        </div>
      </div>
    </v-card-actions>
    <v-divider></v-divider>
    <v-tabs v-model="activeTab">
      <v-tab href="#enquete">
        <v-icon>mdi-account-box</v-icon>
        {{ "アンケート" }}
      </v-tab>
      <v-tab href="#answer" :disabled="isAnswerTabDisabled()">
        <v-icon>mdi-account-box</v-icon>
        {{ "回答" }}
      </v-tab>
      <v-tab href="#settings">
        <v-icon>mdi-account-box</v-icon>
        {{ "設定" }}
      </v-tab>

      <v-tab-item value="enquete">
        <EnqueteTab :enqueteActiveTab="activeTab" :isCopy="isCopy"/>
      </v-tab-item>
      <v-tab-item value="answer">
        <AnswerTab :enqueteOpenId="enqueteOpenInfo.enqueteOpenId"/>
      </v-tab-item>
      <v-tab-item value="settings">
        <SettingsTab />
      </v-tab-item>
    </v-tabs>
    <template v-if="activeTab != 'answer'">
      <v-divider></v-divider>
      <v-row class="text-center ma-4">
        <v-col>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            color="error"
            class="mx-2"
            :disabled="isPublicEnd()"
            @click="onPublicEndItem()">アンケートを終了する</v-btn>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            :loading="loading"
            color="primary"
            class="mx-2"
            :disabled="isPublicEnd()"
            @click="clickRemind()">リマインド配信</v-btn>
          <v-btn 
            v-if="isEdit && !isUnPublished"
            :loading="loading"
            color="primary"
            class="mx-2"
            @click="onCopyEnqueteInfo()">複製して新規作成</v-btn>
          <v-btn 
            v-if="!isEdit"
            :loading="loading"
            color="primary"
            class="mx-2"
            @click="saveEnqueteInfo()">アンケートを実施する</v-btn>
          <v-btn 
            v-if="isEdit && isUnPublished && !isCopy"
            :loading="loading"
            color="primary"
            class="mx-2"
            @click="updateEnqueteInfo()">保存</v-btn>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="loading" persistent width="320">
      <v-card color="primary" dark>
        <v-card-text>
          アンケート開催情報を登録しています。
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from "vuex";

import {
  SAVE_DELIVERY_IMG_TO_S3,
  REGISTER_ENQUETE_OPEN_INFO,
  GET_ENQUETE_OPNE_INFO,
  CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO,
  SEND_DELIVERY,
  SAVE_DELIVERY_PDF_TO_S3,
} from "@/store/action-types";
import { 
  SET_IS_UN_PUBLISHED,
  SET_FORM_EDIT_MODE,
  SET_DELIVERY_DETAIL,
  SET_ENQUETE_DELIVERY_DETAIL,
  SET_ENQUETE_SETTINGS,
  SET_ENQUETE_OPNE_INFO,
  SET_EXTERNAL_ENQUETE_URL,
  SET_DELIVERY_IMGS,
  SET_FORM_SCHEMA,
  UPDATE_SURVEY_SCHEMA,
  SET_ACTIVE_FORM_SCHEMA_ITEM,
  SET_ACTIVE_FORM_SCHEMA_SECTION,
  SET_DELIVERY_PDFS,
} from "@/store/mutation-types";

import { eventBus } from '@/main.js'
import { cloneDeep } from "lodash";
import { generateUUID } from "@/utils/uuidUtils";
import ValidateSurveyForm from "@/model/Form/ValidateSurveyForm";

import * as moment from 'moment-timezone';
moment.tz.setDefault('Asia/Tokyo'); // タイムゾーンをJSTに設定
import EnqueteTab from "@/components/Enquete/EnqueteDeliveryCreation/fragments/EnqueteTab.vue";
import AnswerTab from "@/components/Enquete/EnqueteDeliveryCreation/fragments/AnswerTab.vue";
import SettingsTab from "@/components/Enquete/EnqueteDeliveryCreation/fragments/SettingsTab.vue";

export default {
  name: "EnqueteDeliveryCreation",
  data() {
    return {
      loading: false,
      activeTab: 0,
      tabs: [
        { name: 'アンケート', href: '#enquete' },
        { name: '回答', href: '#answer' },
        { name: '設定', href: '#settings' }
      ],
      isLineEnquete: true,
      isEdit: false,
      isCopy: false,
    };
  },
  components: {
    EnqueteTab,
    AnswerTab,
    SettingsTab,
  },
  computed: {
    ...mapState({
      enqueteDeliveryDetail: (state) => state.enqueteDeliveryDetail,
      deliveryImgs: state => state.deliveryImgs,
      enqueteSettings: state => state.enqueteSettings,
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      isUnPublished: state => state.isUnPublished,
      externalEnqueteURL: state => state.externalEnqueteURL,
      emojiOver20Error: state => state.emojiOver20Error,
      fiscalYear: (state) => state.fiscalYear,
      deliveryPdfs: state => state.deliveryPdfs,
    }),
    ...mapGetters(["step1SurveyFormat"]),
  },
  methods: {
    ...mapActions({
      saveDeliveryImgToS3: SAVE_DELIVERY_IMG_TO_S3,
      registerEnqueteOpenInfo: REGISTER_ENQUETE_OPEN_INFO,
      getEnqueteOpneInfo: GET_ENQUETE_OPNE_INFO,
      closeToPublicEnqueteOpenInfo: CLOSE_TO_PUBLIC_ENQUETE_OPEN_INFO,
      sendDelivery: SEND_DELIVERY,
      saveDeliveryPdfToS3: SAVE_DELIVERY_PDF_TO_S3,
    }),
    ...mapMutations({
      setIsUnPublished: SET_IS_UN_PUBLISHED,
      setFormEditMode: SET_FORM_EDIT_MODE,
      setDeliveryDetail: SET_DELIVERY_DETAIL,
      setEnqueteDeliveryDetail: SET_ENQUETE_DELIVERY_DETAIL,
      setEnqueteSettings: SET_ENQUETE_SETTINGS,
      setEnqueteOpenInfo: SET_ENQUETE_OPNE_INFO,
      setExternalEnqueteUrl: SET_EXTERNAL_ENQUETE_URL,
      setDeliveryImgs: SET_DELIVERY_IMGS,
      setFormSchema: SET_FORM_SCHEMA,
      updateSurveySchema: UPDATE_SURVEY_SCHEMA, 
      setActiveItem: SET_ACTIVE_FORM_SCHEMA_ITEM,
      setActiveSection: SET_ACTIVE_FORM_SCHEMA_SECTION,
      setDeliveryPdfs: SET_DELIVERY_PDFS,
    }),
    toEnqueteDeliveryList() {
      this.$router.push({ name: 'EnqueteDeliveryList' });
    },
    async saveEnqueteInfo() {
      this.$snackbar.hide();
      // バリデーションチェック
      const isValidationError = await this.isValidationError();
      
      if (!isValidationError) {
        const deliveryDetail = cloneDeep(this.enqueteDeliveryDetail)
        // 画像をs3に格納して、s3のurlを設定する
        await this.saveDeliveryImgToS3()
        await this.saveDeliveryPdfToS3()

        console.log('img', this.deliveryImgs)
        console.log('pdf', this.deliveryPdfs)
        
        console.log('deliveryDetail', deliveryDetail)

        for (let i = 0; i < deliveryDetail.messages.length; i++) {
          if (i in (this.deliveryImgs)) {
            if (deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
              deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
            }
          }
          if (i in (this.deliveryPdfs)) {
            if (deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
              deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
              deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
              deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
            }
          }
        }

        let payload;
        // LINEアンケート or 外部アンケートか判定
        if (deliveryDetail.enqueteType == 0) {
          // LINEアンケートならテンプレートを設定
          // テンプレート情報取得
          let templateInfo = cloneDeep(this.step1SurveyFormat);

          if (typeof templateInfo.surveyId === 'undefined') {
            // テンプレートなしで作成したテンプレート情報はsurveyIdが存在しない為、設定する
            templateInfo.surveyId = templateInfo.key;
          }
          
          payload = {
            enqueteOpenId: generateUUID(),
            deliveryInfo: deliveryDetail,
            templateInfo: templateInfo,
            enqueteSettings: this.enqueteSettings,
          }
        } else {
          // 外部アンケートなら外部アンケートURLを設定
          payload = {
            enqueteOpenId: generateUUID(),
            deliveryInfo: deliveryDetail,
            externalEnqueteUrl: this.externalEnqueteURL,
            enqueteSettings: this.enqueteSettings,
          }
        }

        console.log(payload)
        
        this.$dialog.show({
          title: "アンケート開催情報を保存してもよろしいですか？",
          type: "info",
          btnConfirmTitle: "アンケートを保存する",
          onConfirm: async () => {
            // アンケート開催情報の保存
            this.loading = true;
            await this.registerEnqueteOpenInfo(payload);
            this.loading = false;

            // state変数は初期化する
            this.vuexStateReset();

            // アンケート開催情報一覧に遷移
            this.$router.push({ name: 'EnqueteDeliveryList' })
          },
        });
      }
    },
    async updateEnqueteInfo() {
      this.$snackbar.hide();
      // バリデーションチェック
      const isValidationError = await this.isValidationError();
      
      if (!isValidationError) {
        const deliveryDetail = cloneDeep(this.enqueteDeliveryDetail)
        // 画像をs3に格納して、s3のurlを設定する
        await this.saveDeliveryImgToS3()
        await this.saveDeliveryPdfToS3()

        for (let i = 0; i < deliveryDetail.messages.length; i++) {
          if (i in (this.deliveryImgs)) {
            if (deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
              deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
            }
          }
          if (i in (this.deliveryPdfs)) {
            if (deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
              deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
              deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
              deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
            }
          }
        }

        let payload;
        // LINEアンケート or 外部アンケートか判定
        if (deliveryDetail.enqueteType == 0) {
          // LINEアンケートならテンプレートを設定
          payload = {
            enqueteOpenId: this.enqueteOpenInfo.enqueteOpenId,
            deliveryInfo: deliveryDetail,
            templateInfo: this.step1SurveyFormat,
            enqueteSettings: this.enqueteSettings,
          }
        } else {
          // 外部アンケートなら外部アンケートURLを設定
          payload = {
            enqueteOpenId: this.enqueteOpenInfo.enqueteOpenId,
            deliveryInfo: deliveryDetail,
            externalEnqueteUrl: this.externalEnqueteURL,
            enqueteSettings: this.enqueteSettings,
          }
        }
        
        this.$dialog.show({
          title: "アンケート開催情報を更新してもよろしいですか？",
          type: "info",
          btnConfirmTitle: "アンケートを更新する",
          onConfirm: async () => {
            // アンケート開催情報の更新
            this.loading = true;
            await this.registerEnqueteOpenInfo(payload);
            this.loading = false;

            // state変数は初期化する
            this.vuexStateReset();

            // アンケート開催情報一覧に遷移
            this.$router.push({ name: 'EnqueteDeliveryList' })
          },
        });
      }
    },
    vuexStateReset() {
      // state変数を初期化する
      this.setEnqueteDeliveryDetail({});
      this.setEnqueteSettings({});
      this.setEnqueteOpenInfo({});
      this.setExternalEnqueteUrl("");
      this.setDeliveryImgs([]);
      this.setFormSchema(null);
      this.setDeliveryPdfs([]);
    },
    async isValidationError() {
      // 配信条件のチェック
      const isDeliveryDetailValidationError = await this.isDeliveryDetailValidationError();
      if (isDeliveryDetailValidationError) {
        return true;
      }

      // LINEアンケート or 外部アンケートか判定
      if (this.enqueteDeliveryDetail.enqueteType == 0) {
        // テンプレートのチェック
        const isTemplateValidationError = await this.isTemplateValidationError();
        if (isTemplateValidationError) {
          return true;
        }

        // テンプレートの変更内容を反映
        this.setActiveItem(this.step1SurveyFormat.surveyId);
        this.setActiveSection(this.step1SurveyFormat.surveyId);
        eventBus.$emit('updateDataLocalToStore');
      } else if (this.enqueteDeliveryDetail.enqueteType == 1) {
        // 外部アンケートURLのチェック
        const isNullOrEmpty = this.isNullOrEmpty(this.externalEnqueteURL);
        if (isNullOrEmpty) {
          this.$snackbar.show({ text: "外部アンケートのURLが入力されていません。", type: "error" });
          return true;
        }
      }

      // 設定のチェック
      const isSettingsValidationError = await this.isSettingsValidationError();
      if (isSettingsValidationError) {
        return true;
      }

      return false;
    },
    async isDeliveryDetailValidationError() {
      const deliveryDetail = cloneDeep(this.enqueteDeliveryDetail)

      // 開催情報配信時のタイトルが入力されているかチェック
      const isNullOrEmptyDeliveryName = this.isNullOrEmpty(deliveryDetail.deliveryName);
      if (isNullOrEmptyDeliveryName) {
        this.$snackbar.show({ text: "アンケート名が入力されていません。", type: "error" });
        return true;
      }
      // 開催情報配信時のタイトルが30文字以内かチェック
      const isOverMaxLength30DeliveryName = this.isOverMaxLength30(deliveryDetail.deliveryName);
      if (isOverMaxLength30DeliveryName) {
        this.$snackbar.show({ text: "アンケート名は30文字までです。", type: "error" });
        return true;
      }
      // 実施対象者に生徒を選択している場合に生徒が選択されているかチェック
      if (deliveryDetail.segmentType === "PERSONAL") {
        const isNonSelectPersonal = this.isNullOrEmpty(deliveryDetail.targets.student_id) || (deliveryDetail.targets.student_id === -1);
        if (isNonSelectPersonal) {
          this.$snackbar.show({ text: "生徒が選択されていません。", type: "error" });
          return true;
        }
      }
      // 予約配信を選択している場合に予約配信日時が現在日時以降かチェック
      // if (deliveryDetail.deliveryTiming === "ONETIME") {
      //   // 予約日付のチェック
      //   const isNullOrEmptyDate = this.isNullOrEmpty(deliveryDetail.oneTimeDetail.date);
      //   if (isNullOrEmptyDate) {
      //     this.$snackbar.show({ text: "予約配信日付が選択されていません。", type: "error" });
      //     return true;
      //   }
      //   // 予約時刻のチェック
      //   const isNullOrEmptyTime = this.isNullOrEmpty(deliveryDetail.oneTimeDetail.time);
      //   if (isNullOrEmptyTime) {
      //     this.$snackbar.show({ text: "予約配信時刻が選択されていません。", type: "error" });
      //     return true;
      //   }
      //   const isPastDateTime = this.isPastDateTime(deliveryDetail.oneTimeDetail);
      //   if (isPastDateTime) {
      //     this.$snackbar.show({ text: "現在日時より前の日時が選択されています。", type: "error" });
      //     return true;
      //   }
      // }
      // 公開終了日が選択されているかチェック
      const isNullOrEmptyEndDate = this.isNullOrEmpty(deliveryDetail.endDate);
      if (isNullOrEmptyEndDate) {
        this.$snackbar.show({ text: "公開終了日が選択されていません。", type: "error" });
        return true;
      }
      // 公開終了日が公開開始日より前になっていないかチェック
      const endDate = (moment(deliveryDetail.endDate));
      const startDate = (moment(deliveryDetail.startDate));
      const isEndDateBeforeStartDate = this.isDate1BeforeDate2(endDate, startDate);
      if (isEndDateBeforeStartDate) {
        this.$snackbar.show({ text: "公開終了日は公開開始日より前に設定できません。", type: "error" });
        return true;
      }
      // メッセージのチェック
      const isUnsetMessages = this.isUnsetMessages(deliveryDetail.messages);
      if (isUnsetMessages) {
        this.$snackbar.show({ text: "メッセージが入力されていません。", type: "error" });
        return true;
      }
      // メッセージが3000文字以内かチェック
      const isOverMaxLength3000TextMessage = this.isOverMaxLength3000TextMessage(deliveryDetail.messages);
      if (isOverMaxLength3000TextMessage) {
        this.$snackbar.show({ text: "メッセージのテキストは3000文字までです。", type: "error" });
        return true;
      }
      // 絵文字数のチェック
      if (this.emojiOver20Error) {
        this.$snackbar.show({ text: "LINE絵文字は20文字までです。", type: "error" });
        return true;
      }
      
      return false;
    },
    async isTemplateValidationError() {
      const template = cloneDeep(this.step1SurveyFormat);

      const isNullOrEmpty = this.isNullOrEmpty(template);
      if (isNullOrEmpty) {
        this.$snackbar.show({ text: "テンプレートが選択されていません。", type: "error" });
        return true;
      }
      const chkErr = await ValidateSurveyForm.checkItem(template);
      if (chkErr !== null) {
        this.$snackbar.show({ text: chkErr, type: "error" });
        return true;
      }

      return false;
    },
    async isSettingsValidationError() {
      const settings = cloneDeep(this.enqueteSettings);
      const deliveryDetail = cloneDeep(this.enqueteDeliveryDetail)

      const isEmptyObject = this.isEmptyObject(settings);
      console.log('isEmptyObject', isEmptyObject)
      if (isEmptyObject) {
        /**
         * アンケート設定画面を一度も開いていない場合は、空オブジェクトが入る仕組みなので、
         * 空オブジェクトの場合はデフォルト設定のオブジェクトを設定する
         */
        const defaultSettings = {
          isLatest: 0,
          isParentNotification: 0,
          isStaffNotification: 0,
          isRemind: 0,
          daysBefore: 0,
          time: "",
          messages: [{
            id: 0,
            text: "",
            type: "text",
            url: "",
            previewImageUrl: "",
            packageId: "",
            stickerId: "",
            emojis: [],
          }]
        };

        this.setEnqueteSettings(defaultSettings);
        return false;
      }

      // リマインド配信がONの場合のチェック
      if (settings.isRemind) {
        // 公開終了日の〜日前が設定されているかチェック
        const isNullOrEmptyDaysBefore = this.isNullOrEmpty(settings.daysBefore);
        if (isNullOrEmptyDaysBefore) {
          this.$snackbar.show({ text: "リマインドする日数が設定されていません。", type: "error" });
          return true;
        }
        // 公開終了日の〜日前が負の数値でないかチェック
        const isMinusDaysBefore = (parseInt(settings.daysBefore) < 0);
        if (isMinusDaysBefore) {
          this.$snackbar.show({ text: "リマインドできない日数が設定されています。", type: "error" });
          return true;
        }
        // リマインドする時間が設定されているかチェック
        const isNullOrEmptyTime = this.isNullOrEmpty(settings.time);
        if (isNullOrEmptyTime) {
          this.$snackbar.show({ text: "リマインドする時刻が設定されていません。", type: "error" });
          return true;
        }
        // 公開終了日の〜日前の日数と時刻のチェック
        const isRemindDaysInvalid = this.isRemindDateTimeInvalid(settings.daysBefore, settings.time, deliveryDetail.endDate);
        if (isRemindDaysInvalid) {
          this.$snackbar.show({ text: "リマインドできない日時が設定されています。", type: "error" });
          return true;
        }
        // メッセージのチェック
        const isUnsetMessages = this.isUnsetMessages(settings.messages);
        if (isUnsetMessages) {
          this.$snackbar.show({ text: "リマインドメッセージが入力されていません。", type: "error" });
          return true;
        }
        // メッセージが3000文字以内かチェック
        const isOverMaxLength3000TextMessage = this.isOverMaxLength3000TextMessage(settings.messages);
        if (isOverMaxLength3000TextMessage) {
          this.$snackbar.show({ text: "リマインドメッセージのテキストは3000文字までです。", type: "error" });
          return true;
        }
        // 絵文字数のチェック
        if (this.emojiOver20Error) {
          this.$snackbar.show({ text: "LINE絵文字は20文字までです。", type: "error" });
          return true;
        }
      }
      
      return false;
    },
    isUnPublishedCheck() {
      // 現在時刻の日付部分のみを取得
      const now = new Date();
      const nowDateOnly = new Date(now.toDateString());
      // 公開開始日の日付部分のみを取得
      const deliveryInfo = cloneDeep(this.enqueteOpenInfo.deliveryInfo);
      const startDate = new Date(deliveryInfo.startDate);
      const startDateOnly = new Date(startDate.toDateString());

      // 公開開始日が現在時刻より前の日付であるかを比較
      return nowDateOnly < startDateOnly;
    },
    onCopyEnqueteInfo() {
      this.$router.push({ 
        name: "EnqueteDeliveryCreationCopy"
      });
    },
    clickRemind() {
      this.$snackbar.hide();
      const enqueteName = this.enqueteDeliveryDetail.deliveryName;
      this.$dialog.show({
        title: "リマインド配信を行いますか？",
        text: `「${enqueteName}」の回答期限をお知らせするメッセージが配信されます。`,
        btnConfirmTitle: "配信",
        onConfirm: () => {
          this.sendRemind();
        },
      });
    },
    async sendRemind() {
      console.log('アンケート開催情報リマインド配信送信メソッド')
      this.loading = true;

      // 配信情報の作成
      const enqueteName = this.enqueteDeliveryDetail.deliveryName;
      const segmentType = this.enqueteDeliveryDetail.segmentType;
      const targets = this.enqueteDeliveryDetail.targets;
      const remainingPublicDayCount = this.calcDifferenceDays(this.enqueteDeliveryDetail.endDate);
      const deliveryDetail = {
        deliveryName: `${enqueteName}の開催期間についてのお知らせ`,
        segmentType: segmentType,
        targets: targets,
        segmentDetail: {},
        deliveryTiming: "IMMEDIATE",
        oneTimeDetail: {
          date: "",
          time: ""
        },
        recurringDetail: {
          startDate: "2023/1/30",
          endDate: "2023/2/28",
          periodicType: "毎日",
          eliminateStatus: "除外日あり",
          eliminateSetting: [
            "2023/1/31",
            "2023/2/15"
          ]
        },
        flexUseFlg: false,
        messages: [
          {
            text: `${enqueteName}の回答期限の${remainingPublicDayCount}日前になりました。アンケートの回答にご協力お願いします。`,
            type: "text",
            emojis: []
          }
        ]
      };
      this.setDeliveryDetail(deliveryDetail);
      const res = await this.sendDelivery();
      if (res.statusCode != 200) {
        this.$snackbar.show({ text: "配信の登録に失敗しました。ページを再読み込みしてください。" });
      } else {
        this.$snackbar.show({ text: "リマインド配信は完了しました。" });
        this.$router.push({ name: 'EnqueteDeliveryList' });
      }

      this.loading = false;
    },
    calcDifferenceDays(targetDate) {
      const targetDateTime = new Date(targetDate); // 指定された日付をDateオブジェクトに変換
      const currentDate = new Date(); // 現在の日付を取得

      const timeDifference = targetDateTime.getTime() - currentDate.getTime();
      const differenceDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1; // 日数に変換

      return differenceDays;
    },
    onPublicEndItem() {
      this.$dialog.show({
        title: "アンケートの回答受付を終了します。よろしいですか？",
        text: "この操作は取り消せません。",
        type: "error",
        btnConfirmTitle: "アンケートを終了する",
        onConfirm: async () => {
          this.loading = true;
          await this.closeToPublicEnqueteOpenInfo(this.enqueteOpenInfo);
          this.loading = false;
        },
      });
    },
    canPublicEnd() {
      const isBeforePublic = (this.enqueteOpenInfo.enqueteStatus === "0")
      const isAfterPublic = (this.enqueteOpenInfo.enqueteStatus === "2")

      return (isBeforePublic || isAfterPublic);
    },
    isPublicEnd() {
      const enqueteStatus = this.enqueteOpenInfo.enqueteStatus;
      return (enqueteStatus === "2");
    },
    isAnswerTabDisabled() {
      if (this.enqueteOpenInfo.deliveryInfo) {
        const enqueteType = this.enqueteOpenInfo.deliveryInfo.enqueteType;
        if (enqueteType == 1) {
          return true;
        }
      }

      return this.isUnPublished;
    },
    // バリデーションチェックのメソッド
    isNullOrEmpty(value) {
      return (value === null || value === undefined || value === '');
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    isOverMaxLength30(string) {
      return (string.length > 30);
    },
    isOverMaxLength3000(string) {
      return (string.length > 3000);
    },
    isPastDateTime(obj) {
      const { date, time } = obj;
      const dateTimeString = `${date} ${time}`;
      const dateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');
      const now = moment();

      return dateTime.isBefore(now);
    },
    isDate1BeforeDate2(date1, date2) {
      return (date1 < date2);
    },
    isUnsetMessages(messages) {
      let isValidationError = false;
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        switch (message.type) {
          case "text":
            isValidationError = this.isNullOrEmpty(message.text);
            break;
          case "image":
            isValidationError = this.isNullOrEmpty(message.previewImageUrl);
            break;
          case "sticker":
            isValidationError = this.isNullOrEmpty(message.stickerId);
            break;
          case "flex":
            isValidationError = this.isNullOrEmpty(message.text);
            break;
          default:
            break;
        }
        if (isValidationError) {
          break;
        }
      }

      return isValidationError;
    },
    isOverMaxLength3000TextMessage(messages) {
      let isValidationError = false;
      for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        if (message.type === "text") {
          isValidationError = this.isOverMaxLength3000(message.text);
        }
        if (isValidationError) {
          break;
        }
      }

      return isValidationError;
    },
    isRemindDateTimeInvalid(daysBefore, time, enqueteEndDate) {
      // リマインド配信の予約時刻を算出
      const scheduleDateTime = moment.tz(`${enqueteEndDate} ${time}`, 'YYYY-MM-DD HH:mm', 'Asia/Tokyo').subtract(daysBefore, 'days');
      // 時間を画面から選択できなくなったらtimeを固定値にする
      // 0:00 -> 開始日にリマインドはできない
      // time = '0:00'
      // const scheduleDateTime = moment.tz(`${enqueteEndDate} ${time}`, 'YYYY-MM-DD HH:mm', 'Asia/Tokyo').subtract(daysBefore, 'days');

      return this.isPastDateTime({
        date: scheduleDateTime.format('YYYY-MM-DD'),
        time: scheduleDateTime.format('HH:mm')
      });
    },
  },
  watch: {
    async fiscalYear() {
      this.toEnqueteDeliveryList();
    },
  },
  async created() {
    if (this.$route.params.enqueteOpenId) {
      const enqueteOpenId = this.$route.params.enqueteOpenId;
      // アンケート開催情報の取得
      await this.getEnqueteOpneInfo(enqueteOpenId);
      this.setEnqueteDeliveryDetail(this.enqueteOpenInfo.deliveryInfo);
      this.setEnqueteSettings(this.enqueteOpenInfo.enqueteSettings);
      // アンケートが未公開であるかのステータスを更新する
      this.setIsUnPublished(this.isUnPublishedCheck());
      // テンプレートの編集可否を判定
      if (this.isUnPublished) {
        this.setFormEditMode(false);
      } else {
        this.setFormEditMode(true);
      }
      this.isEdit = true;
    } else {
      this.setIsUnPublished(true);
    }
  },
  async mounted() {
  },
  beforeRouteLeave(to, from, next) {
    // 複製して新規作成の場合
    if (to.name == "EnqueteDeliveryCreationCopy") {
      this.setIsUnPublished(true);
      this.isEdit = false;
      this.isCopy = true;
      next();
    } else if(to.name == "EnqueteDeliveryList") {
      next();
    } else if(to.name == "SignIn") {
      next();
    } else {
      this.$snackbar.hide();
      this.$dialog.show({
        title: "このページを離れてもよろしいですか？",
        text: "行った変更は破棄されます。",
        btnConfirmTitle: "このページを離れる",
        onConfirm: () => {
          this.vuexStateReset();
          next();
        },
      });
    }
  },
};
</script>