<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="auto" class="px-0">
          <div class="text-h6 text--gray">ルート編集</div>
        </v-col>
        <v-col v-if="errorMessage !== ''" align-self="center">
          <div class="v-messages theme--light error--text">{{ errorMessage }}</div>
        </v-col>
      </v-row>
      <v-row class="pt-2 my-0" style="min-height: 32px;">
        <v-col style="max-width: 48px;"></v-col>
        <v-col class="pa-0 mx-3">
          <div class="text-center">バス停名</div>
        </v-col>
        <v-col class="pa-0 mx-3">
          <div class="text-center">緯度</div>
        </v-col>
        <v-col class="pa-0 mx-3">
          <div class="text-center">経度</div>
        </v-col>
        <v-col style="max-width: 48px;"></v-col>
      </v-row>
      <v-row>
        <v-list class="pa-0">
          <draggable v-model="routeInfo" :options="dragOptions">
            <v-list-item
              v-for="(busStop, index) in routeInfo" :key="('first-' + busStop.order_no)"
              class="pa-0"
            >
            <!-- <v-list-item
              v-for="(busStop, index) in routeInfo" :key="('first-' + busStop.order_no)"
              class="pa-0"
              @mouseover="setHoveredIndex(index)"
              @mouseout="clearHoveredIndex()"
            > -->
              <div class="d-flex">
                <v-col class="pa-0 mt-2" style="max-width: 48px;">
                  <div class="d-flex">
                    <div>
                      <v-icon color="gray">mdi-drag</v-icon>
                      <!-- <v-icon v-if="index === hoveredIdx" size="x-large" color="gray">mdi-drag</v-icon>
                      <v-icon v-else size="x-large" color="transparent">mdi-drag</v-icon> -->
                    </div>
                    <div>
                      <v-icon v-if="index !== (routeInfo.length - 1)" size="large">mdi-circle-outline</v-icon>
                      <v-icon v-else size="large" color="red">mdi-map-marker-outline</v-icon>
                    </div>
                  </div>
                  <v-icon v-if="index !== (routeInfo.length - 1)" size="x-large" color="gray" class="mt-2 ml-5">mdi-dots-vertical</v-icon>
                </v-col>
                <v-col class="pa-0 mx-3">
                  <v-text-field
                    v-model="busStop.bus_stop_name"
                    :rules="[required_bus_route_name, max_length_33]"
                    outlined
                    dense
                    height="38"
                    color="black"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 mx-3">
                  <v-text-field
                    v-model="busStop.lat"
                    :rules="[required_bus_lat, format_bus_lat, max_length_12]"
                    outlined
                    dense
                    height="38"
                    color="black"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 mx-3">
                  <v-text-field
                    v-model="busStop.lng"
                    :rules="[required_bus_lng, format_bus_lng, max_length_13]"
                    outlined
                    dense
                    height="38"
                    color="black"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0 mt-2" style="max-width: 48px;">
                  <v-icon @click="deleteBusStop(index)">mdi-close-circle-outline</v-icon>
                </v-col>
              </div>
            </v-list-item>
          </draggable>
        </v-list>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <v-btn text class="pa-0" @click="addBusStop()">
            <v-icon class="mr-3">mdi-plus-circle-outline</v-icon>
            <div class="text--gray">目的地を追加</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapState, mapMutations, mapActions } from "vuex";
import {
} from "@/store/action-types";
import {
} from "@/store/mutation-types";
import {
  MAX_LENGTH_12_ERROR_MSG,
  MAX_LENGTH_13_ERROR_MSG,
  MAX_LENGTH_33_ERROR_MSG,
  REQUIRED_ERROR_MSG,
  LAT_FORMAT_ERROR_MSG,
  LNG_FORMAT_ERROR_MSG,
} from "@/constants";

export default {
  name: "RouteEditor",
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String
    },
  },
  data: () => ({
    busStopDefault: {
      order_no: 0,
      bus_stop_name: "",
      lat: "",
      lng: ""
    },
    // hoveredIdx: null,
    dragOptions: {
      animation: 150, // ドラッグ時のアニメーション速度
      group: 'busStopGroup' // 同じグループ内でのみドラッグ可能にするためのグループ名
    },
  }),
  computed: {
    ...mapState({
    }),
    routeInfo: {
      get() {
        return this.value;
      },
      set(newVal) {
        // ドラッグ&ドロップでの入れ替えを反映
        for (let i = 0; newVal.length > i; i++) {
          newVal[i].order_no = i;
        }

        this.$emit("input", newVal);
      }
    },
  },
  watch: {
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    addBusStop() {
      // デフォルトのバス停情報を最後尾に追加
      const busStopAdd = structuredClone(this.busStopDefault);
      busStopAdd.order_no = this.routeInfo.length; // order_noを設定
      this.routeInfo.push(busStopAdd);
    },
    deleteBusStop(index) {
      // 押下されたバス停を削除
      this.routeInfo.splice(index, 1);

      // 残ったバス停情報のorder_noを修正する
      for (let i = 0; i < this.routeInfo.length; i++) {
        this.routeInfo[i].order_no = i;
      }
    },
    // setHoveredIndex(index) {
    //   this.hoveredIdx = index;
    // },
    // clearHoveredIndex() {
    //   this.hoveredIdx = null;
    // },
    required_bus_route_name(value) {
      const isSpace = (v) => {
        v = String(v);
        if(v === undefined || v === null){
          return true;
        }
        for (let i = 0; i < v.length; i++) {
          const code = v.charCodeAt(i);
          if (code === 0x3000 || code === 0x20) { // Unicodeのコードポイントを使って全角or半角スペースを判定
            continue; // ループを継続
          }
          return false; // それ以外の文字が含まれている場合はfalseを返す
        }
        return true; // 全角スペースor半角スペースしか含まれていない場合はtrueを返す
      };

      return (!!value && !isSpace(value)) || "バス停名" + REQUIRED_ERROR_MSG;
    },
    required_bus_lat(value) {
      const isSpace = (v) => {
        v = String(v);
        if(v === undefined || v === null){
          return true;
        }
        for (let i = 0; i < v.length; i++) {
          const code = v.charCodeAt(i);
          if (code === 0x3000 || code === 0x20) { // Unicodeのコードポイントを使って全角or半角スペースを判定
            continue; // ループを継続
          }
          return false; // それ以外の文字が含まれている場合はfalseを返す
        }
        return true; // 全角スペースor半角スペースしか含まれていない場合はtrueを返す
      };

      return (!!value && !isSpace(value)) || "緯度" + REQUIRED_ERROR_MSG;
    },
    format_bus_lat(value) {
      const isNumeric = (v) => {
        if (
          typeof parseFloat(v) === 'number' // キャストすると数値型である
          && /^[0-9.-]+$/.test(v) // ハイフン,数字,ピリオド以外の文字を含まない
          && !/^\.|\.+$|\.{2,}/.test(v) // 先頭と末尾の文字列がピリオドでないこと、およびピリオドが2回以上含まれない
        ) {
          return true;
        } else {
          return false;
        }
      };
      const isFormatBusLat = (v) => {
        if (v >= -90 && v <= 90) {
          return true;
        } else {
          return false;
        }
      };

      return (isNumeric(value) && isFormatBusLat(parseFloat(value))) || LAT_FORMAT_ERROR_MSG;
    },
    required_bus_lng(value) {
      const isSpace = (v) => {
        v = String(v);
        if(v === undefined || v === null){
          return true;
        }
        for (let i = 0; i < v.length; i++) {
          const code = v.charCodeAt(i);
          if (code === 0x3000 || code === 0x20) { // Unicodeのコードポイントを使って全角or半角スペースを判定
            continue; // ループを継続
          }
          return false; // それ以外の文字が含まれている場合はfalseを返す
        }
        return true; // 全角スペースor半角スペースしか含まれていない場合はtrueを返す
      };

      return (!!value && !isSpace(value)) || "経度" + REQUIRED_ERROR_MSG;
    },
    format_bus_lng(value) {
      const isNumeric = (v) => {
        if (
          typeof parseFloat(v) === 'number' // キャストすると数値型である
          && /^[0-9.-]+$/.test(v) // ハイフン,数字,ピリオド以外の文字を含まない
          && !/^\.|\.+$|\.{2,}/.test(v) // 先頭と末尾の文字列がピリオドでないこと、およびピリオドが2回以上含まれない
        ) {
          return true;
        } else {
          return false;
        }
      };
      const isFormatBusLng = (v) => {
        if (v >= -180 && v <= 180) {
          return true;
        } else {
          return false;
        }
      };

      return (isNumeric(parseFloat(value)) && isFormatBusLng(parseFloat(value))) || LNG_FORMAT_ERROR_MSG;
    },
    max_length_33(value) {
      return (value.length <= 33) || MAX_LENGTH_33_ERROR_MSG;
    },
    max_length_12(value) {
      return (value.length <= 12) || MAX_LENGTH_12_ERROR_MSG;
    },
    max_length_13(value) {
      return (value.length <= 13) || MAX_LENGTH_13_ERROR_MSG;
    },
  }
};
</script>

<style scoped>
</style>