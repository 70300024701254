export const parseProductIdEmojiIdFromUrlOrFalse = (path) => {
    const parseRegex = /\/emojis\/([a-zA-Z0-9]{24})_([0-9]{3}).png$/;
    const parsed = path.match(parseRegex);
    if (!parsed || !Array.isArray(parsed)) {
      return false;
    }
    return {
      productId: parsed[1],
      emojiId: parsed[2],
    }
  }