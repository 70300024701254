<template>
  <v-container fill-height>
    <v-row justify="center">
      <div style="width: 90%; max-width: 800px;">
        <SignInIndex  v-if="isLogin == false && isLogged == false && loginUser == null" />
        <FirstPasswordSet v-else-if="isLogin == false && isLogged == false && loginUser != null" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import SignInIndex from "../components/SignIn/SignInIndex.vue";
import FirstPasswordSet from "../components/SignIn/FirstPasswordSet.vue";
import {
  mapState,
} from "vuex";
export default {
  name: 'SignIn',
  components: {
    SignInIndex,
    FirstPasswordSet
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      isLogged: (state) => state.isLogged,
      loginUser: (state) => state.loginUser,
    }),
  },
  methods: {},
}

</script>
<style scoped>
</style>
