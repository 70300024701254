/*
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
*/

// verify the given object is a number
export const requireNumber = (data) => {
  return !isNaN(data);
};

// Verify whether a number is withing a given range; start inclusive, end exclusive
export const requireNumberBetween = (data, start, end) => {
  return !isNaN(data) && start <= data && data < end;
};

// Verify the given number is less than a given value
export const requireNumberLessThan = (data, reference) => {
  return !isNaN(data) && data < reference;
};
// Verify the given number is less than or equal to a given value
export const requireNumberLessThanOrEqualTo = (data, reference) => {
  return !isNaN(data) && data <= reference;
};

// Verify the given number is greater than a given value
export const requireNumberGreaterThan = (data, reference) => {
  return !isNaN(data) && data > reference;
};

// Verify the given number is greater than or equal to a given value
export const requireNumberGreaterThanOrEqualTo = (data, reference) => {
  return !isNaN(data) && data >= reference;
};

// Verify the given text length is less or equal to a given value
export const requireTextMaxLength = (data, length) => {
  return data.length <= length;
};

// Verify the given text length is greater or equal to a given value
export const requireTextMinLength = (data, length) => {
  return data.length >= length;
};

// Verify the given text is a valid Number (pattern check)
export const requireTextIsNumber = (data) => {
  // eslint-disable-next-line no-control-regex
  let pattern = /^[0-9]+$/;
  return pattern.test(data);
};

// Verify the given text is a valid email address (pattern check)
export const requireTextIsEmail = (data) => {
  // eslint-disable-next-line no-control-regex
  let pattern =
    /^[a-zA-Z0-9_-]+(?:[.+][a-zA-Z0-9_-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(data);
};

// Verify the given text is a valid post code (pattern check)
export const requireTextIsPostCode = (data) => {
  //Post code without hyphen is 7 digits
  let pattern = /^[0-9]{7}$/;
  return pattern.test(data);
};

// Verify the given text is a valid phone number (pattern check)
export const requireTextIsPhoneNumber = (data) => {
  let phoneNumberPattern = /^[0-9]{10,11}$/;
  return phoneNumberPattern.test(data);
};

// Verify the text is compliant with the specified format
export const requireFormat = (data, type) => {
  switch (type) {
    case "postcode": {
      return requireTextIsPostCode(data);
    }
    case "email": {
      return requireTextIsEmail(data);
    }
    case "phone": {
      return requireTextIsPhoneNumber(data);
    }
    case "any": {
      return true;
    }
    default:
      return false;
  }
};

// Verify the text contains allowed characters only
export const requireTextCharacterType = (data, characterTypes) => {
  let patternString = "";
  for (let characterType of characterTypes) {
    if (characterType == "all") {
      return /^.*$/.test(data);
    }
    if (characterType == "allFull") {
      patternString += "一-龯";
      patternString += "ァ-ン";
      patternString += "ぁ-ん";
      patternString += "０-９";
      patternString += "Ａ-ｚ";
      patternString += "゛゜ー";
    }
    if (characterType == "kanji") {
      patternString += "一-龯";
    }
    if (characterType == "hiragana") {
      patternString += "ぁ-ん";
      patternString += "゛゜ー";
    }
    if (characterType == "katakana") {
      patternString += "ァ-ン";
      patternString += "゛゜ー";
    }
    if (characterType == "katakanaHalf") {
      patternString += "ｧ-ﾝﾞﾟ";
    }
    if (characterType == "alphabet") {
      patternString += "A-z";
      patternString += "Ａ-ｚ";
    }
    if (characterType == "alphabetFull") {
      patternString += "Ａ-ｚ";
    }
    if (characterType == "alphabetHalf") {
      patternString += "A-z";
    }
    if (characterType == "number") {
      patternString += "0-9";
      patternString += "０-９";
    }
    if (characterType == "numberFull") {
      patternString += "０-９";
    }
    if (characterType == "numberHalf") {
      patternString += "0-9";
    }
  }
  var pattern = new RegExp("^[" + patternString + "]*$");
  return pattern.test(data);
};

// Verify the given date is in the past (Today exclusive)
export const requireDateIsInThePast = (data) => {
  var date = new Date(data);
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return date < now;
};

// Verify a given array has at most a certain number of elements
export const requireArrayContainsAtMost = (data, length) => {
  return data.length <= length;
};
// Verify a given array has at most a certain number of elements
export const requireArrayContainsAtLeast = (data, length) => {
  return data.length >= length;
};
// Verify a given array has at most a certain number of elements
export const requireArrayContainsExactly = (data, length) => {
  return data.length === length;
};

export const isBlank = (data) => {
  return data === undefined || data === null || data === "";
};
