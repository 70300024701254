<template>
  <div class="contentsArea">
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary" @click="dialogImport = true">
          <v-icon left>mdi-import</v-icon>
          インポート
        </v-btn>
        <v-btn class="mr-4" :loading="loading" color="primary" :disabled="!selectItems.length" @click="exportTemplateData">
          <v-icon left>mdi-export</v-icon>
          エクスポート
        </v-btn>
        <v-btn :loading="loading" color="red white--text" :disabled="!selectItems.length" @click="deleteSurveys">
          <v-icon left>mdi-delete</v-icon>
          テンプレート削除
        </v-btn>
        <v-btn :loading="loading" color="primary" @click="move">
          <v-icon left>mdi-form-select</v-icon>
          新規作成
        </v-btn>
      </v-card-actions>
      <v-btn text @click="showSearchBox = !showSearchBox">
        <v-icon left large color="primary">
          {{ showSearchBox ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
        テンプレート検索
      </v-btn>
      <v-expand-transition>
        <v-container v-if="showSearchBox" fluid>
          <v-form @submit.prevent ref="form">
            <v-row>
              <v-col cols="2">
                <v-subheader>テンプレート名</v-subheader>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="items"
                  item-text="surveyTitle"
                  item-value="surveyTitle"
                  v-model="surveyTitleFilterValue"
                  flat
                  outlined
                  dense
                  clearable
                  hide-details
                >
                  <template v-slot:no-data>
                    <div class="px-4">データがありません</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-expand-transition>
      <v-divider></v-divider>
      <v-container class="my-4" fluid>
        <v-row no-gutters>
          <v-col cols="auto">
            <span class="display-1">{{ items.length }}</span>
            <span>件</span>
          </v-col>
          <v-divider vertical class="mx-4"></v-divider>
          <v-btn :loading="loading" color="primary" @click="reload">
            <v-icon left>mdi-reload</v-icon>
            データ更新
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" @click="clearSearchItems" :disabled="!canClearSearch" color="gray">
            条件をクリアする
          </v-btn>
        </v-row>
      </v-container>
      <v-data-table 
        :loading="loading" 
        :loading-text="loadingText" 
        :no-data-text="noDataText" 
        class="table-cursor" 
        item-key="surveyId"
        :headers="headers" 
        :items="items"
        v-model="selectItems"
        :single-select="false"
        show-select
        :items-per-page="perOnPage" 
        :page.sync="currentPage"
        checkbox-color="green"
        @click:row="onEditSurvey"
        :footer-props="{ 'items-per-page-options': [30, 50, 100, -1], 'items-per-page-text': '行/ページ:' }"
        >
        <template v-slot:[`footer.page-text`]="props">
          <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogImport" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-h4 font-weight-bold">テンプレートのインポート</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>
              <v-btn
                @click="$refs.fileInput.click();"
                outlined
                color="green"
              >
                <v-icon left>mdi-import</v-icon>
                ファイルを選択
              </v-btn>
              <div v-if="!!importFile">{{ importFile.name }}</div>
              <input type="file" accept=".json" ref="fileInput" @change="inputFile()" class="d-none"/>
            </div>
          </v-container>
          <v-container>
            <div v-if="importDataErrMsgList.length != 0">
              <v-row v-for="(msg, index) in importDataErrMsgList" :key="index">
                <v-col class="py-0">
                  <div>{{ msg }}</div>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            @click="dialogImport = false"
            outlined
            dark
            color="green"
          >キャンセル</v-btn>
          <v-btn
            :disabled="(importDataErrMsgList.length != 0 || !importData)"
            :loading="loading"
            @click="saveImportData()"
            color="green"
            class="white--text"
          >保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapActions,
  mapMutations,
  mapState,
} from "vuex";
import {
  SET_IS_UN_PUBLISHED
} from "@/store/mutation-types";
import {
  FETCH_SURVEY_CONFIGS,
  DELETE_SURVEY_CONFIGS,
  REGISTER_SURVEY_CONFIGS,
} from "@/store/action-types";
import {
  LOADING_TEXT,
  NO_DATA_TEXT,
} from "@/constants";
import ValidateSurveyForm from "@/model/Form/ValidateSurveyForm";
import { saveAs } from "file-saver";
// import moment from 'moment';
import { generateUUID } from "@/utils/uuidUtils";

export default Vue.extend({
  name: "EnqueteList",
  data() {
    return {
      noDataText: NO_DATA_TEXT,
      loadingText: LOADING_TEXT,
      isCreated: false,
      isReloading: false,
      showSearchBox: true,
      items: [],
      selectItems: [],
      currentPage: 1,
      perOnPage: 10,
      surveyTitleFilterValue: '',
      dialogImport: false,
      importDataErrMsgList: [],
      importFile: null,
      importData: null,
    };
  },
  computed: {
    ...mapState({
      surveyConfigsListType: (state) => state.forms.surveyConfigsListType,
      loginUser: (state) => state.loginUser,
      fiscalYear: (state) => state.fiscalYear,
      isDeletingSurveyConfigs(state) {
        return state.forms.isDeletingSurveyConfigs;
      },
      deleteSurveyConfigsError(state) {
        return (state).forms.deleteSurveyConfigsError;
      },
      isRegisteringSurveyConfigs(state) {
        return state.forms.isRegisteringSurveyConfigs;
      },
      registerSurveyConfigsError(state) {
        return (state).forms.registerSurveyConfigsError;
      },
    }),
    headers() {
      return [
        { text: "", value: "data-table-select", width: "5%" },
        { text: "テンプレートID", value: "surveyId", width: "20%" },
        { text: "テンプレート名", value: "surveyTitle", filter: this.surveyTitleFilter, width: "55%" },
        { text: "作成者", value: "createdBy", width: "10%" },
        { text: "登録日時", value: "createdAt", width: "10%" },
      ];
    },
    loading() {
      return (
        this.isCreated ||
        this.isReloading ||
        this.isDeletingSurveyConfigs ||
        this.isRegisteringSurveyConfigs
      )
    },
    canClearSearch() {
      return (
        (!!this.surveyTitleFilterValue && this.surveyTitleFilterValue !== '')
      )
    },
  },
  watch: {
    surveyConfigsListType(value) {
      this.items = value;
    },
    async fiscalYear() {
      await this.reload();
    },
  },
  methods: {
    ...mapMutations({
      setIsUnPublished: SET_IS_UN_PUBLISHED,
    }),
    ...mapActions({
      fetchSurveyConfigs: FETCH_SURVEY_CONFIGS,
      deleteSurveyConfigs: DELETE_SURVEY_CONFIGS,
      registerSurveyConfigs: REGISTER_SURVEY_CONFIGS,
    }),
    onEditSurvey(item, row) {
      this.$router.push({ 
        name: "EnqueteDetail",
        params: { 
          surveyId: item.surveyId,
          formType: 'survey',
          isEditMode: true,
        }
      });
    },
    async deleteSurveys() {
      let _result = undefined;

      const data = {
        surveyList: this.selectItems,
        loginUser: this.loginUser,
      }
      _result = await this.deleteSurveyConfigs(data);

      if (_result) {
        this.$snackbar.show({ text: "テンプレート削除が成功しました。" });
        await this.reload();
      } else {
        if (this.deleteSurveyConfigsError) {
          this.$snackbar.show({ text: this.deleteSurveyConfigsError, type: "error" });
        }
      }
    },
    inputFile() {
      this.importDataErrMsgList = [];
      const file = this.$refs.fileInput.files[0];
      this.importFile = file;
      const reader = new FileReader();

      reader.readAsText(file, 'utf-8'); // utf-8形式で読み込む
      reader.onload = async () => {
        const importData = reader.result;
        console.log('fileData', importData)
        const isInvalid = await this.validateImportData(importData);
        console.log('isInvalid', isInvalid)
        if (isInvalid) {
          this.importData = null;
          return
        }
        this.importData = JSON.parse(importData);
      };
    },
    async validateImportData(importData) {
      if (!this.isJson(importData)) {
        console.log('dame1')
        this.importDataErrMsgList.push('JSONデータとして読み込めませんでした。');
        return true;
      } else {
        const importDataObj = JSON.parse(importData);
        if (!Array.isArray(importDataObj)) {
          console.log('dame2')
          this.importDataErrMsgList.push('不正なJSONデータです。');
          return true;
        } else {
          for (const template of importDataObj) {
            const chkErr = await ValidateSurveyForm.checkItem(template);
            console.log('chkErr', chkErr)
            if (chkErr !== null) {
              this.importDataErrMsgList.push(chkErr);
              break;
            }
          }
          if (this.importDataErrMsgList.length > 0) {
            console.log('dame3')
            return true;
          }
        }
      }
      return false;
    },
    isJson(data) {
      try {
        JSON.parse(data);
      } catch (error) {
        return false;
      }
      return true;
    },
    async saveImportData() {
      let _result = undefined;

      // アンケートのkeyを作成
      for (let i = 0; i < this.importData.length; i++) {
        this.importData[i].key = generateUUID();
      }

      const data = {
        surveys: this.importData,
        loginUser: this.loginUser,
        fiscalYear: this.fiscalYear
      }
      _result = await this.registerSurveyConfigs(data);

      if (_result) {
        this.$snackbar.show({ text: "テンプレートのインポートが成功しました。" });
        await this.reload();
      } else {
        if (this.registerSurveyConfigsError) {
          this.$snackbar.show({ text: this.registerSurveyConfigsError, type: "error" });
        }
      }
      this.importFile = null;
      this.importData = null;
      this.dialogImport = false;
    },
    exportTemplateData() {
      console.log('selectItems', this.selectItems)
      const templateData = this.selectItems.map((item) => this.convertToTemplateData(item))
      const templateDataJson = JSON.stringify(templateData);
      const templateDataBlob = new Blob([templateDataJson], { type: 'application/json' });
      saveAs(templateDataBlob, 'enquete_templates.json');
    },
    convertToTemplateData(item) {
      const templateData = Object.assign({},item);

      delete templateData["createdAt"];
      delete templateData["createdBy"];
      delete templateData["schoolId"];
      delete templateData["surveyId"];
      delete templateData["key"];
      delete templateData["updatedAt"];
      delete templateData["updatedBy"];

      return templateData;
    },
    move() {
      this.$router.push({ name: 'EnqueteCreation', params: { template: 'blank', formType: 'survey' } });
    },
    clearSearchItems() {
      this.surveyTitleFilterValue = '';
    },
    surveyTitleFilter(value) {
      if (!this.surveyTitleFilterValue || this.surveyTitleFilterValue === '') {
        return true;
      }
      return value.toLowerCase().includes(this.surveyTitleFilterValue.toLowerCase());
    },
    async reload() {
      this.isReloading = true;
      const payload = {
        schoolId: this.loginUser.school_id,
        fiscalYear: this.fiscalYear,
      }
      await this.fetchSurveyConfigs(payload);
      this.isReloading = false;
    },
  },
  async created() {
    await this.reload();

    // 非公開フラグをtrueにする
    this.setIsUnPublished(true);
  },
});
</script>