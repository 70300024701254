/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import { DEFAULT_LANG } from "./formEditor.constants";
import { IsoLangs } from "@/constants/index";

export default {
  sectionLength: (state) => {
    return state.formSchema.length;
  },

  defaultLangDictionary: (state) => {
    return Object.keys(state.langSettings[DEFAULT_LANG]).map((dictKey) => {
      return {
        key: dictKey,
      };
    });
  },
  languageSettingsOptions: (state) => {
    let _langOptions = [];
    Object.keys(state.langSettings).forEach((langCode) => {
      let _langObject = IsoLangs[langCode];
      _langOptions.push({
        text: _langObject.name + ` (${_langObject.nativeName})`,
        value: langCode,
      });
    });
    return _langOptions;
  },
  //this step only have one section so need to convert to one section config format
  step1SurveyFormat: (state) => {
    return state.formSchema ? state.formSchema[0] : null;
  },
};
