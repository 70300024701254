<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div :class="setClass()">
    <!-- <SubAppBar class="mb-6" v-if="!isEnquete"> -->
      <!-- <template> -->
        <!-- <div class="d-flex justify-space-between align-center"> -->
          <!-- <v-breadcrumbs divider="-" :items="breadcrumbs"></v-breadcrumbs> -->
          <!-- <div> -->
            <!-- <v-btn class="mr-2" v-if="isEditMode&&!duplicateFlg" elevation="0" width="200" color="red white--text" @click="saveForm()"><v-icon left>mdi-close-circle-outline</v-icon> アンケートを終了する </v-btn>
            <v-btn v-if="isEditMode&&!duplicateFlg" elevation="0" width="161" color="primary" class="mr-2" outlined @click="duplicateConfig()"><v-icon left>mdi-checkbox-multiple-blank-outline</v-icon> 複製して編集 </v-btn> -->
            <!-- <v-btn elevation="0" width="54" color="primary" @click="saveForm()"> 保存 </v-btn> -->
          <!-- </div> -->
        <!-- </div> -->
      <!-- </template> -->
    <!-- </SubAppBar> -->
    <v-card-actions style="max-height:50px;" v-if="!isEnquete">
      <div class="text-right ma-4 row">
        <v-btn color="primary" text @click="$router.push({ name: 'EnqueteList' })">
          <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mr-2" v-if="isEditMode&&!duplicateFlg" elevation="0" width="200" color="red white--text" @click="saveForm()"><v-icon left>mdi-close-circle-outline</v-icon> アンケートを終了する </v-btn> -->
        <!-- <v-btn v-if="isEditMode&&!duplicateFlg" elevation="0" width="161" color="primary" class="mr-2" outlined @click="duplicateConfig()"><v-icon left>mdi-checkbox-multiple-blank-outline</v-icon> 複製して編集 </v-btn> -->
        <v-btn elevation="0" width="54" color="primary" @click="saveForm()"> 保存 </v-btn>
      </div>
    </v-card-actions>
  <v-container fluid class="pt-0">
      <v-overlay :opacity="0.2" v-if="isRegistingSurveyConfig || isUpdatingSurveyConfig">
        <content-loading :size="50" text="" />
      </v-overlay>
      <ContentLoadError v-if="fetchFormConfigDetailError" :error="fetchFormConfigDetailError" />
      <FormDesign v-else :isBlank="true" :duplicateFlg="duplicateFlg"/>
    </v-container>
  </div>
</template>

<script>
import { eventBus } from '@/main.js'
import Vue from "vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { SET_ACTIVE_FORM_TAB, SET_FORM_TITLE, SET_BACK_BUTTON_CONDITION, SET_FORM_EDIT_MODE, SET_FORM_SCHEMA, } from "@/store/mutation-types";
import { INIT_FORM_SCHEMA, FETCH_FORM_CONFIGS, FETCH_ALL_MEMBER_FORM_CONFIGS } from "@/store/action-types";
// import { RESERVATION_SERVICE_TYPES } from "@/store/modules/payment/payment.constants";
import FormDesign from "./fragments/FormDesign.vue";
import ContentLoadError from "@/components/common/ContentLoadError.vue";
// import YAML from "yamljs";
// import SubAppBar from '@/components/common/SubAppBar.vue';
import { cloneDeep } from "lodash";
import ValidateSurveyForm from "@/model/Form/ValidateSurveyForm";
import DuplicateEditToNewConfig from "@/model/Form/DuplicateEditToNewConfig";

export default Vue.extend({
  name: "EnqueteForm",
  props: {
    templateId: String,
    surveyId: String,
    template: String,
    isEnquete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: null,
      componentKey: 0,
      showImportDialog: false,
      error: null,
      breadcrumbs:[
        {
          text: 'テンプレート一覧',
          disabled: false,
          to: '/EnqueteList',
        },
        {
          text: '',
          disabled: true,
          href: '/',
        },
      ],
      duplicateFlg: false,
    };
  },
  components: {
    FormDesign,
    ContentLoadError,
    // SubAppBar,
  },
  watch: {
    tab(val) {
      this.forceRerender();
      this.setActiveTab(val);
    },
    async surveyId(val) {
      if (this.isEnquete) {
        // テンプレートなしで作成か判定する
        if (val == "blank") {
          await this.initFormConfig({ template: this.surveyId, formType: this.formType });
        } else {
          let _result = await this.initFormConfig({
            surveyId: val,
            formType: this.formType,
            schoolId: this.loginUser.school_id,
            enqueteOpenInfoTemplate: this.enqueteOpenInfo.templateInfo,
          });
          if (_result.noInspectPermission) {
            this.$router.replace({
              path: "/PageNotFound",
            });
          }
          this.updateShowBackButton(true);
        }
      }
    },
    async fiscalYear() {
      this.toEnqueteList();
    },
  },
  computed: {
    ...mapGetters(["step1SurveyFormat"]),
    ...mapState({
      loginUser: (state) => state.loginUser,
      formSchemaStore: (state) => (state).formEditor.formSchema,
      activeItem: (state) => (state).formEditor.activeItem,
      formTitle: (state) => state.formEditor.formTitle,
      isRegistingSurveyConfig(state) {
        return state.forms.isRegistingSurveyConfig;
      },
      registeredSurveyConfigId(state) {
        return state.forms.registeredSurveyConfigId;
      },
      isUpdatingSurveyConfig(state) {
        return state.forms.isUpdatingSurveyConfig;
      },
      fetchFormConfigDetailError(state) {
        return state.forms.fetchFormConfigDetailError;
      },
      isEditMode: (state) => (state).formEditor.isEditMode,
      paymentServiceList: (state) => (state).formEditor.paymentServiceList,
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      fiscalYear: (state) => state.fiscalYear,
    }),
    formTitleLocal: {
      get() {
        return this.formTitle;
      },
      set(val) {
        this.setFormTitle(val);
      },
    },
    isMemberForm() {
      return this.formType === "member" ? true : false;
    },
    formType() {
      return this.$route.params.formType ? this.$route.params.formType : "survey";
    },
  },
  methods: {
    ...mapMutations({
      setActiveTab: SET_ACTIVE_FORM_TAB,
      setFormTitle: SET_FORM_TITLE,
      updateShowBackButton: SET_BACK_BUTTON_CONDITION,
      setFormEditMode: SET_FORM_EDIT_MODE,
      updateFormSchema: SET_FORM_SCHEMA,
    }),
    ...mapActions({
      initFormConfig: INIT_FORM_SCHEMA,
      fetchFormConfigs: FETCH_FORM_CONFIGS,
      fetchMemberConfigs: FETCH_ALL_MEMBER_FORM_CONFIGS,
    }),
    setClass() {
      if (this.isEnquete) {
        return "enqueteArea";
      } else {
        return "contentsArea enqueteArea";
      }
    },
    onImport(configs) {
      this.configs = configs;
      // this.configsJson = YAML.parse(configs);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    saveForm() {
      eventBus.$emit('onActiveItem');
      eventBus.$emit('updateDataLocalToStore');
      this.$nextTick(async () => {
        const chkErr = await ValidateSurveyForm.checkItem(this.step1SurveyFormat);
        if (chkErr !== null) {
          this.$snackbar.show({ text: chkErr, type: "error" });
          return;
        }else{
          eventBus.$emit('moveKeySetting');
        }
      });
    },
    async duplicateConfig(){
      const bkConfig = await DuplicateEditToNewConfig.createDuplicateConfig(cloneDeep(this.step1SurveyFormat));
      // NOTE: 商品選択アイテムがありカレンダー予約をするサービスが選択されている場合はサービスを初期化する
      // const selectProductsItem = bkConfig.surveySchema.find(item => item.type === 'selectProducts');
      // if (selectProductsItem) {
      //   const service = this.paymentServiceList.find(s => s.sortKey === selectProductsItem.input);
      //   service.reservationServiceType === Number(RESERVATION_SERVICE_TYPES.APPLICABLE) && (selectProductsItem.input = null);
      // }
      this.duplicateFlg = true;
      this.breadcrumbs[1].text = 'フォームを複製';
      this.setFormEditMode(false);
      this.$router.push({ 
        name: "FormCreateNewPage",
        params: { 
          template: this.step1SurveyFormat.surveyType,
          formType: this.formType,
        }
      });
      this.updateFormSchema([bkConfig]);
    },
    toEnqueteList() {
      this.$router.push({ name: 'EnqueteList' });
    },
  },
  beforeMount(){
    if(this.$route.params.isEditMode){
      this.setFormEditMode(this.$route.params.isEditMode);
    }
  },
  async created() {
    //edit survey mode
    if (this.isEnquete) {
      if (this.surveyId == "blank") {
        await this.initFormConfig({ template: this.surveyId, formType: this.formType });
      } else {
        let _result = await this.initFormConfig({
          surveyId: this.surveyId,
          formType: this.formType,
          schoolId: this.loginUser.school_id,
          enqueteOpenInfoTemplate: this.enqueteOpenInfo.templateInfo,
        });
        if (_result.noInspectPermission) {
          this.$router.replace({
            path: "/PageNotFound",
          });
        }
        this.updateShowBackButton(true);
      }
    } else if(this.$route.params.surveyId) {
      let _result = await this.initFormConfig({
        surveyId: this.$route.params.surveyId,
        formType: this.formType,
        schoolId: this.loginUser.school_id,
      });
      if (_result.noInspectPermission) {
        this.$router.replace({
          path: "/PageNotFound",
        });
      }
      this.updateShowBackButton(true);
    } else {
      let _template = this.templateId || this.$route.params.template || this.template;
      console.log("_template", _template)
      await this.initFormConfig({ template: _template, formType: this.formType });
      this.updateShowBackButton(!this.templateId);
    }
    
    this.breadcrumbs[0].text = 'テンプレート一覧'; 
    this.breadcrumbs[0].to = '/EnqueteList'; 
    if(this.isEditMode){
      this.breadcrumbs[1].text = '編集';
    }else{
      this.breadcrumbs[1].text = '新規作成';
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.fetchFormConfigDetailError &&
      this.fetchFormConfigDetailError.message &&
      this.fetchFormConfigDetailError.message.indexOf("閲覧設定") > -1
    ) {
      next();
      return;
    } else if(to.name == "SignIn") {
      next();
    } else if(to.name == "EnqueteList") {
      next();
    } else if (!this.duplicateFlg&&!this.registeredSurveyConfigId) {
      this.$snackbar.hide();
      this.$dialog.show({
        title: "このページを離れてもよろしいですか？",
        text: "行った変更は破棄されます。",
        btnConfirmTitle: "このページを離れる",
        onConfirm: () => {
          next();
        },
      });
    } else {
      next();
    }
  },
});
</script>
<style scoped>
.enqueteArea {
  background-color: #FAFBFC;
}
</style>