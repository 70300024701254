/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */
import { generateShortUUID } from "@/utils/uuidUtils";
export default class DuplicateEditToNewConfig {  
  surveyConfig;
  
  constructor(surveyConfig) {
    if(surveyConfig){
      this.surveyConfig = surveyConfig;
    }else{
      throw new Error("surveyConfigが存在しません");
    }
  }

  static async createDuplicateConfig(surveyConfig) {
    const deleteTargets = [
      "memberFormId",
      "categoriesPermissions",
      "endpointURL",
      "isDisplay",
      "createdAt",
      "updatedBy",
      "createdBy",
      "surveyId",
      "updatedAt",
      "headerImageUrl",
      "surveyTeams"
    ];
    for(let i=0 ; i < deleteTargets.length;i++){
      if(surveyConfig.hasOwnProperty(deleteTargets[i])){
        delete surveyConfig[deleteTargets[i]];
      }
    }
    for(let j=0 ; j < surveyConfig.surveySchema.length;j++){
      if(surveyConfig.surveySchema[j].imageUrl){
        delete surveyConfig.surveySchema[j].imageUrl;
      }
      surveyConfig.surveySchema[j].itemKey = generateShortUUID();
    }
    surveyConfig.surveyStatus = "enable";
    surveyConfig.type = "PAGE_BREAK";

    return surveyConfig;
  }
}


