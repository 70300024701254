<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <v-dialog v-model="show" max-width="500" eager>
    <v-system-bar color="primary" dark height="5"> </v-system-bar>
    <v-card>
      <v-toolbar flat height="75">
        <v-toolbar-title class="font-weight-bold" style="font-size:26px;">質問の並べ替え</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <ul ref="moveSectionsDrag" class="form-editor-drag">
        <li v-for="(question, index) in reorderQuestions" :key="question.itemKey">
          <v-hover v-slot:default="{ hover }">
            <v-alert class="py-0 my-0" :color="hover ? 'primary' : 'white'" dense colored-border border="left">
              <v-row align="center" no-gutters>
                <v-col class="shrink item-drag-handle">
                  <v-icon>mdi-drag</v-icon>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-truncate" style="max-width: 300px;font-size:14px;">{{
                        question.title || "無題質問"
                      }}</v-list-item-title>
                      <v-list-item-subtitle>質問 {{ index + 1 }} / {{ reorderQuestions.length }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="shrink">
                  <v-btn icon large :disabled="index === 0" @click="moveUp(index)">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="shrink">
                  <v-btn icon large :disabled="index + 1 === reorderQuestions.length" @click="moveDown(index)">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-hover>

          <v-divider></v-divider>
        </li>
      </ul>
      <v-divider></v-divider>
      <v-card-actions class="pa-6">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-2" class="px-4 mr-3" height="44px" elevation="0"  @click="show = false">
            閉じる
          </v-btn>
          <v-btn color="primary" class="px-4" height="44px" elevation="0" @click="onSaveReorderQuestions">
            適用
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapMutations } from "vuex";
import Sortable from "sortablejs";
import { cloneDeep } from "lodash";
import { UPDATE_SURVEY_SCHEMA } from "@/store/mutation-types";

// interface DataType {
//   reorderQuestions: any;
// }

// interface MethodsType {
//   moveUp(idx: number): void;
//   moveDown(idx: number): void;
//   moveQuestion(newIndex: number, oldIndex: number): void;
//   setupDrag(): void;
//   onSaveReorderQuestions(): void;
// }

// interface ComputedTypes {}

// interface PropsType {}

export default Vue.extend({
  props: {
    visible: Boolean,
    close: Function,
    questions: Array,
  },
  data() {
    return {
      reorderQuestions: cloneDeep(this.questions),
    };
  },

  components: {},
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      updateSurveySchema: UPDATE_SURVEY_SCHEMA,
    }),
    moveUp(idx) {
      this.moveQuestion(idx - 1, idx);
    },
    moveDown(idx) {
      this.moveQuestion(idx + 1, idx);
    },
    moveQuestion(newIndex, oldIndex) {
      //remove old position
      let dragSection = this.reorderQuestions.splice(oldIndex, 1);
      //move to new index
      this.reorderQuestions.splice(newIndex, 0, dragSection[0]);
    },
    setupDrag() {
      let list = this.$refs.moveSectionsDrag;
      const _self = this;
      if (list) {
        Sortable.create(list, {
          handle: ".item-drag-handle", // Use handle so user can select text
          animation: 150,
          chosenClass: "grey",
          onEnd({ newIndex, oldIndex }) {
            _self.moveQuestion(newIndex, oldIndex);
          },
        });
      }
    },
    onSaveReorderQuestions() {
      this.updateSurveySchema(this.reorderQuestions);
      this.show = false;
    },
  },
  watch: {
    questions(val) {
      this.reorderQuestions = cloneDeep(val);
    },
  },
  mounted() {
    this.setupDrag();
  },
});
</script>
