<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        label=""
        hint=""
        :clearable="!disabled"
        persistent-hint
        prepend-icon=""
        :readonly="!disabled"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        :rules="[validateRequired]"
        @click:clear="date = null"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      ref="picker"
      :max="new Date().toISOString().substr(0, 10)"
      min="1900-01-01"
      first-day-of-week="0"
      :locale="locale"
      color="blue"
      @input="menu = false"
      @change="save"
      :disabled="disabled"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import Vue from "vue";
import InputBase from "./InputBase.vue";
import { isBlank } from "../utils/validationUtils";

export default Vue.extend({
  name: "BirthdayPicker",
  mixins: [InputBase],
  data() {
    return {
      date: null,
      // dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      dateFormatted: "",
      menu: false,
      formValid: true,
    };
  },
  props: {
    isLiffMode: Boolean,
    isAdminMode: Boolean,
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  created() {
    var params = Object.assign(
      {
        options: [],
        isRequired: { value: false },
        isRequiredForUser: { value: false },
        input: undefined,
      },
      this.params
    );

    this.itemKey = this.params.itemKey;
    this.locale = navigator.language;
    this.required = params.isRequired.value;
    this.isRequiredForUser = params.isRequiredForUser.value;

    this.date = params.input;
    this.dateFormatted = this.formatDate(this.dateFormatted);
  },

  methods: {
    save(date) {
      //this.$refs.menu.save(date)
      this.date = date;
    },
    getValue() {
      return this.date === null ? "" : this.date;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onChange() {
      this.date = this.parseDate(this.dateFormatted);
    },
    validateRequired() {
      const isValid = !isBlank(this.dateFormatted);
      if (this.isAdminMode) {
        return !this.required || isValid;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || isValid;
      }
    },
  },
});
</script>
