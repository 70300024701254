<template>
    <div>
        <DeliveryCreation 
        :isEnquete="true"
        :enqueteTabType="tabType"
        />
    </div>
</template>

<script>
import DeliveryCreation from "@/components/SegmentDelivery/DeliveryCreation.vue"

export default {
  name: "SettingsTab",
  data() {
    return {
      tabType: 2,
    };
  },
  components: {
    DeliveryCreation,
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  async created() {
  },
  async mounted() {},
};
</script>