<template>
  <div class="bus-status-map-view">
    <v-row>
      <v-col cols="7" class="pr-0">
        <v-container class="pr-0">
          <v-card flat>
            <div ref="googleMap" class="map-view-area">
              <!-- 地図を表示するエリア -->
            </div>
            <button class="bus-stop-label-toggle-btn" @click="toggleBusStopLabel()">
              <span>
                <img :src="busStopIconPath" class="bus-stop-icon">
              </span>
            </button>
            <v-overlay
              :value="isNoBus"
              :absolute="true"
              opacity="0.5"
            >
              <div>現在走行中のバスはありません。</div>
            </v-overlay>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="5" class="pl-0">
        <v-container fill-height>
          <v-card height="75vh" flat outlined class="bus-log-view-area">
            <v-row justify="space-between">
              <v-col cols="auto">
                <v-card-title class="pr-0">
                  運行履歴
                </v-card-title>
              </v-col>
              <v-col cols="auto">
                <v-card-actions class="pa-4">
                  <v-btn
                    :loading="loading"
                    outlined
                    dark
                    color="primary"
                    @click="refresh()"
                  >
                    <v-icon left>
                      mdi-reload
                    </v-icon>
                    データ更新
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-text class="pt-0">
              <v-sheet class="overflow-y-auto bus-log-list">
                <div v-for="(item, index) in busLogHistoryLocal" :key="item.bus_log_id">
                  <v-divider v-if="index !== 0"></v-divider>
                  <div class="my-2">{{ item.bus_log_content }}</div>
                </div>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_BUS_ALL,
  GET_BUS_ALL_LENGTH,
  GET_BUS_ROUTE_LIST,
  GET_BUS_ROUTE_LENGTH,
  GET_BUS_LOG_YEAR,
} from "@/store/action-types";
import {
  BUS_STOP_ICON_URL,
  INTERVAL_SCHEDULED_REFRESH,
} from "@/constants/index.js";

// Google Maps API
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';

export default {
  name: 'BusStatusMapView',
  data() {
    return {
      loading: false, // ローディング
      busListLocal: [], // バス情報
      busRouteListLocal: [], // バスルート情報
      busLogHistoryLocal: [], // バスログ情報

      // バス情報定期更新処理
      scheduleId: null, // 定期実行の識別子
      intervalScheduledRefresh: INTERVAL_SCHEDULED_REFRESH, // 定期実行間隔
      unixLastRefresh: null, // 最終更新時間(unixtime)

      // GoogleMap
      map: null, // Map識別オブジェクト
      initialMapConfig: {}, // Map初期表示設定オブジェクト
      busMarkers: {}, // Marker識別オブジェクト(送迎バスアイコン)
      busStopMarkers: {}, // Marker識別オブジェクト(バス停アイコン)
      busRoutes: {}, // Route識別オブジェクト(走行ルート)
      busStopIconUrl: BUS_STOP_ICON_URL, // バス停表示用
      busStopIconPath: require('@/assets/icons/bus-stop-icon.png'), // バス停ラベル非表示ボタン用
      isShowBusStopLabel: true, // バス停ラベル表示/非表示の状態管理
    };
  },
  computed: {
    ...mapState({
      busAll: state => state.busAll,
      busRouteList: state => state.busRouteList,
      busRouteLength: state => state.busRouteLength,
      busLogHistory: state => state.busLogHistory,
    }),
    busListDisp() {
      return (
        this.busListLocal
          .filter((b) => { // 走行中かつ位置情報取得済みにフィルター
            const isDriving = (b.is_driving === 1)
            const locationIsNotNull = (b.lat !== null || b.lng !== null)
            return (isDriving && locationIsNotNull)
          })
          .sort((a, b) => { // バスIDの若い順にソート
            return this.convertBusIdToIndex(a.bus_id) - this.convertBusIdToIndex(b.bus_id)
          })
      )
    },
    isNoBus() {
      return (this.busListDisp.length === 0);
    },
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getBusAll: GET_BUS_ALL,
      getBusAllLength: GET_BUS_ALL_LENGTH,
      getBusRouteList: GET_BUS_ROUTE_LIST,
      getBusRouteLength: GET_BUS_ROUTE_LENGTH,
      getBusLogYear: GET_BUS_LOG_YEAR,
    }),
    createBusMarkerAll() {
      for (const bus of this.busListDisp) {
        const location = {
          lat: parseFloat(bus.lat),
          lng: parseFloat(bus.lng)
        };
        const icon = {
          url: bus.icon_url,
          scaledSize: new window.google.maps.Size(40, 40),
          anchor: new window.google.maps.Point(30, 0),
        };
        const marker = new MarkerWithLabel({
          position: location,
          map: this.map,
          icon: icon,
          zIndex: 1,
          labelContent: bus.bus_name,
          labelAnchor: new window.google.maps.Point(-58, -40),
          labelClass: "marker-label-bus",
        });
        this.busMarkers[`${bus.bus_id}`] = marker;
      }
    },
    deleteBusMarkerAll() {
      for (const m in this.busMarkers) {
        const marker = this.busMarkers[m];
        marker.setMap(null);
      }
    },
    createBusStopMarkerAll() {
      const busStopListIsDriving = this.busRouteListLocal.filter((bs) => this.busListDisp.some((bus) => bus.driving_route === bs.bus_route_id));
      busStopListIsDriving.forEach((busRoute) =>{
        const busRouteId = busRoute.bus_route_id;
        busRoute.route_info.forEach((busStop) => {
          const location = {
            lat: parseFloat(busStop.lat),
            lng: parseFloat(busStop.lng)
          };
          const icon = {
            url: this.busStopIconUrl,
            scaledSize: new window.google.maps.Size(30, 30),
            anchor: new window.google.maps.Point(10, 26),
          };
          const labelClass = (this.isShowBusStopLabel) ? "marker-label-bus-stop" : "marker-label-bus-stop-invisible";
          const marker = new MarkerWithLabel({
            position: location,
            map: this.map,
            icon: icon,
            zIndex: 0,
            labelContent: busStop.bus_stop_name,
            labelAnchor: new window.google.maps.Point(-30, -56),
            labelClass: labelClass,
          });
          this.busStopMarkers[`${busRouteId}-${busStop.order_no}`] = marker;
        })
      })
    },
    deleteBusStopMarkerAll() {
      for (const m in this.busStopMarkers) {
        const marker = this.busStopMarkers[m];
        marker.setMap(null);
      }
    },
    toggleBusStopLabel() {
      this.isShowBusStopLabel = !this.isShowBusStopLabel; // ラベル表示/非表示を切り替え
      this.deleteBusStopMarkerAll(); // バス停マーカーを全削除
      this.createBusStopMarkerAll(); // バス停マーカーを際作成
    },
    createBusRouteAll() {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      const busRouteListIsDriving = this.busRouteListLocal.filter((route) => this.busListDisp.some((bus) => bus.driving_route === route.bus_route_id));
      busRouteListIsDriving.forEach(busRoute => {
        const first = busRoute.route_info[0]; // 最初の要素を取得
        const last = busRoute.route_info[busRoute.route_info.length - 1]; // 最後の要素を取得
        const between = busRoute.route_info.slice(1, -1); // 最初と最後以外の要素をまとめて新しい配列に格納
        const waypoints = between.map((busRoute) => {
          return {
            location: new window.google.maps.LatLng(busRoute.lat, busRoute.lng),
            stopover: false
          }
        })
        const request = {
          origin: new window.google.maps.LatLng(first.lat, first.lng), // スタート地点
          destination: new window.google.maps.LatLng(last.lat, last.lng), // ゴール地点
          waypoints: waypoints, // 経由地点
          travelMode: window.google.maps.DirectionsTravelMode.DRIVING, // 移動手段
        };
        let self = this;
        let busRouteId = busRoute.bus_route_id;
        directionsService.route(request, function(result, status) {
          if (status == window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setOptions({
              preserveViewport: true, // ズーム率を変更してルート全体を表示しない
              suppressMarkers: true // マーカーを非表示にする
            });
            // ルート検索の結果を地図上に描画
            directionsRenderer.setDirections(result);
            directionsRenderer.setMap(self.map);
            self.busRoutes[`${busRouteId}`] = directionsRenderer;
          }
        });
      })
    },
    deleteBusRouteAll() {
      for (const m in this.busRoutes) {
        const marker = this.busRoutes[m];
        marker.setMap(null);
      }
    },
    async getBusLocationAll() {
      await this.getBusAll();
    },
    async refresh() {
      this.loading = true;

      await this.getBusLocationAll(); // バスの位置情報を取得
      this.deleteBusMarkerAll(); // バスのマーカーを全削除
      this.createBusMarkerAll(); // バスのマーカーを全作成
      this.deleteBusStopMarkerAll(); // バス停のマーカーを全削除
      this.createBusStopMarkerAll(); // バス停のマーカーを全作成
      this.deleteBusRouteAll(); // 走行ルートを全削除
      this.createBusRouteAll(); // 走行ルートを全作成
      await this.getBusLogYear(); // バスのログ情報を取得
      const nowUnix = Math.floor(Date.now() / 1000);
      this.unixLastRefresh = nowUnix; // 最終更新時間の更新

      this.loading = false;
    },
    makeScheduleRefresh() {
      this.scheduleId = setInterval(async () => {
        if (!this.loading) { // 他の更新処理と並行して処理させない
          const nowUnix = Math.floor(Date.now() / 1000);
          if ((nowUnix - this.unixLastRefresh) > this.intervalScheduledRefresh) { // 位置情報の最終更新時刻から一定時間経過していないと処理させない
            await this.refresh(); // バス情報の再取得&マーカーの再作成
          }
        }
      }, 1000);
    },
    clearScheduleRefresh() {
      clearInterval(this.scheduleId);
    },
    setInitialMapConfig() {
      if (!this.busListDisp || this.busListDisp.length === 0) { // 走行中のバスがないなら那覇市を中心に表示
        this.initialMapConfig = {
          center: {
            lat: 26.2111111,
            lng: 127.6851111
          },
          zoom: 14,
          mapTypeControl: false,
          zoomControl: true,
          panControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false
        }
      } else { // 走行中のバスがあるならバスIDの若い順に先頭のものを中心に表示
        const targetBusInfo = this.busListDisp[0];
        this.initialMapConfig = {
          center: {
            lat: parseFloat(targetBusInfo.lat),
            lng: parseFloat(targetBusInfo.lng)
          },
          zoom: 14,
          mapTypeControl: false,
          zoomControl: true,
          panControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false
        }
      }
    },
    async initializeGoogleMap() {
      // GoogleMapの初期表示位置の決定
      this.setInitialMapConfig();
      const loader = new Loader({ apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY });
      await loader.load().then(async () => {
        // GoogleMapの表示
        this.map = await new window.google.maps.Map(this.$refs.googleMap, this.initialMapConfig);

        // バスルートの表示
        this.createBusRouteAll();

        // バス停の表示
        this.createBusStopMarkerAll();

        // バスアイコンの表示
        this.createBusMarkerAll();
      });
    },
    convertBusIdToIndex(busId) {
      return parseFloat(busId.slice(-5));
    },
  },
  watch: {
    busAll: {
      handler() {
        this.busListLocal = structuredClone(this.busAll);
      },
      deep: true
    },
    busRouteList: {
      handler() {
        this.busRouteListLocal = structuredClone(this.busRouteList);
      },
      deep: true
    },
    busLogHistory: {
      handler() {
        this.busLogHistoryLocal = structuredClone(this.busLogHistory);
      },
      deep: true
    },
  },
  async created() {
    this.loading = true;

    // バスの件数を取得する
    let busAllLength = 0;
    const getBusAllLengthRes = await this.getBusAllLength();
    if ((getBusAllLengthRes.statusCode) && (getBusAllLengthRes.statusCode === 200)){
      busAllLength = getBusAllLengthRes.body;
    }

    // バスの件数が一致しない場合のみ取得処理を実行
    if (this.busAll.length !== busAllLength){
      await this.getBusAll();
    }
    this.busListLocal = structuredClone(this.busAll);

    // バスルートの件数を取得する
    await this.getBusRouteLength();

    // バスルートの件数が一致しない場合のみ取得処理を実行
    if ((this.busRouteList.length - this.busRouteLength) !== 0){
      await this.getBusRouteList();
    }
    this.busRouteListLocal = structuredClone(this.busRouteList);

    // GoogleMapを描画
    await this.initializeGoogleMap();

    // バスのログ情報を取得
    await this.getBusLogYear();

    // 最終更新時間の更新
    const nowUnix = Math.floor(Date.now() / 1000);
    this.unixLastRefresh = nowUnix;

    this.loading = false;

    // バス情報の定期更新開始
    this.makeScheduleRefresh();
  },
  mounted() {},
  beforeDestroy() {
    this.clearScheduleRefresh();
  },
}
</script>

<style scoped>
.map-view-area {
  position: relative;
  height: 75vh;
  width: 100%;
}

.bus-stop-label-toggle-btn {
  position: absolute;
  right: 10px;
  bottom: 120px;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
}

.bus-stop-icon {
  margin: 8px;
  height: 24px;
  width: 24px;
}

.bus-log-view-area {
  height: 75vh;
}

.bus-log-list {
  max-height: 64vh;
}

::v-deep .marker-label-bus {
  color: rgba(19, 153, 61, 1);
  background: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: center;
  padding: 4px 12px;
  border-radius: 8px;
  border: 2px solid rgba(19, 153, 61, 1);
  opacity: 1 !important;
}

::v-deep .marker-label-bus-stop {
  color: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 1);
  font-size: 12px;
  text-align: center;
  padding: 2px 10px;
  border-radius: 8px;
  border: 2px solid rgba(244, 244, 64, 1);
}

::v-deep .marker-label-bus-stop-invisible {
  color: transparent;
}
</style>