<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <!-- <v-snackbar v-model="visible" :timeout="timeout">
    <v-icon left color="yellow accent-4" v-if="type === 'error'">mdi-alert-outline</v-icon>
    {{ text }}
    <v-btn color="white" text @click="visible = false">閉じる</v-btn>
  </v-snackbar> -->
  <v-dialog v-model="visible" width="500">
    <v-card>
      <v-system-bar :color="dialogColor" dark height="10"> </v-system-bar>
      <v-list three-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-if="type === 'info'" x-large :color="dialogColor">mdi-alert-circle-outline</v-icon>
            <v-icon v-if="type === 'warning'" x-large :color="dialogColor">mdi-alert-outline</v-icon>
            <v-icon v-if="type === 'confirm'" x-large :color="dialogColor">mdi-help-circle-outline</v-icon>
            <v-icon v-if="type === 'error'" x-large :color="dialogColor">mdi-alert-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="title"></v-list-item-title>
            <div class="mt-2 text-body-2 break-line text--disabled">
              {{ text }}
            </div>
            <div v-if="hideUrlInput">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field v-model="inputUrl" outlined dense hide-details="auto"
                    placeholder="URL" :rules="[required('URL'), validateUrl()]"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions v-if="!hideBtn">
        <v-spacer></v-spacer>
        <v-btn v-if="!hideBtnCancel" color="grey darken-2" outlined @click="hide">
          {{ btnCancelTitle }}
        </v-btn>
        <v-btn v-if="!hideBtnConfirm" :color="dialogColor" @click="confirm" :disabled="isConfrimDisabled">
          {{ btnConfirmTitle }}
        </v-btn>
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import Dialog from "@/plugins/dialog";
import { mapMutations } from "vuex";
import {
  REQUIRED_ERROR_MSG,
} from "@/constants";
import {
  SET_INPUT_URL,
} from "@/store/mutation-types";
const DIALOG_COLOR = {
  info: "primary",
  warning: "warning",
  confirm: "primary",
  error: "error"
};

// interface LocalState {
//   visible: boolean;
//   title: string;
//   text: string;
//   type: string;
//   btnConfirmTitle: string;
//   btnCancelTitle: string;
//   hideBtn: boolean;
//   hideBtnConfirm: boolean;
//   hideBtnCancel: boolean;
// }

export default Vue.extend({
  data() {
    return {
      visible: false,
      title: "",
      text: "",
      type: "info",
      btnConfirmTitle: "OK",
      btnCancelTitle: "キャンセル",
      hideBtn: false,
      hideBtnConfirm: false,
      hideBtnCancel: false,
      hideUrlInput: false,
      inputUrl: "",
      isRequiredError: false,
      isUrlError: false,
    };
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Dialog.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
  computed: {
    dialogColor() {
      return DIALOG_COLOR[this.type] || DIALOG_COLOR.info;
    },
    isConfrimDisabled() {
      if (this.isRequiredError || this.isUrlError) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapMutations({
      setInputUrl: SET_INPUT_URL
    }),
    hide() {
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === "function") {
        // run passed function and then close the modal
        this.setInputUrl(this.inputUrl);
        this.onConfirm();
        this.inputUrl = "";
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      this.text = params.text;
      this.type = params.type || "info";
      this.title = params.title;
      this.hideUrlInput = params.hideUrlInput;
      this.hideBtn = params.hideBtn;
      this.hideBtnConfirm = params.hideBtnConfirm;
      this.hideBtnCancel = params.hideBtnCancel;
      (this.btnConfirmTitle = params.btnConfirmTitle || "OK"),
        (this.btnCancelTitle = params.btnCancelTitle || "キャンセル"),
        (this.onConfirm = params.onConfirm);
    },
    required(str) {
      return value => !!value && !this.isSpace(value) || str + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        this.isRequiredError = true;
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        this.isRequiredError = false;
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      this.isRequiredError = true;
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    validateUrl() {
      // URLの形式をチェックする正規表現パターン
      const urlPattern = /^(http|https):\/\/.*/;

      if (urlPattern.test(this.inputUrl)) {
        this.isUrlError = false;
        return true;
      } else {
        this.isUrlError = true;
        return "URLの形式が正しくありません";
      }
    },
  },
});
</script>

<style lang="less">
.dialog-text {
  white-space: pre !important;
  display: block;
  line-height: 1.5 !important;
}
.break-line {
  white-space: pre-line !important;
}
</style>
