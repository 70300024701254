<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the 'License'); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an 'AS IS' BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-autocomplete
          clearable
          dense
          :label="yearLabel"
          v-model="selectedYear"
          :items="years"
          :rules="[validateRequiredYear]"
          @change="updateSelectedDate"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          clearable
          dense
          :label="monthLabel"
          v-model="selectedMonth"
          :items="months"
          :rules="[validateRequiredMonth]"
          @change="updateSelectedDate"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          clearable
          dense
          :label="dayLabel"
          v-model="selectedDay"
          :items="days"
          :rules="[validateRequiredDay]"
          @change="updateSelectedDate"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import InputBase from "./InputBase.vue";

export default Vue.extend({
  name: "DateDropdown",
  mixins: [InputBase],
  props: {
    mlLibJson:Object,
    // The lowest year option
    minYear: {
      type: Number,
      default: 1900,
    },
    // The highest year option
    maxYear: {
      type: Number,
      default: moment().year(),
    },
    // The format of the date string
    defaultDateFormat: {
      type: String,
      default: "YYYY-MM-DD",
    },
    isLiffMode: Boolean,
    isAdminMode: Boolean,
  },

  data() {
    return {
      yearLabel: "年",
      monthLabel: "月",
      dayLabel: "日",
      years: [],
      months: [],
      days: [],

      dateInputVal: null,
      dateInputDt: null,
      selectedDate: null,

      // The selected year
      selectedYear: moment().format("YYYY"),
      // The selected month
      selectedMonth: moment().format("MM"),
      // The selected day
      selectedDay: moment().format("DD"),

      formValid: true,
    };
  },
  computed: {},
  watch: {
    mlLibJson(val){
      this.yearLabel = "年";
      this.monthLabel = "月";
      this.dayLabel = "日";
    }
  },
  methods: {
    padStart2(value) {
      return String(value).padStart(2, "0");
    },
    getDaysInMonth(year, month) {
      let dt = moment(year + month, "YYYYMM");
      let days = Array.from({ length: dt.daysInMonth() }, (x, i) =>
        dt.startOf("month").add(i, "days").format("DD")
      );
      return days;
    },
    setYearSelect() {
      // init years
      for (let i = this.minYear; i <= this.maxYear; i++) {
        this.years.push(String(i));
      }
    },
    setMonthSelect() {
      // init months
      for (let i = 1; i <= 12; i++) {
        this.months.push(this.padStart2(i));
      }
    },
    setDaySelect() {
      this.days = this.getDaysInMonth(this.selectedYear, this.selectedMonth);
    },
    updateSelectedDate() {
      if (!this.selectedYear || !this.selectedMonth || !this.selectedDay) {
        this.selectedDate = null;
        return;
      }

      let dt = moment(
        this.selectedYear + this.selectedMonth + this.selectedDay,
        "YYYYMMDD",
        true
      ).format("YYYY-MM-DD");
      this.selectedDate = dt;
    },
    getValue() {
      return this.selectedDate;
    },
    validateRequiredYear() {
      const validateResult = this.selectedYear || "年を選択してください。";
      if (this.isAdminMode) {
        return !this.required || validateResult;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || validateResult;
      }
    },
    validateRequiredMonth() {
      const validateResult = this.selectedMonth || "月を選択してください。";
      if (this.isAdminMode) {
        return !this.required || validateResult;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || validateResult;
      }
    },
    validateRequiredDay() {
      const validateResult = this.selectedDay || "日を選択してください。";
      if (this.isAdminMode) {
        return !this.required || validateResult;
      } else if (this.isLiffMode) {
        return (!this.required && !this.isRequiredForUser) || validateResult;
      }
    },
  },
  created() {
    var params = Object.assign(
      {
        options: [],
        isRequired: { value: false },
        isRequiredForUser: { value: false },
        input: undefined,
      },
      this.params
    );

    this.itemKey = this.params.itemKey;
    this.required = params.isRequired.value;
    this.isRequiredForUser = params.isRequiredForUser.value;
    this.dateInputVal = params.input;

    // set select  year, month, day by input date
    if (this.dateInputVal) {
      let dt = moment(this.dateInputVal, this.defaultDateFormat);
      if (dt.isValid()) {
        this.dateInputDt = dt;
        this.selectedYear = dt.format("YYYY");
        this.selectedMonth = dt.format("MM");
        this.selectedDay = dt.format("DD");
      }
    }

    // init years
    this.setYearSelect();
    // init months
    this.setMonthSelect();
    // init days
    this.setDaySelect();
    this.updateSelectedDate();
  },
  mounted() {},
});
</script>
