<template>
  <div>
    <v-dialog v-model="show" :max-width="csvDialogMaxWidth">
      <v-card>
        <v-card-title>
          <span class="text-h4 font-weight-bold">CSVインポート</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-btn-toggle dense group>
              <v-btn color="green" class="my-3" elevation="0" @click="uploadCsv()">CSVをアップロード</v-btn>
              <div v-if="csvFile" style="display: grid; place-items: center;">{{ csvFile.name }}</div>
            </v-btn-toggle>
            {{ csvErrMsg }}
            <div class="detailPair" style="display: none;">
              <div class="elem">
                <div class="upload_zone" style="dis">
                  <input type="file" class="input_file" ref="preview" @change="inputCsv()" accept=".csv" />
                </div>
              </div>
            </div>
            <div v-if="csvDataErrMsg.length != 0">
              <v-row v-for="(data, index) in csvDataErrMsg" :key="index">
                {{ data }}
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="green" dark @click="show = false" outlined>キャンセル</v-btn>
          <v-btn :disabled="csvDataErrMsg.length != 0 || !csvFile" :loading="loading" color="green white--text"
            @click="sendCsv()">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Papa from "papaparse";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  UPLOAD_MULTI_STAFF,
} from "@/store/action-types";
import {
  REQUIRED_ERROR_MSG,
  MAX_LENGTH_255_ERROR_MSG,
  FULL_WIDTH_10_ERROR_MSG,
} from "@/constants";

export default Vue.extend({
  name: 'StaffCsvImport',
  components: {

  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    gradeAll: Array,
    classroomAll: Array,
  },
  data() {
    return {
      loading: false,
      csvFile: null,
      csvData: [],
      csvDataErrMsg: [],
      staffCsv: null,
      csvErrMsg: null,
    }
  },
  computed: {
    ...mapState({
      staffAll: state => state.staffAll,
      fiscalYear: state => state.fiscalYear,
      loginUser: state => state.loginUser,
    }),
    show: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    },
    csvDialogMaxWidth() {
      return "1100px";
    },
  },
  watch: {

  },
  async created() {

  },
  methods: {
    ...mapActions({
      uploadMultiStaff: UPLOAD_MULTI_STAFF,
    }),
    ...mapMutations({

    }),
    uploadCsv() {
      this.$refs.preview.click();
    },
    async inputCsv() {
      this.csvDataErrMsg = [];
      this.csvFile = this.$refs.preview.files[0];
      const file = this.$refs.preview.files[0];
      const reader = new FileReader();
      reader.readAsText(file, 'Shift-JIS'); // Shift-JIS形式で読み込む
      reader.onload = async () => {
        const csv = reader.result;
        const results = Papa.parse(csv, { header: true });
        const products = results.data;
        this.csvData = this.csvDataKeyChange(products);
        
        console.log(this.csvData);
        this.csvDataValidateCheck();
      };
    },
    csvDataValidateCheck() {
      let deleteRowIndexList = [];
      for (let i = 0; i < this.csvData.length; i++) {
        const data = this.csvData[i];
        if (this.deleteNullOrEmptyRow(data)) {
          deleteRowIndexList.push(i);
        } else {
          this.checkLastNameValidate(data, i + 2);
          this.checkFirstNameValidate(data, i + 2);
          this.checkMailAddressValidate(data, i + 2);
          this.checkAuthority(data, i + 2);
          this.checkManagedGradeValidate(data, i + 2);
          this.checkManagedClassValidate(data, i + 2);
          this.checkMemoValidate(data, i + 2);
        }
      }
      for (let i = 0; i < deleteRowIndexList.length; i++) {
        this.csvData.splice(deleteRowIndexList[i] - i, 1)
      }
    },
    checkLastNameValidate(data, i) {
      // 姓のValidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "姓" + REQUIRED_ERROR_MSG;
      const lengthErrMsg = "姓は" + FULL_WIDTH_10_ERROR_MSG;

      const validateTarget = data.last_name;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg);
      } else if (validateTarget !== null && validateTarget.length > 10) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      } else if (!/* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      }
    },
    checkFirstNameValidate(data, i) {
      // 名のValidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "名" + REQUIRED_ERROR_MSG;
      const lengthErrMsg = "名は" + FULL_WIDTH_10_ERROR_MSG;

      const validateTarget = data.first_name;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg);
      } else if (validateTarget !== null && validateTarget.length > 10) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      } else if (!/* eslint no-control-regex: off */ /[^\x01-\x7E\uFF61-\uFF9F]+$/.test(validateTarget)) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      }
    },
    checkMailAddressValidate(data, i){
      // メールアドレスのValidation
      const posErrMsg = "行目でエラー : ";
      const lengthErrMsg = "メールアドレスは" + MAX_LENGTH_255_ERROR_MSG;
      const mailErrMsg = "メールアドレスの形式が正しくありません。";

      const validateTarget = data.mail;
      const mailAddressRule = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(validateTarget);

      if (validateTarget.length > 255) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      } else if (validateTarget !== "" && !mailAddressRule) {
        this.csvDataErrMsg.push(i + posErrMsg + mailErrMsg);
      }
    },
    checkAuthority(data, i) {
      // 権限のValidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "権限" + REQUIRED_ERROR_MSG;
      const adminErrMsg = "全権管理者はインポートで登録することができません。";

      const validateTarget = data.authority;

      if (validateTarget == null || validateTarget == "") {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg);
      } else if (validateTarget == "1") {
        this.csvDataErrMsg.push(i + posErrMsg + adminErrMsg);
      }
    },
    checkManagedGradeValidate(data, i) {
      // 担当学年のValidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsgGakunen = "学年主任の場合、学年" + REQUIRED_ERROR_MSG;
      const emptyErrMsgTannnin = "担任の場合、学年" + REQUIRED_ERROR_MSG;
      const wrongAreaErrMsg = "担当学年の入力形式が誤っています。";
      const outAreaErrMsg = "存在しない担当学年が指定されています。";

      const validateTarget = data.managed_grade;
      const containGrade = this.gradeAll.some(item => item.grade_id == validateTarget);

      if (data.authority == "3" && (validateTarget == null || validateTarget == "")) {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsgGakunen);
      } else if (data.authority == "4" && (validateTarget == null || validateTarget == "")) {
        this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsgTannnin);
      } else if ((data.authority == "3") || (data.authority == "4")) {
        if (validateTarget.length != 2) {
          this.csvDataErrMsg.push(i + posErrMsg + wrongAreaErrMsg);
        } else if (!containGrade) {
          this.csvDataErrMsg.push(i + posErrMsg + outAreaErrMsg);
        }
      }
    },
    checkManagedClassValidate(data, i) {
      // 担当クラスのValidation
      const posErrMsg = "行目でエラー : ";
      const emptyErrMsg = "担任の場合、クラス" + REQUIRED_ERROR_MSG;
      const wrongAreaErrMsg = "担当クラスの入力形式が誤っています。";
      const outAreaErrMsg = "存在しない担当クラスが指定されています。";

      const validateTarget = data.managed_class;
      const findGrade = this.classroomAll.filter(item => item.grade_id == data.managed_grade);

      let containClass = "";
      if (findGrade != null && findGrade != "" && findGrade != undefined) {
        containClass = findGrade.some(item => item.class_id == validateTarget);
      }

      if (data.authority == "4") {
        if (validateTarget == null || validateTarget == "") {
          this.csvDataErrMsg.push(i + posErrMsg + emptyErrMsg);
        } else if (validateTarget.length != 2) {
          this.csvDataErrMsg.push(i + posErrMsg + wrongAreaErrMsg);
        } else if (!containClass && data.managed_grade) {
          this.csvDataErrMsg.push(i + posErrMsg + outAreaErrMsg);
        }
      }
    },
    checkMemoValidate(data, i) {
      // メモのValidation
      const posErrMsg = "行目でエラー : ";
      const lengthErrMsg = "メモは" + MAX_LENGTH_255_ERROR_MSG;

      const validateTrget = data.memo;

      if (validateTrget.length > 255) {
        this.csvDataErrMsg.push(i + posErrMsg + lengthErrMsg);
      }
    },
    deleteNullOrEmptyRow(csvData) {
      if (
        (csvData.last_name == "" || !csvData.last_name) &&
        (csvData.first_name == "" || !csvData.first_name)
      ) {
        if (csvData.authority == "" || !csvData.authority) {
          return true;
        } else if (
          csvData.authority == "3" &&
          (csvData.managed_grade == "" || !csvData.managed_grade)
        ) {
          return true;
        } else if (
          csvData.authority == "4" &&
          (csvData.managed_grade == "" || !csvData.managed_grade) &&
          (csvData.managed_class == "" || !csvData.managed_class)
        ) {
          return true;
        }
      }
      return false;
    },
    csvDataKeyChange(csvData) {
      // csvDataのキー名を日本語から英語に変える 
      for (let i = 0; i < csvData.length; i++) {
        csvData[i].last_name = csvData[i]['職員氏名（姓）'];
        delete csvData[i]['職員氏名（姓）'];
        
        csvData[i].first_name = csvData[i]['職員氏名（名）'];
        delete csvData[i]['職員氏名（名）'];

        csvData[i].mail = csvData[i]['メールアドレス'];
        delete csvData[i]['メールアドレス'];

        csvData[i].managed_grade = csvData[i]['担当学年'];
        delete csvData[i]['担当学年'];
        
        csvData[i].managed_class = csvData[i]['担当クラス'];
        delete csvData[i]['担当クラス'];

        csvData[i].memo = csvData[i]['メモ'];
        delete csvData[i]['メモ'];
        
        csvData[i].authority = csvData[i]['権限'];
        delete csvData[i]['権限'];

        csvData[i].is_driver = csvData[i]['バス運転手'];
        delete csvData[i]['バス運転手'];
      }
      return csvData;
    },
    async sendCsv() {
      this.loading = true;
      let index = 0;
      const chunkSize = 50;
      this.loadingDialogText = '職員情報を登録中です。';
      this.allDownloadingLength = '%';
      while (index < this.csvData.length) {
        this.currentDownloadingClass = Math.floor(((index == 0 ? 1 : index) / this.csvData.length) * 100);
        let chunk = this.csvData.slice(index, index + chunkSize);
        await this.uploadMultiStaff(chunk);
        index += chunkSize;
      }
      this.loadingDialogText = '';
      this.allDownloadingLength = '';
      this.currentDownloadingClass = '';

      this.show = false;
      this.$emit('reload');
    },
  },
});
</script>

<style scoped></style>