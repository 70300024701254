import moment from 'moment';
const ENV_NAME = process.env.VUE_APP_ENV_NAME;

export const STUDENT_TEMPLATE = {
  first_name: "",
  first_name_kana: "",
  last_name: "",
  last_name_kana: "",
  number: "",
  classroom_id: "",
  classroom: {
    classroom_id: "",
    school_class: "",
    school_grade: "",
    school_id: "",
  },
};

export const STUDENT_MANAGE_HEADER = [
  { text: "学年", value: "school_grade", width: "60px" },
  { text: "クラス", value: "school_class", width: "70px" },
  { text: "出席番号", value: "number", width: "80px" },
  { text: "氏名(姓)", value: "last_name", width: "110px" },
  { text: "氏名(名)", value: "first_name", width: "110px" },
  { text: "氏名(セイ)", value: "last_name_kana", width: "110px" },
  { text: "氏名(メイ)", value: "first_name_kana", width: "110px" },
  {
    text: "セキュリティーキー",
    value: "security_key",
    sortable: false,
    width: "120px",
  },
  { value: "actions", sortable: false, width: "300px" },
];

export const AUTHORITIES =  [
  {
    authority_id: 1,
    authority_name: "全権管理者",
    allow_menus: [
      "メッセージ配信",
      "アンケート実施",
      "テンプレート一覧",
      "献立カレンダー",
      "アレルゲン通知",
      "欠席/遅刻連絡",
      "生徒管理",
      "職員管理",
      "送迎バス管理",
      "学校設定",
      "職員グループ管理",
      "カレンダー管理"
    ],
  },{
    authority_id: 2,
    authority_name: "管理者",
    allow_menus: [
      "メッセージ配信",
      "アンケート実施",
      "テンプレート一覧",
      "献立カレンダー",
      "アレルゲン通知",
      "欠席/遅刻連絡",
      "生徒管理",
      "職員管理",
      "送迎バス管理",
      "職員グループ管理",
      "カレンダー管理"
    ],
  },{
    authority_id: 3,
    authority_name: "学年主任",
    allow_menus: [
      "メッセージ配信",
      "アンケート実施",
      "献立カレンダー",
      "アレルゲン通知",
      "欠席/遅刻連絡",
      "生徒管理",
      "カレンダー管理",
    ],
  },{
    authority_id: 4,
    authority_name: "担任",
    allow_menus: [
      "メッセージ配信",
      "アンケート実施",
      "献立カレンダー",
      "アレルゲン通知",
      "欠席/遅刻連絡",
      "生徒管理",
      "カレンダー管理"
    ],
  },{
    authority_id: 5,
    authority_name: "その他職員",
    allow_menus: [
      "メッセージ配信",
    ],
  },
];

// 欠席連絡_連絡種別一覧
export const ABSENCE_FORM_TYPE_LIST = [
  { id: "absence", text: "欠席"},
  { id: "late", text: "遅刻"}
];

// 欠席理由一覧
export const ABSENCE_REASON_LIST = [
  { id: 'fever', text: '発熱' },
  { id: 'headache', text: '頭痛' },
  { id: 'stomachache', text: '腹痛' },
  { id: 'diarrhea', text: '下痢' },
  { id: 'other', text: 'その他の理由' }
];

// 共通定数値
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const CREATE_TITLE = "新規追加";
export const EDIT_TITLE = "編集";

export const LOADING_TEXT = "読み込んでいます。しばらくお待ちください"
export const NO_DATA_TEXT = "データがありません。"
export const NO_RESULT_TEXT = "検索結果が見つかりません。"

// errMsg
export const LOGIN_ERROR_MSG = "ログインIDまたはパスワードが正しくありません";
export const REQUIRED_ERROR_MSG = "を入力してください。";
export const SELECT_REQUIRED_ERROR_MSG = "を選択してください。";
export const INPUT_REQUIRED_ERROR_MSG = "メッセージを入力してください。";
export const MIN_LENGTH_5_ERROR_MSG = "5文字以上で入力してください。";
export const MAX_LENGTH_10_ERROR_MSG = "10文字以内で入力してください。";
export const MAX_LENGTH_12_ERROR_MSG = "12文字以内で入力してください。";
export const MAX_LENGTH_13_ERROR_MSG = "13文字以内で入力してください。";
export const MAX_LENGTH_15_ERROR_MSG = "15文字以内で入力してください。";
export const MAX_LENGTH_20_ERROR_MSG = "20文字以内で入力してください。";
export const MAX_LENGTH_30_ERROR_MSG = "30文字以内で入力してください。";
export const MAX_LENGTH_33_ERROR_MSG = "33文字以内で入力してください。";
export const MAX_LENGTH_128_ERROR_MSG = "128文字以内で入力してください";
export const MAX_LENGTH_255_ERROR_MSG = "255文字以内で入力してください。";
export const MAX_LENGTH_319_ERROR_MSG = "319文字以内で入力してください。";
export const MAX_LENGTH_3000_ERROR_MSG = "3000文字以内で入力してください。";
export const HALF_NUM_AND_MAX_LENGTH_1 = "半角数字1文字以内で入力してください。";
export const HALF_NUM_AND_MAX_LENGTH_2 = "半角数字2文字以内で入力してください。";
export const MAIL_ERROR_MSG = "メールアドレスを入力してください。";
export const HALF_ALPHA_AND_NUM_ERROR_MSG = "半角英数字で入力してください。";
export const FULL_WIDTH_10_ERROR_MSG = "全角10文字以内で入力してください。";
export const FULL_WIDTH_20_KANA_ERROR_MSG = "全角ひらがな・カタカナ20文字以内で入力してください。";
export const LAT_FORMAT_ERROR_MSG = "緯度は-90度~90度の範囲で入力してください。";
export const LNG_FORMAT_ERROR_MSG = "経度は-180度~180度の範囲で入力してください。";

const makeLineStickerList = (minId, maxId) =>{
  const sticker = []
  for (let i = minId; maxId >= i; i++) {
    sticker.push({
      stickerId : String(i),
      url : "https://stickershop.line-scdn.net/stickershop/v1/sticker/" + String(i) + "/android/sticker.png"
    })
  }
  return sticker
}

export const LINE_STICKER = [
  {
    packageId : "446",
    nameJP : "ムーン スペシャル",
    child : makeLineStickerList(1988, 2027)
  },
  {
    packageId : "789",
    nameJP : "サリー スペシャル",
    child : makeLineStickerList(10855, 10894)
  },
  {
    packageId : "6136",
    nameJP : "謝罪のプロ！LINEキャラクターズ",
    child : makeLineStickerList(10551376, 10551399)
  },
  {
    packageId : "6325",
    nameJP : "ちっちゃいブラコニ",
    child : makeLineStickerList(10979904, 10979927)
  },
  {
    packageId : "8515",
    nameJP : "ゆる敬語★LINEキャラクターズ",
    child : makeLineStickerList(16581242, 16581265)
  },
  {
    packageId : "11537",
    nameJP : "動くブラウン＆コニー＆サリー スペシャル",
    child : makeLineStickerList(52002734, 52002773)
  },
  {
    packageId : "11538",
    nameJP : "動くチョコ＆LINEキャラ スペシャル",
    child :  makeLineStickerList(51626494, 51626533)
  },
  {
    packageId : "11539",
    nameJP : "ユニバースター BT21 スペシャルVer.",
    child :  makeLineStickerList(52114110, 52114149)
  },
]

// バス位置情報送信の間隔
export const INTERVAL_SEND_LOCATION = 30;

// バス停アイコンのURL
export const BUS_STOP_ICON_URL = `https://school-app-back-${ENV_NAME}-bus-icon-bucket.s3.ap-northeast-1.amazonaws.com/bus_stop_icon.png`;

// バス位置情報再取得の間隔
export const INTERVAL_SCHEDULED_REFRESH = 5;

// アンケートプレビューで使用
export const IME_TYPE = {
  none: {
    text: "指定なし",
    value: "none",
    input_type: "text",
  },
  hankaku_only: {
    text: "半角のみ",
    value: "hankaku_only",
    input_type: "tel",
  },
};