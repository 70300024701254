<template>
    <div>
        HomeView
    </div>
</template>
<script>
  export default {
    name: 'HelloWorld',
  }
</script>
