<template>
  <v-container fill-height>
    <v-dialog persistent v-model="endRequestDialog" v-if="endRequestDialog">
      <v-card>
        <v-card-title>
          LINE連携
        </v-card-title>
        <v-card-text v-if="requestStatus">
          連携が正常に完了しました。<br>ページを閉じてください。
        </v-card-text>
        <v-card-text v-else>
          {{ errMsg }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <div style="width: 90%; max-width: 800px;">
        <v-card class="mx-auto mt-5" style="height: 50vh;">
          <v-card-title>
            学校連絡アプリ LINE連携
          </v-card-title>
          <v-card-text>
            <v-row align-content="center">
              <v-col align="center" v-if="loading">
                読み込み中です。しばらくお待ちください。
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>
  
<script>
import axios from "axios";
import liff from "@line/liff";
export default {
  name: 'StaffIdRelation',
  data() {
    return {
      errMsg : "",
      pass: "",
      uid: "",
      loading: false,
      requestStatus: null,
      endRequestDialog: false
    }
  },
  computed: {
  },
  methods: {
    async registerId() {
      const apiUrl = process.env.VUE_APP_BACKEND_ENDPOINT + "/staffIdRelation";
      const body = {
        staff_id: this.staffId,
        pass: this.pass,
        uid: this.uid
      };
      await Promise.all([
        await axios
          .post(apiUrl, body)
          .then(response => {
            console.log('response', response)
            if (response.data.body) {
              if (response.data.body == 200) {
                this.requestStatus = true;

              } else if(response.data.err == 'already-relation') {
                this.requestStatus = false;
                this.errMsg = 'この職員は既にLINE連携済です。\n新しくLINE連携を行うには、一度連携の解除を行ってください。'
              } else {
                this.requestStatus = false;
                this.errMsg = '不明なエラーが発生しました。\n最初からやり直してください。'
              }
            }
            this.endRequestDialog = true;
          })
          .catch(err => {
            console.error(err);
            this.isError = true;
          }),
      ]);
    }
  },
  async created() {
    this.loading = true;
    this.pass = this.$route.query.pass
    this.staffId = this.$route.query.staffId
    
    // ここでLIFF initしてUIDをStoreに保存する
    await Promise.all([
      await liff
        .init({
          liffId: process.env.VUE_APP_STAFF_LOGIN_LIFF_ID,
          // liffId: "1661206203-OWJ1gQG5",//kawamitsu
          // liffId: "1660889742-9MLBMdD8", //開発用
          // liffId: "1660995840-8mNOGkYJ", //stg
          // liffId: "1660889733-x0Z3GvNR", //本番用
          withLoginOnExternalBrowser: true
        })
        .then(async () => {
          if (!liff.isLoggedIn()) {
            liff.login();
          } else {
            const tokenPayload = liff.getDecodedIDToken();
            this.uid = tokenPayload.sub
          }
        }),
    ]);
    // console.log('テスト用ログ', liff)
    // this.uid = "U627667a181f828069b90ff791a0be923"
    await this.registerId();
    this.loading = false;
  }
}

</script>

  