/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import { ITEM_TYPE } from "../../store/modules/formEditor/formEditor.constants";
import Item from "@/model/Form/Item";

export default class TextAreaItem extends Item {

  length;
  default;

  constructor(title, description) {
    super(ITEM_TYPE.PARAGRAPH_TEXT, title, description);
  }

  init() {
    this.length = {
      min: null,
      max: null,
    };
    this.default = null;

    return {
      type: this.type,
      title: this.title,
      description: this.description,
      default: this.default,
      length: this.length,
    };
  }
}
