<template>
    <div>
        <v-form @submit.prevent v-model="isNotValid">
            <v-card class="mx-auto mt-5">
                <v-card-title>
                    初回パスワード設定
                </v-card-title>
                <v-card-subtitle>
                    パスワードを設定してください
                </v-card-subtitle>
                <v-card-text>
                    <v-text-field 
                        v-bind:type="showPassword ? 'text' : 'password'"
                        solo
                        @click:append="showPassword = !showPassword" 
                        prepend-inner-icon="mdi-lock"
                        v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                        placeholder="パスワード"
                        v-model="firstPassword"
                        :rules="[ noPassword, passwordPolicy, samePassword ]"
                    />
                </v-card-text>
                <v-card-text>
                    <v-text-field 
                        v-bind:type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword" 
                        prepend-inner-icon="mdi-lock"
                        solo
                        v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
                        placeholder="もう一度入力して下さい"
                        v-model="secondPassword" 
                        :rules="[ noPassword, passwordPolicy, samePassword ]"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="info" @click="submit" :disabled="!isNotValid">
                        <v-icon left>
                        mdi-login
                        </v-icon>
                        ログイン
                    </v-btn>    
                    <v-spacer></v-spacer>
                </v-card-actions>
                <p class="errMsg" style="color:red" v-if="errMsg != ''">{{ errMsg }}</p>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
    LOGIN_TRUE,
    LOGGED_TRUE,
    CHANGE_PASSWORD
} from "@/store/action-types";
import {
    SET_NEW_PASSWORD,
} from "@/store/mutation-types";
import {
    isLoginCheck
} from '@/mixins/index.js';
import store from "@/store/index";
export default {
    name: 'FirstPasswordSet',
    data() {
        return {
            showPassword: false,
            firstPassword: '',
            secondPassword: '',
            errMsg: '',
            noPassword: v => !!v || "パスワードを入力して下さい",
            passwordPolicy: v => this.isPasswordPolicy(v) || "パスワードは英数字混在8桁以上です",
            samePassword: v => v !== this.auth.password || "同じパスワードを設定することはできません",
            isNotValid:false,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            auth: (state) => state.auth,
        }),
    },
    methods: {
        ...mapActions({
            loginTrue: LOGIN_TRUE,
            loggedTrue: LOGGED_TRUE,
            changePassword: CHANGE_PASSWORD
        }),
        ...mapMutations({
            setNewPassword: SET_NEW_PASSWORD,
        }),
        async sha256(str) {
            // Convert string to ArrayBuffer
            const buff = new Uint8Array([].map.call(str, (c) => c.charCodeAt(0))).buffer;
            // Calculate digest
            const digest = await crypto.subtle.digest('SHA-256', buff);
            // Convert ArrayBuffer to hex string
            // (from: https://stackoverflow.com/a/40031979)
            return [].map.call(new Uint8Array(digest), x => ('00' + x.toString(16)).slice(-2)).join('');
        },
        async submit() {
            this.errMsg ="";
            if(this.firstPassword != this.secondPassword){
                this.errMsg = "パスワードが一致しません。"
                this.secondPassword = "";
                return;
            }
            const newPassword = {
                firstPassword: this.firstPassword,
                secondPassword: this.secondPassword,
            }
            this.setNewPassword(newPassword);
            const isSuccess = await this.changePassword();
            const loginInfoJson = localStorage.getItem('loginInfo');
            const loginInfo = JSON.parse(loginInfoJson);
            const hasedPass = await this.sha256(this.firstPassword)
            if (loginInfo) {
                loginInfo.data.staff.password = hasedPass
            }
            localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
            store.state.loginUser.password = hasedPass

            const isLogin = isSuccess.isLogin;
            if (isLogin) {
                this.errMsg = "";
                this.loginTrue();
                this.loggedTrue();
                isLoginCheck();
                this.$router.push({
                    name: "MainView"
                })
            }
        },
        validLoginBtn() {
            if (
                this.firstPassword == null ||
                this.firstPassword == '' ||
                this.secondPassword == null ||
                this.secondPassword == ''
            ) {
                this.errMsg = ''
                return false
            } else if (this.firstPassword === this.secondPassword) {
                this.errMsg = ''
                return true
            }
            return false
        },
        isPasswordPolicy(password) {
            // 正規表現を使用して英数字混在のチェックを行います
            const regex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
            return regex.test(password);
        },
    },
}

</script>

<style scoped>
.errMsg{
    padding-bottom:2%;
    margin-left:2%;
}
</style>
