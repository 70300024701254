<template>
  <div class="contentsArea">
    <v-toolbar flat>
      <v-row>
        <v-card-title>献立カレンダー</v-card-title>
        <v-spacer></v-spacer>
      </v-row>
    </v-toolbar>

    <v-card elevation="0">
      <v-card-actions>
        <v-btn icon :disabled="isStartOfFiscalYear(monthDisplay)" @click="changeMonthPrev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div>{{ yearMonthDisplayFormatted }}</div>
        <v-btn icon :disabled="isEndOfFiscalYear(monthDisplay)" @click="changeMonthNext">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-sheet height="800">
      <v-calendar
        ref="calendar"
        type="month"
        locale="ja-jp"
        v-model="calendar.value"
        :show-month-on-first="false"
        :start="calendar.start"
        :weekdays="[0, 1, 2, 3, 4, 5, 6]"
        @click:date="openDialogMenuInfo"
      >
        <template #day="{ year, month, day }">
          <template v-if="isIncludedMonthDisp(year, month)">
            <template v-if="getFoodList(year, month, day).length === 0">
              <div class="pt-7 text-center">
                <div class="text-body-2 grey--text text--darken-2">献立なし</div>
              </div>
            </template>
            <div class="px-3">
              <div
                v-for="food in getFoodList(year, month, day)"
                :key="food.school_lunch_food_id"
                class="mb-1"
              >
                <div class="text-body-2 grey--text text--darken-2">{{ food.school_lunch_food_name }}</div>
              </div>
            </div>
          </template>
        </template>
      </v-calendar>
    </v-sheet>

    <!-- 献立情報ダイアログ -->
    <DialogMenuInfo
      v-model="showDialogMenuInfo"
      :year="dialogMenuInfo.year"
      :month="dialogMenuInfo.month"
      :date="dialogMenuInfo.date"
    />
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapState,
  mapActions
} from "vuex";
import {
  FETCH_SCHOOL_LUNCH_FOOD_LIST
} from "@/store/action-types";
import DialogMenuInfo from "./fragments/DialogMenuInfo.vue";
import moment from "moment";

export default Vue.extend({
  components: {
    DialogMenuInfo
  },
  data() {
    return {
      /* カレンダー表示 */
      calendar: {
        value: moment().format('YYYY-MM-DD'),
        start: moment().format('YYYY-MM-DD')
      },

      /* 献立情報ダイアログ */
      showDialogMenuInfo: false,
      dialogMenuInfo: {
        year: "",
        month: "",
        date: ""
      }
    };
  },
  computed: {
    ...mapState({
      fiscalYear: state => state.fiscalYear,
      schoolId: state => state.loginUser.school_id,
      schoolLunchFoodList: state => state.schoolLunchFoodList,
    }),

    /* カレンダー表示 */
    yearDisplay() {
      const dateStr = this.calendar.value;
      return dateStr.split("-")[0];
    },
    monthDisplay() {
      const dateStr = this.calendar.value;
      return dateStr.split("-")[1];
    },
    yearMonthDisplayFormatted() {
      return `${this.yearDisplay}年${parseInt(this.monthDisplay)}月`;
    }
  },
  async created() {
    await this.fetchSchoolLunchFoodList({
      schoolId: this.schoolId,
      fiscalYear: this.fiscalYear,
    });
  },
  methods: {
    ...mapActions({
      fetchSchoolLunchFoodList: FETCH_SCHOOL_LUNCH_FOOD_LIST
    }),

    /* カレンダー表示 */
    isStartOfFiscalYear(monthDisplay) {
      return monthDisplay === "04";
    },
    isEndOfFiscalYear(monthDisplay) {
      return monthDisplay === "03";
    },
    isIncludedMonthDisp(yearNum, monthNum) {
      const year = yearNum.toString();
      const month = monthNum.toString().padStart(2, '0');
      return (
        (year === this.yearDisplay)
        && (month === this.monthDisplay)
      );
    },
    changeMonthPrev() {
      this.$refs.calendar.prev();
    },
    changeMonthNext() {
      this.$refs.calendar.next();
    },
    getFoodList(yearNum, monthNum, dateNum) {
      const year = yearNum.toString();
      const month = monthNum.toString().padStart(2, '0');
      const date = dateNum.toString().padStart(2, '0');
      const filter = this.schoolLunchFoodList.filter(food => (food.year === year) && (food.month === month) && (food.date === date));
      return filter || [];
    },

    /* 献立情報ダイアログ */
    openDialogMenuInfo(dateObj) {
      /* ダイアログのPropsを設定 */
      this.dialogMenuInfo.year = dateObj.year.toString();
      this.dialogMenuInfo.month = dateObj.month.toString().padStart(2, '0');
      this.dialogMenuInfo.date = dateObj.day.toString().padStart(2, '0');
      /* ダイアログを開く */
      this.showDialogMenuInfo = true;
    }
  }
});
</script>