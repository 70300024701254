<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <v-row>
    <v-col cols="3">
      <v-autocomplete 
        v-model="input" 
        outlined
        dense 
        :items="countVaccines"
        :required="true"
        :disabled="true"
        hide-details
      ></v-autocomplete>
    </v-col>
    <v-col class="lebel-count-vaccines pl-4">
      <span>{{ "回目接種" }}</span>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import InputBase from './InputBase.vue';

export default Vue.extend({
  name: 'InputCountVaccines',
  mixins: [InputBase],
  props: {
    mlLibJson:Object,
    isLiffMode: Boolean,
  },
  computed: {
    // 1 ~ 99の数値
    countVaccines() {
      return [...Array(99)].map((_, i) => i + 1);
    }
  },
  data: () => {
    return {
      input: '',
    };
  },
  created() {
    this.itemKey = this.params.itemKey;
    this.input = Number(this.params.default);
  },
  methods: {
    setValue(value) {
      this.input = value;
    },
    getValue() {
      return this.input;
    },
  },
});
</script>

<style scoped>
.lebel-count-vaccines {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
