<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <PageBreak
      :index="index"
      :section="section"
      @chgTemplateParent="chgTemplateParent"
      :duplicateFlg="duplicateFlg"
    />

    <ul :id="`list-items-${section.key}`" class="form-editor-drag">
      <li v-for="(item, index) in section.surveySchema" :key="item.itemKey">
        <Item
          :item="item"
          :sectionKey="section.key"
          :section="cloneSection"
          :initialItem="initialItem[index] || {}"
          :editedItemKey="editedItemKey"
          @updateEditItemKey="updateEditItemKey"
          ref="inputForm"
        />
      </li>
    </ul>

    <!-- <LinkMemberSurveyForms v-if="isMemberForm" :sectionKey="section.key" /> -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import PageBreak from "./PageBreak.vue";
// import LinkMemberSurveyForms from "./LinkMemberSurveyForms.vue";
import Item from "./Item.vue";
import Sortable from "sortablejs";
import { ITEM_TYPE } from "@/store/modules/formEditor/formEditor.constants";
import { INIT_FORM_SCHEMA, ACTION_FETCH_PAYMENT_SERVICE_LIST } from "@/store/action-types";
import { SET_FORM_SCHEMA } from "@/store/mutation-types";
import "@/assets/styles/form-editor.less";

export default Vue.extend({
  props: {
    section: Object,
    index: Number,
    duplicateFlg: Boolean,
  },
  data() {
    return {
      editedItemKey: null,
      initialItem: {},
      cloneSection: {},
    };
  },
  components: { PageBreak, Item },
  created() {
    this.initialItem = cloneDeep(this.section.surveySchema);
    this.cloneSection = cloneDeep(this.section);
    // this.fetchPaymentServiceList();
  },
  computed: {
    ...mapState({
      // isMemberForm(state) {
      //   return (state).formEditor.formType === "member";
      // },
    }),
    formType() {
      return this.$route.params.formType ? this.$route.params.formType : "survey";
    },
  },
  methods: {
    ...mapActions({
      initFormConfig: INIT_FORM_SCHEMA,
      fetchPaymentServiceList: ACTION_FETCH_PAYMENT_SERVICE_LIST,
    }),
    ...mapMutations({
      updateFormSchema: SET_FORM_SCHEMA,
    }),
    moveQuestion(newIndex, oldIndex) {
      //remove old position
      let dragSection = this.section.surveySchema.splice(oldIndex, 1);
      //move to new index
      this.section.surveySchema.splice(newIndex, 0, dragSection[0]);
    },
    async chgTemplateParent(_template) {
      this.initFormConfig({ template: _template, formType: this.formType });
    },
    updateEditItemKey(itemKey) {
      this.editedItemKey = itemKey;
    },
  },
  mounted() {
    let table = document.getElementById(`list-items-${this.section.key}`);
    const _self = this;
    Sortable.create(table, {
      group: "section-group",
      handle: ".item-drag-handle", // Use handle so user can select text
      animation: 150,
      chosenClass: "grey",
      onEnd({ newIndex, oldIndex }) {
        _self.moveQuestion(newIndex, oldIndex);
      },
    });

    {
      // groupheaderList
      this.cloneSection = cloneDeep(this.section);
      this.cloneSection.groupheaderList = {};
      this.cloneSection.surveySchema.forEach((element) => {
        if (element.type === ITEM_TYPE.GROUPHEADER) {
          if (!this.cloneSection.groupheaderList[element.itemKey]) {
            this.cloneSection.groupheaderList[element.itemKey] = element.title;
          }
        }
      });
      this.cloneSection.radioGroupHeaderList = cloneDeep(this.cloneSection.groupheaderList);
    }
  },
});
</script>
