/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

// import { Option } from './SelectPayTypeItem';

export default class ValidateSurveyForm{
  surveyConfig;
  chkErr;
  
  constructor(surveyConfig) {
    this.chkErr = [];
    if(surveyConfig){
      this.surveyConfig = surveyConfig;
    }else{
      throw new Error("surveyConfigが存在しません");
    }
  }

  static async checkItem(surveyConfig) {
    const surveySchemaTypeList =  surveyConfig.surveySchema.map(x => x.type)
    const selectProductsType = surveySchemaTypeList.filter(x => x === 'selectProducts')
    const reservationType = surveySchemaTypeList.filter(x => x === 'reservation')
    const usePayment = surveyConfig.usePayment === 1;
    const selectPayTypeItems = surveyConfig.surveySchema.filter(x => x.type === 'selectPayType');
    
    for(let i=0;i < surveyConfig.surveySchema.length;i++){
      if (!surveyConfig.surveySchema[i].title) {
        return "質問のタイトルを入力してください。";
      } else if (surveyConfig.surveySchema[i].title.length > 128) {
        return "質問のタイトルは128文字以内で入力してください。";
      } else if (surveyConfig.surveySchema[i].description.length > 128) {
        return "質問説明は128文字以内で入力してください。";
      }
    }

    if(!surveyConfig.surveyTitle){
      return "テンプレートのタイトルを入力してください。";
    } else if(surveyConfig.surveyTitle.length > 128) {
      return "テンプレートのタイトルは128文字以内で入力してください。";
    }

    if(surveyConfig.description.length > 128){
      return "テンプレートの説明は128文字以内で入力してください。";
    }

    if (surveyConfig.surveySchema.length === 0) {
      return "質問は１個以上を登録して下さい。";
    }
    if (surveyConfig.surveySchema.length > 100) {
      return "質問は100個以下を登録して下さい。";
    }

    let hasBunrui = false;
    let hasCalendar = false;
    let reservationOptions = [];
    let itemCount = {
      bunrui: 0,
      calendar: 0,
      choicegroupheader: 0,
      sesshuVaccineMaker: 0,
    };
    let sesshuVaccineMakerItem = null;
    let previousVaccineMakerItem = null;
    let hasSelectProducts = false;
    let hasRequiredItemWithoutSelectProducts = false;
    let hasRequiredItemWithoutReservation = false;

    for (const [key, value] of Object.entries(surveyConfig.surveySchema)) {
      console.log(key);
      const item = value;
      if (item.isRequired?.value) {
        if (item.type !== "selectProducts") {
          hasRequiredItemWithoutSelectProducts = true;
        }
        if (!["reservation", "linkbutton"].includes(item.type)) {
          hasRequiredItemWithoutReservation = true;
        }
      }
      switch (item.type) {
        case "reservation":
          hasBunrui = true;
          itemCount.bunrui++;
          let reservationOption = {
            reservationCheckBox: item.reservationCheckBox,
            selectedLargeCategory: item.selectedLargeCategory,
            setLargeCategoryTitle: item.setLargeCategoryTitle,
            setMediumCategoryTitle: item.setMediumCategoryTitle,
            setSmallCategoryTitle: item.setSmallCategoryTitle,
            isProductSelection: item.isProductSelection,
          };
          if (item.reservationSupCheckBox) {
            reservationOption["reservationSupCheckBox"] = item.reservationSupCheckBox;
            reservationOption["selectedMediumCategory"] = item.selectedMediumCategory;
          }
          reservationOptions.push(reservationOption);
          break;
        case "linkbutton":
          hasCalendar = true;
          itemCount.calendar++;
          break;
        case "choicegroupheader":
          itemCount.choicegroupheader++;
          break;

        case "sesshuVaccineMaker":
          itemCount[item.type]++;
          sesshuVaccineMakerItem = item;
          break;

        case "previousVaccineMaker":
          previousVaccineMakerItem = item;
          break;

        case "selectProducts":
          hasSelectProducts = true;
          break;

        default:
          break;
      }
    }

    if (surveyConfig.isAppending && surveyConfig.isAppending.value === true) {
      // Bunrui and Calendar are a set.
      if (hasBunrui) {
        if (!hasCalendar) {
          return "カレンダーボタンを設定してください。 分類とカレンダーボタンはセットで必要です。";
        }
        for (let i = 0; i < reservationOptions.length; i++) {
          const element = reservationOptions[i];
          if(usePayment && !element?.reservationCheckBox) {
            return "「決済機能を利用する」がONの場合は大分類を固定してください。";
          }
          if (element.reservationCheckBox === true) {
            if (!element.selectedLargeCategory) {
              return "大分類固定をチェックしている場合は、大分類を選択してください。";
            }
          }
          if (element.reservationSupCheckBox && element.reservationSupCheckBox === true) {
            if (element.selectedMediumCategory === undefined || element.selectedMediumCategory === null) {
              return "中分類固定をチェックしている場合は、中分類を選択してください。";
            }
          }
          
        }
      } else {
        if (hasCalendar) {
          return "分類を設定してください。 分類とカレンダーボタンはセットで必要です。";
        }
      }

      if (itemCount.bunrui > 1) {
        return "分類は一つの帳票につき、1個しか設定できません。";
      }
      if (itemCount.calendar > 1) {
        return "カレンダーボタンは一つの帳票につき、1個しか設定できません。";
      }
      if (hasSelectProducts) {
        const productItem = surveyConfig.surveySchema.find(s => s.type === 'selectProducts');
        if (!productItem.input) {
          return `${productItem.title}のサービスが選択されていません。`;
        }
      }
    } else { // 更新型
      if (hasBunrui) {
        return "追加型以外、分類を設定しないでください。";
      }
      if (hasCalendar) {
        return "追加型以外、カレンダーボタンを設定しないでください。";
      }
      for (let i = 0; i < surveyConfig.surveySchema.length; i++) {
        const element = surveyConfig.surveySchema[i];
        if (element.isAdminItem !== undefined && element.isAdminItem) {
          return "追加型以外、管理項目を設定しないでください。";
        }
      }
      delete surveyConfig.deliveryMessageSetting;
      if(hasSelectProducts) {
        return  "追加型以外、「商品選択（カレンダー予約なし）」を設定しないでください。"
      }
    }

    // 接種ワクチンのメーカ
    if (sesshuVaccineMakerItem && sesshuVaccineMakerItem.sectionOptions) {
      const errorMessage = this.checkIsErrorVaccineMakerItem(sesshuVaccineMakerItem);
      if (errorMessage) {
        return errorMessage;
      }
    }

    // 前回の接種ワクチンのメーカー
    if (previousVaccineMakerItem && previousVaccineMakerItem.sectionOptions) {
      const errorMessage = this.checkIsErrorVaccineMakerItem(previousVaccineMakerItem);
      if (errorMessage) {
        return errorMessage;
      }
    }

    if(selectProductsType.length > 0 && reservationType.length > 0) {
      return '「商品選択（カレンダー予約なし）」か「分類」+「カレンダーボタン」のどちらかしか設定できません。'
    }

    // if (selectProductsType.length > 1) {
    //   return  '商品選択アイテムは1帳票につき1つのみ選択可能です。'
    // }
    // if(usePayment){
    //   if(!hasBunrui && !hasSelectProducts){
    //     return "「決済機能を利用する」がONの場合、「商品選択（カレンダー予約なし）」または「分類」+「カレンダーボタン」を設定する必要があります。"
    //   }
    //   if (hasSelectProducts && !hasRequiredItemWithoutSelectProducts) {
    //     return "「商品選択（カレンダー予約なし）」以外に1つ以上は必須項目を設定してください。"
    //   }
    //   if ((hasBunrui || hasCalendar) && !hasRequiredItemWithoutReservation) {
    //     return "1つ以上は必須項目を設定してください。"
    //   }
    // } else {
    //   if (hasSelectProducts) {
    //     return "「決済機能を利用する」がOFFの場合、「商品選択（カレンダー予約なし）」は設定できません。"
    //   }
    // }

    const selectPayTypeItemError = this.validatePayTypeItem(
      selectPayTypeItems,
      // usePayment,
      selectProductsType.length > 0,
      reservationType.length > 0
    );
    if (selectPayTypeItemError) {
      return selectPayTypeItemError;
    }

    const underTheGroupHeaderError = ValidateSurveyForm.validateUnderTheGroupHeader(surveyConfig);
    if (underTheGroupHeaderError) {
      return underTheGroupHeaderError;
    }
    return null;
  }

  static checkIsErrorVaccineMakerItem(item) {
    const itemName = item.type === 'previousVaccineMaker' ? '前回の接種ワクチンのメーカー' : '接種ワクチンメーカー';
    const vaccinationIntervalLabel = item.type === 'previousVaccineMaker'
      ? '前回接種翌日からの接種間隔日数'
      : '1回目接種翌日からの接種間隔日数';
    const errorMessage = `${itemName}の「${vaccinationIntervalLabel}」に適切な日数を入力してください。`;

    const length = item.sectionOptions.length;
    for (let i = 0; i < length; i++) {
      const sectionOption = item.sectionOptions[i];
      let value = sectionOption.groupheader.value;
      if (value == "" || value == undefined || isNaN(value)) {
        return errorMessage;
      } else {
        value = parseInt(value);
        if (value < 1 || 999 < value) {
          return errorMessage;
        }

        sectionOption.groupheader.value = value;
      }
    }
    return null;
  }

  static validateUnderTheGroupHeader(surveyConfig) {
    const surveySchema = surveyConfig.surveySchema;
    const firstRadioGroupHeaderIndex = surveySchema.findIndex(item => item.type === 'groupheader');
    if (firstRadioGroupHeaderIndex === -1) {
      return null;
    }

    const errorMessage = (itemType) => `「${itemType}」は「グループヘッダー」より下に設定できません。`;

    // グループヘッダー以下に分類・カレンダーボタン・商品選択が設定されていないかチェック
    const reservationIndex = surveySchema.findIndex(item => item.type === 'reservation');
    if (reservationIndex > firstRadioGroupHeaderIndex) {
      return errorMessage('分類');
    }

    const calendarButtonIndex = surveySchema.findIndex(item => item.type === 'linkbutton');
    if (calendarButtonIndex > firstRadioGroupHeaderIndex) {
      return errorMessage('カレンダーボタン');
    }

    const selectProductsIndex = surveySchema.findIndex(item => item.type === 'selectProducts');
    if (selectProductsIndex > firstRadioGroupHeaderIndex) {
      return errorMessage('商品選択（カレンダー予約なし）');
    }
  }

  static validatePayTypeItem(
    selectPayTypeItems,
    usePayment,
    hasSelectProductsItem,
    hasReservationItem
  ) {
    if (selectPayTypeItems.length === 0) {
      return;
    }
    if (!usePayment) {
      return "「決済機能を利用する」がOFFの場合、「支払方法選択」は設定できません。";
    }
    if (!hasSelectProductsItem && !hasReservationItem) {
      return "「支払方法選択」は「商品選択（カレンダー予約なし）」もしくは「分類」とセットで設定してください。"
    }
    if (selectPayTypeItems.length > 1) {
      return "「支払方法選択」は1帳票につき1つのみ設定可能です。";
    }
    const selectPayTypeItem = selectPayTypeItems[0];
    const hasEmptyOption = selectPayTypeItem.payTypeOptions.some(item => !item.input);
    if (hasEmptyOption) {
      return "「支払方法選択」の選択肢名を入力してください。";
    }
    if (!selectPayTypeItem.default && selectPayTypeItem.isNotEditable) {
      return "「支払方法選択」の初期値を設定してください。";
    }
  }
}


