/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */


export const DEFAULT_LANG = "ja";

export const DEFAULT_LANG_DICTIONARY = {
  [DEFAULT_LANG]: {
    "@[フォームのタイトル]": "無題のフォーム",
    "@[送信確認のタイトル]": "送信確認",
    "@[フォームの送信ボタン]": "送信",
    "@[フォームの閉じるボタン]": "閉じる",
    こんにちはお客様: "こんにちは {name} 様",
    アンケートページ: "{survey}のアンケート",
  },
  en: {
    "@[フォームのタイトル]": "Untitled form",
    "@[送信確認のタイトル]": "Survey confirmation",
    "@[フォームの送信ボタン]": "Submit",
    "@[フォームの閉じるボタン]": "Close",
    こんにちはお客様: "Hello Mr. {name}",
    アンケートページ: "Survey on {survey}",
  },
};

export const DEFAULT_END_OF_SURVEY_MESSAGE = "ありがとうございます。\nご登録を承りました。";

export const INIT_ITEM_ADDITIONAL_PROPS = {
  isIndexable: {
    value: false
  },
  isNotEditable: false,
  isRequiredForUser: {
    value: false,
  },
  isSearchable: {
    value: false
  },
  isAdminItem: false,
  isRequired: {
    value: false,
  },
  isPersonalInfo: {
    value: false
  },
  duplicateKeyCheck: false,
  imageUrl: null
};
export const OVERRIDE_ITEM_PROPS_WHEN_DUPLICATE = {
  // キー設定は初期値でコピーする
  isIndexable: INIT_ITEM_ADDITIONAL_PROPS.isIndexable,
  isSearchable: INIT_ITEM_ADDITIONAL_PROPS.isSearchable,
  duplicateKeyCheck: INIT_ITEM_ADDITIONAL_PROPS.duplicateKeyCheck,
  // 画像アドレスの重複を避けるため、画像Urlは複製しない
  imageUrl: INIT_ITEM_ADDITIONAL_PROPS.imageUrl
}

export const END_OF_SURVEY_MESSAGE_TYPE_LIST = [
  { label: "予約内容を全て送信（管理項目,個人情報含む項目は除く）", value: 1 },
  { label: "インデックスキー＋分類＋予約日時を送信 (個人情報含む項目は除く)", value: 2 },
  { label: "予約内容を送信しない", value: 3 },
]

export const INIT_ITEM = {
  title: "",
  description: "",
  type: "textarea",
  isRequired: {
    value: false,
  },
  length: {
    min: null,
    max: null,
  },
  isAdminItem: false,
};

export const INIT_SECTION = {
  surveyTitle: "",
  description: "",
  endOfSurveyMessage: DEFAULT_END_OF_SURVEY_MESSAGE,
  type: "PAGE_BREAK",
  surveySchema: [],
};

export const ITEM_TYPE = {
  TEXT: "text",
  TEXT_EMAIL: "email",
  TEXT_PHONE: "phone",
  TEXT_NUMBER: "number",
  TEXT_POSTCODE: "postcode",
  TEXT_ADDRESS: "address",
  PARAGRAPH_TEXT: "textarea",
  RADIO: "radio",
  SESSHU_VACCINE_MAKER: "sesshuVaccineMaker",
  CHOICE_GROUPHEADER: "choicegroupheader",
  CHECKBOX: "checkboxes",
  LIST: "dropdown",
  SUGGEST: "suggest",
  LANGUAGE_SUGGEST: "languageSuggest",
  FILES: "files",
  SCALE: "linearScale",
  GRID: "choiceGrid",
  CHECKBOX_GRID: "checkboxGrid",
  DATE: "date",
  DATE_DROPDOWN: "dateDropdown",
  DATE_PICKER_BIRTHDAY: "birthday",
  TIME: "time",
  DATETIME: "datetime",
  GUIDE: "guide",
  GROUPHEADER: "groupheader",
  BUNRUI: "reservation",
  CALENDAR: "calendar",
  LINK: "linkbutton",
  SESSHU_JISSHI_DATE: "sesshuJisshiDate",
  MEMBER_NUMBER: "memberNumber",
  COUNT_VACCINES: "countVaccines",
  PREVIOUS_VACCINE_DATE: "previousVaccineDate",
  PREVIOUS_VACCINE_MAKER: "previousVaccineMaker",
  SELECT_PRODUCTS: 'selectProducts',
  SELECT_PAY_TYPE: 'selectPayType',
};
