<template>
  <v-app>
    <v-main>
      <router-view />
      <app-snackbar />
      <app-dialog />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_IS_SMART_PHONE,
} from "@/store/mutation-types"

export default {
  name: 'App',
  computed: {
    ...mapState({
      isSmartPhone: (state) => state.isSmartPhone,
      loginUser: (state) => state.loginUser,
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
      setIsSmartPhone: SET_IS_SMART_PHONE,
    }),
  },
  created() {
    this.setIsSmartPhone(window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches);
    if (this.isSmartPhone && this.loginUser && this.loginUser.is_driver === 1) { // スマホでログインした際は送迎バスに遷移
      if(this.$route.name != 'StaffIdRelation'){
        this.$router.push({ path: 'BusDriverMenu' });
      }
    }
  },
};
</script>
<style>
.font-weight-bold{
  font-weight: bold!important;
}
.contentsArea {
  max-width: calc(100% - 200px);
  width: 100%;
  margin-left: auto;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
.v-data-table-header > tr > .sortable:hover {
  background-color: rgba(100, 215, 130, 0.1);
}
</style>
