var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-text-field',{staticClass:"not-print-applicant-details-item",attrs:{"counter":_vm.counter,"rules":[
      _vm.validateRequired,
      _vm.validateLength,
      _vm.validateType,
      _vm.validateImeInput,
      _vm.validateFormat,
    ],"required":_vm.required,"maxlength":_vm.maxLength,"disabled":_vm.disabled,"type":_vm.input_type},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),_c('span',{staticClass:"print-applicant-details-item"},[_vm._v(" "+_vm._s(_vm.input)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }