var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"checkbox-container"},[_vm._l((_vm.options),function(option,index){return [(option != 'その他')?[_c('v-checkbox',{key:option,attrs:{"label":option,"value":option,"rules":[
          _vm.validateRequired,
          _vm.validateMinSelection,
          _vm.validateMaxSelection,
        ],"disabled":_vm.isDisabled(option) || _vm.disabled,"hide-details":""},model:{value:(_vm.checkboxes),callback:function ($$v) {_vm.checkboxes=$$v},expression:"checkboxes"}})]:[_c('v-row',{key:index,staticClass:"other-checkbox",staticStyle:{"margin-left":"0px"}},[_c('v-checkbox',{attrs:{"value":_vm.otherTextValue,"rules":[
            _vm.validateRequired,
            _vm.validateMinSelection,
            _vm.validateMaxSelection,
          ],"disabled":_vm.isDisabled(option) || _vm.disabled,"hide-details":""},model:{value:(_vm.checkboxes),callback:function ($$v) {_vm.checkboxes=$$v},expression:"checkboxes"}}),_c('v-text-field',{attrs:{"placeholder":option},on:{"input":_vm.handleChange},model:{value:(_vm.otherTextValue),callback:function ($$v) {_vm.otherTextValue=$$v},expression:"otherTextValue"}})],1)]]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }