<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-text-field
      v-if="params.isAutomaticNumbering"
      v-model="input"
      :disabled="input_disabled && !isConfirmSurveyMode"
      :append-outer-icon="input_icon"
    ></v-text-field>

    <div v-else>
      <v-text-field
        v-model="shudou_value"
        clearable
        :counter="input_counter"
        :maxlength="input_maxLength"
        :rules="[
          validateRequired,
          validateLength,
          validateAllowedCharacters,
        ]"
        :max="input_max_number"
        :append-outer-icon="input_icon"
        @blur="handleCheckSaibanExistingShudou"
      ></v-text-field>

      <div v-if="input" :class="input_check_existing_color">
        {{ input }}
        <v-icon small class="mx-1" :color="input_check_existing_color">{{
          shudou_valid_flag_icon
        }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import InputBase from "./InputBase.vue";
import { isBlank } from "../utils/validationUtils";

export default Vue.extend({
  name: "InputNumbering",
  mixins: [InputBase],
  props: {
    mlLibJson:Object,
    configJson: Object,
    isLiffMode: {
      type: Boolean,
      default: true,
      required: true,
    },
    isSurveyRegistering: {
      type: Boolean,
      default: true,
      required: false,
    },
    isSaibanExisted: {
      type: Boolean,
      default: true,
      required: false,
    },
    isConfirmSurveyMode: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  watch: {
    shudou_value(input_value) {
      if (input_value) {
        this.value =
          this.input_memberSurveySubId + ("" + input_value).padStart(7, "0");
      } else {
        this.value = "";
      }
    },
    is_valid_input(valid) {
      if (valid) {
        this.shudou_valid_flag_icon = "mdi-check-bold";
      } else {
        this.shudou_valid_flag_icon = "mdi-close-thick";
      }
    },
  },

  computed: {
    input: {
      get() {
        let value = this.value;
        if (this.params.isAutomaticNumbering) {
          value = this.isConfirmSurveyMode && this.value? this.value :
            this.isConfirmSurveyMode? '' : this.isSurveyRegistering ? "自動採番" : this.value;
        }
        return value;
      },
      set(value) {
        return (this.value = value);
      },
    },

    input_type: {
      get() {
        return this.params.isAutomaticNumbering ? "text" : "number";
      },
    },
    input_icon: {
      get() {
        return this.params.isAutomaticNumbering
          ? "mdi-auto-fix"
          : "mdi-database-search";
      },
    },
    //入力文字数表示
    input_counter: {
      get() {
        return this.input_maxLength;
      },
    },

    input_maxLength: {
      get() {
        let max_length = 11;
        if (!this.params.isAutomaticNumbering) {
          max_length = 7;
        }
        return max_length;
      },
    },
    input_max_number: {
      get() {
        let limitation = 99999999999; // 11桁数
        if (!this.params.isAutomaticNumbering) {
          limitation = 9999999; // 7桁数
        }
        return limitation;
      },
    },
    input_disabled: {
      get() {
        let parent_disabled = this.$parent.disabled;

        if (parent_disabled || this.params.isAutomaticNumbering) {
          return true;
        }

        return !this.isSurveyRegistering;
      },
    },
    input_memberSurveySubId: {
      get() {
        let value = ("" + (this.configJson.memberSurveySubId ?? "")).padStart(
          4,
          "0"
        );
        return value;
      },
    },
    input_check_existing_color: {
      get() {
        return this.is_valid_input ? "success--text" : "red--text";
      },
    },

    input_button_check_existing_color: {
      get() {
        let value = "primary";
        if (!this.isSurveyRegistering) {
          value = "grey lighten-4 grey--text";
        }
        return value;
      },
    },

    is_valid_input: {
      get() {
        return !this.isSaibanExisted && this.valid_input;
      },
    },
  },
  data() {
    return {
      value: "",
      shudou_value: "",
      shudou_valid_flag_icon: "mdi-close-thick",
      shudou: {
        original_value: "",
        isAddedNew: true,
      },
      valid_input: false
    };
  },
  created() {
    this.itemKey = this.params.itemKey;

    // 省略されたパラメータを補完する
    let formParams = Object.assign(
      {
        isRequired: { value: false },
        input: "",
      },
      this.params
    );

    this.itemKey = this.params.itemKey;
    this.options = this.params.options;
    this.required = this.params.isRequired.value;

    if (this.params.isAutomaticNumbering) {
      this.input = formParams.input;
    } else {
      this.shudou.original_value = formParams.input;
      this.shudou.isAddedNew = !formParams.input;
      this.setShudouValueBySaibanFull(formParams.input);
    }
  },
  methods: {
    setValue(value) {
      (this).input = value;
    },
    getValue() {
      return (this).input;
    },

    setShudouValueBySaibanFull(saibanFull) {
      if (!saibanFull || typeof saibanFull !== "string") {
        saibanFull = "";
      }
      let saibanString = saibanFull.substring(4);
      let saibanNumber = saibanString.replace(/^0+/, "");
      (this).shudou_value = saibanNumber;
    },

    validateRequired(input) {
      return !(this).required || !isBlank(input) || "必須項目";
    },
    validateLength(input) {
      (this).valid_input = true;

      if (!input) {
        return true;
      }

      let validMax = (this).input_maxLength;

      if (validMax < input.length) {
        (this).valid_input = false;
        return validMax + "桁英数字以下で入力してください";
      }

      return true;
    },

    validateAllowedCharacters(input) {
      (this).valid_input = true;

      if (input === "") {
        return true;
      }

      let isValid = /^[0-9a-zA-Z]+$/.test(input);

      if (isValid) {
        return true;
      } else {
        (this).valid_input = false;
        return "半角の英数字を記入してください。";
      }
    },

    handleCheckSaibanExisting() {
      this.$emit("handleCheckSaibanExisting", (this).input);
    },

    handleCheckSaibanExistingShudou() {
      if ((this).shudou.isAddedNew) {
        this.$emit("handleCheckSaibanExisting", (this).input);
      } else {
        let payload = {
          saibanString: (this).input,
          exceptSaibanString: (this).shudou.original_value,
        };
        this.$emit("handleCheckSaibanExistingForUpdate", payload);
      }
    },
  },
});
</script>
