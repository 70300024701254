<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-textarea
      class="not-print-applicant-details-item custom-textarea"
      v-model="input"
      :counter="counter"
      :rules="[
        validateRequired,
        validateLength,
        validateType,
        validateFormat,
      ]"
      :required="required"
      :maxlength="maxLength"
      :disabled="disabled"
    ></v-textarea>
    <span style="white-space: pre-line" class="print-applicant-details-item">
      {{ input }}
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import InputShortAnswer from "./InputShortAnswer.vue";

export default Vue.extend({
  name: "InputParagraph",
  mixins: [InputShortAnswer],
});
</script>

<style scoped>
.custom-textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}
</style>