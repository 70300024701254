import moment from 'moment'
import store from "../store/index";

export const isLoginCheck = () => {
  // sessionからログイン状態を取得
  const loginInfoJson = localStorage.getItem('loginInfo');
  const loginInfo = JSON.parse(loginInfoJson);
  // console.log("loginInfo", loginInfo);
  if (loginInfo) {
    // sessionからログインの有効期限を取得
    const expirationTime = moment(loginInfo.expirationTime);
    // 現在時刻(utc + 9h)
    const time = moment().utc().add(9, 'h')

    // console.log("expirationTime", expirationTime);
    // 有効期限のチェック
    if (time.isAfter(expirationTime) && (loginInfo.data.staff.authority !== 5)) { // 運転手権限の職員は自動ログアウトさせない
      // 過去日付の場合はログイン画面に遷移させる
      store.state.isLogin = false;
      store.state.isLogged = false;
      store.state.loginUser = null;
      alert('長時間操作されていないためログアウトしました。')
      return false
    }
    // ログイン状態のチェック
    if (!loginInfo.isLogin) {
      store.state.isLogin = false;
      store.state.isLogged = false;
      store.state.loginUser = null;
      return false
    }
    const loginCheckResult = loginInfo.data
    if (loginCheckResult.statusCode != 200) {
      store.state.isLogin = false;
      store.state.isLogged = false;
      store.state.loginUser = null;
      return false
    } else {
      // store.state直書き換えやばい
      store.state.loginUser = (loginCheckResult.staff)
      const isLogin = loginCheckResult.isLogin;
      const isLogged = loginCheckResult.isLogged;
      if (isLogin) {
        if (isLogged) {
          // store.state直書き換えやばい
          store.state.isLogin = true;
          store.state.isLogged = true;
        }
      } else {
        store.state.isLogin = false;
        store.state.isLogged = false;
        store.state.loginUser = null;
        return false
      }
    }
    // 学校についての各種ステータスをsessionから取得
    const needCreateSchool = JSON.parse(localStorage.getItem('needCreateSchool'));
    const needCreateStaff = JSON.parse(localStorage.getItem('needCreateStaff'));
    const needCreateStudent = JSON.parse(localStorage.getItem('needCreateStudent'));
    // store.state直書き換えやばい
    store.state.setup.needCreateSchool = needCreateSchool;
    store.state.setup.needCreateStaff = needCreateStaff;
    store.state.setup.needCreateStudent = needCreateStudent;

    //現在時刻(utc + 9h)に10分加算したものをセッション有効期限として保存
    const newExpirationTime = moment().utc().add(9, 'h').add(30, 'm');

    loginInfo.expirationTime = newExpirationTime;

    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));

    return true
  } else {
    store.state.isLogin = false;
    store.state.isLogged = false;
    store.state.loginUser = null;
    // sessionが存在しない場合はログイン画面に遷移する
    return false
  }
}

export const base64ToBlob = (base64Data)  => {
  const byteCharacters = atob(base64Data.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: "image/png" }); // MIMEタイプを適切に設定してください
}

export const Mixin = {
  methods: {
    isLoginCheck,
    base64ToBlob,
  },
};