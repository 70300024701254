<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <v-dialog v-model="show" max-width="500" eager>
    <v-system-bar color="primary" dark height="5"> </v-system-bar>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Reorder sections</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <ul ref="moveSectionsDrag" class="form-editor-drag">
        <li v-for="(section, index) in reorderSections" :key="section.key">
          <v-hover v-slot:default="{ hover }">
            <v-alert class="py-0 my-0" :color="hover ? 'primary' : 'white'" dense colored-border border="left">
              <v-row align="center" no-gutters>
                <v-col class="shrink item-drag-handle">
                  <v-icon>mdi-drag</v-icon>
                </v-col>
                <v-col>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-truncate" style="max-width: 300px">{{
                        section.title || "Untitled Section"
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        >Section {{ index + 1 }} of {{ reorderSections.length }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="shrink">
                  <v-btn icon large :disabled="index === 0" @click="moveUp(index)">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="shrink">
                  <v-btn icon large :disabled="index + 1 === reorderSections.length" @click="moveDown(index)">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-hover>

          <v-divider></v-divider>
        </li>
      </ul>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="px-8" elevation="4" @click="onSaveReorderSections">
            <v-icon left>mdi-content-save-outline</v-icon>
            保存
          </v-btn>
          <v-btn color="grey lighten-2" class="ml-3" elevation="4" @click="show = false">
            <v-icon left>mdi-content-save-outline</v-icon>
            キャンセル
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import Sortable from "sortablejs";
import { cloneDeep } from "lodash";
import { SET_FORM_SCHEMA } from "@/store/mutation-types";

// interface DataType {
//   detailDataModal: any;
//   reorderSections: any;
// }

// interface MethodsType {
//   moveUp(idx);
//   moveDown(idx);
//   moveSection(newIndex, oldIndex);
//   setupDrag();
//   onSaveReorderSections();
// }

// interface ComputedTypes {}

// interface PropsType {}

export default Vue.extend({
  props: {
    visible: Boolean,
    close: Function,
    sections: Array,
  },
  data() {
    return {
      detailDataModal: this.detailData,
      reorderSections: cloneDeep(this.sections),
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      updateFormSchema: SET_FORM_SCHEMA,
    }),
    moveUp(idx) {
      this.moveSection(idx - 1, idx);
    },
    moveDown(idx) {
      this.moveSection(idx + 1, idx);
    },
    moveSection(newIndex, oldIndex) {
      //remove old position
      let dragSection = this.reorderSections.splice(oldIndex, 1);
      //move to new index
      this.reorderSections.splice(newIndex, 0, dragSection[0]);
    },
    setupDrag() {
      let list = this.$refs.moveSectionsDrag;
      if (list) {
        Sortable.create(list, {
          handle: ".item-drag-handle", // Use handle so user can select text
          animation: 150,
          chosenClass: "grey",
          onEnd({ newIndex, oldIndex }) {
            this.moveSection(newIndex, oldIndex);
          },
        });
      }
    },
    onSaveReorderSections() {
      this.updateFormSchema(this.reorderSections);
      this.show = false;
    },
  },
  watch: {
    section(val) {
      this.reorderSections = cloneDeep(val);
    },
  },
  mounted() {
    this.setupDrag();
  },
});
</script>
