/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

// import { API } from "aws-amplify";
// import { ApiName, PlatformAdminApi, Prefix } from "./amplify.service";
// const GET_SURVEY_LIST = "/survey";
// const GET_ALL_SURVEY_CONFIGS = "/v1/survey";
// const UPDATE_SURVEY_CONFIG = "/v1/survey";
import axios from "axios";
const apiEndPoint = process.env.VUE_APP_BACKEND_ENDPOINT;

export const GetSurveyList = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/get-list";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

// export const GetAndStockSurveyList = async (download) => {
//   const surveyConfigs = [];
//   let response = {};
//   let lastEvaluatedKey = null;
//   do {
//     response = await GetSurveyList({
//       download,
//       lastEvaluatedKey,
//     });
//     if (response && response.data) {
//       lastEvaluatedKey = response.lastEvaluatedKey;
//       surveyConfigs.push(...response.data);
//     }
//   } while (response.lastEvaluatedKey);
//   return surveyConfigs;
// };

// export const GetAllFormConfigs = async () => {
//   let result, lastEvaluatedKey;
//   do {
//     const params = lastEvaluatedKey
//       ? { queryStringParameters: { lastEvaluatedKey: JSON.stringify(lastEvaluatedKey) } }
//       : {};
//     const res = await API.get(PlatformAdminApi, GET_ALL_SURVEY_CONFIGS, params);
//     lastEvaluatedKey = res.lastEvaluatedKey;
//     if (result && res.data) {
//       result.data = result.data ? result.data.concat(res.data) : res.data;
//     } else {
//       result = res;
//     }
//   } while (lastEvaluatedKey);
//   delete result.lastEvaluatedKey;
//   return result;
// };

export const GetFormConfigById = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/get";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

export const RegisterSurveyConfig = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/put";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

export const UpdateSurveyConfig = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/put";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

export const RegisterSurveyConfigs = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/put-list";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

export const DeleteSurveyConfigs = async (payload) => {
  const apiUrl = apiEndPoint + "/enquete/template/delete";
  const body = {
    body: payload,
  };
  const res = await axios
    .post(apiUrl, body)
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err);
    });
  return res;
};

// export const GetCategoriesTrees = async () => {
//   return await API.get(PlatformAdminApi, "/" + Prefix + "/calendars/categories_tree?include_display_settings", {});
// };