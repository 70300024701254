<template>
  <div class="contentsArea">
    <template>
      <v-toolbar style="height: 100px;" flat class="titleBar">
        <v-row>
          <v-card-title>カレンダー管理</v-card-title>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="false" :loading="loading" color="primary" @click="reload">
              <v-icon left>mdi-reload</v-icon>
              データ更新
            </v-btn>
            <v-btn :loading="loading" color="green" dark @click="addItem(), dialogMode = 'create'">新規イベント追加</v-btn>
          </v-card-actions>
        </v-row>
      </v-toolbar>
    </template>

    <CalendarMonth @loadingFunc="loadingFunc" @changeCalenderMon="changeCalenderMon" @clickedEvent="clickedEvent" @clickDate="dateToScheduleListChange" @clickMore="dateToScheduleListChange"></CalendarMonth>
    <!-- イベントの編集または新規作成の際に開くダイアログ -->
    <v-dialog v-if="addItemDialog" v-model="addItemDialog" persistent max-width="600px">
      <v-card>
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-card-title>{{ addItemDialogTitle() }}</v-card-title>
          </v-col>
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue" outlined :loading="loading" @click="addItemDialog = false">閉じる</v-btn>
              <v-btn v-if="dialogMode == 'edit'" color="red" class="white--text" :loading="loading" @click="deleteItemDialog = true">削除</v-btn>
              <v-btn :disabled="!isValid()" color="primary" class="white--text" :loading="loading" @click="confirmDialog = true">保存</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-card-text>       
          <v-row>
            <v-col cols="3" class="pb-0" style="display: inherit; align-items: center;">
              イベント名
            </v-col>
            <v-col class="pb-0" style="display: inherit; align-items: center;">
              <v-text-field counter="15" class="scheduleNameArea" v-model="createItem.name" :rules="[required('イベント名'), max_length_15]"></v-text-field>
              <v-btn v-if="false" :color="hex" dark fab small elevation="0" style="margin-bottom:4px; border: 1px solid black;" class="ml-1" @click="colorPickerDialog = true"></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="pb-0" style="display: inherit; align-items: center;">
              開始日
            </v-col>
            <v-col class="pl-0" style="display: inherit; align-items: center;">
              <v-container class="pt-0 pb-0">
                <v-menu v-model="fromStartDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field readonly outlined dense :value="createItemStartDateDisplay"
                      prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker @change="changecreateItemStartDate" no-title color="primary" locale="jp-ja" v-if="fromStartDateMenu"
                    :day-format="(date) => new Date(date).getDate()" :min="formatToYYYYMMDD(currentDate)">
                  </v-date-picker>
                </v-menu>
              </v-container>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="3" class="pb-0" style="display: inherit; align-items: center;">
              終了日
            </v-col>
            <v-col class="pl-0" style="display: inherit; align-items: center;">
              <v-container class="pt-0 pb-0">
                <v-menu v-model="fromEndDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field readonly outlined dense :value="createItemEndDateDisplay"
                      prepend-inner-icon="mdi-calendar-outline"
                      placeholder="YYYY-MM-DD" hide-details v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker @change="changecreateItemEndDate" no-title color="primary" locale="jp-ja" v-if="fromEndDateMenu"
                    :day-format="(date) => new Date(date).getDate()"  :min="formatToYYYYMMDD((createItem.start))">
                  </v-date-picker>
                </v-menu>
              </v-container>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-if="!kaisibiIsSameOrBeforeSyuryobi()">
            <v-col cols="3" class="pb-0" style="display: inherit; align-items: center;"></v-col>
            <v-col class="pl-3" style="color:red;display: inherit; align-items: center;">
              開始日は終了日より前に設定してください
            </v-col>
          </v-row>

          <v-row class="pl-0 pr-0 pt-3" no-gutters dense>
            <v-col class="pl-0 pr-0" :cols="fieldsCols">
              <v-subheader class="pl-0 pr-0">対象</v-subheader>
            </v-col>
            <v-col>
              <v-radio-group mandatory v-model="selectedDivision" row class="mt-2 radioFlex">
                <v-radio value="SCHOOL" v-if="loginUser.authority == 1 || loginUser.authority == 2">
                  <template v-slot:label><v-card-text class="pa-0">全て</v-card-text></template>
                </v-radio>
                <v-radio value="GRADE" v-if="loginUser.authority == 1 || loginUser.authority == 2 || loginUser.authority == 3">
                  <template v-slot:label><v-card-text class="pa-0">学年</v-card-text></template>
                </v-radio>
                <v-radio value="CLASS">
                  <template v-slot:label><v-card-text class="pa-0">組</v-card-text></template>
                </v-radio>
                <v-radio value="PERSONAL">
                  <template v-slot:label><v-card-text class="pa-0">生徒</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 

          <v-row class="pl-0 pr-0" no-gutters dense>
            <v-col class="pl-0 pr-0" :cols="fieldsCols">
              <v-subheader class="pl-0 pr-0">学年</v-subheader>
            </v-col>
            <v-col>
              <v-radio-group :disabled="selectedDivision == 'SCHOOL'" mandatory v-model="selectedGrade" row class="mt-2 radioFlex">
                <v-radio v-for="(grade, index) in gradeClassAll?.grade" :key="index" :label="grade.school_grade"
                  :value="grade.grade_id">
                  <template v-slot:label><v-card-text class="pa-0">{{ grade.school_grade }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="pl-0 pr-0" no-gutters dense>
            <v-col class="pl-0 pr-0" :cols="fieldsCols">
              <v-subheader class="pl-0 pr-0">組</v-subheader>
            </v-col>
            <v-col>
              <v-radio-group :disabled="selectedDivision == 'SCHOOL' || selectedDivision == 'GRADE'" mandatory v-model="selectedClass" row hide-details="auto" class="mt-2 radioFlex">
                <v-radio v-for="(classroom, idx) in classroomItems" :key="idx" :label="classroom.school_class"
                  :value="classroom.classroom_id">
                  <template v-slot:label><v-card-text class="pa-0">{{ classroom.school_class }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="pl-0 pr-0" no-gutters dense>
            <v-col class="pl-0 pr-0" :cols="fieldsCols">
              <v-subheader class="pl-0 pr-0">生徒</v-subheader>
            </v-col>
            <v-col>
              <div class="d-flex">
                <v-select v-model="createItem.target_student" :disabled="selectedDivision != 'PERSONAL'" :items="selectedClassStudent" :item-text="item => (item.number + '番 : ' + item.last_name + item.first_name)" item-value="student_id"></v-select>
              </div>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="colorPickerDialog" v-model="colorPickerDialog" width="400px">
      <v-card>
        <v-container class="pb-0">
          <v-row>
            <v-col class="d-flex justify-center">
              <v-color-picker v-model="color"></v-color-picker>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="colorReset()" color="blue" outlined>デフォルトに戻す</v-btn>
          <v-btn @click="colorPickerDialog=false" class="white--text" color="#00E676">保存</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" v-if="confirmDialog" width="300px">
      <v-card>
        <v-card-text class="pt-5 pb-0">
          {{ confirmDialogText() }}
        </v-card-text>
        <v-card-text>
          よろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined :loading="loading" @click="confirmDialog = false">閉じる</v-btn>
          <v-btn color="primary" class="white--text" :loading="loading" @click="dialogMode == 'create' ? saveItem() : updateItem()">保存</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteItemDialog" v-if="deleteItemDialog" width="300px">
      <v-card>
        <v-card-text class="pt-5 pb-0">
          以下のイベントを削除します。
        </v-card-text>
        <v-card-text>
          本当によろしいですか？
        </v-card-text>
        <v-card-text>
          イベント名：{{ createItem.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined :loading="loading" @click="deleteItemDialog = false">閉じる</v-btn>
          <v-btn color="red" class="white--text" :loading="loading" @click="deleteItem()">削除</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="scheduleListDateDialog" v-if="scheduleListDateDialog" width="800px">
      <v-card style="min-height: 70vh;  display: flex;flex-direction: column;">
        <div style="display: flex; align-items: center;">
          <v-btn icon @click="subtractDateToScheduleList()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
            <v-card-title style="text-align: center;">{{ formatToMMDD(dateToScheduleList) }}のイベント情報</v-card-title>
          <v-spacer></v-spacer>
          

          <v-btn icon @click="addDateToScheduleList()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>

        </div>
        
        <template>
          <v-data-table
            :headers="headers"
            :items="scheduleListByDate"
            :items-per-page="10"
          >
            <template v-slot:[`item.start`]="{ item }">
              <div>
                {{ item.start ? formatToYYYYMMDD(item.start) : "ーー" }}
              </div>
            </template>
            <template v-slot:[`item.end`]="{ item }">
              <div>
                {{ item.end ? formatToYYYYMMDD(item.end) : "ーー" }}
              </div>
            </template>
            <template v-slot:[`item.editBtn`]="{ item }">
              <div>
                <v-btn color="primary" @click="clickedEvent(item)">編集</v-btn>
              </div>
            </template>
          </v-data-table>
        </template>
        <v-card-actions style="margin-top: auto;">
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined :loading="loading" @click="scheduleListDateDialog = false">閉じる</v-btn>
          <v-btn color="primary" outlined :loading="loading" @click="addItem(), dialogMode = 'create'">イベント追加</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" multi-line>
      {{ snackbarText }}ページを再読み込みしてください。
    </v-snackbar>
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CalendarMonth from '@/components/CalendarManage/CalendarComponents/CalendarMonth.vue';
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_SCHEDULE_ITEMS,
  SET_SELECTED_CLASSROOM
} from "@/store/mutation-types";
import {
  GET_SCHEDULE_ITEMS,
  DELETE_SCHEDULE_ITEMS,
  CREATE_SCHEDULE_ITEMS,
  UPDATE_SCHEDULE_ITEMS,
  GET_GRADE_CLASS_ALL,
  GET_STUDENT_BY_CLASSROOM_ID
} from "@/store/action-types";
import {
  MAX_LENGTH_15_ERROR_MSG,
  REQUIRED_ERROR_MSG
} from "@/constants";
export default {
  name: "CalendarManage",
  data: () => ({
    max_length_15: value => value.length <= 15 || MAX_LENGTH_15_ERROR_MSG,
    currentDate: moment(),
    selectedDivision: null,
    selectedStudent: null,
    selectedGrade: null,
    selectedClass: null,
    classroomItems: null,
    dateToScheduleList:moment(),
    headers: [
      {
        text: 'イベント名',
        sortable: false,
        value: 'name',
      },
      { 
        text: '開始日',
        sortable: false,
        value: 'start' 
      },
      { 
        text: '終了日',
        sortable: false, 
        value: 'end' 
      },
      {
        text: '編集',
        sortable: false,
        value: 'editBtn'
      }
    ],
    fromStartDateMenu: false,
    fromEndDateMenu: false,
    type: 'hex',
    hex: '#00E676',
    loading: false,
    snackbar: false,
    snackbarText: '',
    addItemDialog: false,
    deleteItemDialog: false,
    colorPickerDialog:false,
    scheduleListDateDialog: false,
    confirmDialog: false,
    scheduleListByDate: [],
    fieldsCols: 3,
    createItem: {
      color : '',
      end : moment().toDate(),
      start : moment().toDate(),
      name : '',
      timed : false,
      target_grade: null,
      target_class: null,
      target_student: null,
      target_division: null,
    },
    defaultItem: {
      color : '',
      end : moment().toDate(),
      start : moment().toDate(),
      name : '',
      timed : false,
      target_grade: null,
      target_class: null,
      target_student: null,
      target_division: null,
    },
    // 新規作成か編集かを表す 基本はeditで、
    // 新規schedule追加・イベント追加が押されたときのみcreateになり
    // dialogが閉じるときにeditに戻る
    dialogMode: 'edit'
  }),
  components: {
    CalendarMonth,
  },
  computed: {
    ...mapState({
      scheduleItems: state => state.scheduleItems,
      fiscalYear: state => state.fiscalYear,
      gradeClassAll: state => state.gradeClassAll,
      selectedClassStudent: state => state.selectedClassStudent,
      loginUser: state => state.loginUser,
    }),
    createItemEndDateDisplay() {
      return moment(this.createItem.end).format('YYYY-MM-DD')
    },
    createItemStartDateDisplay() {
      return moment(this.createItem.start).format('YYYY-MM-DD')
    },
    createItemStartTimeDisplay() {
      return moment(this.createItem.start).format('HH:mm')
    },
    createItemEndTimeDisplay() {
      return moment(this.createItem.end).format('HH:mm')
    },
    color: {
      get () {
        return this[this.type]
      },
      set (v) {
        this[this.type] = v
      },
    },
  },
  watch: {
    // 'createItem.start': {
    //   handler() {
    //     if(moment(this.createItem.start).isBefore(this.currentDate)){
    //       this.createItem.start = moment().toDate
    //     }
    //   },
    //   deep: true
    // },
    // 'createItem.end': {
    //   handler() {
    //     if(moment(this.createItem.end).isBefore(this.currentDate)){
    //       this.createItem.end = moment().toDate
    //     }
    //   },
    //   deep: true
    // },
    // 'defaultItem.start': {
    //   handler() {
    //     if(moment(this.defaultItem.start).isBefore(this.currentDate)){
    //       this.defaultItem.start = moment().toDate
    //     }
    //   },
    //   deep: true
    // },
    // 'defaultItem.end': {
    //   handler() {
    //     if(moment(this.defaultItem.end).isBefore(this.currentDate)){
    //       this.defaultItem.end = moment().toDate
    //     }
    //   },
    //   deep: true
    // },
    selectedDivision(v) {
      this.createItem.target_division = v
      this.changeStudentItems();
    },
    selectedClass(v) {
      this.createItem.target_class = v
      this.changeStudentItems();
    },
    selectedGrade(v) {
      this.createItem.target_grade = v
      this.classroomItems = this.gradeClassAll['class'].filter(a => a["grade_id"] === this.selectedGrade)
      this.changeStudentItems();
    },

    async fiscalYear() {
      this.loading = true;
      await this.getScheduleItems();
      this.loading = false;
    },
    addItemDialog(v) {
      if(!v){
        this.colorReset();
        this.selectedClass = null
        this.selectedGrade = null
        this.selectedDivision = null
        this.createItem = { ...this.defaultItem };
        this.dialogMode = 'edit'
      }
    },
    dateToScheduleList(date) {
      // ○月○日のイベント一覧ダイアログで使う日付
      // ここが変わったらダイアログに表示するscheduleリストを更新する
      this.scheduleListByDate = [];
      // isSameだと開始日と終了日のイベントしか取れない
      // 例：1月1日から1月3日までのイベントがあって、1月2日を指定してもイベントが取れない
      // 条件を見直す
      console.log(date)
      this.scheduleListByDate = this.scheduleItems.filter(item => moment(item.start).isSameOrBefore(date, "day") && moment(item.end).isSameOrAfter(date, "day"));
    },

  },
  async created() {
    this.loading = true;
    await this.getScheduleItems()
    await this.getGradeClassAll();
    this.loading = false;
  },
  methods: {
    loadingFunc(bool) {
      this.loading = bool
    },
    ...mapActions({
      getScheduleItems: GET_SCHEDULE_ITEMS,
      deleteScheduleItems: DELETE_SCHEDULE_ITEMS,
      createScheduleItems: CREATE_SCHEDULE_ITEMS,
      updateScheduleItems: UPDATE_SCHEDULE_ITEMS,
      getGradeClassAll: GET_GRADE_CLASS_ALL,
      getStudentByClassId: GET_STUDENT_BY_CLASSROOM_ID,
    }),
    ...mapMutations({
      setScheduleItems: SET_SCHEDULE_ITEMS,
      setSelectedClassroom: SET_SELECTED_CLASSROOM,
    }),
    required(str) {
      return value => !!value && !this.isSpace(value) || str + REQUIRED_ERROR_MSG;
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    kaisibiIsSameOrBeforeSyuryobi() {
      return moment(this.createItem.start).isSameOrBefore(moment(this.createItem.end))
    },
    changeCalenderMon(date) {
      const newDate = moment(String(date.year) + '-' + String(date.month) + '-' + String(date.day))
      if(newDate.isBefore(moment())){
        this.createItem.start = moment().toDate()
        this.createItem.end = moment().toDate()
        this.defaultItem.start = moment().toDate()
        this.defaultItem.end = moment().toDate()
      } else {
        this.createItem.start = newDate.toDate()
        this.createItem.end = newDate.toDate()
        this.defaultItem.start = newDate.toDate()
        this.defaultItem.end = newDate.toDate()
      }
    },
    isValid() {
      const name = this.createItem?.name
      const target_grade = this.createItem?.target_grade
      const target_class = this.createItem?.target_class
      const target_student = this.createItem?.target_student
      const target_division = this.createItem?.target_division
      const start = this.createItem?.start
      const end = this.createItem?.end
      
      let errFlag = 0
      // 今特に使ってない
      // この関数の中でdataを書き換えるのはNGなので気を付ける
      let errMsgList = []
      if(name == '' || name == null || name == undefined) {
        errFlag = 1
        errMsgList.push('イベント名は必須です。')
      }
      if(target_division != 'SCHOOL' && target_division != 'GRADE' && target_division != 'CLASS' && target_division != 'PERSONAL') {
        errFlag = 1
        errMsgList.push('イベントの対象の値が不正です。')
      }
      if(target_division != 'SCHOOL' && (target_grade == '' || target_grade == null || target_grade == undefined)) {
        errFlag = 1
        errMsgList.push('学年の選択は必須です。')
      }
      if(target_division != 'SCHOOL' && target_division != 'GRADE' && (target_class == '' || target_class == null || target_class == undefined)) {
        errFlag = 1
        errMsgList.push('クラスの選択は必須です。')
      }
      if(target_division == 'PERSONAL' && (target_student == '' || target_student == null || target_student == undefined)) {
        errFlag = 1
        errMsgList.push('生徒の選択は必須です。')
      }
      if(start == '' || start == null || start == undefined) {
        errFlag = 1
        errMsgList.push('開始日は必須です。')
      }
      if(end == '' || end == null || end == undefined) {
        errFlag = 1
        errMsgList.push('終了日は必須です。')
      }
      if(!moment(start).isSameOrBefore(end)){
        errFlag = 1
        errMsgList.push('開始日は終了日より前である必要があります。')
      }
      if(name.length > 15){
        errFlag = 1
        errMsgList.push('イベント名は15文字以下である必要があります。')
      }
      return errFlag == 0 ? true : false
    },
    async changeStudentItems(){
      this.createItem.target_student=null
      await this.setSelectedClassroom({
        class_id: this.selectedClass,
        grade_id: this.selectedGrade
      });
      if (this.createItem.target_division === "PERSONAL") {
        await this.getStudentByClassId();
      }
    },
    addItemDialogTitle() {
      return this.dialogMode == 'create' ? 'イベント登録' : 'イベント編集'
    },
    confirmDialogText() {
      return this.dialogMode == 'create' ? '新しいイベントを登録します。' : 'イベントを上書き登録します。'
    },
    colorReset() {
      this.hex = '#00E676'
    },
    addItem() {
      this.addItemDialog = true;
    },
    reload() {
      console.log('reload')
    },
    dateToScheduleListChange(time){ 
      this.scheduleListDateDialog = true;
      this.dateToScheduleList = moment(time);
    },
    addDateToScheduleList() {
      this.dateToScheduleList = moment(this.dateToScheduleList).add(1, 'day')
    },
    subtractDateToScheduleList() {
      this.dateToScheduleList = moment(this.dateToScheduleList).subtract(1, 'day')
    },
    async openScheduleListDateDialog() {
      this.scheduleListDateDialog = true;
    },
    formatToYYYYMMDD(value) {
      return moment(value).format('YYYY-MM-DD')
    },
    formatToMMDD(value) {
      return moment(value).format('MM月DD日')
    },
    changecreateItemEndDate(newDateStr) {
      // 既に設定されている時刻は変えずに
      //日付だけ変える
      const newDate =  moment(newDateStr)
      const newVal = moment(this.createItem.end).set({
        year: newDate.year(),
        month: newDate.month(),
        date: newDate.date(),
      })
      this.createItem.end = newVal.toDate();
      this.fromEndDateMenu = false;
    },
    changecreateItemStartDate(newDateStr) {
      // 既に設定されている時刻は変えずに
      //日付だけ変える
      const newDate =  moment(newDateStr)
      const newVal = moment(this.createItem.start).set({
        year: newDate.year(),
        month: newDate.month(),
        date: newDate.date(),
      })
      this.createItem.start = newVal.toDate();
      this.fromStartDateMenu = false;
    },
    changecreateItemStartTime(newTimeStr){
      // 既に設定されている時刻は変えずに
      //日付だけ変える
      const newDate =  moment(newTimeStr)
      const newVal = moment(this.createItem.start).set({
        hour: newDate.hour(),
        minute: newDate.minute(),
      })
      this.createItem.start = newVal.toDate();
    },
    changecreateItemEndTime(newTimeStr){
      // 既に設定されている時刻は変えずに
      //日付だけ変える
      const newDate =  moment('2000-01-01 ' + newTimeStr + ':00')
      const newVal = moment(this.createItem.end).set({
        hour: newDate.hours(),
        minute: newDate.minute(),
      })
      this.createItem.end = newVal.toDate();
    },
    async clickedEvent(event_item) {
      this.addItemDialog = true;
      const event = {...event_item}
      this.createItem = {...event};
      this.selectedDivision = event.target_division
      this.selectedGrade = event.target_grade
      this.selectedClass = event.target_class
      this.$nextTick(function() {
        this.selectedStudent = event.target_student
        this.createItem.target_student = event.target_student
      });
      // this.createItem.timed = !this.createItem.timed
    },
    async deleteItem() {
      this.loading = true;
      console.log(this.createItem)
      await this.deleteScheduleItems(this.createItem.event_id)
      await this.getScheduleItems()
      this.deleteItemDialog = false;
      this.addItemDialog = false;
      this.loading = false;
    },
    async saveItem() {
      this.loading = true;
      // 色と終日フラグが必要になった場合
      // this.createItem.color = this.hex;
      // this.createItem.timed = !this.createItem.timed
      await this.createScheduleItems(this.createItem)
      await this.getScheduleItems()
      this.addItemDialog = false;
      this.confirmDialog = false;
      this.loading = false;
    },
    async updateItem() {
      this.loading = true;
      this.createItem.color = this.hex;
      this.createItem.timed = !this.createItem.timed
      await this.updateScheduleItems(this.createItem)
      await this.getScheduleItems()
      this.addItemDialog = false;
      this.confirmDialog = false;
      this.loading = false;
    },
  }
};
</script>

<style scoped>
</style>
