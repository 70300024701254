<template>
  <div>
    <v-divider />
    <v-container fluid>
      <v-row class="justify-end align-center">
        <v-col cols="auto">
          <div class="d-flex align-center">
            <div class="mr-6 caption grey--text text--darken-2">
              行/ページ:
            </div>
            <v-select v-model="perOnPageLocal" :items="dispPerOnPageOptions" item-text="text" item-value="value"
              underlined hide-details dense color="var(--app-color)" class="ma-0 caption" style="width: 60px;" />
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="mx-3 caption">
            {{ paginationText }}
          </div>
        </v-col>
        <v-col cols="auto">
          <div class="d-flex align-center">
            <v-icon left color="grey--text text--darken-1" class="mr-6" :disabled="(isPreviousDisabled || loading)"
              @click="handlePrevious">
              mdi-chevron-left
            </v-icon>
            <v-icon left color="grey--text text--darken-1" :disabled="(isNextDisabled || loading)" @click="handleNext">
              mdi-chevron-right
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    totalCount: Number,
    page: Number,
    perOnPage: Number,
    perOnPageOptions: Array,
    loading: Boolean
  },
  computed: {
    pageLocal: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit('changePage', value);
      }
    },
    perOnPageLocal: {
      get() {
        return this.perOnPage;
      },
      set(value) {
        this.$emit('changePerOnPage', value);
      }
    },
    dispPerOnPageOptions() {
      return this.perOnPageOptions.map((o) => {
        return {
          text: (o === -1) ? "全て" : o,
          value: o
        }
      });
    },
    paginationText() {
      const start = (this.perOnPageLocal === -1) ? 1 : (1 + (this.perOnPageLocal * (this.pageLocal - 1)));
      const end = (this.perOnPageLocal === -1) ? this.totalCount : ((this.perOnPageLocal * this.pageLocal) < this.totalCount) ? (this.perOnPageLocal * this.pageLocal) : this.totalCount;
      return `全 ${this.totalCount} 件中 ${start} 件 〜 ${end}件を表示`;
    },
    isPreviousDisabled() {
      return (this.perOnPageLocal === -1) ? true : (this.page <= 1);
    },
    isNextDisabled() {
      return (this.perOnPageLocal === -1) ? true : (this.perOnPageLocal * this.pageLocal) >= this.totalCount;
    }
  },
  methods: {
    handlePrevious() {
      this.pageLocal = (this.pageLocal - 1);
    },
    handleNext() {
      this.pageLocal = (this.pageLocal + 1);
    },
  }
});
</script>