var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{attrs:{"id":"render"}}),(_vm.isConfirmMode)?_c('DataConfirm',{attrs:{"data":_vm.dataSurvey}}):[(_vm.isLiffMode)?[_c('v-row',[_c('v-col',[_c('div',{staticClass:"custom-color"},[_c('h2',{staticClass:"font-weight-bold mb-2 custom-layout-title"},[_vm._v(" "+_vm._s(_vm.configJson.surveyTitle)+" ")]),_c('div',[_c('div',{staticClass:"survey-description",domProps:{"innerHTML":_vm._s(_vm.renderToLink(_vm.configJson.description))}})])])])],1)]:_vm._e(),_c('v-form',{ref:"allFormValid",on:{"submit":function($event){$event.preventDefault();return _vm.redirect.apply(null, arguments)}},model:{value:(_vm.allFormValid),callback:function ($$v) {_vm.allFormValid=$$v},expression:"allFormValid"}},[_c('v-row',{staticClass:"d-none"},[_c('v-col',[_vm._v(_vm._s(_vm.surveySchemaUpdatedFlag))])],1),_vm._l((_vm.surveySchema1),function(item,sIndex){return _c('div',{key:sIndex},[(!item.disabled)?_c('v-row',[(item.type !== 'linkbutton')?_c('v-col',[(item.type === 'groupheader')?_c('div',[(!item.disabled)?_c('v-expansion-panels',{staticClass:"groupheader",attrs:{"accordion":"","tile":"","multiple":"","disabled":item.disabled},model:{value:(_vm.groupheaderPanelValues[item.itemKey]),callback:function ($$v) {_vm.$set(_vm.groupheaderPanelValues, item.itemKey, $$v)},expression:"groupheaderPanelValues[item.itemKey]"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"groupheader-header"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"survey-description",domProps:{"innerHTML":_vm._s(_vm.renderToLink(item.description))}})])]),_vm._l((item.items),function(value,index){return _c('v-expansion-panel-content',{key:index,attrs:{"eager":""}},[(value.type !== 'linkbutton')?_c('div',[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(value.title))]),(
                            (_vm.isAdminMode &&
                              value.isRequired.admin === true) ||
                            (_vm.isAdminMode &&
                              (!('admin' in value.isRequired) ||
                                value.isRequired.admin === null) &&
                              value.isRequired.value) ||
                            (!_vm.isAdminMode &&
                              (value.isRequired.value ||
                                (value.isRequiredForUser && value.isRequiredForUser.value)))
                          )?_c('span',{staticClass:"v-messages theme--light error--text"},[_vm._v(" *")]):_vm._e()]),(value.description)?_c('div',[_c('span',{staticClass:"caption grey--text text--darken-3",domProps:{"innerHTML":_vm._s(_vm.renderToLink(value.description))}})]):_vm._e(),(value.imageUrl)?_c('div',[_c('v-img',{staticClass:"liffItemImg",staticStyle:{"border-radius":"4px"},attrs:{"src":value.imageUrl.itemUrl ? value.imageUrl.itemUrl:value.imageUrl}})],1):_vm._e(),(
                          value.type !== 'groupheader' &&
                          value.type !== 'guide'
                        )?_c('div',{class:_vm.disabledFormRenderer},[_c(_vm.getComponent(value.type),{ref:value.itemKey,refInFor:true,tag:"component",attrs:{"mlLibJson":_vm.mlLibJson,"configJson":_vm.configJson,"params":value,"isAdminMode":_vm.isAdminMode,"isLiffMode":_vm.isLiffMode,"isNotEditable":value.isNotEditable,"forceNotEditable":_vm.forceNotEditable,"isConfirmSurveyMode":_vm.isConfirmSurveyMode,"isSurveyRegistering":_vm.isSurveyRegistering,"categoriesTree":_vm.categoriesTree,"isSaibanExisted":_vm.isSaibanExisted,"paymentResult":_vm.paymentResult,"taxRate":_vm.taxRate,"paymentService":_vm.paymentService,"products":_vm.products,"isFetchingPaymentInfo":_vm.isFetchingPaymentInfo,"isLanguageSettingsActive":_vm.isLanguageSettingsActive,"isNewAnswer":_vm.isNewAnswer},on:{"fill-address":_vm.fillAddress,"choice-groupheader-radio-click":_vm.choiceGroupheaderRadioClick,"handleCheckSaibanExisting":_vm.handleCheckSaibanExisting,"handleCheckSaibanExistingForUpdate":_vm.handleCheckSaibanExistingForUpdate,"setPaymentInfo":_vm.handleSetPaymentInfo}})],1):_vm._e()]):_vm._e()])})],2)],1):_vm._e()],1):_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"font-weight-bold custom-color"},[_vm._v(_vm._s(item.title))]),(
                        (_vm.isAdminMode && item.isRequired.admin === true) ||
                        (_vm.isAdminMode &&
                          (!('admin' in item.isRequired) ||
                            item.isRequired.admin === null) &&
                          item.isRequired.value) ||
                        (!_vm.isAdminMode &&
                          (item.isRequired.value ||
                            (item.isRequiredForUser && item.isRequiredForUser.value)))
                        || (!_vm.isAdminMode && item.type === 'previousVaccineMaker')
                        || (!_vm.isAdminMode && item.type === 'previousVaccineDate')
                      )?_c('span',{staticClass:"v-messages theme--light error--text"},[_vm._v(" *")]):_vm._e()]),(item.description)?_c('div',[_c('span',{staticClass:"caption grey--text text--darken-3 survey-description custom-color",domProps:{"innerHTML":_vm._s(_vm.renderToLink(item.description))}})]):_vm._e(),(item.imageUrl)?_c('div',[_c('v-img',{staticClass:"liffItemImg",staticStyle:{"border-radius":"4px"},attrs:{"src":item.imageUrl.itemUrl ? item.imageUrl.itemUrl:item.imageUrl}})],1):_vm._e(),(
                      item.type !== 'groupheader' && item.type !== 'guide'
                    )?_c('div',{class:_vm.disabledFormRenderer},[_c(_vm.getComponent(item.type),{ref:item.itemKey,refInFor:true,tag:"component",attrs:{"mlLibJson":_vm.mlLibJson,"configJson":_vm.configJson,"hasCountVaccines":_vm.hasCountVaccines,"params":item,"isAdminMode":_vm.isAdminMode,"isLiffMode":_vm.isLiffMode,"isNotEditable":item.isNotEditable,"forceNotEditable":_vm.forceNotEditable,"isConfirmSurveyMode":_vm.isConfirmSurveyMode,"isSurveyRegistering":_vm.isSurveyRegistering,"categoriesTree":_vm.categoriesTree,"isValidatePreviousVaccineDate":_vm.isValidatePreviousVaccineDate,"isValidatePreviousVaccineMaker":_vm.isValidatePreviousVaccineMaker,"vaccinationIntervalType":_vm.vaccinationIntervalType,"isSaibanExisted":_vm.isSaibanExisted,"paymentResult":_vm.paymentResult,"taxRate":_vm.taxRate,"paymentService":_vm.paymentService,"products":_vm.products,"isFetchingPaymentInfo":_vm.isFetchingPaymentInfo,"isLanguageSettingsActive":_vm.isLanguageSettingsActive,"isNewAnswer":_vm.isNewAnswer},on:{"fill-address":_vm.fillAddress,"choice-groupheader-radio-click":_vm.choiceGroupheaderRadioClick,"handleCheckSaibanExisting":_vm.handleCheckSaibanExisting,"handleCheckSaibanExistingForUpdate":_vm.handleCheckSaibanExistingForUpdate,"handleCategoryChange":(value) => _vm.$emit('onCategoryChange', value),"setPaymentInfo":_vm.handleSetPaymentInfo,"handleSelectCategoriesTreeChange":_vm.handleSelectCategoriesTreeChange}})],1):_vm._e()])],1)],1)],1):_vm._e()],1):_vm._e()],1)})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }