/*
 * Copyright 2022 LINE Fukuoka Corporation
 *
 * LINE Corporation licenses this file to you under the Apache License,
 * version 2.0 (the "License"); you may not use this file except in compliance
 * with the License. You may obtain a copy of the License at:
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 *
 */

import {
  GetSurveyList,
//   GetFormConfigById,
//   GetAllFormConfigs,
  RegisterSurveyConfig,
  UpdateSurveyConfig,
  RegisterSurveyConfigs,
  DeleteSurveyConfigs,
} from "../../../services/form.service";

// import {
//   UploadSurveyImageToS3,
// } from "../../../services/survey.service";

import {
  FETCH_SURVEY_CONFIGS,
//   FETCH_SURVEY_CONFIGS_BY_ID,
//   FETCH_FORM_CONFIGS,
  REGISTER_SURVEY_CONFIG,
  UPDATE_SURVEY_CONFIG,
  REGISTER_SURVEY_CONFIGS,
  DELETE_SURVEY_CONFIGS,
//   FETCH_SURVEY_RESULTS_COUNT,
} from "@/store/action-types";

import {
  SURVEY_CONFIGS_LIST_TYPE,
  SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE,
  SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR,

//   SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID,
//   SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR,

//   SET_FORM_CONFIGS,
//   SET_IS_FETCHING_FORM_CONFIGS,
//   SET_FETCH_FORM_CONFIGS_ERROR,
//   SET_FORM_CONFIG_DETAIL,
//   SET_IS_FETCHING_FORM_CONFIG_DETAIL,
//   SET_FETCH_FORM_CONFIG_DETAIL_ERROR,
  SET_IS_REGISTING_SURVEY_CONFIG,
  SET_REGISTER_SURVEY_CONFIG_ERROR,
  SET_REGISTERED_SURVEY_CONFIG_ID,
  ADD_FORM_CONFIG,
  SET_IS_UPDATING_SURVEY_CONFIG,
  SET_UPDATE_SURVEY_CONFIG_ERROR,
  UPDATE_SURVEY_CONFIG_IN_STORE,
  REMOVE_SURVEY_CONFIG_IN_STORE,
  SET_IS_REGISTERING_SURVEY_CONFIGS,
  SET_REGISTER_SURVEY_CONFIGS_ERROR,
  SET_IS_DELETING_SURVEY_CONFIGS,
  SET_DELETE_SURVEY_CONFIGS_ERROR,
//   SET_IS_EXIST_SURVEY_RESULTS,
//   SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS,
//   SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR,
} from "@/store/mutation-types";
import { orderBy, cloneDeep } from "lodash";
// import { generateUUID } from "@/utils/uuidUtils";
// // import { UploadImageToS3 } from "@/services/scenarios.service";
// // import { SurveyResultsService } from "@/services/surveyResults.service";

// const surveyResultsService = new SurveyResultsService();

export default {
  [FETCH_SURVEY_CONFIGS]: async ({ commit }, payload) => {
    try {
      commit(SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE, true);
      commit(SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR, null);

      const response = await GetSurveyList(payload);
      commit(SURVEY_CONFIGS_LIST_TYPE, response.data.items);

    } catch (error) {
      commit(SET_FETCH_SURVEY_CONFIGS_LIST_TYPE_ERROR, error.response.data);
    } finally {
      commit(SET_IS_FETCHING_SURVEY_CONFIGS_LIST_TYPE, false);
    }
  },
//   [FETCH_SURVEY_CONFIGS_BY_ID]: async ({ commit }, surveyId) => {
//     try {
//       commit(SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID, true);
//       commit(SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR, null);

//       let response = await GetFormConfigById(surveyId);
//       return response;
//     } catch (error) {
//       commit(SET_FETCH_SURVEY_CONFIGS_BY_ID_ERROR, error.response.data);
//     } finally {
//       commit(SET_IS_FETCHING_SURVEY_CONFIGS_BY_ID, false);
//     }
//   },

//   [FETCH_FORM_CONFIGS]: async ({ commit }) => {
//     try {
//       commit(SET_IS_FETCHING_FORM_CONFIGS, true);
//       commit(SET_FETCH_FORM_CONFIGS_ERROR, null);
//       let response = await GetAllFormConfigs();
//       if (response.result == "OK") {
//         commit(SET_FORM_CONFIGS, orderBy(response.data, ["updatedAt"], ["desc"]));
//         return true;
//       } else {
//         commit(SET_FETCH_FORM_CONFIGS_ERROR, response.errorMessage);
//       }
//     } catch (error) {
//       console.log(error.response.data);
//       commit(SET_FETCH_FORM_CONFIGS_ERROR, error.response.data);
//     } finally {
//       commit(SET_IS_FETCHING_FORM_CONFIGS, false);
//     }
//   },
  [REGISTER_SURVEY_CONFIG]: async ({ commit }, payload) => {
    console.log("REGISTER_SURVEY_CONFIG")
    try {
      const surveyConfig = cloneDeep(payload.survey);

      commit(SET_IS_REGISTING_SURVEY_CONFIG, true);
      commit(SET_REGISTER_SURVEY_CONFIG_ERROR, null);

      for (const [key, item] of Object.entries(surveyConfig.surveySchema)) {
        if ((item).isNewItem) {
          delete (item).isNewItem;
        }
      }

      const request = {
        surveyConfig: surveyConfig,
        loginUser: payload.loginUser,
        fiscalYear: payload.fiscalYear
      }

      let response = await RegisterSurveyConfig(request);
      if (response.status == 200) {
        commit(SET_REGISTERED_SURVEY_CONFIG_ID, response.data.item.surveyId);
        commit(ADD_FORM_CONFIG, response.data.item);

        return response.data.item.surveyId;
      } else {
        commit(SET_REGISTER_SURVEY_CONFIG_ERROR, response.errorMessage);
        return false;
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response.status === 403) {
        commit(SET_REGISTER_SURVEY_CONFIG_ERROR, "この操作を行う場合は、権限を管理者にお問い合わせください");
      } else {
        commit(SET_REGISTER_SURVEY_CONFIG_ERROR, error.response.data);
      }
    } finally {
      commit(SET_IS_REGISTING_SURVEY_CONFIG, false);
    }
  },
  [UPDATE_SURVEY_CONFIG]: async ({ commit }, payload) => {
    try {
      const surveyConfig = cloneDeep(payload.survey);

      commit(SET_IS_UPDATING_SURVEY_CONFIG, true);
      commit(SET_UPDATE_SURVEY_CONFIG_ERROR, null);

      for (const [key, item] of Object.entries(surveyConfig.surveySchema)) {
        if ((item).isNewItem) {
          delete (item).isNewItem;
        }
      }

      const request = {
        surveyConfig: surveyConfig,
        loginUser: payload.loginUser,
        fiscalYear: payload.fiscalYear
      }

      // surveyConfig.endOfSurveyMessage && await uploadMediaAndFilterMessages(surveyConfig);
      let response = await UpdateSurveyConfig(request);
      if (response.status == 200) {
        if (surveyConfig.isDisplay !== undefined && surveyConfig.isDisplay === false) {
          commit(REMOVE_SURVEY_CONFIG_IN_STORE, surveyConfig);
          return true;
        }
        commit(SET_REGISTERED_SURVEY_CONFIG_ID, response.data.item.surveyId);
        commit(UPDATE_SURVEY_CONFIG_IN_STORE, response.data.item);
        return true;
      } else {
        commit(SET_UPDATE_SURVEY_CONFIG_ERROR, response.errorMessage);
        return false;
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response.status === 403) {
        commit(SET_UPDATE_SURVEY_CONFIG_ERROR, "この操作を行う場合は、権限を管理者にお問い合わせください");
      } else {
        commit(SET_UPDATE_SURVEY_CONFIG_ERROR, error.response.data);
      }
    } finally {
      commit(SET_IS_UPDATING_SURVEY_CONFIG, false);
    }
  },
  [REGISTER_SURVEY_CONFIGS]: async ({ commit }, payload) => {
    console.log("REGISTER_SURVEY_CONFIGS")
    try {
      const surveyConfigs = cloneDeep(payload.surveys);

      commit(SET_IS_REGISTERING_SURVEY_CONFIGS, true);
      commit(SET_REGISTER_SURVEY_CONFIGS_ERROR, null);

      for (let surveyConfig of surveyConfigs) {
        for (const [key, item] of Object.entries(surveyConfig.surveySchema)) {
          if ((item).isNewItem) {
            delete (item).isNewItem;
          }
        }
      }

      const request = {
        surveyConfigList: surveyConfigs,
        loginUser: payload.loginUser,
        fiscalYear: payload.fiscalYear
      }

      let response = await RegisterSurveyConfigs(request);
      if (response.status == 200) {
        // commit(SET_REGISTERED_SURVEY_CONFIG_ID, response.data.item.surveyId);
        for (const item of response.data.items) {
          commit(ADD_FORM_CONFIG, item);
        }

        return true;
      } else {
        commit(SET_REGISTER_SURVEY_CONFIGS_ERROR, response.errorMessage);
        return false;
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response.status === 403) {
        commit(SET_REGISTER_SURVEY_CONFIGS_ERROR, "この操作を行う場合は、権限を管理者にお問い合わせください");
        return false;
      } else {
        commit(SET_REGISTER_SURVEY_CONFIGS_ERROR, error.response.data);
        return false;
      }
    } finally {
      commit(SET_IS_REGISTERING_SURVEY_CONFIGS, false);
    }
  },
  [DELETE_SURVEY_CONFIGS]: async ({ commit }, payload) => {
    try {
      console.log(commit)
      const surveyConfigs = cloneDeep(payload.surveyList);

      commit(SET_IS_DELETING_SURVEY_CONFIGS, true);
      commit(SET_DELETE_SURVEY_CONFIGS_ERROR, null);

      for (let surveyConfig of surveyConfigs) {
        for (const [key, item] of Object.entries(surveyConfig.surveySchema)) {
          if ((item).isNewItem) {
            delete (item).isNewItem;
          }
        }
      }

      const request = {
        surveyConfigs: surveyConfigs,
        loginUser: payload.loginUser,
      }

      // surveyConfig.endOfSurveyMessage && await uploadMediaAndFilterMessages(surveyConfig);
      let response = await DeleteSurveyConfigs(request);
      if (response.status === 200) {
        return true;
      } else {
        commit(SET_DELETE_SURVEY_CONFIGS_ERROR, response.errorMessage);
        return false;
      }
    } catch (error) {
      console.log(error);
      commit(SET_DELETE_SURVEY_CONFIGS_ERROR, "不明なエラーが発生しました");
    } finally {
      commit(SET_IS_DELETING_SURVEY_CONFIGS, false);
    }
  },
//   [FETCH_SURVEY_RESULTS_COUNT]: async ({ commit, state }, surveyId) => {
//     commit(SET_IS_EXIST_SURVEY_RESULTS, false);
//     commit(SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS, false);
//     commit(SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR, null);

//     const { itemKey, originalValue } = state.editedMultipleItem;
//     const params = {
//       countOnly: true,
//       filterCommon: {
//         check: [],
//         [itemKey]: [originalValue],
//       },
//       filterDate: {},
//       lastEvaluatedKey: null,
//     };
//     let response = { data: null };
//     let resultsCount = 0;

//     try {
//       do {
//         response = await surveyResultsService.GetSurveyResultsList(surveyId, params);
//         if (response && response.data) {
//           params.lastEvaluatedKey = response.data.lastEvaluatedKey;
//           resultsCount += response.data.totalCount;
//           if (response.data.isTimedOut) {
//             break;
//           }
//         }
//       } while (response && (response.data.lastEvaluatedKey && response.data.totalCount === 0 && !response.data.isTimedOut));

//       if (response.data.isTimedOut) {
//         commit(SET_IS_TIMED_OUT_COUNT_SURVEY_RESULTS, true);
//       }
//       const isExist = resultsCount > 0;
//       commit(SET_IS_EXIST_SURVEY_RESULTS, isExist);
//     } catch (error) {
//       commit(SET_FETCH_SURVEY_RESUTLS_COUNT_ERROR, error);
//     }
//   }
};
