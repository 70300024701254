<template>
  <div>
    <v-dialog
      v-model="show"
      eager
      persistent
      width="800"
    >
      <v-card>
        <v-card-title>
          <v-container class="pa-0">
            <v-row no-gutters class="justify-space-between">
              <v-col>
                <div class="d-flex align-center">
                  <v-btn
                    icon
                    :disabled="isFirstDateOfMonth"
                    @click="changeDateEarlier"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <div>{{ monthDateDisplayFormatted }}</div>
                  <v-btn
                    icon
                    :disabled="isLastDateOfMonth"
                    @click="changeDateLater"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="auto" @click="close">
                <v-btn elevation="0">
                  閉じる
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-sheet height="400" class="align-content-center text-center">
            <template v-if="loading">
              <v-progress-circular
                color="primary"
                indeterminate
              ></v-progress-circular>
            </template>
            <template v-else-if="!existsMenuInfo">
              <div>献立情報はありません。</div>
            </template>
            <template v-else>
              <v-tabs v-model="tab" class="custom-tabs">
                <v-tab href="#menu-info">献立情報</v-tab>
                <v-tab href="#allergen-info">アレルゲン情報</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" class="mt-3">
                <v-tab-item value="menu-info">
                  <v-card outlined color="black">
                    <v-data-table
                      :loading="loading"
                      :loading-text="loadingText"
                      :no-data-text="noDataText"
                      item-key="id"
                      :headers="menuInfo.headers"
                      :items="menuInfo.items"
                      :items-per-page="menuInfo.perOnPage"
                      :page.sync="menuInfo.currentPage"
                      :footer-props="{
                        'items-per-page-options': [30, 50, 100, -1],
                        'items-per-page-text': '行/ページ:'
                      }"
                      height="280"
                      class="custom-table"
                    >
                      <template v-slot:[`item.red`]="{ item }">
                        <div>
                          {{ convertIngredientListToRed(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.yellow`]="{ item }">
                        <div>
                          {{ convertIngredientListToYellow(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.green`]="{ item }">
                        <div>
                          {{ convertIngredientListToGreen(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.seasoning`]="{ item }">
                        <div>
                          {{ convertIngredientListToSeasoning(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`footer.page-text`]="props">
                        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="allergen-info">
                  <v-card outlined color="black">
                    <v-data-table
                      :loading="loading"
                      :loading-text="loadingText"
                      :no-data-text="noDataText"
                      item-key="id"
                      :headers="allergenInfo.headers"
                      :items="allergenInfo.items"
                      :items-per-page="allergenInfo.perOnPage"
                      :page.sync="allergenInfo.currentPage"
                      :footer-props="{
                        'items-per-page-options': [30, 50, 100, -1],
                        'items-per-page-text': '行/ページ:'
                      }"
                      height="280"
                      class="custom-table"
                    >
                    <template v-slot:[`item.red`]="{ item }">
                        <div>
                          {{ convertIngredientListToRed(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.yellow`]="{ item }">
                        <div>
                          {{ convertIngredientListToYellow(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.green`]="{ item }">
                        <div>
                          {{ convertIngredientListToGreen(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`item.seasoning`]="{ item }">
                        <div>
                          {{ convertIngredientListToSeasoning(item.ingredientList) }}
                        </div>
                      </template>
                      <template v-slot:[`footer.page-text`]="props">
                        <div>全 {{ props.itemsLength }} 件中 {{ props.pageStart }} 件 〜 {{ props.pageStop }} 件を表示</div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  mapState,
  mapActions
} from "vuex";
import {
  FETCH_SCHOOL_LUNCH_MENU_INFO_LIST,
  FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST
} from "@/store/action-types";
import {
  LOADING_TEXT,
  NO_DATA_TEXT
} from "@/constants";
import moment from "moment";

export default Vue.extend({
  props: {
    value: Boolean,
    year: String,
    month: String,
    date: String
  },
  data() {
    return {
      loading: false,
      dateDisplay: "",
      tab: "menu-info",
      menuInfo: {
        headers: [
          { text: "こんだて", value: "name" },
          { text: "あか", value: "red", sortable: false },
          { text: "きいろ", value: "yellow", sortable: false },
          { text: "みどり", value: "green", sortable: false },
          { text: "調味料由来", value: "seasoning", sortable: false }
        ],
        items: [],
        currentPage: 1,
        perOnPage: -1
      },
      allergenInfo: {
        headers: [
          { text: "こんだて", value: "name" },
          { text: "あか", value: "red", sortable: false },
          { text: "きいろ", value: "yellow", sortable: false },
          { text: "みどり", value: "green", sortable: false },
          { text: "調味料由来", value: "seasoning", sortable: false }
        ],
        items: [],
        currentPage: 1,
        perOnPage: -1
      },
      loadingText: LOADING_TEXT,
      noDataText: NO_DATA_TEXT
    };
  },
  computed: {
    ...mapState({
      schoolId: state => state.loginUser.school_id,
      schoolLunchMenuInfoList: state => state.schoolLunchMenuInfoList,
      schoolLunchAllergenInfoList: state => state.schoolLunchAllergenInfoList
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    /* 献立情報 */
    existsMenuInfo() {
      return (this.menuInfo.items.length > 0);
    },
    monthDateDisplayFormatted() {
      if (this.dateDisplay === "") return "";
      return `${this.month}月${this.dateDisplay}日`;
    },
    isFirstDateOfMonth() {
      /* 表示日がその月の最初の日付かどうか */
      const momentDate = moment(`${this.year}-${this.month}-${this.dateDisplay}`, "YYYY-MM-DD");
      return momentDate.isSame(moment(momentDate).startOf('month'), 'day');
    },
    isLastDateOfMonth() {
      /* 表示日がその月の最後の日付かどうか */
      const momentDate = moment(`${this.year}-${this.month}-${this.dateDisplay}`, "YYYY-MM-DD");
      return momentDate.isSame(moment(momentDate).endOf('month'), 'day');
    }
  },
  watch: {
    async show(val) {
      if (val === true) {
        /* ローディング開始 */
        this.loading = true;
        /* 表示日時をPropsから取得 */
        this.applyPropsToData();
        /* 献立情報取得 */
        await this.fetchSchoolLunchMenuInfoList({
          schoolId: this.schoolId,
          year: this.year,
          month: this.month
        });
        /* アレルゲン情報取得 */
        await this.fetchSchoolLunchAllergenInfoList({
          schoolId: this.schoolId
        });
        /* ローディング終了 */
        this.loading = false;
      } else {
        /* コンポーネントの状態をリセット */
        this.clearData();
      }
    },
    schoolLunchMenuInfoList: {
      handler() {
        /* Storeの情報をDataに反映する */
        this.applyStoreToData();
      },
      deep: true
    },
    schoolLunchAllergenInfoList: {
      handler() {
        /* Storeの情報をDataに反映する */
        this.applyStoreToData();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchSchoolLunchMenuInfoList: FETCH_SCHOOL_LUNCH_MENU_INFO_LIST,
      fetchSchoolLunchAllergenInfoList: FETCH_SCHOOL_LUNCH_ALLERGEN_INFO_LIST
    }),
    applyPropsToData() {
      this.dateDisplay = this.date;
    },
    applyStoreToData() {
      /* 表示日の献立情報をStoreから取得&変換 */
      const menuInfoList = this.schoolLunchMenuInfoList
        .filter(schoolLunchMenuInfo => {
          return (
            schoolLunchMenuInfo.year === this.year,
            schoolLunchMenuInfo.month === this.month,
            schoolLunchMenuInfo.date === this.dateDisplay
          );
        })
        .map(schoolLunchMenuInfo => {
          return {
            id: schoolLunchMenuInfo.school_lunch_food_id,
            year: schoolLunchMenuInfo.year,
            month: schoolLunchMenuInfo.month,
            date: schoolLunchMenuInfo.date,
            name: schoolLunchMenuInfo.school_lunch_food_name,
            ingredientList: schoolLunchMenuInfo.school_lunch_ingredient_list.map(school_lunch_ingredient => {
              return {
                id: school_lunch_ingredient.school_lunch_ingredient_id,
                type: school_lunch_ingredient.school_lunch_ingredient_type,
                name: school_lunch_ingredient.school_lunch_ingredient_name
              };
            })
          };
        });
      this.menuInfo.items = menuInfoList;

      /* 表示日のアレルゲン情報をStoreから取得&変換 */
      const allergenInfoList = this.menuInfo.items
        .map(item => {
          return {
            id: item.id,
            year: item.year,
            month: item.month,
            date: item.date,
            name: item.name,
            ingredientList: item.ingredientList.filter(ingredient => {
              return this.schoolLunchAllergenInfoList.some(schoolLunchAllergenInfo => ingredient.name.includes(schoolLunchAllergenInfo.school_lunch_allergen_name));
            })
          };
        });
      this.allergenInfo.items = allergenInfoList;
    },
    clearData() {
      this.dateDisplay = "";
      this.menuInfo.items = [];
      this.allergenInfo.items = [];
    },

    /* 献立情報 */
    convertIngredientListToRed(ingredientList) {
      return ingredientList
        .filter(ingredient => ingredient.type === 'red')
        .map(ingredient => ingredient.name)
        .join(',');
    },
    convertIngredientListToYellow(ingredientList) {
      return ingredientList
        .filter(ingredient => ingredient.type === 'yellow')
        .map(ingredient => ingredient.name)
        .join(',');
    },
    convertIngredientListToGreen(ingredientList) {
      return ingredientList
        .filter(ingredient => ingredient.type === 'green')
        .map(ingredient => ingredient.name)
        .join(',');
    },
    convertIngredientListToSeasoning(ingredientList) {
      return ingredientList
        .filter(ingredient => ingredient.type === 'seasoning')
        .map(ingredient => ingredient.name)
        .join(',');
    },
    convertFoodListToIncluded(foodList) {
      return foodList
        .map(food => food.name)
        .join(',');
    },

    /* 日付変更 */
    changeDateEarlier() {
      /* momentオブジェクトに変換 */
      const date = moment(`${this.year}-${this.month}-${this.dateDisplay}`, "YYYY-MM-DD");
      /* 一日を減算 */
      date.subtract(1, 'days');
      /* 新しい日付を取得 */
      const newDate = date.format("DD");
      /* 表示日時に反映 */
      this.dateDisplay = newDate;
      /* 表示情報に反映 */
      this.applyStoreToData();
    },
    changeDateLater() {
      /* momentオブジェクトに変換 */
      const date = moment(`${this.year}-${this.month}-${this.dateDisplay}`, "YYYY-MM-DD");
      /* 一日を加算 */
      date.add(1, 'days');
      /* 新しい日付を取得 */
      const newDate = date.format("DD");
      /* 表示日時に反映 */
      this.dateDisplay = newDate;
      /* 表示情報に反映 */
      this.applyStoreToData();
    },

    /* 閉じる */
    close() {
      this.show = false;
    }
  }
});
</script>

<style scoped>
/* タブ */
.custom-tabs.v-tabs .v-tab {
  border: solid 1px rgba(243, 243, 243, 1);
  background-color: rgba(247, 247, 247, 1);
}
.custom-tabs.v-tabs .v-tab:first-of-type {
  border-radius: 4px 0 0 4px;
}
.custom-tabs.v-tabs .v-tab:last-of-type {
  border-radius: 0 4px 4px 0;
}

/* テーブル */
.custom-table.theme--light.v-data-table {
  background-color: #F9FAFB;
}
.custom-table.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
.custom-table.theme--light.v-data-table .v-data-footer {
  border-top: 1px solid rgba(0, 0, 0, 1);
}
</style>