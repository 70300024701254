<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-row no-gutters class="mb-8 mt-4">
      <v-col cols="12">
        <ul ref="itemRadioDrag" class="form-editor-drag">
          <li v-for="(option, index) in item.options" :key="option + index">
            <v-hover v-slot:default="{ hover }">
              <v-text-field
                :key="option + index"
                class="deactive pt-1"
                hide-details
                :value="option"
                @change="(value) => onChangeOption(value, index)"
                :readonly="isReadOnly(index)"
              >
                <template v-slot:prepend>
                  <span style="width: 25px" v-if="isEditMode === false">
                    <v-icon v-if="hover && isActive" class="radio-drag-handle pl-1">mdi-drag </v-icon>
                  </span>
                  <span style="width: 25px" v-else> </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="pt-1" @click="setDefaultValue(option)" v-bind="attrs" v-on="on">
                        <span
                          :class="{
                            'item-dropdown-default': isDefaultValue(option),
                          }"
                          >{{ index + 1 }}.</span
                        >
                      </span>
                    </template>
                    <span>新規入力時の初期値</span>
                  </v-tooltip>
                </template>
                <template v-slot:append-outer v-if="isActive">
                  <v-btn icon @click="removeOption(index)" v-if="isShowRemoveButton(index)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-hover>
          </li>
        </ul>
        <v-row no-gutters v-if="isShowAddOption" :class="{ 'mt-2': !isMultipleOptions }">
          <v-col>
            <div class="pt-2 pl-6">
              <a @click="addOption">
                <span style="width: 25px"> </span>
                <span class="pt-1">{{ item.options.length + 1 }}. </span>
                選択肢を追加</a
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import Sortable from "sortablejs";

export default Vue.extend({
  name: "ItemMultipleChoice",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
    editIndex: {
      type: Number,
      required: false,
    },
    originalOptionsIndex: {
      type: Number,
      required: true,
    }
  },
  computed: {
    isMultipleOptions() {
      return this.item.options.length > 1;
    },
    isShowAddOption() {
      if (!this.isActive) {
        return false;
      }

      const originalOptionsLength = this.originalOptionsIndex + 1;
      if (this.isEditMode) {
        return this.item.isNewItem ? true : this.item.options.length === originalOptionsLength;
      }

      return true;
    },
  },
  methods: {
    setupDrag() {
      let list = this.$refs.itemRadioDrag;
      const _self = this;
      if (list) {
        Sortable.create(list, {
          handle: ".radio-drag-handle", // Use handle so user can select text
          animation: 150,
          chosenClass: "white",
          onEnd({ newIndex, oldIndex }) {
            _self.$emit("moveOption", newIndex, oldIndex);
          },
        });
      }
    },
    addOption() {
      this.$emit("addOption");
    },
    removeOption(index) {
      this.$emit("removeOption", index);
    },
    onChangeOption(value, index) {
      this.$emit("updateOption", index, value);
    },
    setDefaultValue(value) {
      if (this.item.default === value) {
        this.item.default = null;
        this.item.input = null;
      } else {
        this.item.default = value;
        this.item.input = value;
      }
    },
    isDefaultValue(value) {
      return this.item.default === value;
    },
    isReadOnly(index) {
      const isNotEditableIndex = this.editIndex !== index && this.originalOptionsIndex >= index && this.editIndex !== -1;
      return this.isEditMode && !this.item.isNewItem && isNotEditableIndex;
    },
    isShowRemoveButton(index) {
      if (this.isEditMode) {
        return this.isNewItem ? this.isMultipleOptions : index > this.originalOptionsIndex;
      } else {
        return this.isMultipleOptions;
      }
    },
  },
  mounted() {
    this.setupDrag();
  },
});
</script>

<style lang="less" scoped>
.radio-drag-handle {
  cursor: move;
}
.item-dropdown-default {
  border-bottom: 3px solid grey;
  font-weight: 500;
}
</style>
