<!--
Copyright 2022 LINE Fukuoka Corporation

LINE Corporation licenses this file to you under the Apache License,
version 2.0 (the "License"); you may not use this file except in compliance
with the License. You may obtain a copy of the License at:

  https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
-->
<template>
  <div>
    <v-container class="pa-0">
      <v-radio-group v-model="radio" :rules="[validateRequired]">
        <v-row
          no-gutters
          v-for="(sectionOption, index) in sectionOptions"
          :key="index"
        >
          <v-col :cols="cols.col1">
            <v-radio
              :class="isLiffMode ? 'my-1' : ''"
              :label="sectionOption.option.value"
              :value="sectionOption.option.value"
              style="display: flex"
              @click="radioClick"
              :disabled="disabled"
            ></v-radio>
          </v-col>
          <v-col v-if="isAdminMode" :cols="cols.col2">
            <v-text-field
              v-model="sectionOption.groupheader.value"
              :label="label(index)"
              dense
              flat
              type="number"
              :suffix="suffix"
              disabled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import InputChoiceGroupheader from "./InputChoiceGroupheader.vue";

import { isBlank } from "../utils/validationUtils";

export default Vue.extend({
  name: "InputPreviousVaccineMaker",
  mixins: [InputChoiceGroupheader],
  props: {
    mlLibJson:Object,
    isAdminMode: {
      type: Boolean,
      default: false,
    },
    isLiffMode: {
      type: Boolean,
      default: false,
    },
    isValidatePreviousVaccineMaker: Boolean,
    vaccinationIntervalType: {
      type: String,
      required: true,
    }
  },
  computed: {
    suffix() {
      switch (this.vaccinationIntervalType) {
        case "日数":
          return "日";
        case "月数":
          return "ヶ月";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      cols: {
        col1: 8,
        col2: 4,
      },
      isValid: true,
    };
  },
  created() {
    this.cols.col1 = this.isAdminMode ? 8 : 12;
  },
  methods: {
    label(index) {
      let labelName = "";
      switch (this.vaccinationIntervalType) {
        case "日数":
          labelName = "前回接種翌日からの間隔";
          break;
        case "月数":
          labelName = "前回接種からの間隔";
          break
        default:
          break;
      }
      return index === 0 ? labelName : null;
    },
    validateRequired() {
      if (this.params.isRequired?.value) {
        return !isBlank(this.radio);
      }
      if (this.isAdminMode) {
        return this.isValid;
      }
      if (this.isLiffMode) {
        return !isBlank(this.radio);
      }
      return true;
    },
    radioClick() {
      this.$emit(
        "choice-groupheader-radio-click",
        this.getValue(),
        this.params
      );
      this.isValid = !isBlank(this.radio);
    },
  },
  watch: {
    isValidatePreviousVaccineMaker(value) {
      this.isValid = value;
    },
  },
});
</script>
