<template>
  <div :class="setClass()">
    <!-- <v-card> -->
      <v-card-actions style="max-height:50px;" v-if="!isEnquete">
        <div class="text-right ma-4 row">
          <v-btn color="primary" text @click="$router.push({ name: 'DeliveryList' })">
              <v-icon left>mdi-arrow-left-circle</v-icon>前へ戻る
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div v-if="canEdit">
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)"
              color="grey lighten-2" elevation="1" class="mx-2" @click="testDelFlag = true">テスト配信</v-btn>
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)"
              color="primary" elevation="0" class="mx-2" @click="send()">配信</v-btn>
          </div>
          <div v-if="!canEdit">
            <v-btn v-if="!isReserve" color="primary" class="mx-2" elevation="0" @click="canEdit = true">複製して編集</v-btn>
            <v-btn v-if="isReserve" color="red" class="mx-2" elevation="0" @click="del()">予約を取り消し</v-btn>
            <v-btn v-if="isReserve" color="primary" class="mx-2" elevation="0" @click="canEdit = true">編集</v-btn>
          </div>
        </div>
      </v-card-actions>
      <v-divider v-if="!isEnquete"></v-divider>
      <v-form @submit.prevent v-model="isValid">
        <v-container fluid v-if="enqueteTabType != 2">

          <v-row no-gutters dense v-if="enqueteTabType == 0">
            <v-col :cols="fieldsCols.label">
              <v-subheader>アンケートタイプ</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group hide-details="auto" row :disabled="!canEdit" v-model="deliveryDetail.enqueteType" class="mt-2 radioFlex">
                <v-radio v-for="(enqueteType, index) in enqueteTypes" :key="index" :label=enqueteType.label
                  :value="enqueteType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ enqueteType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 
          
          <v-row no-gutters>
            <v-col :cols="fieldsCols.label">
              <template v-if="isEnquete">
                <v-subheader>アンケート名</v-subheader>
              </template>
              <template v-else>
                <v-subheader>配信名</v-subheader>
              </template>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-text-field 
                :disabled="!canEdit" 
                v-model="deliveryDetail.deliveryName" 
                outlined 
                dense 
                hide-details="auto"
                :placeholder="isEnquete ? 'アンケート配信名': '配信名'" 
                :rules="[required('配信名'), max_length_30]"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters dense>
            <v-col :cols="fieldsCols.label">
              <template v-if="isEnquete">
                <v-subheader>実施対象者</v-subheader>
              </template>
              <template v-else>
                <v-subheader>配信先</v-subheader>
              </template>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group :rules="canEdit ? [requiredRadio('配信先')] : []" hide-details="auto" row :disabled="!canEdit" v-model="deliveryDetail.segmentType" class="mt-2 radioFlex">
                <v-radio v-for="(segmentType, index) in segmentTypes" :key="index" :label=segmentType.label
                  :value="segmentType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ segmentType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 

          <v-row no-gutters dense>
            <v-col :cols="fieldsCols.label">
              <v-subheader>学年</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group mandatory @change="gradeChange()"
                :rules="canEdit && (deliveryDetail.segmentType == 'GRADE' || deliveryDetail.segmentType == 'CLASS' || deliveryDetail.segmentType == 'PERSONAL') ? [requiredRadioGrade('学年')]: []"
                row hide-details="auto"
                :disabled="!canEdit || (deliveryDetail.segmentType != 'GRADE' && deliveryDetail.segmentType != 'CLASS' && deliveryDetail.segmentType != 'PERSONAL')"
                v-model="selectedGrade" class="mt-2 radioFlex">
                <v-radio v-for="(grade, idx) in gradeClassAll?.grade" :key="idx" :label="grade.school_grade"
                  :value="grade.grade_id">
                  <template v-slot:label><v-card-text class="pa-0">{{ grade.school_grade }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters dense>
            <v-col :cols="fieldsCols.label">
              <v-subheader>組</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group row hide-details="auto" mandatory @change="classChange()"
                :rules="canEdit && (deliveryDetail.segmentType == 'CLASS' || deliveryDetail.segmentType == 'PERSONAL') ? [requiredRadioClass('組')]: []" 
                :disabled="!canEdit || (deliveryDetail.segmentType != 'CLASS' && deliveryDetail.segmentType != 'PERSONAL')"
                v-model="selectedClass" class="mt-2 radioFlex">
                <v-radio v-for="(classroom, idx) in classroomItems" :key="idx" :label="classroom.school_class"
                  :value="classroom.classroom_id">
                  <template v-slot:label><v-card-text class="pa-0">{{ classroom.school_class }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row no-gutters dense>
            <v-col :cols="fieldsCols.label">
              <v-subheader>生徒</v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <div class="d-flex">
                <v-select hide-details="auto" clearable label="生徒" :disabled="!canEdit || deliveryDetail.segmentType != 'PERSONAL'" @change="studentChange()"
                  v-model="selectedStudent" :items="selectedClassStudent"
                  :rules="deliveryDetail.segmentType === 'PERSONAL' ? [select] : []"
                  :item-text="item => (item.number + '番 : ' + item.last_name + item.first_name)" item-value="student_id"
                  density="comfortable"></v-select>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters dense v-if="!isEnquete">
            <v-col :cols="fieldsCols.label">
            </v-col>
            <v-col :cols="fieldsCols.input">
              <div class="d-flex">
                <v-checkbox
                  :disabled="!canEdit"
                  hide-details="auto"
                  v-model="deliveryDetail.isStaffDelivery"
                  label="配信先に職員グループを含める"
                ></v-checkbox>
              </div>
              <v-autocomplete v-if="deliveryDetail.isStaffDelivery" :no-data-text="noDataText" :disabled="!canEdit"
                dense hide-details="auto" v-model="deliveryDetail.staff_group" :items="allStaffGroupList"
                item-text="group_name" item-value="staff_group_id"  multiple chips :rules="[multiAutocomplete]"
              ></v-autocomplete>
            </v-col>
          </v-row>

        </v-container>
        <!-- アンケートの場合、公開期間の開始日が当日の場合は即時公開・未来の場合は公開予約の値が入るように修正 -->
        <v-row no-gutters v-if="!isEnquete">
          <v-col :cols="fieldsCols.label">
            <template v-if="isEnquete">
              <v-subheader>公開日時</v-subheader>
            </template>
            <template v-else>
              <v-subheader>配信日時</v-subheader>
            </template>
          </v-col>
          <v-col :cols="fieldsCols.input">
            <v-radio-group :disabled="!canEdit" v-model="deliveryDetail.deliveryTiming" class="mt-2">
              <v-radio label="今すぐ配信" value="IMMEDIATE" class="fit-content-width">
                <template v-slot:label v-if="isEnquete">
                  <v-card-text class="pa-0">即時公開</v-card-text>
                </template>
                <template v-slot:label v-else>
                  <v-card-text class="pa-0">今すぐ配信</v-card-text>
                </template>
              </v-radio>
              <v-radio label="予約配信" value="ONETIME" class="fit-content-width">
                <template v-slot:label v-if="isEnquete">
                  <v-card-text class="pa-0">公開予約</v-card-text>
                </template>
                <template v-slot:label v-else>
                  <v-card-text class="pa-0">予約配信</v-card-text>
                </template>
              </v-radio>
              <v-row no-gutters class="pl-8">
                <v-col :cols="fieldsCols.input">
                  <v-row no-gutters>
                    <v-col cols="12" sm="auto" class="pr-2">
                      <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                        min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :disabled="!canEdit || deliveryDetail.deliveryTiming == 'IMMEDIATE'"
                            v-model="deliveryDetail.oneTimeDetail.date" readonly outlined dense
                            prepend-inner-icon="mdi-calendar-outline"
                            :rules="deliveryDetail.deliveryTiming === 'ONETIME' ? [required('配信日')] : []"
                            placeholder="YYYY-MM-DD" clearable hide-details v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="startMinDate" :max="String(Number(fiscalYear) + 1) + '-03-31'" v-if="fromDateMenu"
                          v-model="deliveryDetail.oneTimeDetail.date" no-title color="primary" locale="jp-ja"
                          :day-format="(date) => new Date(date).getDate()">
                        </v-date-picker>
                      </v-menu>
                      <v-text-field hide-details="auto" :disabled="!canEdit || deliveryDetail.deliveryTiming == 'IMMEDIATE'"
                        v-model="deliveryDetail.oneTimeDetail.time" type="time"
                        :rules="deliveryDetail.deliveryTiming === 'ONETIME' ? [required('配信時刻')] : []"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <div
                        v-if="(!isdeliveryScheduleAfterNow() && deliveryDetail.deliveryTiming == 'ONETIME' && deliveryDetail.oneTimeDetail.date != '' && deliveryDetail.oneTimeDetail.time != '' && canEdit)"
                        style="color:red">
                        予約時刻は現在より後の時刻に設定してください
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- 繰り返し予約配信 後々追加予定の為一旦コメント -->
              <!-- <v-radio value="RECURRING" class="pt-4 fit-content-width">
              <template slot="label">
                <v-row no-gutters>
                  <v-col cols="12" sm="auto">
                    <v-btn :disabled="!canEdit" color="primary" elevation="0"
                      @click="openRecurringSettingsModal">繰り返し予約配信</v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-radio>
            <v-row no-gutters class="px-7" align="center">
              <v-col cols="12" sm="auto" class="pa-1">
                <v-text-field v-model="deliveryDetail.recurringDetail.startDate" filled dense readonly
                  :disabled="(deliveryDetail.deliveryTiming !== 'RECURRING') || (deliveryDetail.recurringDetail.startDate !== '')"
                  :error="(deliveryDetail.deliveryTiming === 'RECURRING') && (deliveryDetail.recurringDetail.startDate === '')"
                  :outlined="(deliveryDetail.deliveryTiming === 'RECURRING') && (deliveryDetail.recurringDetail.startDate === '')"
                  hide-details="auto" style="max-width: 180px"></v-text-field>
              </v-col>
              <span>-</span>
              <v-col cols="12" sm="auto" class="pa-1">
                <v-text-field v-model="deliveryDetail.recurringDetail.endDate" filled dense readonly
                  :disabled="(deliveryDetail.deliveryTiming !== 'RECURRING') || (deliveryDetail.recurringDetail.endDate !== '')"
                  :error="(deliveryDetail.deliveryTiming === 'RECURRING') && (deliveryDetail.recurringDetail.endDate === '')"
                  :outlined="(deliveryDetail.deliveryTiming === 'RECURRING') && (deliveryDetail.recurringDetail.endDate === '')"
                  hide-details="auto" style="max-width: 180px"></v-text-field>
              </v-col>
              <v-col cols="12" sm="auto" class="pa-1">
                <v-text-field v-model="deliveryDetail.recurringDetail.periodicType" filled dense readonly disabled
                  hide-details="auto" style="max-width: 100px"></v-text-field>
              </v-col>
              <v-col cols="12" sm="auto" class="pa-1">
                <v-text-field v-model="deliveryDetail.recurringDetail.eliminateStatus" filled dense readonly disabled
                  hide-details="auto" style="max-width: 120px"></v-text-field>
              </v-col>
            </v-row> -->
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="isEnquete && enqueteTabType != 2">
          <v-col :cols="fieldsCols.label">
            <v-subheader>公開期間</v-subheader>
          </v-col>
          <v-col cols="auto">
            <v-menu v-model="publicFromDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
              min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                  v-model="deliveryDetail.startDate" 
                  readonly 
                  outlined 
                  dense 
                  prepend-inner-icon="mdi-calendar-outline"
                  placeholder="YYYY-MM-DD" 
                  hide-details 
                  v-bind="attrs" 
                  v-on="on"
                  :disabled="!canEdit">
                </v-text-field>
              </template>
              <v-date-picker 
                v-model="deliveryDetail.startDate" 
                no-title 
                color="primary" 
                locale="jp-ja"
                :day-format="(date) => new Date(date).getDate()"
                :min="startMinDate" 
                :max="String(Number(fiscalYear) + 1) + '-03-31'">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto"><v-subheader>~</v-subheader></v-col>
          <v-col cols="auto">
            <v-menu v-model="publicToDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y
              min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field 
                  v-model="deliveryDetail.endDate" 
                  readonly 
                  outlined 
                  dense 
                  prepend-inner-icon="mdi-calendar-outline"
                  placeholder="YYYY-MM-DD" 
                  clearable 
                  hide-details 
                  v-bind="attrs" 
                  v-on="on"
                  :disabled="!canEdit">
                </v-text-field>
              </template>
              <v-date-picker 
                v-model="deliveryDetail.endDate" 
                no-title color="primary" 
                locale="jp-ja"
                :day-format="(date) => new Date(date).getDate()"
                :min="deliveryDetail.startDate" 
                :max="String(Number(fiscalYear) + 1) + '-03-31'">
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <template v-if="enqueteTabType == 2">
          <v-row no-gutters dense>
            <v-col :cols="fieldsCols.label">
              <v-subheader>新着情報への反映
              </v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group hide-details="auto" row :disabled="!canEdit" v-model="settings.isLatest" class="mt-2 radioFlex">
                <v-radio v-for="(doNotOrDoType, index) in doNotOrDoTypes" :key="index" :label=doNotOrDoType.label
                  :value="doNotOrDoType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ doNotOrDoType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 
          <v-row no-gutters dense v-if="isEnquete">
            <v-col :cols="fieldsCols.label">
              <v-subheader>保護者への通知
              </v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group hide-details="auto" row :disabled="!canEdit" v-model="settings.isParentNotification" class="mt-2 radioFlex">
                <v-radio v-for="(doNotOrDoType, index) in doNotOrDoTypes" :key="index" :label=doNotOrDoType.label
                  :value="doNotOrDoType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ doNotOrDoType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 
          <v-row no-gutters dense v-if="!isEnquete">
            <v-col :cols="fieldsCols.label">
              <v-subheader>職員への通知
              </v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group hide-details="auto" row :disabled="!canEdit" v-model="settings.isStaffNotification" class="mt-2 radioFlex">
                <v-radio v-for="(doNotOrDoType, index) in doNotOrDoTypes" :key="index" :label=doNotOrDoType.label
                  :value="doNotOrDoType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ doNotOrDoType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 
          <v-row no-gutters dense v-if="isEnquete">
            <v-col :cols="fieldsCols.label">
              <v-subheader>リマインド配信
              </v-subheader>
            </v-col>
            <v-col :cols="fieldsCols.input">
              <v-radio-group hide-details="auto" row :disabled="!canEdit" v-model="settings.isRemind" class="mt-2 radioFlex">
                <v-radio v-for="(yesOrNoType, index) in yesOrNoTypes" :key="index" :label=yesOrNoType.label
                  :value="yesOrNoType.value">
                  <template v-slot:label><v-card-text class="pa-0">{{ yesOrNoType.label }}</v-card-text></template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row> 
          <v-row align="center" no-gutters class="mb-2" dense v-if="settings.isRemind">
            <v-col :cols="fieldsCols.label">
              <!-- <v-subheader>リマインド配信
              </v-subheader> -->
            </v-col>
            <v-col :cols="1" class="align-items-center">
              <span>公開終了日の</span>
            </v-col>
            <v-col :cols="2" class="align-items-center">
              <v-text-field
                v-model="settings.daysBefore"
                dense
                hide-details="auto"
                outlined
                type="number"
                :min="0"
                :rules="daysBeforRules"
                :disabled="!canEdit"
              ></v-text-field>
            </v-col>
            <v-col :cols="1" class="align-items-center" justify="center">
              <span class="ml-2">日前</span>
            </v-col>
            <v-col :cols="2" class="align-items-center">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ attrs }">
                  <v-text-field
                    v-model="settings.time"
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    type="time"
                    :disabled="!canEdit"
                  ></v-text-field>
                </template>
              </v-menu>
            </v-col>
          </v-row> 
        </template>
        
        <v-divider v-if="enqueteTabType != 2"></v-divider>
        <v-row no-gutters class="my-2" v-if="enqueteTabType != 2">
          <v-col :cols="2" class="d-flex align-center">
            <v-subheader>装飾モードON</v-subheader>
            <v-switch
              class="mt-0"
              v-model="deliveryDetail.flexUseFlg"
              :input-value=false
              hide-details
              :disabled="!canEdit"
              @change="showDialog"
            ></v-switch>
          </v-col>
        </v-row>
        <div v-for="(message, messageIndex) in deliveryDetail.messages" :key="message.id">
          <v-card class="ma-4" outlined tile>
            <v-toolbar color="grey lighten-2" dense elevation="0">
              <v-btn-toggle v-if="canEdit" dense group>
                <v-btn :disabled="deliveryDetail.messages.length == 1" color="black" class="my-3" elevation="0"
                  @click="deleteTextArea(message.id, messageIndex)">削除</v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>

              <div v-if="canEdit">
                <v-btn-toggle dense>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" @click="selectText(messageIndex)">
                        <v-icon color="white">
                          mdi-format-title
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>テキスト</span>
                  </v-tooltip>

                  <v-tooltip top v-if="!isEnquete || (isEnquete && enqueteTabType != 2)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" @click="message.type = 'image'">
                        <v-icon color="white">
                          mdi-image
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>画像</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="primary" elevation="0" @click="message.type = 'sticker'">
                        <v-icon color="white">
                          mdi-emoticon-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>スタンプ</span>
                  </v-tooltip>

                </v-btn-toggle>
              </div>

            </v-toolbar>

            <v-container v-if="message.type == 'text'">
              <item-text
                :value="message.text"
                :rules="textMessageRules"
                :index="messageIndex"
                :passedByParentsPositionInfo="deliveryDetail.messages"
                :messages="deliveryDetail.messages"
                :flexUseFlg="deliveryDetail.flexUseFlg"
                textcolor="primary"
                :canEdit="canEdit"
                :title="deliveryDetail.deliveryName"
                @onChangeTextMessage="onChangeTextMessage($event, messageIndex)"
              />
            </v-container>

            <v-container v-if="message.type == 'flex' && url[message.id] == null">
              <div>
                <v-row>
                  <v-col cols="8">
                    <template>
                      <item-flex
                        :value="message.text"
                        :rules="textMessageRules"
                        :index="messageIndex"
                        :messages="deliveryDetail.messages"
                        textcolor="primary"
                        :canEdit="canEdit"
                        @onChangeMarkdownMessage="onChangeMarkdownMessage($event, messageIndex)"
                      />
                    </template>
                  </v-col>
                  <v-col cols="4">
                      <div class="line-preview-box mt-16">
                          <div class="line-preview-elements">
                            <template>
                              <BubbleFlexPreview :isFirstFlex="isFirstFlex(deliveryDetail.messages, messageIndex)" :title="deliveryDetail.deliveryName" :message="deliveryDetail.messages[messageIndex].flexMessage"/>
                            </template>
                          </div>
                      </div>
                  </v-col>
                </v-row>
              </div>
            </v-container>

            <v-container v-if="message.type == 'flex' && url[message.id] != null">
              <div class="preview_zone" v-if="!imageLoading">
                <img style="max-width: 500px; max-height: 500px;" :src="url[message.id]" v-if="url" />
              </div>
              <div v-else>
                <v-card-text>
                  読み込んでいます。しばらくお待ちください。<br>
                  画像サイズが大きいなど、時間がかかる場合があります。
                </v-card-text>
              </div>
              <div class="detailPair">
                <div class="elem">
                  <div class="upload_zone">
                    <v-file-input v-if="canEdit" :clearable="false" v-model="inputImageValue[message.id]"
                      @change="inputImage(message.id, messageIndex)" :rules="message.type === 'image' ? [validateFile] : []"
                      accept=".png,.jpg,.jpeg,.pdf" label="写真をアップロード" outlined></v-file-input>
                  </div>
                </div>
              </div>
            </v-container>

            <v-container v-if="message.type == 'image'">
              <div class="preview_zone" v-if="!imageLoading">
                <img style="max-width: 500px; max-height: 500px;" :src="url[message.id]" v-if="url" />
              </div>
              <div v-else>
                <v-card-text>
                  読み込んでいます。しばらくお待ちください。<br>
                  画像サイズが大きいなど、時間がかかる場合があります。
                </v-card-text>
              </div>
              <div class="detailPair">
                <div class="elem">
                  <div class="upload_zone">
                    <v-file-input v-if="canEdit" :clearable="false" v-model="inputImageValue[message.id]"
                      @change="inputImage(message.id, messageIndex)" :rules="message.type === 'image' ? [validateFile] : []"
                      accept=".png,.jpg,.jpeg,.pdf" label="写真をアップロード" outlined></v-file-input>
                  </div>
                </div>
              </div>
            </v-container>

            <v-container v-if="message.type == 'sticker'">
              <v-row>
                <v-col :cols="canEdit ? 3 : 12">
                  <v-card style="background-color: #8cabd9;max-width: 100%; height: 350px;" hide-details>
                    <div class="selectStickerArea" v-if="message.packageId">
                      <v-img :rules="[false]" :src="serchImgUrl(message.packageId, message.stickerId)"
                        style="margin-top: auto; height: 100%; width: 100%;"></v-img>
                    </div>
                  </v-card>
                </v-col>
                <v-col v-if="canEdit" cols="9">
                  <v-card style="height: 350px;" class="stickerListArea" outlined hide-details height="300px">
                    <v-row no-gutters>
                      <v-col>
                        <v-card style="position: sticky; top: 0; margin-top: 0%; z-index: 7;" elevation="4">
                          <v-tabs v-model="tab" icons-and-text>
                            <div v-for="(stickers, index) in lineStickerList()" :key="index">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-tab style="height: 100%;" v-bind="attrs" v-on="on">
                                    <v-img style="height: 100%;" :src=stickers.child[0].url max-height="72"
                                      max-width="72"></v-img>
                                  </v-tab>
                                </template>
                                <span>
                                  {{ stickers.nameJP }}
                                </span>
                              </v-tooltip>
                            </div>
                          </v-tabs>
                        </v-card>
                        <v-tabs-items v-model="tab">
                          <v-tab-item v-for="(stickers, index) in lineStickerList()" :key="index">
                            <v-row>
                              <div v-for="(sticker, index) in stickers.child" :key="index">
                                <v-col
                                  @click="stickerClick(messageIndex, stickers.packageId, sticker.stickerId)">
                                  <v-img :contain=true max-height="72" max-width="72" :src=sticker.url></v-img>
                                </v-col>
                              </div>
                            </v-row>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

          </v-card>
        </div>
      </v-form>
      <v-container fluid>
        <v-btn v-if="canEdit" color="primary" class="mx-1 mb-4" elevation="0" outlined :disabled="isMax"
          @click="addMessage">
          <v-icon left>mdi-plus</v-icon>追加
        </v-btn>
        <v-divider v-if="!isEnquete"></v-divider>
        <div class="text-center" v-if="!isEnquete">
          <div v-if="canEdit">
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)"
              color="grey lighten-2" elevation="1" class="mt-4 mr-2" @click="testDelFlag = true">テスト配信</v-btn>
            <v-btn :loading="loading || imageLoading" :disabled="(!isValid || !isdeliveryScheduleAfterNow() || textLengthError)"
              color="primary" elevation="0" class="mt-4" @click="send()">配信</v-btn>
          </div>
          <v-btn v-if="!canEdit && !isReserve" color="primary" class="mt-4" elevation="0" @click="canEdit = true">複製して編集</v-btn>
          <v-btn v-if="isReserve" color="primary" class="mx-2" elevation="0" @click="canEdit = true">編集</v-btn>
        </div>
      </v-container>
      <div class="message-preview" v-if="!isEnquete || (isEnquete && enqueteTabType != 2)">
        <v-menu v-model="preview" top offset-y nudge-top="5px" opacity="0" :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-4" color="primary" min-width="320" v-bind="attrs" v-on="on" @click="preview = !preview">
              <v-icon left> {{ preview ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
              プレビュー
            </v-btn>
          </template>
          <div>
            <MessagePreview :title="deliveryDetail.deliveryName" :messages="deliveryDetail.messages" :positionInfo="emojisPositionInfo" :preview="preview"/>
          </div>
        </v-menu>
      </div>
      <DisplayFlexSegmentDeliveryModal
        v-model="isShowDialog"
        color="primary"
        @colse="closeDialog"
        @onChangeDecorationMode="onChangeDecorationMode"
      />
      <v-snackbar v-model="snackbar" multi-line>
        {{ snackbarText }}
      </v-snackbar>
      <TestDeliveryDialog v-if="testDelFlag" :loading="loading" :staff_group="allStaffGroupList" @sendTestDel="sendTestDel" @endTestDel="endTestDel"/>
    <!-- </v-card> -->
    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          読み込んでいます
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions } from "vuex";
import {
  GET_STAFF_GROUP_ALL,
  GET_STAFF_DELIVERY_TARGET,
  GET_GRADE_CLASS_ALL,
  GET_STUDENT_BY_CLASSROOM_ID,
  SAVE_DELIVERY_IMG_TO_S3,
  SEND_DELIVERY,
  UPDATE_DELIVERY,
  DELETE_DELIVERY,
  SEND_TEST_DELIVERY,
  SAVE_DELIVERY_PDF_TO_S3,
} from "@/store/action-types";
import {
  SET_CLASSROOM_ALL,
  SET_SELECTED_CLASSROOM_STUDENT,
  SET_SELECTED_CLASSROOM,
  SET_DELIVERY_IMGS,
  CLEAR_DELIVERY_IMGS,
  SET_DELIVERY_IMGS_NAME,
  SET_DELIVERY_DETAIL,
  SET_ENQUETE_DELIVERY_DETAIL,
  SET_ENQUETE_SETTINGS,
  SET_DELIVERY_PDFS,
  CLEAR_DELIVERY_PDFS,
  SET_DELIVERY_PDFS_NAME,
} from "@/store/mutation-types";
import {
  REQUIRED_ERROR_MSG,
  NO_DATA_TEXT,
  LINE_STICKER
} from "@/constants";
import { cloneDeep } from "lodash";
import ItemText from "./deliveryMessages/ItemText.vue";
import ItemFlex from "./deliveryMessages/ItemFlex.vue";
import MessagePreview from "./deliveryMessages/MessagePreview.vue";
import BubbleFlexPreview from "./fragments/BubbleFlexPreview.vue"
import DisplayFlexSegmentDeliveryModal from "./fragments/DisplayFlexSegmentDeliveryModal.vue";
import TestDeliveryDialog from "./TestDeliveryDialog.vue";
import { parseProductIdEmojiIdFromUrlOrFalse } from "@/store/modules/segments/segments-utils";
import * as PDFJS from 'pdfjs-dist'
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].[contenthash].js!pdfjs-dist/legacy/build/pdf.worker.js");
PDFJS.GlobalWorkerOptions.workerPort = new PdfjsWorker();

const MAX_MESSAGES_LENGTH = 4;

export default {
  name: "DeliveryCreation",
  props: {
    item: {
      type: Object,
      default: null,
    },
    // アンケート画面 or 配信画面
    isEnquete: {
      type: Boolean,
      default: false,
    },
    // 0 == アンケートタブ, 1 == 回答タブ, 2 == 設定タブ
    enqueteTabType: {
      type: Number,
      default: null,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    enqueteActiveTab:{
      type:String,
      default: null,
    }
  },
  data() {
    return {
      testDelFlag: false,
      noDataText : NO_DATA_TEXT,
      allStaffGroupList: null,
      tab: null,
      imageLoading: false,
      snackbar: false,
      snackbarText: null,
      loading: false,
      inputImageValue: [],
      isValid: false,
      isEditModeValue: false,
      canEdit: true,
      isReserve: false,
      image: [],
      url: [],
      radioGroup: 1,
      segmentDialog: false,
      delivery: null,
      positionInfo: 1,
      selectedGrade: null,
      selectedClass: null,
      selectedStudent: null,
      classroomItems: null,
      fromDateMenu: false,
      fieldsCols: {
        label: 2,
        input: 8
      },
      grades: [],
      segmentTypes: [],
      deliveryDetail: {
        deliveryName: "",
        enqueteType: 0,
        segmentType: "",
        targets: "",
        segmentDetail: {},
        deliveryTiming: "IMMEDIATE",
        oneTimeDetail: {
          date: "",
          time: ""
        },
        recurringDetail: {
          startDate: "2023/1/30",
          endDate: "2023/2/28",
          periodicType: "毎日",
          eliminateStatus: "除外日あり",
          eliminateSetting: ["2023/1/31", "2023/2/15"]
        },
        startDate: moment().format("YYYY-MM-DD"), // アンケート開始日
        endDate: "", // アンケート終了日
        flexUseFlg: false,
        messages: [],
      },
      /**
       * アンケート機能
       */
      settings: {
        isLatest: 0,
        isParentNotification: 0,
        isStaffNotification: 0,
        isRemind: 0,
        daysBefore: 0,
        time: "",
      },
      enqueteTypes: [
        {
          label: "LINE",
          value: 0
        },
        {
          label: "外部",
          value: 1
        },
      ],
      doNotOrDoTypes: [
        {
          label: "しない",
          value: 0
        },
        {
          label: "する",
          value: 1
        },
      ],
      yesOrNoTypes: [
        {
          label: "OFF",
          value: 0
        },
        {
          label: "ON",
          value: 1
        },
      ],
      publicFromDateMenu: false,
      publicToDateMenu: false,
      startMinDate: moment().format("YYYY-MM-DD"),
      /**
       * 絵文字関連data
       *  */
      textMessageRules: [(v) => !!v || "テキストは必須入力です。"],
      emojisPositionInfo: [
        {
          emojis: [],
          text: "",
        }
      ],
      preview: false,
      textLengthError: false,
      isShowDialog: false,
      select: (v) => (v != null && v != -1) || "選択してください。",
      multiAutocomplete:(v) => this.multiAutocompleteValid(v) || "選択してください。",
      max_length_30: value => value.length <= 30 || "30文字以内で入力してください。",
      max_length_255: value => value.length <= 255 || "255文字以内で入力してください。",
      max_length_3000: value => value.length <= 3000 || "3000文字以内で入力してください。",
      daysBeforRules: [(v) =>  (!!v && v >= 0) || ''],
    };
  },
  components: {
    ItemText,
    ItemFlex,
    MessagePreview,
    BubbleFlexPreview,
    DisplayFlexSegmentDeliveryModal,
    TestDeliveryDialog,
  },
  computed: {
    isMax: {
      get() {
        return this.deliveryDetail.messages.length > MAX_MESSAGES_LENGTH;
      }
    },
    ...mapState({
      deliveryImgs: state => state.deliveryImgs,
      gradeClassAll: state => state.gradeClassAll,
      selectedClassStudent: state => state.selectedClassStudent,
      selectedClassroom: state => state.selectedClassroom,
      deliveryImgsUrl: state => state.deliveryImgsUrl,
      loginUser: state => state.loginUser,
      fiscalYear: state => state.fiscalYear,
      emojiOver20Error: state => state.emojiOver20Error,
      // アンケート開催情報
      enqueteOpenInfo: state => state.enqueteOpenInfo,
      isUnPublished: state => state.isUnPublished,

      deliveryPdfs: state => state.deliveryPdfs,
      deliveryPdfsUrl: state => state.deliveryPdfsUrl,
    }),
    deliveryModeTitle() {
      return this.isEditModeValue ? "配信編集" : "新規配信"
    },
  },
  watch: {
    enqueteActiveTab() {
      this.preview = false;
    },
    'deliveryDetail': {
      handler: async function (val) {
        if (this.isEnquete) {
          if (this.enqueteTabType === 0) {
            let deliveryDetail = cloneDeep(val);

            // 配信区分の設定を行う
            const targetObj = this.createTargetObj(deliveryDetail.segmentType)
            deliveryDetail.targets = targetObj
            
            this.setEnqueteDeliveryDetail(deliveryDetail)
          } else if (this.enqueteTabType === 2) {
            console.log('val', val)
          }
        }
      },
      deep: true,
    },
    'deliveryDetail.segmentType': {
      handler: function (val) {
        if (val === "PERSONAL") {
          this.fetchStudentByClassId();
        }
      }
    },
    'deliveryDetail.oneTimeDetail.date' : {
      handler: function () {
        this.fromDateMenu = false;
      },
    },
    'deliveryDetail.oneTimeDetail': {
      handler: function () {
        this.deliveryScheduleText = this.deliveryDetail.oneTimeDetail.date + ' ' + this.deliveryDetail.oneTimeDetail.time
      },
      deep: true,
    },
    // gradeClassAll(val) {
    //   if (this.canEdit == true) {
    //     this.selectedGrade = val.grade[0].grade_id;
    //   }
    // },
    selectedGrade() {
      // this.changeSelectedClass();
      // this.selectedGradesClass();
      // this.changeDetailTargets();
    },
    // classroomItems(val) {
    //   if (this.canEdit == true) {
    //     this.selectedClass = val[0].classroom_id;
    //   }
    // },
    selectedClass() {
      // this.changeSelectedClass()
      // this.changeDetailTargets()
    },
    selectedStudent() {
      // this.changeDetailTargets();
    },
    'deliveryDetail.flexUseFlg': {
      handler: function (val) {
        // メッセージタイプをflexUseFlgを元にtextまたは、flexに変更する
        this.deliveryDetail.messages.map(message => {
          if (message.type === "text" || message.type === "flex") {
            // flexUseFlgの値を元に変更する
            if (val) {
              // flexUseFlgがtrueの場合は、flexに変更
              message.type = "flex";
            } else {
              // pdfメッセージではない場合
              if(!message.pdfUrl) {
                // flexUseFlgがfalseの場合は、textに変更
                message.type = "text";
              }
            }
          }
          return message;
        });
      },
      deep: true,
    },
    'settings': {
      handler: async function (val) {
        if (this.isEnquete) {
          let settings = cloneDeep(val);
          // メッセージを設定
          settings.messages = cloneDeep(this.deliveryDetail.messages);
          
          this.setEnqueteSettings(settings);
        }
      },
      deep: true,
    },
    'deliveryDetail.messages': {
      handler: async function (val) {
        if (this.enqueteTabType == 2) {
          let settings = cloneDeep(this.settings);
          // メッセージを設定
          settings.messages = cloneDeep(val);
          
          this.setEnqueteSettings(settings);
        }
      },
      deep: true,
    },
    isCopy(val) {
      // 編集可否フラグ
      this.canEdit = val;
    },
    'deliveryDetail.enqueteType': {
      handler: async function (val) {
        this.$emit("onChangeEnqueteType", val);
      },
      deep: true,
    },
    emojiOver20Error(val) {
      // テキスト入力エリアのエラーを反映
      this.textLengthError = val;
    },
  },
  methods: {
    gradeChange(){
      this.changeSelectedClass();
      this.selectedGradesClass();
      this.changeDetailTargets();
    },
    classChange(){
      this.changeSelectedClass()
      this.changeDetailTargets()
    },
    studentChange() {
      this.changeDetailTargets();
    },
    // selectedGrade selectedClass selectedStudentのwatchから呼ばれる
    // targetのOBJを作ってstoreのアンケートデリバリーデタイルに保存
    changeDetailTargets() {
      const enqueteDeliveryDetailObj = cloneDeep(this.deliveryDetail)
      const targetObj = this.createTargetObj(enqueteDeliveryDetailObj.segmentType)
      enqueteDeliveryDetailObj.targets = targetObj
          
      this.setEnqueteDeliveryDetail(enqueteDeliveryDetailObj)
    },
    createTargetObj(segmentType) {
      let targetObj = ""
      switch (segmentType) {
        case "SCHOOL":
          targetObj = ""
          break;
        case "GRADE":
          targetObj = {
          "grade_id" : this.selectedGrade,
          "class_id" : "",
          "student_id" : "",
        }
          break;
        case "CLASS":
          targetObj = {
            "grade_id" : this.selectedGrade,
            "class_id" : this.selectedClass,
            "student_id" : "",
          }
          break;
        case "PERSONAL":
          targetObj = {
            "grade_id" : this.selectedGrade,
            "class_id" : this.selectedClass,
            "student_id" : this.selectedStudent
          }
          break;
        default:
          break;
        }
      return targetObj
    },
    isFirstFlex(message, index) {
      let firstMsg = null
      for(let i = 0; i < message.length; i++) {
        if (message[i].type == 'text' || message[i].type == 'flex') {
          firstMsg = i
          break
        } 
      }
      return firstMsg == Number(index) ? true : false
    },
    async sendTestDel(selected_staff_group) {
      this.loading = true

      await this.saveDeliveryImgToS3()
      await this.saveDeliveryPdfToS3()
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (i in (this.deliveryImgs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
          }
        }
        if (i in (this.deliveryPdfs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
            this.deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
            this.deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
          }
        }
      }

      this.setDeliveryDetail(this.deliveryDetail)

      let res = null
      console.log('配信を送信します')
      res = await this.sendTestDelibery(selected_staff_group);
      console.log('配信の送信を終えました')
      
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = 'テスト配信でエラーが発生しました。ページを再読み込みしてください。'
      } else {
        this.snackbar = true
        this.snackbarText = 'テスト配信が完了しました。'
      }
      this.loading = false
      this.testDelFlag = false
    },
    endTestDel() {
      this.testDelFlag = false;
    },
    multiAutocompleteValid(v){
      return (v.length == 0 ? false : true)
    },
    ...mapActions({
      get_staff_delivery_target: GET_STAFF_DELIVERY_TARGET,
      getStaffGroupAll: GET_STAFF_GROUP_ALL,
      getGradeClassAll: GET_GRADE_CLASS_ALL,
      getStudentByClassId: GET_STUDENT_BY_CLASSROOM_ID,
      saveDeliveryImgToS3: SAVE_DELIVERY_IMG_TO_S3,
      sendDelivery: SEND_DELIVERY,
      updateDelivery: UPDATE_DELIVERY,
      deleteDelivery: DELETE_DELIVERY,
      sendTestDelibery: SEND_TEST_DELIVERY,
      saveDeliveryPdfToS3: SAVE_DELIVERY_PDF_TO_S3,
    }),
    ...mapMutations({
      setClassroomAll: SET_CLASSROOM_ALL,
      setSelectedClassStudent: SET_SELECTED_CLASSROOM_STUDENT,
      setSelectedClassroom: SET_SELECTED_CLASSROOM,
      setDeliveryImgs: SET_DELIVERY_IMGS,
      clearDeliveryImgs: CLEAR_DELIVERY_IMGS,
      setDeliveryImgsName: SET_DELIVERY_IMGS_NAME,
      setDeliveryDetail: SET_DELIVERY_DETAIL,
      setEnqueteDeliveryDetail: SET_ENQUETE_DELIVERY_DETAIL,
      setEnqueteSettings: SET_ENQUETE_SETTINGS,
      setDeliveryPdfs: SET_DELIVERY_PDFS,
      clearDeliveryPdfs: CLEAR_DELIVERY_PDFS,
      setDeliveryPdfsName: SET_DELIVERY_PDFS_NAME,
    }),
    setClass() {
      if (this.isEnquete) {
        return "";
      } else {
        return "contentsArea";
      }
    },
    lineStickerList() {
      return LINE_STICKER
    },
    async fetchStudentByClassId() {
      await this.getStudentByClassId();
    },
    async isEditMode() {
      if (this.isEnquete) {
        // アンケートの場合は「enqueteOpenInfo」の値だけを監視して新規 or 編集なのか判定する
        if (this.enqueteOpenInfo.deliveryInfo) {
          // タブの種別を判定してアンケートタブ、設定タブの値を反映する
          if (this.enqueteTabType == 0) {

            const deliveryInfo = cloneDeep(this.enqueteOpenInfo.deliveryInfo);
            this.deliveryDetail = cloneDeep(deliveryInfo);
            // アンケートタイプがバックエンドから返却される際に文字列数字になっているので数値に変換して入れ直す
            this.deliveryDetail.enqueteType = Number(deliveryInfo.enqueteType); 
            // 既存配信フラグ
            this.isEditModeValue = true;
            
            if (deliveryInfo.targets) {
              this.selectedGrade = deliveryInfo.targets.grade_id;
              this.selectedClass = deliveryInfo.targets.class_id;
              // this.$nextTick(function() {
              //   this.selectedStudent = deliveryInfo.targets.student_id;
              // });
              // await new Promise(resolve => setTimeout(resolve, 10000));
              this.selectedStudent = deliveryInfo.targets.student_id;
            }

            for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
              this.deliveryDetail.messages[i]["id"] = i;

              if (typeof this.deliveryDetail.messages[i].pdfUrl !== 'undefined') {
                // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
                this.inputImageValue[i] = [{ name: 'pdf' }]
                // this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
                this.url[i] = this.deliveryDetail.messages[i]["url"]
              } else if (this.deliveryDetail.messages[i]["type"] == "image") {
                // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
                this.inputImageValue[i] = [{ name: 'image' }]
                // this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
                this.url[i] = this.deliveryDetail.messages[i]["url"]
              }
            }
            // 編集可否フラグを公開状態を見て設定する
            if (this.isUnPublished) {
              // 未公開の場合は編集可
              this.canEdit = true;
            } else {
              // 未公開以外の場合は編集不可
              this.canEdit = false;
            }
            await this.selectedGradesClass();
            await this.fetchStudentByClassId();
            await this.gradeChange();
            await this.classChange();
            this.$nextTick(function() {
              this.selectedStudent = deliveryInfo.targets.student_id;
            });
            return;
          } else if (this.enqueteTabType == 2) {
            // 既存配信フラグ
            this.isEditModeValue = true;
            // 編集可否フラグを公開状態を見て設定する
            if (this.isUnPublished) {
              // 未公開の場合は編集可
              this.canEdit = true;
            } else {
              // 未公開以外の場合は編集不可
              this.canEdit = false;
            }

            const enqueteSettings = cloneDeep(this.enqueteOpenInfo.enqueteSettings);
            this.settings.isLatest = Number(enqueteSettings.isLatest);
            this.settings.isParentNotification = Number(enqueteSettings.isParentNotification);
            this.settings.isStaffNotification = Number(enqueteSettings.isStaffNotification);
            this.settings.isRemind = Number(enqueteSettings.isRemind);
            this.settings.daysBefore = Number(enqueteSettings.daysBefore);
            this.settings.time = enqueteSettings.time;
            this.deliveryDetail.messages = cloneDeep(enqueteSettings.messages);
            return;
          }
        } else {
          // 新規作成時は要素を追加する
          this.deliveryDetail.messages.push({
            id: 0,
            text: "",
            type: "text",
            url: "",
            previewImageUrl: "",
            packageId: "",
            stickerId: "",
            emojis: [],
          });
          return;
        }
      } else {
        // deliveryがnullじゃない場合は既存配信
        if (this.delivery != null) {
          // 既存配信フラグ
          this.isEditModeValue = true;
          // 編集可否フラグ
          this.canEdit = false;
          if (this.item.delivery_status == "RESERVED") {
            //予約配信編集フラグ
            this.isReserve = true
            this.deliveryDetail.oldDetail = this.item
          }
          //delivery_schedule 予約配信の時のみnullじゃない
          if (this.item.delivery_schedule != null) {
            const date = moment(this.item.delivery_schedule).subtract(9, 'h')
            this.deliveryDetail.deliveryTiming = "ONETIME"
            this.deliveryDetail.oneTimeDetail = {
              date: date.format("YYYY-MM-DD"),
              time: date.format("HH:mm")
            }
          }
          // 職員配信の場合、配信区分・配信先をAPIからとってくる
          const response = await this.get_staff_delivery_target(this.item.delivery_id)
          if (response.status == 200){
            if(response.body){
              if ('staff_group' in response.body){
                this.deliveryDetail.staff_group = response.body.staff_group
                this.deliveryDetail.isStaffDelivery = true;
              }
            }
          }
          // propsの値をdetailに入れる
          this.deliveryDetail.deliveryName = this.item.delivery_name;
          this.deliveryDetail.messages = this.item.delivery_contents;
          this.deliveryDetail.segmentType = this.item.delivery_division;
          this.selectedGrade = this.item.target_grade;
          this.selectedClass = this.item.target_class;
          // this.selectedStudent = this.item.target_student;
          this.$nextTick(function() {
            this.selectedStudent = this.item.target_student;
          });
          this.deliveryDetail.flexUseFlg = this.item.flex_use_flg;
          // detail用にIDを振る
          
          for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
            this.deliveryDetail.messages[i]["id"] = i;
            if (this.deliveryDetail.messages[i]["type"] == "image") {
              // imageMsgの場合、detailとurl管理用の配列にURLを持たせなおす
              this.inputImageValue[i] = [{ name: 'image' }]
              this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i]["originalContentUrl"]
              this.url[i] = this.delivery.delivery_contents[i]["originalContentUrl"]
            } else if (this.deliveryDetail.messages[i]["type"] == "flex" && this.delivery?.delivery_contents?.[i]?.contents?.body?.contents?.[0]?.url != null)  {
              // pdfMsgの場合、detailとurl管理用の配列にURLを持たせなおす(DBに保存されたjsonオブジェクトを参照)
              this.inputImageValue[i] = [{ name: 'pdf' }]
              this.deliveryDetail.messages[i]["url"] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
              this.deliveryDetail.messages[i]["pdfUrl"] = this.delivery.delivery_contents[i].contents.body.contents[0].action.uri;
              const aspectRatio = this.delivery.delivery_contents[i].contents.body.contents[0].aspectRatio;
              const splitAspectRatio = aspectRatio.split(':');
              this.deliveryDetail.messages[i]["imgWidth"] = splitAspectRatio[0];
              this.deliveryDetail.messages[i]["imgHeight"] = splitAspectRatio[1];
              this.url[i] = this.delivery.delivery_contents[i].contents.body.contents[0].url;
              // console.log('deliveryDetail', this.deliveryDetail)
            } else if (this.deliveryDetail.messages[i]["type"] == "flex") {
              // FlexMsgの場合、flexMessageキーを追加してcontentsを入れる
              this.deliveryDetail.messages[i].flexMessage = this.deliveryDetail.messages[i].contents;
            }
          }
          // inputImageValue[message.id]
          await this.selectedGradesClass();
          await this.fetchStudentByClassId();
          // this.selectedStudent = this.item.target_student;
          await this.gradeChange();
          await this.classChange();
          this.$nextTick(function() {
            this.selectedStudent = this.item.target_student;
          });
        } else {
          // 新規作成時は要素を追加する
          this.deliveryDetail.messages.push({
            id: 0,
            text: "",
            type: "text",
            url: "",
            previewImageUrl: "",
            packageId: "",
            stickerId: "",
            emojis: [],
          });
        }
      }
    },
    selectText(index) {
      if (this.deliveryDetail.flexUseFlg) {
        this.deliveryDetail.messages[index].type = 'flex';
      } else {
        this.deliveryDetail.messages[index].type = 'text';
      }
    },
    validateFile(file) {
      if (!file) return "ファイルがありません。";
      return true;
    },
    async del() {
      this.loading = true
      await this.setDeliveryDetail(this.deliveryDetail);
      const res = await this.deleteDelivery();
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = '配信の削除に失敗しました。ページを再読み込みしてください。'
      } else {
        this.$router.push({ name: 'DeliveryList' })
      }
      this.loading = false
    },
    async send() {
      console.log('配信送信メソッド')
      console.log(this.deliveryDetail)

      this.loading = true
      if (this.isdeliveryScheduleAfterNow() == false) {
        this.loading = false
        return null
      }
      switch (this.deliveryDetail.segmentType) {
        case "SCHOOL":
          this.deliveryDetail.targets = ""
          break;
        case "GRADE":
          this.deliveryDetail.targets = {
            "grade_id" : this.selectedGrade,
            "class_id" : "",
            "student_id" : "",
          }

          break;
        case "CLASS":
          this.deliveryDetail.targets = {
            "grade_id" : this.selectedGrade,
            "class_id" : this.selectedClass,
            "student_id" : "",
          }
          break;
        case "PERSONAL":
          this.deliveryDetail.targets = {
            "grade_id" : this.selectedGrade,
            "class_id" : this.selectedClass,
            "student_id" : this.selectedStudent
          }
          break;
        default:
          break;
      }
      
      await this.saveDeliveryImgToS3()
      await this.saveDeliveryPdfToS3()
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (i in (this.deliveryImgs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryImgs[i]['url'] != '' || this.deliveryImgs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].url = this.deliveryImgs[i].imgUrl
          }
        }
        if (i in (this.deliveryPdfs)) {
          if (this.deliveryDetail.messages[i].type == 'image' && (this.deliveryPdfs[i]['url'] != '' || this.deliveryPdfs[i]['url'] != undefined)) {
            this.deliveryDetail.messages[i].pdfUrl = this.deliveryPdfs[i].imgUrl
            this.deliveryDetail.messages[i].imgWidth = this.deliveryImgs[i].imgWidth
            this.deliveryDetail.messages[i].imgHeight = this.deliveryImgs[i].imgHeight
          }
        }
      }
      this.setDeliveryDetail(this.deliveryDetail)
      let res = null
      if (this.isReserve == false) {
        console.log('配信を送信します')
        res = await this.sendDelivery();
        console.log('配信の送信を終えました')
      } else if (this.isReserve == true) {
        res = await this.updateDelivery();
      }
      if (res.statusCode != 200) {
        this.snackbar = true
        this.snackbarText = '配信の登録に失敗しました。ページを再読み込みしてください。'
      } else {
        this.$router.push({ name: 'DeliveryList' })
      }
      this.loading = false
    },
    serchImgUrl(packageId, stickerId) {

      const targetPackageId = packageId
      const targetStickerId = stickerId

      const targetPackage = LINE_STICKER.filter(
        linePackage => {
          return linePackage.packageId == targetPackageId;
        }
      );

      const targetStciker = targetPackage[0].child.filter(
        lineSticker => {
          return lineSticker.stickerId == targetStickerId;
        }
      )

      return targetStciker[0].url
    },
    deleteTextArea(id, index) {
      this.deliveryDetail.messages = this.deliveryDetail.messages.filter(
        messages => {
          return messages["id"] != id;
        }
      );
      this.emojisPositionInfo = this.emojisPositionInfo.filter(
        emojiPositionInfo => {
          return emojiPositionInfo["id"] != id;
        }
      );
      this.setDeliveryImgs(this.deliveryImgs.splice(index, 1));
    },
    addMessage() {
      this.positionInfo += 1;
      this.deliveryDetail.messages.push({
        id: this.positionInfo,
        text: "",
        type: this.deliveryDetail.flexUseFlg ? "flex" : "text",
        url: "",
        previewImageUrl: "",
        packageId: "",
        stickerId: "",
        emojis: [],
      });
      this.emojisPositionInfo.push({
        text: "",
        emojis: [],
      });
    },
    uploadImage(index) {
      this.$refs.preview[index].click();
    },
    onChangeTextMsg(text, position) {
      for (let i = 0; i < this.deliveryDetail.messages.length; i++) {
        if (this.deliveryDetail.messages[i].id === position) {
          this.deliveryDetail.messages[i].text = text;
        }
      }
    },
    async compressImage(file) {
      //画像圧縮
      // const maxSize = 1024;
      const quality = 1.0;
      const MAX_IMG_SIZE = 500000; // inputされた画像はここまで圧縮されます 100000 = 100KB
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          let width = image.width;
          let height = image.height;

          // // 画像サイズをリサイズ
          // if (width > height) {
          //   if (width > maxSize) {
          //     height *= maxSize / width;
          //     width = maxSize;
          //   }
          // } else {
          //   if (height > maxSize) {
          //     width *= maxSize / height;
          //     height = maxSize;
          //   }
          // }

          canvas.width = width;
          canvas.height = height;

          // 画像をcanvasに描画
          ctx.drawImage(image, 0, 0, width, height);

          // canvasからデータURLを取得し、JPEG形式で圧縮
          let dataURL = canvas.toDataURL('image/jpeg', quality);

          // 圧縮された画像のファイルサイズを取得
          let compressedFileSize = Math.round(dataURL.length * 0.75);
          // console.log('初回の画像サイズ', compressedFileSize)

          // 200KBより大きかったらさらに縮小
          let i = 1
          // console.log('MAX_IMG_SIZE', MAX_IMG_SIZE)
          // console.log('typeof(MAX_IMG_SIZE)', typeof(MAX_IMG_SIZE))
          // if (compressedFileSize > MAX_IMG_SIZE) {
            // console.log('大きい')
          // } else {
            // console.log('最初から小さい')
          // }
          if (compressedFileSize > MAX_IMG_SIZE) {
            const n = MAX_IMG_SIZE / compressedFileSize
            dataURL = canvas.toDataURL('image/jpeg', n.toFixed(1));
            compressedFileSize = Math.round(dataURL.length * 0.75);
            // console.log(0 + '回目のサイズ : ', compressedFileSize)
          }
          if (compressedFileSize > MAX_IMG_SIZE) {
            while (i > 0) {
              width = 0.85 * width;
              height = 0.85 * height;
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(image, 0, 0, width, height);
              dataURL = canvas.toDataURL('image/jpeg', 0.8);
              compressedFileSize = Math.round(dataURL.length * 0.75);
              // console.log(i + '回目のサイズ : ', compressedFileSize)
              i++;
              if (compressedFileSize < MAX_IMG_SIZE) {
                break;
              }
            }
            // console.log('最終サイズ : ', compressedFileSize)
          }
          // Promiseを解決して、圧縮された画像データを返す
          resolve({
            dataURL: dataURL,
            width: width,
            height: height,
          });
        };

        image.onerror = function () {
          reject('画像の読み込みに失敗しました。');
        };
      });
    },
    async inputImage(id, index) {
      this.imageLoading = true;
      this.image[id] = undefined;
      if (this.inputImageValue[id]) {
        const isPdf = (this.getExtByFileName(this.inputImageValue[id].name) === 'pdf');
        if (isPdf) { // 選択されたファイルがPDF

          this.setDeliveryPdfs({
            index: index,
            img: {
              imgObj: this.inputImageValue[id],
              imgExe: '.pdf',
              imgUrl: ''
            }
          })

          // PDFファイルデータをArrayBuffer型で取得
          const fileData = await this.readFileAsync(this.inputImageValue[id])

          // PDFファイルのパース
          const pdf = await PDFJS.getDocument({
            data: fileData,
            cMapUrl: '/cmaps/',
            cMapPacked: true,
          }).promise

          // 1ページ目をcanvasにレンダリング
          const page = await pdf.getPage(1)
          const canvas = document.createElement('canvas')
          const viewport = page.getViewport({ scale: 5 })
          canvas.height = viewport.height
          canvas.width = viewport.width
          const context = canvas.getContext('2d')
          const task = page.render({
            canvasContext: context,
            viewport: viewport,
          })
          await task.promise

          // canvasにレンダリングされた画像をファイル化
          const base64 = canvas.toDataURL('image/png')
          const tmp = base64.split(',')
          const data = atob(tmp[1])
          const mime = tmp[0].split(':')[1].split(';')[0]
          const buf = new Uint8Array(data.length)
          for (let i = 0; i < data.length; i++) {
            buf[i] = data.charCodeAt(i)
          }
          const blob = new Blob([buf], { type: mime })
          const imageFile = new File([blob], 'image.png', {
            lastModified: new Date().getTime(),
          })

          this.image[id] = imageFile;
        } else { // 選択されたファイルがPNG,JPG,JPEG
          this.image[id] = this.inputImageValue[id];
        }

        // ファイルサイズを小さくする
        const compressImg = (await this.compressImage(this.image[id]));
        // ストアに登録(画像配信用)
        const blob = this.dataURLtoBlob(compressImg.dataURL);
        this.setDeliveryImgs({
          index: index,
          img: {
            imgObj: blob,
            imgExe: ('.' + (this.image[id].name).split('.').pop()),
            imgUrl: '',
            imgWidth: Math.round(compressImg.width),
            imgHeight: Math.round(compressImg.height)
          }
        })

        // preview用のURLをセット
        this.deliveryDetail.messages[index].previewImageUrl = URL.createObjectURL(this.image[id])
        this.previewImage(blob, id);

      } else if (this.url != "" && this.url != "None") {
        const dataUrl = (await this.compressImage(this.image[id])).dataURL;
        this.image[id] = URL.createObjectURL(dataUrl);
      } else {
        this.url = "";
      }
      this.imageLoading = false;
    },
    getExtByFileName(fileName) {
      // 最後のドットの位置を取得
      const lastDotPosition = fileName.lastIndexOf('.');

      // ドットが見つかった場合
      if (lastDotPosition !== -1) {
        // ドット以降の文字列を取得（拡張子）
        const extension = fileName.slice(lastDotPosition + 1);
        return extension
      } else {
        return ''
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    },
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async previewImage(blob, id) {
      this.url[id] = URL.createObjectURL(blob);
      this.$forceUpdate();
    },
    selectedGradesClass() {
      this.classroomItems = this.gradeClassAll['class'].filter(a => a["grade_id"] === this.selectedGrade)
      if (this.canEdit == true) {
        this.selectedStudent = -1
      }
    },
    async changeSelectedClass() {
      await this.setSelectedClassroom({
        class_id: this.selectedClass,
        grade_id: this.selectedGrade
      });
      if (this.deliveryDetail.segmentType === "PERSONAL") {
        this.fetchStudentByClassId();
      }
      if (this.canEdit == true, this.canEdit) {
        
        this.selectedStudent = -1
      }
    },
    isdeliveryScheduleAfterNow() {
      if (this.deliveryDetail.deliveryTiming == 'ONETIME') {
        return moment(this.deliveryScheduleText).isAfter(moment())
      }
      return true
    },
    required(str) {
      return value => !!value && !this.isSpace(value) || str + REQUIRED_ERROR_MSG;
    },
    requiredRadio(str) {
      return v => this.segmentTypes.some(item => item.value === v) && v != null && v != undefined && v != '' || str + 'は必須です。';
    },
    requiredRadioGrade(str) {
      return v => this.gradeClassAll.grade.some(item => item.grade_id === v) && v != null && v != undefined && v != '' || str + 'は必須です。';
    },
    requiredRadioClass(str) {
      return v => this.classroomItems.some(item => item.classroom_id === v) && v != null && v != undefined && v != '' || str + 'は必須です。';
    },
    isSpace(v) {
      v = String(v)
      if(v == undefined || v == null){
        return true
      }
      for (let i = 0; i < v.length; i++) {
        const code = v.charCodeAt(i);
        if (code === 0x3000) { // Unicodeのコードポイントを使って全角スペースを判定
          continue; // 全角スペースの場合はループを継続
        }
        if (code === 0x20) { // 半角スペースの場合はループを継続
          continue;
        }
        return false; // それ以外の文字が含まれている場合はfalseを返す
      }
      return true; // 全角スペースか半角スペースしか含まれていない場合はtrueを返す
    },
    onChangeTextMessage(value, index) {
      const { text, emojis } = value.positionInfo;
      const positionInfo = cloneDeep(this.deliveryDetail.messages[index]);
      
      positionInfo.text = text;
      positionInfo.emojis = emojis.map((emoji) => {
        const parsedIds = parseProductIdEmojiIdFromUrlOrFalse(emoji.path) || { productId: '', emojiId: ''};
        return {
          index: emoji.index,
          productId: parsedIds.productId,
          emojiId: parsedIds.emojiId,
        }
      });
      // メッセージに反映
      this.$set(this.deliveryDetail.messages, index, positionInfo);
      // 絵文字のリストに反映
      this.$set(this.emojisPositionInfo, index, positionInfo);
    },
    onChangeMarkdownMessage(value, index) {
      // マークダウンとフレックスメッセージを取得
      const markdown = value.markdown;
      const flexMessage = value.flexMessage;
      // 現在のメッセージを取得
      let message = cloneDeep(this.deliveryDetail.messages[index]);
      // 更新されたマークダウンとフレックスメッセージをセット
      message.text = markdown;
      // テキストが入力されているかチェック
      if (markdown) {
        message.flexMessage = flexMessage;
      } else {
        // 空の場合は、flexMessageにからオブジェクトを渡す
        message.flexMessage = {};
      }
      // メッセージに反映
      this.$set(this.deliveryDetail.messages, index, message);
    },
    showDialog() {
      this.isShowDialog = this.deliveryDetail.flexUseFlg;
    },
    closeDialog() {
      this.isShowDialog = false;
      this.deliveryDetail.flexUseFlg = false;
      this.$forceUpdate();
    },
    onChangeDecorationMode() {
      // ダイアログ閉じる
      this.isShowDialog = false;
      // 入力内容を破棄する
      this.deliveryDetail.messages = [];
      // 新しい要素を追加する
      this.deliveryDetail.messages.push({
        id: 0,
        text: "",
        type: "flex",
        url: "",
        previewImageUrl: "",
        packageId: "",
        stickerId: "",
        emojis: [],
        flexMessage: {},
      });
    },
    stickerClick(messageIndex, packageId, stickerId) {
      const message = this.deliveryDetail.messages[messageIndex];
      message.packageId = packageId;
      message.stickerId = stickerId;
    },
    // isUnPublished() {
    //   // 現在時刻の日付部分のみを取得
    //   const now = new Date();
    //   const dateOnly = new Date(now.toDateString());
    //   // 公開開始日の日付部分のみを取得
    //   const deliveryInfo = cloneDeep(this.enqueteOpenInfo.deliveryInfo);
    //   const startDate = new Date(deliveryInfo.startDate);
    //   const startDateOnly = new Date(startDate.toDateString());

    //   // 公開開始日が現在時刻より前の日付であるかを比較
    //   return startDateOnly < dateOnly;
    // },
  },
  async created() {
    if (!(this.isEnquete == true && this.enqueteTabType == 2)){
      await this.getGradeClassAll();
    }

    this.delivery = cloneDeep(this.item)

    const authority_id = this.loginUser.authority;

    // 全権管理者か管理者なら全ての絞り込みが可能
    if (authority_id === 1 || authority_id === 2) {
      this.segmentTypes = [
        {
          "label": "全て",
          "value": "SCHOOL"
        },
        {
          "label": "学年",
          "value": "GRADE"
        },
        {
          "label": "組",
          "value": "CLASS"
        },
        {
          "label": "生徒",
          "value": "PERSONAL"
        },
      ];
      this.deliveryDetail.segmentType = "SCHOOL"

      // 学年主任なら担当学年の絞り込みのみ可能
    } else if (authority_id === 3) {
      this.segmentTypes = [
        {
          "label": "学年",
          "value": "GRADE"
        },
        {
          "label": "組",
          "value": "CLASS"
        },
        {
          "label": "生徒",
          "value": "PERSONAL"
        },
      ];
      this.deliveryDetail.segmentType = "GRADE"

      // 担任なら担当クラスの絞り込みのみ可能
    } else if (authority_id === 4) {
      this.segmentTypes = [
        {
          "label": "組",
          "value": "CLASS"
        },
        {
          "label": "生徒",
          "value": "PERSONAL"
        },
      ];
      this.deliveryDetail.segmentType = "CLASS"
    }
  },
  async mounted() {
    this.loading = true;
    const res = await this.getStaffGroupAll();
    if (res.body == '200') {
      this.allStaffGroupList = res.result.filter(obj => obj.is_delete != true);
    }
    if (!(this.isEnquete == true && this.enqueteTabType == 2)){
      await this.getGradeClassAll();
      this.clearDeliveryImgs();
      this.clearDeliveryPdfs();
    }
    this.loading = true;
    await this.isEditMode();
    this.loading = false;
  }
};
</script>
<style scoped>
.radioFlex .v-input--radio-group__input {
  display: flex;
  flex-direction: row;
}

.radioFlex .v-input--radio-group__input>* {
  min-width: initial;
  max-width: 150px;
  width: initial;
  margin-bottom: 8px;
  margin-right: 24px;
}

.radioFlex.alot .v-input--radio-group__input>* {
  min-width: 100px;
  max-width: 100px;
  width: 100%;
  margin-bottom: 8px;
  margin-right: 24px;
}

.stickerListArea {
  overflow: auto !important;
}

.stickerListArea::-webkit-scrollbar {
  display: none !important;
}

.selectStickerArea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  /* -webkit- transform: translateY(-50%) translateX(-50%); */
}

.message-preview {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  z-index: 11;
}
.fixed-preview {
  position: fixed;
  top: 60px;
}

.preview-row {
  width: fit-content;
  margin-left: 1em;
}
.line-preview-box {
  border-radius: 0.25em;
  background-color: #688bbc;
  width: 100%;
  height: auto;
}
.line-preview-elements {
  display: block;
  justify-content: center;
  overflow: auto;
  overflow-y: auto;
}
.fit-content-width {
  width: fit-content;
}
</style>